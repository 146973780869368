import React, { FC } from 'react';
import { config } from '@grafana/runtime';
import './css/alarmThreshold.css';

type TextProps = { text: string; enable: boolean; };

export const Text: FC<TextProps> = ({ text, enable }) => {
  const isDark = config.theme.isDark ? '_dark' : '';
  const color = (enable && text !== 'Cualquiera' && text !== 'Todas' && text !== 'Ninguna' && text !== '-') ? '' : '_shadow';

  return (
	<div className={'alertGroupTags'}>
	  <span className={'alertGroupText' + color + isDark}>{text}</span>
	</div>
  );
};

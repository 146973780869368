import { TableCellDisplayMode, TableSortByFieldState } from '@grafana/ui';
import { TableFieldOptions } from '@grafana/schema';

export const modelVersion = Object.freeze([1, 0]);

export interface PanelOptions {
  frameIndex: number;
  showHeader: boolean;
  showtoolbar: boolean;
  showFooterInfo: boolean;
  rowColorEnable: boolean;
  rowColorCell: number;
  description: 'Seleccione una función',
  showRowNumber: boolean;
  showTypeIcons?: boolean;
  sortBy?: TableSortByFieldState[];
  footer?: TableFooterCalc;
}

export interface TableFooterCalc {
  show: boolean;
  reducer: string[];
  fields?: string[];
  enablePagination?: boolean;
}

export const defaultPanelOptions: PanelOptions = {
  frameIndex: 0,
  showFooterInfo: false,
  showToolbar: false,
  hideTable: false,
  showHeader: true,
  showTypeIcons: false,
  rowColorEnable: false,
  rowColorCell: 1,
  footer: {
    show: false,
    reducer: [],
  },
};

export const defaultPanelFieldConfig: TableFieldOptions = {
  displayMode: TableCellDisplayMode.Auto,
  align: 'auto',
  width: 'auto',
  inspect: false,
};

import React, { FC } from 'react';
import { config } from '@grafana/runtime';
import './css/contactManager.css';

type TextProps = { text: string; };

export const Text: FC<TextProps> = ({ text }) => {
  const isDark = config.theme.isDark ? '_dark' : '';
  const color = (text !== 'Cualquiera' && text !== 'Todas' && text !== 'Ninguna' && text !== '-') ? '' : '_shadow';
  return (
	<div className={'contactManagerTags'}>
	  <span className={'contactManagerText' + color + isDark}>{text}</span>
	</div>
  );
};
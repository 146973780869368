import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import { LinkInspectorData } from 'types';
import { Icon } from '@grafana/ui';
import './css/LinkInspector.css';

interface LinkDescriptorRadioProps {
  id: string;
  link: LinkInspectorData[];
  width: number;
  height: number;
  lineWidth: number;
  fontSize: number;
  iconSize: number;
  metricUnit: string;
}

export class LinkDescriptorRadio extends Component<LinkDescriptorRadioProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
	const linkInfo = this.props.link;
    const isDark = config.theme.isDark || false;
	const fontColor = isDark ? '#E6E9ED' : '#23282E';
    const okColor = isDark ? '#936BFF' : '#5D2BE9';
    const warningColor = isDark ? '#D9AF27' : ' #FF7F27';
	const alarmColor = isDark ? '#F74545' : '#FB3333';
	const sfpCage = isDark ? '#58A95680' : '#D8DFE9';
    const markColor = isDark ? '#E6E9ED' : '#44444C';
    const shadowColor = isDark ? '#5A6171' : '#646A73';
    const lineWidth = this.props.lineWidth;
    const totalWidth = this.props.width;
	const totalHeight = this.props.height;
	const rectWidth = [110, 110, 35, 35, 30, 30, 30, 30, 30, 30];
	const rectHeight = [100, 100, 80, 80, 30, 30, 30, 30, 30, 30];
	const odfType = isDark ? [
      { icon: 'parabolic-antenna', value: 'RE-LC', color: '#557FFF', label: 'Radio con Interface óptica [LC]' },
      { icon: 'parabolic-antenna', value: 'RE', color: '#E6E9ED', label: 'Radio con Interface RJ45' },
      { icon: 'parabolic-antenna', value: 'RE-UTP', color: '#E6E9ED', label: 'Radio con Interface RJ45' }
    ] :	[
      { icon: 'parabolic-antenna', value: 'RE-LC', color: '#6C63FE', label: 'Radio con Interface óptica [LC]' },
      { icon: 'parabolic-antenna', value: 'RE', color: '#E6E9ED', label: 'Radio con Interface RJ45' },
      { icon: 'parabolic-antenna', value: 'RE-UTP', color: '#E6E9ED', label: 'Radio con Interface RJ45' }
    ];

    const odfTypeA = odfType.find(types => {
      return types.value === linkInfo.odfTypeA; 
    });
    const odfTypeB = odfType.find(types => {
      return types.value === linkInfo.odfTypeB;
    });
	const icons = [
	  linkInfo.assetTypeA,
	  linkInfo.assetTypeB,
	  '',
	  '',
	  'arrow-to-right',
	  odfTypeA.icon + '-a',
	  'arrow-from-right',
	  'left-arrow-from-left',
	  odfTypeB.icon + '-b',
	  'left-arrow-to-left'
	];
	const legends = [
	  linkInfo.assetA,
	  linkInfo.assetB,
	  linkInfo.moduleTypeA,
	  linkInfo.moduleTypeB,
	  '',
	  totalWidth > 795 ? linkInfo.odfA1 : '',
	  '',
	  '',
	  totalWidth > 795 ? linkInfo.odfB1 : '',
	  ''
	];

    const radioColorA = linkInfo.linkAdminA ? sfpCage : alarmColor;
	const radioColorB = linkInfo.linkAdminB ? sfpCage : alarmColor;
	const rectColors = isDark ?
	  ['#5A6171', '#5A6171', radioColorA, radioColorB, '#D9AF27', odfTypeA.color, '#E5CD68', '#E5CD68', odfTypeB.color, '#D9AF27'] :
	  ['#F4F9FF', '#F4F9FF', radioColorA, radioColorB, '#FF7F27', odfTypeA.color, '#FFC530', '#FFC530', odfTypeB.color, '#FF7F27'];

	const lineAColor = linkInfo.linkStatusA && linkInfo.linkStatusB ? okColor : alarmColor;
    const lineBColor = linkInfo.linkStatusA && linkInfo.linkStatusB ? okColor : alarmColor;

    const rectPositionsX = totalWidth > 1100 ? [
	  120, totalWidth - 170, 120, totalWidth - 170, 120, 370, totalWidth - 170, 120, totalWidth - 420, totalWidth - 170
	] : [
	  120, totalWidth - 170, 120, totalWidth - 170, 120, 220, totalWidth - 170, 120, totalWidth - 270, totalWidth - 170
	];
	const rectPositionsY = [30, 30, 35, 35, 40, 60, 40, 75, 60, 75];
	const convertUnit = (kbps) => {
	  if (kbps >= 1000000000) {
		const tbps = kbps / 1000000000;
		return `${tbps.toFixed(0)} Tbps`;
	  } else if (kbps >= 1000000) {
		const gbps = kbps / 1000000;
		return `${gbps.toFixed(0)} Gbps`;
	  } else if (kbps >= 1000) {
		const mbps = kbps / 1000;
		return `${mbps.toFixed(0)} Mbps`;
	  } else {
		return `${kbps} Kbps`;
	  }
	};

	const radioLossA = -(linkInfo.radioDataB[2] - linkInfo.radioDataA[1]);
	const radioLossB = -(linkInfo.radioDataA[2] - linkInfo.radioDataB[1]);
	const radioLossTextA = (Math.round(radioLossA * 100) / 100).toFixed(2);
	const radioLossTextB = (Math.round(radioLossB * 100) / 100).toFixed(2);
	const ssrA = linkInfo.radioDataA.length > 3 ? linkInfo.radioDataA[4] : 0;
	const ssrB = linkInfo.radioDataB.length > 3 ? linkInfo.radioDataB[4] : 0;
	const ssrTextA = (Math.round(ssrA * 100) / 100).toFixed(2);
	const ssrTextB = (Math.round(ssrB * 100) / 100).toFixed(2);
	const rxWarningA = linkInfo.ddmRxA < linkInfo.ddmWlA ? warningColor : fontColor;
	const rxAlarmA = (linkInfo.ddmRxA < linkInfo.ddmAlA || !linkInfo.linkAdminA) ? alarmColor : rxWarningA;
	const rxWarningB = linkInfo.ddmRxB < linkInfo.ddmWlB ? warningColor : fontColor;
	const rxAlarmB = (linkInfo.ddmRxB < linkInfo.ddmAlB || !linkInfo.linkAdminB) ? alarmColor : rxWarningA;

	const isUTPA = (linkInfo.odfTypeA === 'RE' || linkInfo.odfTypeA === 'RE-UTP') ? true : false;
	const isUTPB = (linkInfo.odfTypeB === 'RE' || linkInfo.odfTypeB === 'RE-UTP') ? true : false;
	const ddmTxA = (Math.round(linkInfo.ddmTxA * 100) / 100).toFixed(2);
	const ddmRxA = (Math.round(linkInfo.ddmRxA * 100) / 100).toFixed(2);
	const ddmTxB = (Math.round(linkInfo.ddmTxB * 100) / 100).toFixed(2);
	const ddmRxB = (Math.round(linkInfo.ddmRxB * 100) / 100).toFixed(2);

	const tooltips = isUTPA || isUTPB ? [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  linkInfo.assetTypeB + ' ' + linkInfo.assetB,
	  `Puerto ${linkInfo.portA} - ${linkInfo.moduleTypeA}`,
	  `Puerto ${linkInfo.portB} - ${linkInfo.moduleTypeB}`,
	  `Estado Administrativo: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  odfTypeA.label,
	  `Estado Operativo: ${linkInfo.linkStatusA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Estado Operativo: ${linkInfo.linkStatusB ? 'Habilitado' : 'Deshabilitado'}`,
	  odfTypeB.label,
	  `Estado Administrativo: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	] : [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  linkInfo.assetTypeB + ' ' + linkInfo.assetB,
	  `Puerto ${linkInfo.portA} - ${linkInfo.moduleTypeA}`,
	  `Puerto ${linkInfo.portB} - ${linkInfo.moduleTypeB}`,
	  `Tx Optical power: ${ddmTxA} ${this.props.metricUnit}`,
	  odfTypeA.label,
	  `Rx Optical power: ${ddmRxB} ${this.props.metricUnit}`,
	  `Rx Optical power: ${ddmRxA} ${this.props.metricUnit}`,
	  odfTypeB.label,
	  `Tx Optical power: ${ddmTxA} ${this.props.metricUnit}`
	];
	const OriginInfoRadio = linkInfo.radioDataA.length > 2 ? [
	    `${linkInfo.odfA1} ▶ ${linkInfo.odfB1}`,
	    `Frecuencia: ${linkInfo.radioDataA[0]} GHz`,
	    `Rx Power: ${linkInfo.radioDataA[1]} dBm`,
	    `Tx Power: ${linkInfo.radioDataA[2]} dBm`,
	    `Rango: ${linkInfo.radioDataA[3]} m`
	  ] : [];
	const DestinationInfoRadio = linkInfo.radioDataB.length > 2 ? [
	    `${linkInfo.odfA1} ◀ ${linkInfo.odfB1}`,
	    `Frecuencia: ${linkInfo.radioDataB[0]} GHz`,
	    `Rx Power: ${linkInfo.radioDataB[1]} dBm`,
	    `Tx Power: ${linkInfo.radioDataB[2]} dBm`,
	    `Rango: ${linkInfo.radioDataB[3]} m`
	  ] : [];
	const OriginInfoUTP = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`,
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const DestinationInfoUTP = linkInfo.linkAdminB ? [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedB)}`,
	] : [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	];
	const OriginInfo = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`,
	  `λ: ${linkInfo.moduleLambdaA} nm`,
	  `Tx Optical power: ${ddmTxA} ${this.props.metricUnit}`,
	  `Rx Optical power: ${ddmRxA} ${this.props.metricUnit}`,
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const DestinationInfo = linkInfo.linkAdminB ? [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedB)}`,
	  `λ: ${linkInfo.moduleLambdaB} nm`, 
	  `Tx Optical power: ${ddmTxB} ${this.props.metricUnit}`,
	  `Rx Optical power: ${ddmRxB} ${this.props.metricUnit}`
	] : [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	];
	const OriginTrafficInfo = linkInfo.linkAdminA ? [
	  `${linkInfo.assetA} ▶`,
	  `Unicast: ${linkInfo.uPaquetsA} Pkts`,
	  `NonUnicast: ${linkInfo.nuPaquetsA} Pkts`,
	  `Error: ${linkInfo.errorPaquetsA} Pkts`,
	] : [];
	const DestinationTrafficInfo = linkInfo.linkAdminB ? [
	  `◀ ${linkInfo.assetB}`,
	  `Unicast: ${linkInfo.uPaquetsB} Pkts`,
	  `NonUnicast: ${linkInfo.nuPaquetsB} Pkts`,
	  `Error: ${linkInfo.errorPaquetsB} Pkts`,
	] : [];

	const OriginLldpInfo = linkInfo.linkAdminA && linkInfo.lldpDataA.length > 2 ? [
	  `LLDP ▶ ${linkInfo.assetB}`,
	  `Activo Remoto: ${linkInfo.lldpDataB[0]}`,
	  `Interface: ${linkInfo.lldpDataB[1]}`,
	  `Descripción: ${linkInfo.lldpDataB[2]}`,
	] : [];
	const DestinationLldpInfo = linkInfo.linkAdminB && linkInfo.lldpDataB.length > 2 ? [
	  `${linkInfo.assetA} ◀ LLDP`,
	  `Activo Remoto: ${linkInfo.lldpDataA[0]}`,
	  `Interface: ${linkInfo.lldpDataA[1]}`,
	  `Descripción: ${linkInfo.lldpDataA[2]}`,
	] : [];

	const trafficAlarmA = linkInfo.errorPaquetsA > linkInfo.inPaquetsA * 0.01 ? warningColor : fontColor;
	const trafficAlarmB = linkInfo.errorPaquetsB > linkInfo.inPaquetsB * 0.01 ? warningColor : fontColor;
	const radioColors = isDark ? ['#D9AF27', '#58A956', '#557FFF', '#673D39'] : ['#FF7F27', '#24AB00', '#6C63FE', '#562236'];

	const lldpAlarmA = (linkInfo.lldpDataA[0] !== linkInfo.assetB || linkInfo.lldpDataA[1] !== linkInfo.portB) ?
	  alarmColor : fontColor;
	const lldpAlarmB = (linkInfo.lldpDataB[0] !== linkInfo.assetA || linkInfo.lldpDataB[1] !== linkInfo.portA) ?
	  alarmColor : fontColor;

    return (
      <svg width={totalWidth} height={totalHeight}>
        {/* Línea de Radio */}
		<line
		  x1={rectPositionsX[3] + rectWidth[3] / 2}
		  y1={rectPositionsY[3] + (rectHeight[3] / 2)}
		  x2={rectPositionsX[8] + rectWidth[8] / 2}
		  y2={rectPositionsY[8] + (rectHeight[8] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={rectPositionsX[2] + rectWidth[2] / 2}
		  y1={rectPositionsY[2] + (rectHeight[2] / 2)}
		  x2={rectPositionsX[5] + rectWidth[5] / 2}
		  y2={rectPositionsY[5] + (rectHeight[5] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={rectPositionsX[5] + rectWidth[5] / 2}
		  y1={rectPositionsY[5] + (rectHeight[5] / 2)}
		  x2={(totalWidth * 0.5) + 5}
		  y2={rectPositionsY[5] + (rectHeight[5] / 2) + 5}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={(totalWidth * 0.5) + 5}
		  y1={rectPositionsY[5] + (rectHeight[5] / 2) + 5}
		  x2={(totalWidth * 0.5) - 5}
		  y2={rectPositionsY[8] + (rectHeight[8] / 2) - 5}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={(totalWidth * 0.5) - 5}
		  y1={rectPositionsY[8] + (rectHeight[8] / 2) - 5}
		  x2={rectPositionsX[8] + rectWidth[8] / 2}
		  y2={rectPositionsY[8] + (rectHeight[8] / 2)}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		{/* Elementos */}
        {rectWidth.map((width, index) => {
		  const iconX = index === 1 ? (width * 0.4) : 2;
		  const rectX = index === 1 || index === 3 ? -2 : 0;
		  return (
            <g key={'I_' + index}>
		      <rect
                x={index === 0 || index === 2 ? (rectPositionsX[4] + rectWidth[4] - width + 2) : rectPositionsX[index] + rectX}
                y={rectPositionsY[index]}
				rx={width * 0.05 > 2 ? width * 0.05 : 2}	
                width={width}
                height={rectHeight[index]}
                fill={rectColors[index]}
				stroke={shadowColor}
				stroke-width="1"
              />
              {icons[index] !== '' && (
			    <image
                  href={`/public/img/icons/unicons/${icons[index]}.svg`}
                  x={index === 0 ? (rectPositionsX[4] + rectWidth[4] - width) : rectPositionsX[index] + iconX}
                  y={rectPositionsY[index] + 5}
				  style={isDark && index < 2 ? { filter: 'invert(1)' } : { filter: 'none' }}
				  width={index < 2 ? width * 0.6 : width - 4}
                  height={index < 2 ? rectHeight[index] * 0.6 : rectHeight[index] - 8}
                />
			  )}
			  <title>{tooltips[index]}</title>
		    </g>
		  );
        })}
		{/* Testo de leyendas */}
		<g>
		  {legends.map((legend, index) => {
            const isTop = (index === 0 || index === 1) ? true : false;
            const isLeft = (index === 2 || index === 6 || index === 8 || index === 9) ? true : false; 
			const yGap = (index === 5 || index  === 8) ? 10 : 0;
			const textY = isTop ? 20 : (rectPositionsY[index] + rectHeight[index] + yGap);
            let textX = isTop ? (rectPositionsX[index] + (rectWidth[index] / 2)) : (rectPositionsX[index] + rectWidth[index] + 5);
            let textAnchor = isTop ? 'middle' : 'start';
			if (isLeft) {
			  textX = index === 2 ? (rectPositionsX[index] - 10) : ((rectPositionsX[index]) - 5);
			  textAnchor = 'end';
			}
            return (
              <text
                key={index}
                x={index === 0 ? (rectPositionsX[4] + rectWidth[2] - (rectWidth[0] / 2)) : textX}
                y={textY}
                textAnchor={textAnchor}
                fill={fontColor}
                fontSize={isTop ? this.props.fontSize + 2 : this.props.fontSize}
                fontWeight={isTop ? 'bold' : 'normal'}
              >
                {legend}
              </text>
            );
		  })}
		  {totalWidth > 795 && OriginInfoRadio.length > 2 && OriginInfoRadio.map((line, index) => (
		    <text
			  key={'OriginInfoRadio'}
			  x={rectPositionsX[5] + (rectWidth[5] / 2) - 5}
			  y={rectPositionsY[5] + rectHeight[5] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === OriginInfoRadio.length - 1 ? rxAlarmA : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 795 && DestinationInfoRadio.length > 2 && DestinationInfoRadio.map((line, index) => (
		    <text
			  key={'DestinationInfoRadio'}
			  x={rectPositionsX[8] + (rectWidth[8] / 2) + 5}
			  y={rectPositionsY[8] + rectHeight[8] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === DestinationInfoRadio.length - 1 ? rxAlarmB : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {isUTPA && OriginInfoUTP.map((line, index) => (
		    <text
			  key={'OriginInfoUTP'}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === OriginInfoUTP.length - 1 ? rxAlarmA : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {isUTPB && DestinationInfoUTP.map((line, index) => (
		    <text
			  key={'DestinationInfoUTP'}
			  x={rectPositionsX[3] + (rectWidth[3] / 2) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === DestinationInfoUTP.length - 1 ? rxAlarmB : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {!isUTPA && OriginInfo.map((line, index) => (
		    <text
			  key={'OriginInfo'}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === OriginInfo.length - 1 ? rxAlarmA : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {!isUTPB && DestinationInfo.map((line, index) => (
		    <text
			  key={'DestinationInfo'}
			  x={rectPositionsX[3] + (rectWidth[3] / 2) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === DestinationInfo.length - 1 ? rxAlarmB : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 1100 && DestinationLldpInfo.map((line, index) => (
		    <text
			  key={'DestinationLldpInfo'}
			  x={rectPositionsX[2] + rectWidth[2] + 25}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={fontColor}
			  fontSize={index < 1 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 1100 && OriginLldpInfo.map((line, index) => (
		    <text
			  key={'OriginLldpInfo'}
			  x={rectPositionsX[3] - 25}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={fontColor}
			  fontSize={index < 1 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1395 && linkInfo.linkAdminA && linkInfo.linkAdminB) && OriginTrafficInfo.map((line, index) => (
		    <text
			  key={'OriginTrafficInfo'}
			  x={(totalWidth * 0.45) - 5}
			  y={rectPositionsY[8] + (rectHeight[8] / 2) + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={(index === OriginTrafficInfo.length - 1) ? trafficAlarmA : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1395 && linkInfo.linkAdminA && linkInfo.linkAdminB) && DestinationTrafficInfo.map((line, index) => (
		    <text
			  key={'DestinationTrafficInfo'}
			  x={(totalWidth * 0.55) + 5}
			  y={rectPositionsY[8] + (rectHeight[8] / 2) + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={(index === DestinationTrafficInfo.length - 1) ? trafficAlarmB : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {linkInfo.linkAdminA && linkInfo.linkAdminB && (
		    <g>
			  <text
			    key={'radioStatusA'}
			    x={totalWidth / 2}
			    y={rectPositionsY[4] + (rectHeight[4] / 2) - 20}
			    textAnchor={'middle'}
			    fill={radioLossA > radioLossB * 1.2 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1 }
			    fontWeight={radioLossA > radioLossB * 1.2 ? 'bold': 'normal'}
		      >
			    {`Path Loss: ${radioLossTextA} / ${radioLossTextB} dB`}
		      </text>
			  <text
			    key={'rrsStatusA'}
			    x={totalWidth / 2}
			    y={rectPositionsY[4] + (rectHeight[4] / 2) - 5}
			    textAnchor={'middle'}
			    fill={ssrA > 3 || ssrB > 3 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1}
			    fontWeight={ssrA > 3 || ssrB > 3 ? 'bold': 'normal'}
		      >
			    {`SSR: ${ssrTextA} / ${ssrTextB} dB V-H`}
		      </text>
			</g>
		  )}
		</g>
		{/* Lineas de leyendas */}
		<line
          x1={rectPositionsX[2] + rectWidth[2] / 2}
          y1={rectPositionsY[2] + rectHeight[2]}
          x2={rectPositionsX[2] + rectWidth[2] / 2}
          y2={rectPositionsY[2] + rectHeight[2] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		<line
          x1={rectPositionsX[3] + rectWidth[3] / 2}
          y1={rectPositionsY[3] + rectHeight[3]}
          x2={rectPositionsX[3] + rectWidth[3] / 2}
          y2={rectPositionsY[3] + rectHeight[3] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		{totalWidth > 795 && OriginInfoRadio.length > 2 && (
		  <line
            x1={rectPositionsX[5] + rectWidth[5] / 2}
            y1={rectPositionsY[5] + rectHeight[5]}
            x2={rectPositionsX[5] + rectWidth[5] / 2}
            y2={rectPositionsY[5] + rectHeight[5] + 20}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="2,2"
          />
        )}
		{totalWidth > 795 && DestinationInfoRadio.length > 2 && (
          <line
            x1={rectPositionsX[8] + rectWidth[8] / 2}
            y1={rectPositionsY[8] + rectHeight[8]}
            x2={rectPositionsX[8] + rectWidth[8] / 2}
            y2={rectPositionsY[8] + rectHeight[8] + 20}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="2,2"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1395 && ( 
		  <line
            x1={totalWidth * 0.45}
            y1={rectPositionsY[5] + (rectHeight[5] / 2) + 5}
            x2={totalWidth * 0.45}
            y2={rectPositionsY[5] + (rectHeight[5] / 2) + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1395 && ( 
          <line
            x1={totalWidth * 0.55}
            y1={rectPositionsY[8] + (rectHeight[8] / 2)}
            x2={totalWidth * 0.55}
            y2={rectPositionsY[8] + (rectHeight[8] / 2) + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
      </svg>
    );
  }
}

import _ from 'lodash';
import { config } from '@grafana/runtime';
import cytoscape from 'cytoscape';
import { ServiceDependencyGraph } from '../serviceDependencyGraph/ServiceDependencyGraph';
import { CyCanvas, GraphDataType } from '../../types';
import assetUtils from '../asset_utils';
import '../../css/netmonitor-topology-map.css';

const isDark = config.theme.isDark;

export default class CanvasDrawer {
  readonly colors = {
    default: isDark ? '#161F29' : '#F4F9FF',
	line: isDark ? '#E6E9ED' : '#23282E',
	shadow: isDark ? '#44444C' : '#D8DFE9',
    label: isDark ? '#F4F9FF' : '#161F29',
    background: isDark ? '#1B2733' : '#EFF4FA',
	border: isDark ? '#585A5E' : '#9DA5B8',
    warning: isDark ? '#E5CD6B' : '#FFC530',
    danger: isDark ? '#F74545' : '#FB3333',
    ok: isDark ? '#58A956' : '#24AB00',
    edge: isDark ? '#936BFF' : '#5D2BE9',
    disable: isDark ? '#585A5E' : '#9DA5B8',
    status:  {
      warning: isDark ? '#D9AF27' : '#FF7F27',
    },
  };

  zoom: number = -1;
  
  readonly donutRadius: number = 15;

  controller: ServiceDependencyGraph;

  cytoscape: cytoscape.Core;

  context: CanvasRenderingContext2D;

  cyCanvas: CyCanvas;

  canvas: HTMLCanvasElement;

  offscreenCanvas: HTMLCanvasElement;

  offscreenContext: CanvasRenderingContext2D;

  frameCounter = 0;

  fpsCounter = 0;

  pixelRatio: number;

  imageAssets: any = {};

  selectionNeighborhood: cytoscape.Collection;

  constructor(ctrl: ServiceDependencyGraph, cy: cytoscape.Core, cyCanvas: CyCanvas, zoom: number) {
	this.cytoscape = cy;
    this.cyCanvas = cyCanvas;
    this.controller = ctrl;
    this.pixelRatio = window.devicePixelRatio || 1;

    this.canvas = cyCanvas.getCanvas();
    const ctx = this.canvas.getContext('2d');
    if (ctx) {
      this.context = ctx;
    } else {
      console.error('Could not get 2d canvas context.');
    }

    this.offscreenCanvas = document.createElement('canvas');
    this.offscreenContext = this.offscreenCanvas.getContext('2d');
  }

  resetAssets() {
    this.imageAssets = {};
  }

  _loadImage(imageUrl: string, imageUrlAlternative: string, assetName: string) {
    const that = this;
    const loadImage = (url: string, asset: keyof typeof that.imageAssets) => {
      const image = new Image();
      that.imageAssets[asset] = {
        image,
        loaded: false,
      };

      return new Promise((resolve, reject) => {
        image.onload = () => resolve(asset);
		image.onerror = () => reject(asset);
        image.src = url;
		image.className = 'iconImage';
      });
    };
    loadImage(imageUrl, assetName)
	  .then((asset: any) => {
	    that.imageAssets[asset].loaded = true;
      })
	  .catch((asset: any) => {
		loadImage(imageUrlAlternative, asset)
		  .then((asset: any) => {
		    that.imageAssets[asset].loaded = true;
          })
		  .catch((asset: any) => {
	        console.log('Fail to get ' + asset);
		  });
	  });
  }

  _isImageLoaded(assetName: string) {
    if (_.has(this.imageAssets, assetName) && this.imageAssets[assetName].loaded) {
      return true;
    } else {
      return false;
    }
  }

  _getImageAsset(assetName: string, resolveName = true) {
    const { iconShape } = this.controller.getSettings(false);
	try {
	  const iconName = assetName.toLowerCase();
      if (!_.has(this.imageAssets, assetName) && iconName.length > 0) {
        const { externalIcons } = this.controller.getSettings(true);
		if (iconName !== 'external' && iconName !== 'group_exp'  && iconName !== 'group_col' && iconName !== 'group') { 
          const assetUrl = assetUtils.getTypeSymbol(iconName, externalIcons, resolveName, iconShape);
		  this._loadImage(assetUrl, assetUrl, assetName);
		} else if (iconName !== 'group_exp' || iconName !== 'group_col' || iconName !== 'group') {
          const assetUrl = assetUtils.getTypeSymbol('object-group', externalIcons, resolveName, iconShape);
		  this._loadImage(assetUrl, assetUrl, assetName);		  
		} else {
          const assetUrl = assetUtils.getTypeSymbol('default', externalIcons, resolveName, iconShape);
		  this._loadImage(assetUrl, assetUrl, assetName);		  
		}
      }

      if (this._isImageLoaded(assetName)) {
        return this.imageAssets[assetName].image;
      } else {
        return null;
      }
	} catch (error) {
      console.log(error);
	  return null;
	}
  }

  _getAsset(assetName: string) {
    const { iconShape } = this.controller.getSettings(false);
	try {
	  const iconName = assetName.toLowerCase();
	  if (!_.has(this.imageAssets, assetName) && iconName.length > 0) {
        const assetUrl = assetUtils.getAssetUrl(iconName, iconShape, true);
        const assetUrlAlternative = assetUtils.getAssetUrl(iconName, iconShape, false);
	    this._loadImage(assetUrl, assetUrlAlternative, assetName);
      }

      if (this._isImageLoaded(assetName)) {
        return this.imageAssets[assetName].image;
      } else {
        return null;
      }
	} catch (error) {
      console.log(error);
	  return null;
	}
  }

  repaint(zoom: number) {
    const ctx = this.context;
    const cyCanvas = this.cyCanvas;
    const offscreenCanvas = this.offscreenCanvas;
    const offscreenContext = this.offscreenContext;
	this.zoom = zoom;

    offscreenCanvas.width = this.canvas.width;
    offscreenCanvas.height = this.canvas.height;

    // offscreen rendering
    this._setTransformation(offscreenContext);

    this.selectionNeighborhood = this.cytoscape.collection();
    const selection = this.cytoscape.$(':selected');
    selection.forEach((element: cytoscape.SingularElementArgument) => {
      this.selectionNeighborhood.merge(element);
      if (element.isNode()) {
        const neighborhood = element.neighborhood();
        this.selectionNeighborhood.merge(neighborhood);
      } else {
        const source = element.source();
        const target = element.target();
        this.selectionNeighborhood.merge(source);
        this.selectionNeighborhood.merge(target);
      }
    });

    this._drawEdgeAnimation(offscreenContext);
	this._drawNodes(offscreenContext);

    cyCanvas.clear(ctx);

    if (offscreenCanvas.width > 0 && offscreenCanvas.height > 0) {
      ctx.drawImage(offscreenCanvas, 0, 0);
    }
  }

  _setTransformation(ctx: CanvasRenderingContext2D) {
    const pan = this.cytoscape.pan();
    const zoom = this.cytoscape.zoom();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.translate(pan.x * this.pixelRatio, pan.y * this.pixelRatio);
    ctx.scale(zoom * this.pixelRatio, zoom * this.pixelRatio);
  }

  _drawEdgeAnimation(ctx: CanvasRenderingContext2D) {
    const now = Date.now();

    ctx.save();
    const edges = this.cytoscape.edges().toArray();
    const hasSelection = this.selectionNeighborhood.size() > 0;

    const transparentEdges = edges.filter(edge => hasSelection && !this.selectionNeighborhood.has(edge));
    const opaqueEdges = edges.filter(edge => !hasSelection || this.selectionNeighborhood.has(edge));

    ctx.globalAlpha = 0.5;
    this._drawEdges(ctx, transparentEdges, now);
    ctx.globalAlpha = 1;
    this._drawEdges(ctx, opaqueEdges, now);
    ctx.restore();
  }

  _drawEdges(ctx: CanvasRenderingContext2D, edges: cytoscape.EdgeSingular[], now: number) {
    const cy = this.cytoscape;
	const { hideNodes, hideThreshold } = this.controller.getSettings(false);
    for (const edge of edges) {
      const source = edge.source().data('id');
      const target = edge.target().data('id');
      const parent_source = cy.getElementById(source).data('parent');
      const parent_target = cy.getElementById(target).data('parent');
      const visible_source = cy.getElementById(source).hidden();
      const visible_target = cy.getElementById(target).hidden();
      var isVisible = false;
      if (
        parent_source !== '' &&
        parent_source !== undefined &&
        parent_target !== '' &&
        parent_target !== undefined &&
        !visible_source &&
        !visible_target
      ) {
        const sourceParent = cy.getElementById(parent_source);
        const targetParent = cy.getElementById(parent_target);
        if (sourceParent.data('type') === 'GROUP_EXP' || targetParent.data('type') === 'GROUP_EXP') {
          isVisible = true;
        }
      } else if (!visible_source && !visible_target) {
        isVisible = true;
      }
	  const targetEdges = cy.getElementById(target).connectedEdges().length;
	  
	  if (hideNodes && this.zoom > 4 && this.zoom < hideThreshold && targetEdges < 2) {
	    isVisible = false;
	  }

      if (isVisible) {
        const sourcePoint = edge.sourceEndpoint();
        const targetPoint = edge.targetEndpoint();
        this._drawEdgeLine(ctx, edge, sourcePoint, targetPoint);
      }
    }
  }

  _drawEdgeLine(
    ctx: CanvasRenderingContext2D,
    edge: cytoscape.EdgeSingular,
    sourcePoint: cytoscape.Position,
    targetPoint: cytoscape.Position
  ) {
    ctx.beginPath();

    ctx.moveTo(sourcePoint.x, sourcePoint.y);
    ctx.lineTo(targetPoint.x, targetPoint.y);

    const metrics = edge.data('metrics');
    const metricOneThreshold = _.defaultTo(metrics.metricOne_threshold, 0);
    const metricOneActual = _.defaultTo(metrics.metricOne_actual, 0);
    const metricOneMax = _.defaultTo(metrics.metricOne_max, 0);
    var line_width = metricOneMax;
    if (line_width === 0) {
      line_width = 1;
    }
    if (line_width > 6) {
      line_width = 6;
    }

	ctx.shadowColor = this.colors.shadow;
	ctx.shadowBlur = 5;

    if (metricOneMax === 0 && metricOneActual === 0 && metricOneThreshold === 0) {
      ctx.strokeStyle = this.colors.background;
    } else {
      ctx.strokeStyle = this.colors.line;
      if (metricOneActual > metricOneThreshold) {
        ctx.strokeStyle = this.colors.danger;
      } else if (metricOneActual === metricOneThreshold) {
        ctx.strokeStyle = this.colors.warning;
      }
    }
    if (!this.selectionNeighborhood.empty() && this.selectionNeighborhood.has(edge)) {
      ctx.lineWidth = line_width / 2 + 3;
    } else {
      ctx.lineWidth = line_width / 2;
    }

    ctx.stroke();
  }

  _drawLabel(ctx: CanvasRenderingContext2D, label: string, label2: string, cX: number, cY: number, fondo: string) {
    const labelPadding = 1;
    const labelPadding2 = 2;
    var labelWidth = ctx.measureText(label).width + 10;
    const label2Width = ctx.measureText(label2).width + 10;
    const xPos = cX + 2 - labelWidth / 2;
    const xPos2 = cX + 2 - label2Width / 2;
    var fPos = xPos;
    if (label2Width > 4 && label2Width > labelWidth) {
      labelWidth = label2Width;
      fPos = xPos2;
    }

    const yPos = cY + 1;
    if (label2Width > 4) {
	  ctx.lineWidth = 1;
      ctx.fillStyle = this.colors.border;
      this._roundRect(
        ctx,
        fPos - labelPadding2,
        yPos - 14 - labelPadding2,
        labelWidth + 2 * labelPadding2,
        14 + 2 * labelPadding2,
        6,
        true,
        false
      );
      ctx.fillStyle = fondo;
      this._roundRect(
        ctx,
        fPos - labelPadding,
        yPos - 14 - labelPadding,
        labelWidth + 2 * labelPadding,
        14 + 2 * labelPadding,
        6,
        true,
        false
      );
      ctx.font = '6px arial';
	  ctx.fillStyle = this.colors.label;
      ctx.fillText(label, xPos + 2, yPos - 9);
      ctx.fillText(label2, xPos2 + 2, yPos - 1);
    } else {
      ctx.fillStyle = this.colors.border;
      this._roundRect(
        ctx,
        fPos - labelPadding2,
        yPos - 7 - labelPadding2,
        labelWidth + 2 * labelPadding2,
        7 + 2 * labelPadding2,
        6,
        true,
        false
      );
      ctx.fillStyle = fondo;
      this._roundRect(
        ctx,
        xPos - labelPadding,
        yPos - 7 - labelPadding,
        labelWidth + 2 * labelPadding,
        7 + 2 * labelPadding,
        6,
        true,
        false
      );
      ctx.font = '6px arial';
	  ctx.fillStyle = this.colors.label;
      ctx.fillText(label, xPos + 2, yPos - 1);
    }
  }

  _drawNodes(ctx: CanvasRenderingContext2D) {
    const that = this;
    const cy = this.cytoscape;

    const nodes = cy.nodes().toArray();
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (that.selectionNeighborhood.empty() || that.selectionNeighborhood.has(node)) {
        ctx.globalAlpha = 1;
      } else {
        ctx.globalAlpha = 0.75;
      }
      that._drawNode(ctx, node);
    }
  }

  interpolate(zoom: number) {
    const num = zoom < 4 ? 4 : zoom;
    const x1 = 4, y1 = 8;
    const x2 = 19, y2 = 0.35;
  
    const result = zoom > 19 ? 0.35 : y1 * Math.pow((y2 / y1), (num - x1) / (x2 - x1));
    return result;
  }

  _drawNode(ctx: CanvasRenderingContext2D, node: cytoscape.NodeSingular) {
    const cy = this.cytoscape;
    const nodeType = node.data('type');
    let label = String(node.id());
    const { isStpTopology, nodeShape, showBaselines, hideNodes, hideThreshold, scaleNodes } = this.controller.getSettings(false);
    const slaMetric = _.defaultTo(node.data('node_sla'), -1);
    const isRoot = _.defaultTo(node.data('is_root'), false);
	const nodeEdges = node.connectedEdges();
    var isGroup = false;
    if (nodeType === 'GROUP_EXP' || nodeType === 'GROUP_COL') {
      isGroup = true;
    }
    const parent_node = node.data('parent');
    var isVisible = false;
    const visible_node = node.hidden();
    if (parent_node !== '' && parent_node !== undefined && !visible_node) {
      const nodeParent = cy.getElementById(parent_node);
      if (nodeParent.data('type') === 'GROUP_EXP') {
        isVisible = true;
      }
    } else if (!visible_node) {
      isVisible = true;
    }
	let hideNode = false;
	if (hideNodes && this.zoom > 4 && this.zoom < hideThreshold && nodeEdges.size() < 2) {
	  hideNode = true;
	}

    if (!isGroup) {
      if (isVisible && !hideNode) {
        if (nodeType === GraphDataType.INTERNAL) {
          var target = 'S/D';
          var thresholdViolation = 0;
          var conections = 0;
		  var radius = nodeEdges.size() * 4;
          if (radius < 12) {
            radius = 12;
          } else if (radius > 25) {
            radius = 25;
          }
		  if (this.zoom > 4 && scaleNodes) {
		    radius = radius / this.interpolate(this.zoom);
		  }
          var edges = node.connectedEdges().map(edge => edge.data().metrics);
          edges.forEach(function(ele) {
            const metricOneActual = _.defaultTo(ele.metricOne_actual, 0);
            const metricOneThreshold = _.defaultTo(ele.metricOne_threshold, 0);
            target = ele.target_label;
            if (metricOneActual > 0 && metricOneThreshold >= 0) {
              conections = conections + 1;
            }
            if (metricOneActual > metricOneThreshold) {
              thresholdViolation = thresholdViolation + 1;
            }
          });
          if (showBaselines && thresholdViolation > 0) {
            this._drawThresholdStroke(ctx, node, thresholdViolation, conections, radius, radius / 3, 1);
          }
          if (isRoot && isStpTopology) {
            this._drawThresholdStroke(ctx, node, thresholdViolation, conections, radius, radius / 3, 1);
          }
          if (nodeShape === 'circle') {
            this._drawDonut(ctx, node, radius, radius / 3, 0.5, slaMetric);
          } else {
            this._drawRect(ctx, node, radius, radius / 3, 0.5, slaMetric);
          }
          this._drawServiceIcon(ctx, node, radius);
        } else {
          this._drawExternalService(ctx, node, radius);
        }
        if (cy.zoom() > 2) {
          this._drawNodeStatistics(ctx, node, thresholdViolation, radius);
        }
        if (cy.zoom() >= 1) {
          this._drawNodeLabel(ctx, node, thresholdViolation, radius, target);
        }
      } else if (isVisible) {
        if (nodeShape === 'circle') {
          this._drawDonut(ctx, node, 5, 2, 0.5, slaMetric);
        } else {
          this._drawRect(ctx, node, 5, 2, 0.5, slaMetric);
        }
      }
    } else if (nodeType === 'GROUP_COL') {
      const visible_group = node.hidden();
      if (!visible_group) {
        if (nodeShape === 'circle') {
          this._drawDonut(ctx, node, 20, 10, 0.5, slaMetric);
        } else {
          this._drawRect(ctx, node, 20, 10, 0.5, slaMetric);
        }
        this._drawServiceIcon(ctx, node, 20);
        if (cy.zoom() > 2) {
          this._drawNodeStatistics(ctx, node, 0, 20);
        }
        if (cy.zoom() >= 1) {
          this._drawNodeLabel(ctx, node, 0, 20, label);
        }
      }
    }
  }

  _drawServiceIcon(ctx: CanvasRenderingContext2D, node: cytoscape.NodeSingular, radius: number) {
    const nodeId: string = node.id();
	const nodeIcon: string = node.data('node_icon');
    const nodeType: string = node.data('type');
	var imageIcon = '';
	const iconMappings = this.controller.getSettings(true).icons;
    const { iconShape } = this.controller.getSettings(false);
    const mapping = _.find(iconMappings, ({ pattern }) => {
      try {
        return new RegExp(pattern).test(nodeId);
      } catch (error) {
        try {
		  return new RegExp(pattern).test(nodeIcon);
		} catch (error) {
		  return false;
		}
      }
    });

	if (mapping) {
	  imageIcon = mapping.filename;
	} else if (nodeIcon) {
	  imageIcon = nodeIcon !== null && nodeIcon !== '' ? nodeIcon : 'default';
    }
	if (imageIcon === 'group' || nodeType  === 'GROUP_COL') {
	  imageIcon = 'object-group';
	}

	const image = this._getAsset(imageIcon) !== null ? this._getAsset(imageIcon) : this._getAsset('default');
	if (image !== null) {
	  const cX = node.position().x;
      const cY = node.position().y;
      var iconSize = radius * 1.4;
	  if (iconShape === 'circle') {
	    ctx.fillStyle = isDark ? '#557FFF' : '#6C63FE';
	    ctx.beginPath();
	    ctx.arc(cX, cY, radius * 0.8, 0, 2 * Math.PI);
	    ctx.fill();
      } else {
	    ctx.fillStyle = isDark ? '#557FFF' : '#6C63FE';
	    this._roundRect(ctx, cX - iconSize / 2, cY - iconSize / 2, iconSize, iconSize, 6, true, false);
      }
	  ctx.drawImage(image, cX - iconSize / 2, cY - iconSize / 2, iconSize, iconSize);
    }
  }

  _drawNodeStatistics(
    ctx: CanvasRenderingContext2D,
    node: cytoscape.NodeSingular,
    thresholdViolation: number,
    radius: number
  ) {
    var label = '';
    const slaMetric = _.defaultTo(node.data('node_sla'), -1);
	const isRoot = _.defaultTo(node.data('is_root'), false);
    const pos = node.position();
    const labelPadding = 1;
    const labelPadding2 = 2;
    const { showBaselines, showSLA } = this.controller.getSettings(false);

    if (slaMetric >= 0 && showSLA) {
      label = 'SLA: ' + slaMetric.toString() + '%';
      const labelWidth = ctx.measureText(label).width + 5;
      var cX = pos.x + radius;
      var cY = pos.y + radius / 2;

      if (showBaselines) {
        cX = cX + 5 + thresholdViolation;
        cY = cY + 5 + thresholdViolation;
      }
	  ctx.lineWidth = 1;
      ctx.fillStyle = this.colors.border;
      this._roundRect(
        ctx,
        cX - labelPadding2,
        cY - 7 - labelPadding2,
        labelWidth + 2 * labelPadding2,
        7 + 2 * labelPadding2,
        6,
        true,
        false
      );

	  ctx.lineWidth = 1;
      if (slaMetric >= 85) {
		ctx.fillStyle = this.colors.ok;
      } else {
        if (slaMetric >= 60) {
		  ctx.fillStyle = this.colors.warning;
        } else {
		  ctx.fillStyle = this.colors.danger;
        }
      }
      this._roundRect(
        ctx,
        cX - labelPadding,
        cY - 7 - labelPadding,
        labelWidth + 2 * labelPadding,
        7 + 2 * labelPadding,
        6,
        true,
        false
      );

	  ctx.lineWidth = 1;
      ctx.fillStyle = this.colors.label;
      ctx.font = '6px arial';
      ctx.fillText(label, cX + 2, cY - 1);
    }
  }

  _drawThresholdStroke(
    ctx: CanvasRenderingContext2D,
    node: cytoscape.NodeSingular,
    violation: number,
    conections: number,
    radius: number,
    width: number,
    baseStrokeWidth: number
  ) {
    const pos = node.position();
    const cX = pos.x;
    const cY = pos.y;
    var color = this.colors.ok;
    var strokeWidth = baseStrokeWidth;

    if (violation > 0) {
      strokeWidth = strokeWidth * violation;
      color = this.colors.danger;
    }
    if (strokeWidth > 3) {
      strokeWidth = 3;
    }
    var offset = strokeWidth;
    if (offset > 2) {
      offset = 2;
    }
    const { nodeShape } = this.controller.getSettings(false);
    if (nodeShape === 'circle') {
      // inner
      ctx.beginPath();
      ctx.arc(cX, cY, radius + offset + 2, 0, 2 * Math.PI, false);
      ctx.closePath();
      ctx.fillStyle = this.colors.default;
      ctx.fill();
      ctx.beginPath();
      ctx.arc(cX, cY, radius + offset + 2, 0, 2 * Math.PI, false);
      ctx.closePath();
      ctx.setLineDash([6, 4]);
      ctx.lineWidth = strokeWidth * 1.2;
      ctx.strokeStyle = color;
      ctx.stroke();
    } else {
      ctx.fillStyle = this.colors.default;
      ctx.setLineDash([6, 4]);
      ctx.strokeStyle = color;
      ctx.lineWidth = strokeWidth * 1.2;
      this._roundRect(
        ctx,
        cX - radius - offset,
        cY - radius - offset,
        radius * 2 + offset,
        radius * 2 + offset,
        6,
        true,
        true
      );
    }
  }

  _drawExternalService(ctx: CanvasRenderingContext2D, node: cytoscape.NodeSingular, radius: number) {
    const { nodeShape, iconSize } = this.controller.getSettings(false);
	const pos = node.position();
    const cX = pos.x;
    const cY = pos.y;
    const size = radius * 1.3;

    ctx.beginPath();
    ctx.arc(cX, cY, 12, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#45AFB8';
    ctx.fill();

    ctx.beginPath();
    ctx.arc(cX, cY, 11.5, 0, 2 * Math.PI, false);
    ctx.fillStyle = this.colors.ok;
    ctx.fill();

    const nodeType = node.data('external_type');

    const image = this._getImageAsset(nodeType);

    if (image !== null) {
	  if (nodeShape === 'circle') {
		ctx.fillStyle = isDark ? '#557FFF' : '#6C63FE';
		ctx.beginPath();
		ctx.arc(cX, cY, radius * 0.77, 0, 2 * Math.PI);
		ctx.fill();
      } else {
		ctx.fillStyle = isDark ? '#557FFF' : '#6C63FE';
		this._roundRect(
		  ctx,
		  cX - iconSize / 2,
		  cY - iconSize / 2,
		  iconSize,
		  iconSize,
		  6,
		  true,
		  false
		);
      }
      ctx.drawImage(image, cX - size / 2, cY - size / 2, size, size);
    }
  }

  _drawNodeLabel(
    ctx: CanvasRenderingContext2D,
    node: cytoscape.NodeSingular,
    thresholdViolation: number,
    radius: number,
    target: string
  ) {
    const { showBaselines } = this.controller.getSettings(false);
    const slaMetric = _.defaultTo(node.data('node_sla'), -1);
	const isRoot = _.defaultTo(node.data('is_root'), false);
    const fondo = this.colors.background;
    const labelPadding = 1;
    const labelPadding2 = 1.5;

    let asset = String(node.id());
    if (node.data('label') === undefined) {
      asset = target;
    }

    const pos = node.position();
    ctx.font = '6px arial';

    if (this.selectionNeighborhood.empty() || !this.selectionNeighborhood.has(node)) {
      ctx.font = '6px arial';
    }
    let label = '' + asset;
    if (label.length >= 25) {
      label = label.substr(0, 22) + '...';
    }

    const labelWidth = ctx.measureText(label).width + 6;
    const xPos = pos.x - labelWidth / 2;
    var yPos = pos.y + radius + 12;

    if (slaMetric > 100 || slaMetric < 0) {
      ctx.fillStyle = this.colors.disable;
    } else {
      if (slaMetric >= 85) {
        ctx.fillStyle = this.colors.default;
      } else {
        if (slaMetric >= 60) {
          ctx.fillStyle = this.colors.warning;
        } else {
          ctx.fillStyle = this.colors.danger;
        }
      }
    }

    if (showBaselines) {
      yPos = yPos + thresholdViolation * 2;
    }
	ctx.lineWidth = 1;
    ctx.fillStyle = this.colors.border;
    this._roundRect(
      ctx,
      xPos - labelPadding2,
      yPos - 8 - labelPadding2,
      labelWidth + 2 * labelPadding2,
      8 + 2 * labelPadding2,
      6,
      true,
      false
    );

    ctx.fillStyle = fondo;
    this._roundRect(
      ctx,
      xPos - labelPadding,
      yPos - 8 - labelPadding,
      labelWidth + 2 * labelPadding,
      8 + 2 * labelPadding,
      6,
      true,
      false
    );
	ctx.font = '6px arial';
    ctx.fillStyle = this.colors.label;
    ctx.fillText(label, xPos + 3, yPos - 2);
  }

  _drawDonut(
    ctx: CanvasRenderingContext2D,
    node: cytoscape.NodeSingular,
    radius: number,
    width: number,
    strokeWidth: number,
    slaMetric: number
  ) {
    const cX = node.position().x;
    const cY = node.position().y;
    let currentArc = -Math.PI / 2; // offset

    ctx.beginPath();
    ctx.arc(cX, cY, radius + strokeWidth, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fillStyle = isDark ? '#44444C' : '#D8DFE9';
    ctx.fill();

    const { healthyColor, warningColor, dangerColor, noDataColor } = this.controller.getSettings(false).style;
	const { healthyThreshold, warningThreshold } = this.controller.getSettings(false).style;
	const { isStpTopology } = this.controller.getSettings(false);
    const colors = [dangerColor, warningColor, noDataColor, healthyColor];
    const priorityColors = isDark ? 
      ['#F17171','#71E200','#A2ADB8','#7100FF','#FFA071','#A20471','#FF71B7','#7EA9ff','#FF0071','#B380FF'] :
	  ['#D64545','#45C5B0','#7D8995','#45B0E5','#E57A45','#7DB545','#E54594','#5485E5','#E58045','#9065E5'];

    if (isStpTopology) {
	  const colorNumber = Math.floor(slaMetric / 10);
	  let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[colorNumber]);
      currentArc += arc;
	} else if (slaMetric > 100 || slaMetric < 0) {
      let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[2]);
      currentArc += arc;
    } else {
      if (slaMetric >= healthyThreshold) {
        let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[3]);
        currentArc += arc;
      } else {
        if (slaMetric >= warningThreshold) {
          let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[1]);
          currentArc += arc;
        } else {
          let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[0]);
          currentArc += arc;
        }
      }
    }

    ctx.beginPath();
    ctx.arc(cX, cY, radius - width, 0, 2 * Math.PI, false);
    ctx.shadowColor = this.colors.shadow;
    ctx.shadowBlur = 10;
	ctx.fillStyle = this.colors.edge;
    ctx.fill();

    // cut out an inner-circle == donut
    ctx.beginPath();
    ctx.arc(cX, cY, radius - width - strokeWidth, 0, 2 * Math.PI, false);
    ctx.shadowColor = this.colors.shadow;
    ctx.shadowBlur = 5;
	if (node.selected()) {
      ctx.fillStyle = this.colors.edge;
    } else {
      ctx.fillStyle = this.colors.background;
    }
    ctx.fill();
  }

  _drawRect(
    ctx: CanvasRenderingContext2D,
    node: cytoscape.NodeSingular,
    radius: number,
    width: number,
    strokeWidth: number,
    slaMetric: number
  ) {
    const cX = node.position().x - radius;
    const cY = node.position().y - radius;

    const { healthyColor, warningColor, dangerColor, noDataColor } = this.controller.getSettings(false).style;
	const { healthyThreshold, warningThreshold } = this.controller.getSettings(false).style;
	const { isStpTopology } = this.controller.getSettings(false);
    const colors = [dangerColor, warningColor, noDataColor, healthyColor];
    const priorityColors = isDark ? 
      ['#F17171','#71E200','#A2ADB8','#7100FF','#FFA071','#A20471','#FF71B7','#7EA9ff','#FF0071','#B380FF'] :
	  ['#D64545','#45C5B0','#7D8995','#45B0E5','#E57A45','#7DB545','#E54594','#5485E5','#E58045','#9065E5'];

    if (isStpTopology) {
	  const colorNumber = Math.floor(slaMetric / 10);
	  let arc = this._drawArc(ctx, currentArc, cX, cY, radius, 1.0, colors[colorNumber]);
      currentArc += arc;
	} else if (slaMetric > 100 || slaMetric < 0) {
      ctx.fillStyle = noDataColor;
    } else {
      if (slaMetric >= healthyThreshold) {
        ctx.fillStyle = healthyColor;
      } else {
        if (slaMetric >= warningThreshold) {
          ctx.fillStyle = warningColor;
        } else {
          ctx.fillStyle = dangerColor;
        }
      }
    }
    ctx.shadowColor = this.colors.shadow;
    ctx.shadowBlur = 10;
    this._roundRect(ctx, cX, cY, radius * 2, radius * 2, 6, true, false);
    ctx.shadowBlur = 5;
    ctx.fillStyle = isDark ? '#936BFF' : '#5D2BE9';
    this._roundRect(ctx, cX + (width / 2), cY + (width / 2), (radius * 2) - width , (radius * 2) - width , 6, true, false);
  }

  _drawArc(
    ctx: CanvasRenderingContext2D,
    currentArc: number,
    cX: number,
    cY: number,
    radius: number,
    percent: number,
    color: string
  ) {
    // calc size of our wedge in radians
    var WedgeInRadians = (percent * 360 * Math.PI) / 180;
    // draw the wedge
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(cX, cY);
    ctx.arc(cX, cY, radius, currentArc, currentArc + WedgeInRadians, false);
    ctx.closePath();
    ctx.fillStyle = color;
    ctx.fill();
    ctx.restore();
    // sum the size of all wedges so far
    // We will begin our next wedge at this sum
    return WedgeInRadians;
  }

  _updatePosition(ctx: CanvasRenderingContext2D) {
    const cy = this.cytoscape;
    cy.nodes().forEach(function(n) {
      var x = n.data('x');
      var y = n.data('y');
      n.position().x = x;
      n.position().y = y;
    });
  }

  _roundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    fill: boolean,
    stroke: boolean
  ) {
    var tl = 0;
    var tr = 0;
    var br = 0;
    var bl = 0;

    if (typeof stroke === 'undefined') {
      stroke = true;
    }
    if (typeof radius === 'undefined') {
      radius = 2;
    }
    if (typeof radius === 'number') {
      tl = radius;
      tr = radius;
      br = radius;
      bl = radius;
    }
	ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(x + tl, y);
    ctx.lineTo(x + width - tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + tr);
    ctx.lineTo(x + width, y + height - br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - br, y + height);
    ctx.lineTo(x + bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - bl);
    ctx.lineTo(x, y + tl);
    ctx.quadraticCurveTo(x, y, x + tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }
}

import React, { useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';
import { AlertErrorPayload, AlertPayload, AppEvents, dateTime, PanelProps, GraphSeriesValue } from '@grafana/data';
import { getAppEvents, getTemplateSrv, locationService, RefreshEvent, SystemJS } from '@grafana/runtime';
import { Alert, Button, ConfirmModal, FieldSet, useStyles2, useTheme2, Icon } from '@grafana/ui';
import { ButtonVariant, ButtonOrientation, FormElementType, LayoutVariant, RequestMethod } from '../../constants';
import { Styles } from '../../styles';
import { FormElement, PanelOptions, ElementData } from '../../types';
import { FormElements } from '../FormElements';
import { contextSrv } from 'app/core/services/context_srv';
import '../../css/GenericForm.css';

interface Props extends PanelProps<PanelOptions> {}

export const FormPanel: React.FC<Props> = ({
  id,
  options,
  width,
  height,
  onOptionsChange,
  eventBus,
  replaceVariables,
  data,
}) => {
  const error1 = replaceVariables(options.error.error1);
  const error2 = replaceVariables(options.error.error2);
  const error3 = replaceVariables(options.error.error3);
  const error4 = replaceVariables(options.error.error4);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [initial, setInitial] = useState<{ [id: string]: any }>({});
  const [queryElement, setQueryElement] = useState({});
  const [updateConfirmation, setUpdateConfirmation] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [panelWidth, setPanelWidth] = useState(width);
  const [renderCount, setRenderCount] = useState(0);
  const theme = useTheme2();
  const styles = useStyles2(Styles);
  const templateSrv = getTemplateSrv();
  const appEvents = getAppEvents();
  const notifySuccess = (payload: AlertPayload) => appEvents.publish({ type: AppEvents.alertSuccess.name, payload });
  const notifyError = (payload: AlertErrorPayload) => appEvents.publish({ type: AppEvents.alertError.name, payload });
  const isAdmin = options.initial.editorCanAdmin ? contextSrv.isEditor : contextSrv.isNetMonitorAdmin;
  const hasElements = data.series[0].fields[0].values.toArray().length > 0 && options.initial.method === RequestMethod.QUERY ? true : false;

  const executeCustomCode = (code: string, initial: any, response: Response | void) => {
    if (!code) {
      return;
    }
    const f = new Function(
      'options',
      'data',
      'response',
      'elements',
      'locationService',
      'templateService',
      'onOptionsChange',
      'initialRequest',
      'setInitial',
      'json',
      'initial',
      'notifyError',
      'notifySuccess',
      replaceVariables(code)
    );

    try {
      f(
        options,
        data,
        response,
        options.elements,
        locationService,
        templateSrv,
        onOptionsChange,
        initialRequest,
        setInitial,
        initial,
        initial,
        notifyError,
        notifySuccess
      );
    } catch (error: any) {
      console.error(error);
      setError(error.toString());
    }
  };

  const updateRequest = async () => {
    const body: any = {};
    setLoading(true);

    if (options.update.method === RequestMethod.NONE) {
      await executeCustomCode(options.update.code, initial);
      setLoading(false);
      return;
    }

    const headers: HeadersInit = new Headers();
    headers.set('Content-Type', options.update.contentType);

    if (options.update.useNetMonitorSintaxis) {
	  body[`dest`] = options.update.netmonitorFunction;
	  let updateString = '';
	  options.elements.forEach((element) => {
	    updateString = updateString + String((parseInt(element.id) + 1)) + ';' + String(element.value) + '||';
	  });
	  body[`params`] = updateString;
	} else {
	  options.elements?.forEach((element) => {
        if (!options.update.updatedOnly) {
          body[element.id] = element.value;
          return;
        }
        if (element.value === initial[element.id]) {
          return;
        }
        if (element.type === FormElementType.DISABLED) {
          return;
        }
        body[element.id] = element.value;
      });
	}

    options.update.header?.forEach((parameter) => {
      const name = replaceVariables(parameter.name);
      if (!name) {
        setError(`All request parameters should be defined. Remove empty parameters.`);
        return;
      }
      headers.set(name, replaceVariables(parameter.value));
    });

    const response = await fetch(replaceVariables(options.update.url), {
      method: options.update.method,
      headers,
      body: replaceVariables(JSON.stringify(body)),
    }).catch((error: Error) => {
      console.error(error);
	  SystemJS.load('app/core/app_events').then((appEvents: any) => {
        appEvents.emit(AppEvents.alertError, ['Se produjo un error al intentar obtener datos']);
      });
      setError(error.toString());
    });

    if (response?.ok) {
	  setTitle('Los datos fueron actualizados correctamente');
      SystemJS.load('app/core/app_events').then((appEvents: any) => {
        appEvents.emit(AppEvents.alertSuccess, ['Los datos fueron actualizados correctamente']);
      });
    }
    await executeCustomCode(options.update.code, initial, response);
    setLoading(false);
  };
  
  const initialRequest = async () => {
	if (options.initial.method === RequestMethod.NONE && (!options.elements || !options.elements.length)) {
      await executeCustomCode(options.initial.code, initial);
      setLoading(false);
      return;
    } else if (options.initial.method === RequestMethod.GET && !options.initial.url) {
      setError('Por favor declare una URL inicial');
      setLoading(false);
      return;
    } else if (options.initial.method === RequestMethod.QUERY) {
	  options.elements.forEach((element, index) => {
        if (index < queryElement.length) {
		  element.id = elements[index].id;
		  element.section = elements[index].section;
		  element.type = elements[index].style;
		  element.title = elements[index].label;
		  element.value = elements[index].value;
		  element.unit = elements[index].unit;
		  element.options = elements[index].options;
		  element.max = (elements[index].style === 'number' || elements[index].style === 'slider') ? elements[index].max : element.max;
		  element.min = (elements[index].style === 'number' || elements[index].style === 'slider') ? elements[index].min : element.min;
		  element.step = elements[index].style === 'slider' ? elements[index].step : element.step;
		  element.rows = elements[index].style === 'textarea' ? elements[index].rows : element.rows;
		  element.height = elements[index].height;
		  element.labelWidth = elements[index].labelWidth;
		  element.width = elements[index].width;
		  element.tooltip = elements[index].tooltip;
		}
	  });
	  setLoading(false);
      setInitial(elements);
	  return;
	} else if (options.initial.method === RequestMethod.QUERY && !hasElements) {
	  setLoading(false);
	  return;
	}

    const headers: HeadersInit = new Headers();
    if (options.initial.method === RequestMethod.GET) {
      headers.set('Content-Type', options.initial.contentType);
    }
    options.initial.header?.forEach((parameter) => {
      const name = replaceVariables(parameter.name);
      if (!name) {
        setError(`All request parameters should be defined. Remove empty parameters.`);
        return;
      }

      headers.set(name, replaceVariables(parameter.value));
    });

    const response = await fetch(replaceVariables(options.initial.url), {
      method: options.initial.method,
      headers,
    }).catch((error: Error) => {
      console.error(error);
	  SystemJS.load('app/core/app_events').then((appEvents: any) => {
        appEvents.emit(AppEvents.alertError, ['Se produjo un error al actualizar los datos']);
      });
      setError(error.toString());
    });

    if (response?.type === 'opaque') {
      setError('CORS prevents access to the response for Initial values.');
    }

    let json: any = null;
    if (response?.ok) {
      json = await response.json();
	  options.elements.forEach((element) => {
        element.value = json[element.id];
      });
      onOptionsChange(options);
      setInitial(json);
      setTitle('Los datos fueron actualizados correctamente');
      SystemJS.load('app/core/app_events').then((appEvents: any) => {
        appEvents.emit(AppEvents.alertSuccess, ['Los datos fueron actualizados correctamente']);
      });
    }

    await executeCustomCode(options.initial.code, json, response);
    setLoading(false);
  };

  const wrapperWidth = width - (options.layout.padding * 2);

  const elements: ElementData[] = [];
  if (options.initial.method === RequestMethod.QUERY) {
    let mustUpdate = false;
	if (hasElements) {
	  data.series.forEach(series => {
	    const formElements: GraphSeriesValue[] = series.fields[0].values.toArray();
	    for (let i = 0; i < formElements.length; i++) {
		  let tableData: ElementData[] = {
		    id: i,
		    section: series.fields.find(field => field.name === options.initial.eleSection)?.values.get(i),
		    style: series.fields.find(field => field.name === options.initial.eleType)?.values.get(i),
		    width: (wrapperWidth * 0.6),
		    height: 20,
		    rows: 1,
		    label: series.fields.find(field => field.name === options.initial.eleLabel)?.values.get(i),
		    labelWidth: (wrapperWidth * 0.3),
		    tooltip: series.fields.find(field => field.name === options.initial.eleTitle)?.values.get(i),
		    value: null,
		    unit: width > 350 ? series.fields.find(field => field.name === options.initial.eleUnit)?.values.get(i) : '',
		    min: 0,
		    max: 100,
		    step: 1,
		    options: []
		  };
		  if (tableData.section === null || tableData.section === undefined) {
		    tableData.section = '';
		  }
		  if (tableData.style === null || tableData.style === undefined) {
		    const types = ['number', 'string', 'boolean', 'radio', 'slider', 'select', 'textarea', 'secret', 'datetime', 'disabled', 'password'];
			if (!types.includes(tableData.style)) {
			  tableData.style = 'string';
			}
		  }
		  const eleLabelwidth = series.fields.find(field => field.name === options.initial.eleLabelwidth)?.values.get(i);
		  if (eleLabelwidth !== null && eleLabelwidth !== undefined && !isNaN(eleLabelwidth)) {
		    tableData.labelWidth = Number(eleLabelwidth);
		  }
		  const eleWidth = series.fields.find(field => field.name === options.initial.eleWidth)?.values.get(i);
		  if (eleWidth !== null && eleWidth !== undefined && !isNaN(eleWidth)) {
			if (tableData.unit !== '' && Number(eleWidth) > 10) {
			  if (Number(eleWidth) < (wrapperWidth - tableData.labelWidth - 80)) {
			    tableData.width = Number(eleWidth);
			  } else {
			    tableData.width = wrapperWidth - tableData.labelWidth - 80;
			  }
			} else if (Number(eleWidth) > 10) {
			  if (Number(eleWidth) < (wrapperWidth - tableData.labelWidth - 30)) {
			    tableData.width = Number(eleWidth);
			  } else {
			    tableData.width = wrapperWidth - tableData.labelWidth - 30;
			  }
			}
		  }
		  const eleHeight = series.fields.find(field => field.name === options.initial.eleHeight)?.values.get(i);
		  if (eleHeight == null && eleHeight === undefined && !isNaN(eleHeight)) {
		    tableData.height = Number(eleHeight);
		  }
		  if (tableData.style === 'textarea') {
		    const eleRows = series.fields.find(field => field.name === options.initial.eleRows)?.values.get(i);
		    if (eleRows !== null && eleRows !== undefined && !isNaN(eleRows)) {
			  tableData.rows = Number(eleRows);
		    }
		  }
		  if (tableData.label === null || tableData.label === undefined) {
		    tableData.label = 'Label ' + i.toString();
		  }
		  if (tableData.tooltip === null || tableData.tooltip === undefined) {
		    tableData.tooltip = tableData.label;
		  }
		  const eleValue = series.fields.find(field => field.name === options.initial.eleValue)?.values.get(i);
		  if (eleValue !== null && eleValue !== undefined) {
		    if (tableData.style === 'number' || tableData.style === 'slider') {
			  if (isNaN(eleValue)) {
			    tableData.value = 0;
			  } else {
			    tableData.value = Number(eleValue);
			  }
			} else if (tableData.style === 'boolean') {
			  if (eleValue === 'true') {
			    tableData.value = true;
			  } else {
			    tableData.value = false;
			  }
			} else {
			  tableData.value = String(eleValue);
			}
		  }	else {
		    if (tableData.style === 'number' || tableData.style === 'slider') {
			  tableData.value = 0;
			} else {
			  tableData.value = '-';
			}
		  }
		  if (tableData.style === 'number' || tableData.style === 'slider') {
		    const minValue = series.fields.find(field => field.name === options.initial.eleValueMin)?.values.get(i);
		    const maxValue = series.fields.find(field => field.name === options.initial.eleValueMax)?.values.get(i);
		    if (minValue !== null && minValue !== undefined && !isNaN(minValue)) {
			  tableData.min = Number(minValue);
		    }
		    if (maxValue !== null && maxValue !== undefined && !isNaN(maxValue)) {
			  tableData.max = Number(maxValue);
		    }
		    if (tableData.style === 'slider') {
			  const stepValue = series.fields.find(field => field.name === options.initial.eleValueStep)?.values.get(i);
			  if (stepValue !== null && stepValue !== undefined && !isNaN(stepValue)) {
			    tableData.step = Number(stepValue);
			  }
		    }
		  }
		  if (tableData.style === 'select' || tableData.style === 'radio') {
		    const optionValues = series.fields.find(field => field.name === options.initial.eleValueOptions)?.values.get(i);
			const selectOptions = [];
			if (optionValues !== null && optionValues !== undefined) {
			  const optionArray = optionValues.split(',');
			  if (optionArray.length > 0) {
			    optionArray.forEach((option) => {
				  const optionElement = option.split('|');
				  if (optionElement.length > 1) {
				    let newOption = {
					  type: (optionElement[0] === 'number' || optionElement[0] === 'string') ? optionElement[0] : 'string',
					  value: optionElement[0] === 'number' ? Number(optionElement[1]) : optionElement[1],
					  label: optionElement[2],
					  icon: optionElement.length > 2 ? optionElement[3] : 'angle-right',
				    }
				    selectOptions.push(newOption);
				  }
			    });
		      }
			}
			tableData.options = selectOptions;
		  }
		  elements.push(tableData);
	    }
		if (queryElement.length !== elements.length) {
	      mustUpdate = true;
	    } else {
		  queryElement.forEach((element, index) => {
            if (element.value !== elements[index].value) {
		      mustUpdate = true;
			}
	      });
		}

	  });
    } else {
	  let tableData: ElementData[] = {
		id: 0,
		section: error2,
		style: 'string',
		width: (wrapperWidth * 0.6),
		height: 20,
		rows: 1,
		label: error2,
		labelWidth: (wrapperWidth * 0.3),
		tooltip: error2,
		value: null,
		unit: '',
		min: 0,
		max: 100,
		step: 1,
		options: []
	  };
	  elements.push(tableData);
	  if (queryElement.length !== 1) {
	    mustUpdate = true;
	  }
	}
	if (mustUpdate) {
	  setQueryElement(elements);
	  setRenderCount(1);
	} 
  }

  useEffect(() => {
    initialRequest();
    const subscriber = eventBus.getStream(RefreshEvent).subscribe((event) => {
      initialRequest();
    });

    return () => {
      subscriber.unsubscribe();
    };
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (renderCount === 1) {
    initialRequest();
	setRenderCount(2);
  }

  const sectionClass = 'inlineForm-Section';
  var submitButtonClass = theme.isDark ? 'inlineForm-Button blueButton_dark' : 'inlineForm-Button blueButton';
  if (options.submit.variant === ButtonVariant.SECONDARY) {
    submitButtonClass = 'inlineForm-Button greenButton';
  } else if (options.submit.variant === ButtonVariant.DESTRUCTIVE) {
    submitButtonClass = 'inlineForm-Button orangeButton';
  } else if (options.submit.variant === ButtonVariant.CUSTOM) {
    submitButtonClass = 'inlineForm-Button';
  }
  var resetButtonClass = 'inlineForm-Button redButton';
  if (options.reset.variant === ButtonVariant.SECONDARY) {
    resetButtonClass = 'inlineForm-Button greenButton';
  } else if (options.reset.variant === ButtonVariant.DESTRUCTIVE) {
    resetButtonClass = 'inlineForm-Button orangeButton';
  } else if (options.reset.variant === ButtonVariant.CUSTOM) {
    resetButtonClass = 'inlineForm-Button';
  }
  var buttonGroupClass = 'inlineForm-buttonGroup inlineForm-buttonGroup-center';
  if (options.buttonGroup.orientation === ButtonOrientation.LEFT) {
    buttonGroupClass = 'inlineForm-buttonGroup inlineForm-buttonGroup-left';
  } else if (options.buttonGroup.orientation === ButtonOrientation.RIGHT) {
    buttonGroupClass = 'inlineForm-buttonGroup inlineForm-buttonGroup-right';
  }

  if (width !== panelWidth) {
    setPanelWidth(width);
	initialRequest();
  }

  const changeSectionVisibility = (name: string, visible: boolean) => {
	var updateSection = false;
    options.layout.sections.forEach((section) => {
      if (section.name === name && section.visible !== visible) {
		section.visible = visible;
		updateSection = true;
 	  }
    });
	if (updateSection) {
	  initialRequest();
	}
  };

  const lastSectionName = options.layout.sections.length > 0 ? 
    options.layout.sections[options.layout.sections.length - 1].name :
	'';

  if (width < 250 || height < 250) {
    return (
	  <div className="inlineFieldErrorContainer">
	    <div><Icon name={'cloud-slash'} size="xxl" /></div>
		<div>{error4}</div>
	  </div>
	);
  }
  if (data.state === 'Error' && options.initial.method === RequestMethod.QUERY) {
    return (
	  <div className="inlineFieldErrorContainer">
	    <div><Icon name={'sync-slash'} size="xxl" /></div>
		<div>{error1}</div>
	  </div>
	);
  }
  if (!hasElements) {
    return (
	  <div className="inlineFieldErrorContainer">
	    <div><Icon name={'image-slash'} size="xxl" /></div>
		<div>{error2}</div>
	  </div>
	);
  }

  return (
	<div
      id={'FormPanel_' + id}
	  className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
          padding: ${options.layout.padding}px;
        `
      )}
    >
      {(!options.elements || !options.elements.length) && options.layout.variant !== LayoutVariant.NONE && (
        <Alert severity="info" title="Algo fallo">{error2}</Alert>
      )}

      {options.layout.variant === LayoutVariant.SINGLE && (
        <table className={styles.table}>
          <tr>
            <td>
              <FormElements
                options={options}
                onOptionsChange={onOptionsChange}
                initial={initial}
                section={null}
				width={wrapperWidth}
				refresh={true}
              ></FormElements>
            </td>
          </tr>
          {isAdmin && (
		    <tr>
              <td colSpan={options.layout?.sections?.length}>
                <div className={buttonGroupClass}>
                  <div
                    className={submitButtonClass}
				    style={options.submit.variant === ButtonVariant.CUSTOM ? 
				      { 
					    backgroundImage: `linear-gradient(to bottom right, ${options.submit.backgroundColor}, ${options.submit.foregroundColor}`,
                        border: `1px solid ${options.submit.backgroundColor}`
					  } : {}
				    }
                    onClick={options.update.confirm ? () => {setUpdateConfirmation(true)} : updateRequest }
                  >
                    <Icon name={loading ? 'loading' : options.submit.icon} size={options.buttonGroup.size} />
                    <div className="inlineForm-Button-label">{options.submit.text}</div>    
                  </div>
                  {options.reset.variant !== ButtonVariant.HIDDEN && (
                    <div
                      className={resetButtonClass}
				      style={options.submit.variant === ButtonVariant.CUSTOM ? 
				        { 
					      backgroundImage: `linear-gradient(to bottom right, ${options.reset.backgroundColor}, ${options.reset.foregroundColor}`,
                          border: `1px solid ${options.reset.backgroundColor}`
					    } : {}
				      }
                      onClick={initialRequest}
                    >
                      <Icon name={loading ? 'loading' : options.reset.icon} size={options.buttonGroup.size} />
                      <div className="inlineForm-Button-label">{options.reset.text}</div>    
                    </div>
                  )}
                </div>
              </td>
            </tr>
		  )}
        </table>
      )}
      {options.layout.variant === LayoutVariant.SPLIT && (
		<div className="inlineForm-Section-Container"> 
		  {options.layout?.sections?.map((section) => {
            const isLastSection = section.name === lastSectionName ? true : false;
			return (
			  <div className={isLastSection ? 'inlineForm-Section_noBorder' : sectionClass}>
                <div key={section.name}>
                  <div className="inlineForm-Section-Wrapper">
				    <div
					  className={section.visible ? 'inlineForm-Section-label' : 'inlineForm-Section-label'}
					  onClick={renderCount > 0 ? () => {changeSectionVisibility(section.name, !section.visible)} : null}
					>
					  <Icon className="inlineForm-row_icon" name={section.visible ? 'angle-right' : 'angle-down'} />
					  {section.icon !== '' && <Icon className="inlineForm-label_icon"  name={section.icon} size="lg" />}
					  <span className="inlineForm-Section-Label" >{section.name}</span>
					</div>
				  </div>
				  {section.visible && (
                    <FormElements
                      options={options}
                      onOptionsChange={onOptionsChange}
                      initial={initial}
                      section={section}
					  width={wrapperWidth}
					  refresh={true}
                    ></FormElements>
				  )}
                </div>
			  </div>
            );
          })}
          {isAdmin && (
			<div className={buttonGroupClass}>
			  <div
				className={submitButtonClass}
				style={options.submit.variant === ButtonVariant.CUSTOM ? 
				  { 
					backgroundImage: `linear-gradient(to bottom right, ${options.submit.backgroundColor}, ${options.submit.foregroundColor}`,
					border: `1px solid ${options.submit.backgroundColor}`
				  } : {}
				}
				onClick={options.update.confirm ? () => {setUpdateConfirmation(true)} : updateRequest }
			  >
				<Icon name={loading ? 'loading' : options.submit.icon} size={options.buttonGroup.size} />
				<div className="inlineForm-Button-label">{options.submit.text}</div>    
			  </div>
			  {options.reset.variant !== ButtonVariant.HIDDEN && (
				<div
				  className={resetButtonClass}
				  style={options.submit.variant === ButtonVariant.CUSTOM ? 
					{ 
					  backgroundImage: `linear-gradient(to bottom right, ${options.reset.backgroundColor}, ${options.reset.foregroundColor}`,
					  border: `1px solid ${options.reset.backgroundColor}`
					} : {}
				  }
				  onClick={initialRequest}
				>
				  <Icon name={loading ? 'loading' : options.reset.icon} size={options.buttonGroup.size} />
				  <div className="inlineForm-Button-label">{options.reset.text}</div>    
				</div>
			  )}
			</div>
		  )}
		</div>
      )}

      {error && (
        <Alert severity="error" title="Algo fallo">
          {error}
        </Alert>
      )}

      <ConfirmModal
        isOpen={!!updateConfirmation}
        title="Se requiere confirmación"
        body={
          <div>
            <h4>Por favor confirme que desea actualizar los valores?</h4>
            <table className={styles.confirmTable}>
              <thead>
                <tr className={styles.confirmTable}>
                  <td className={styles.confirmTableTd}>
                    <b>Etiqueta</b>
                  </td>
                  <td className={styles.confirmTableTd}>
                    <b>Valor anterior</b>
                  </td>
                  <td className={styles.confirmTableTd}>
                    <b>Nuevo valor</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {options.elements?.map((element: FormElement) => {
                  if (element.value === initial[element.id]) {
                    return;
                  }
                  if (element.type === FormElementType.DISABLED) {
                    return;
                  }
                  if (element.type === FormElementType.PASSWORD) {
                    return (
                      <tr className={styles.confirmTable} key={element.id}>
                        <td className={styles.confirmTableTd}>{element.title || element.tooltip}</td>
                        <td className={styles.confirmTableTd}>*********</td>
                        <td className={styles.confirmTableTd}>*********</td>
                      </tr>
                    );
                  }
                  if (element.type === FormElementType.DATETIME) {
                    element.value = dateTime(element.value).toISOString();
                  }

                  return (
                    <tr className={styles.confirmTable} key={element.id}>
                      <td className={styles.confirmTableTd}>{element.title || element.tooltip}</td>
                      <td className={styles.confirmTableTd}>
                        {initial[element.id] === undefined ? '' : String(initial[element.id])}
                      </td>
                      <td className={styles.confirmTableTd}>
                        {element.value === undefined ? '' : String(element.value)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
        confirmText="Confirmar"
        onConfirm={() => {
          updateRequest();
          setUpdateConfirmation(false);
        }}
        onDismiss={() => setUpdateConfirmation(false)}
      />
    </div>
  );
};

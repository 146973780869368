import { SelectableValue } from '@grafana/data';

export interface SignManagerPanelOptions {
  api: string;
  variableToTrack: string;
  assetList: string;
  assetTypes: string;
  signTypes: string;
  assetVariable: string;
  oidVariable: string;
  pluginTitle: string;
  signId: string;
  signAsset: string;
  signOid: string;
  signOidValueType: string;
  signDualThreshold: string;
  signThresholdBelow: string;
  signOidThresholdUp: string;
  signOidThresholdDown: string;
  signType: string;
  signName: string;
  signState: string;
  signDate: string;
  signUser: string;
  alertName: string;
  alertSummary: string;
  resolSummary: string;
  alertSeverity: string;
  alertScope: string;
  openTicket: string;
  successMessage: string;
  alertmanager: string;
  editorCanAdmin: boolean;
  showTitle: boolean;
  error4: string;
}

export const valueTypeOptions: SelectableValue[] = [
  { label: 'Numerico', description: 'Valor numerico positivo o negativo', value: 'numeric' },
  { label: 'Logico', description: 'Un balor binario (true / false)', value: 'boolean' },
  { label: 'Texto', description: 'Un cadena de texto generica', value: 'string' },
];
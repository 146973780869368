import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import { LinkInspectorData } from 'types';
import { Icon } from '@grafana/ui';
import './css/LinkInspector.css';

interface LinkDescriptorBidiProps {
  id: string;
  link: LinkInspectorData[];
  width: number;
  height: number;
  lineWidth: number;
  fontSize: number;
  iconSize: number;
  metricUnit: string;
}

export class LinkDescriptorBidi extends Component<LinkDescriptorBidiProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
	const linkInfo = this.props.link;
    const isDark = config.theme.isDark || false;
	const fontColor = isDark ? '#E6E9ED' : '#23282E';
    const okColor = isDark ? '#936BFF' : '#5D2BE9';
    const warningColor = isDark ? '#D9AF27' : ' #FF7F27';
	const alarmColor = isDark ? '#F74545' : '#FB3333';
	const sfpCage = isDark ? '#58A95680' : '#D8DFE9';
    const markColor = isDark ? '#E6E9ED' : '#44444C';
    const shadowColor = isDark ? '#5A6171' : '#646A73';
	const isUTP = (linkInfo.odfTypeA === 'RJ-45' || linkInfo.odfTypeB === 'RJ-45') ? true : false;
    const lineWidth = this.props.lineWidth;
    const totalWidth = this.props.width;
	const totalHeight = this.props.height;
	const rectWidth = [110, 110, 35, 35, 30, 30, 30, 30, 30, 30];
	const rectHeight = [100, 100, 80, 80, 30, 30, 30, 30, 30, 30];
	const odfType = isDark ? [
      { icon: 'sc_connector', value: 'SC-APC', color: '#58A956' },
      { icon: 'sc_connector', value: 'SC-UPC', color: '#557FFF' },
      { icon: 'lc_connector', value: 'LC-APC', color: '#58A956' },
      { icon: 'lc_connector', value: 'LC-UPC', color: '#557FFF' },
      { icon: 'lc_connector', value: 'LC-PC', color: '#557FFF' },
      { icon: 'sc_connector', value: 'SC', color: '#557FFF' },
      { icon: 'lc_connector', value: 'LC', color: '#557FFF' },
      { icon: 'fo_connector', value: 'ST', color: '#D9AF27' },
      { icon: 'utp_connector', value: 'RJ-45', color: '#E6E9ED' }
    ] :	[
      { icon: 'sc_connector', value: 'SC-APC', color: '#24AB00' },
      { icon: 'sc_connector', value: 'SC-UPC', color: '#6C63FE' },
      { icon: 'lc_connector', value: 'LC-APC', color: '#24AB00' },
      { icon: 'lc_connector', value: 'LC-UPC', color: '#6C63FE' },
      { icon: 'lc_connector', value: 'LC-PC', color: '#6C63FE' },
      { icon: 'sc_connector', value: 'SC', color: '#6C63FE' },
      { icon: 'lc_connector', value: 'LC', color: '#6C63FE' },
      { icon: 'fo_connector', value: 'ST', color: '#FF7F27' },
      { icon: 'utp_connector', value: 'RJ-45', color: '#E6E9ED' }
    ];
    const odfTypeA = odfType.find(types => {
      return types.value === linkInfo.odfTypeA;
    });
    const odfTypeB = odfType.find(types => {
      return types.value === linkInfo.odfTypeB;
    });
	const icons = [
	  linkInfo.assetTypeA,
	  linkInfo.assetTypeB,
	  '',
	  '',
	  'arrow-to-right',
	  odfTypeA.icon,
	  'arrow-from-right',
	  'left-arrow-from-left',
	  odfTypeB.icon,
	  'left-arrow-to-left'
	];
	const moduleAType = linkInfo.moduleTypeA.split('|', 2).map(String);
	const moduleBType = linkInfo.moduleTypeB.split('|', 2).map(String);
	const legends = [
	  `${linkInfo.assetA} [${linkInfo.siteA}]`,
	  `${linkInfo.assetB} [${linkInfo.siteB}]`,
	  moduleAType[0],
	  moduleBType[0],
	  '',
	  totalWidth > 895 ? linkInfo.odfA1 : '',
	  '',
	  '',
	  totalWidth > 895 ? linkInfo.odfB1 : '',
	  ''
	];

    const sfpColorA = linkInfo.linkAdminA ? sfpCage : alarmColor;
	const sfpColorB = linkInfo.linkAdminB ? sfpCage : alarmColor;
	const rectColors = isDark ?
	  ['#5A6171', '#5A6171', sfpColorA, sfpColorB, '#D9AF27', odfTypeA.color, '#E5CD68', '#E5CD68', odfTypeB.color, '#D9AF27'] :
	  ['#F4F9FF', '#F4F9FF', sfpColorA, sfpColorB, '#FF7F27', odfTypeA.color, '#FFC530', '#FFC530', odfTypeB.color, '#FF7F27'];

	const lineAColor = linkInfo.linkStatusA ? okColor : alarmColor;
    const lineBColor = linkInfo.linkStatusB ? okColor : alarmColor;

    const rectPositionsX = isUTP && totalWidth > 1195 ? [
	  150, totalWidth - 170, 150, totalWidth - 170, 150, 425, totalWidth - 170, 150, totalWidth - 445, totalWidth - 170
	] : [
	  150, totalWidth - 170, 150, totalWidth - 170, 150, 225, totalWidth - 170, 150, totalWidth - 245, totalWidth - 170
	];
	const rectPositionsY = [30, 30, 35, 35, 40, 60, 40, 75, 60, 75];

	const convertUnit = (kbps) => {
	  if (kbps >= 1000000000) {
		const tbps = kbps / 1000000000;
		return `${tbps.toFixed(0)} Tbps`;
	  } else if (kbps >= 1000000) {
		const gbps = kbps / 1000000;
		return `${gbps.toFixed(0)} Gbps`;
	  } else if (kbps >= 1000) {
		const mbps = kbps / 1000;
		return `${mbps.toFixed(0)} Mbps`;
	  } else {
		return `${kbps} Kbps`;
	  }
	};

	const tooltips = isUTP ? [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  linkInfo.assetTypeB + ' ' + linkInfo.assetB,
	  `Puerto ${linkInfo.portA} - ${linkInfo.moduleTypeA}`,
	  `Puerto ${linkInfo.portB} - ${linkInfo.moduleTypeB}`,
	  `Estado Administrativo: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Panel ${linkInfo.odfTypeA}`,
	  `Estado Operativo: ${linkInfo.linkStatusA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Estado Operativo: ${linkInfo.linkStatusB ? 'Habilitado' : 'Deshabilitado'}`,
	  `Panel ${linkInfo.odfTypeB}`,
	  `Estado Administrativo: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	] : [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  linkInfo.assetTypeB + ' ' + linkInfo.assetB,
	  `Puerto ${linkInfo.portA} - ${linkInfo.moduleTypeA}`,
	  `Puerto ${linkInfo.portB} - ${linkInfo.moduleTypeB}`,
	  `Tx Optical power: ${linkInfo.ddmTxA} ${this.props.metricUnit}`,
	  `Panel ${linkInfo.odfTypeA}`,
	  `Rx Optical power: ${linkInfo.ddmRxB} ${this.props.metricUnit}`,
	  `Rx Optical power: ${linkInfo.ddmRxA} ${this.props.metricUnit}`,
	  `Panel ${linkInfo.odfTypeB}`,
	  `Tx Optical power: ${linkInfo.ddmTxA} ${this.props.metricUnit}`
	];
	const OriginInfoTDR = linkInfo.odfTypeA === 'RJ-45' && linkInfo.cableLenghtA.length > 3 ? [
	  [`Medición TDR`],
	  [`Par`,`Long`,`Estado`],
	  [`1`,`${linkInfo.cableLenghtA[0]}m`,`${linkInfo.cableStatusA[0]}`],
	  [`2`,`${linkInfo.cableLenghtA[1]}m`,`${linkInfo.cableStatusA[1]}`],
	  [`3`,`${linkInfo.cableLenghtA[2]}m`,`${linkInfo.cableStatusA[2]}`],
	  [`4`,`${linkInfo.cableLenghtA[3]}m`,`${linkInfo.cableStatusA[3]}`]
	] : [];
	const DestinationInfoTDR = linkInfo.odfTypeB === 'RJ-45' && linkInfo.cableLenghtB.length > 3 ? [
	  [`Medición TDR`],
	  [`Par`,`Long`,`Estado`],
	  [`1`,`${linkInfo.cableLenghtB[0]}m`,`${linkInfo.cableStatusB[0]}`],
	  [`2`,`${linkInfo.cableLenghtB[1]}m`,`${linkInfo.cableStatusB[1]}`],
	  [`3`,`${linkInfo.cableLenghtB[2]}m`,`${linkInfo.cableStatusB[2]}`],
	  [`4`,`${linkInfo.cableLenghtB[3]}m`,`${linkInfo.cableStatusB[3]}`]
	] : [];
	const destTdrInfoY = [-50, -23, 15];
	const sourceTdrInfoY = [-20, 7, 45];
	const OriginInfoUTP = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`,
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const DestinationInfoUTP = linkInfo.linkAdminB ? [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedB)}`,
	] : [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	];

	const ddmTxA = (Math.round(linkInfo.ddmTxA * 100) / 100).toFixed(2);
	const ddmRxA = (Math.round(linkInfo.ddmRxA * 100) / 100).toFixed(2);
	const ddmTxB = (Math.round(linkInfo.ddmTxB * 100) / 100).toFixed(2);
	const ddmRxB = (Math.round(linkInfo.ddmRxB * 100) / 100).toFixed(2);
	const OriginInfo = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`,
	  `λ: ${linkInfo.moduleLambdaA} nm`,
	  `Tx Optical power: ${ddmTxA} ${this.props.metricUnit}`,
	  `Rx Optical power: ${ddmRxA} ${this.props.metricUnit}`,
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const DestinationInfo = linkInfo.linkAdminB ? [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedB)}`,
	  `λ: ${linkInfo.moduleLambdaB} nm`, 
	  `Tx Optical power: ${ddmTxB} ${this.props.metricUnit}`,
	  `Rx Optical power: ${ddmRxB} ${this.props.metricUnit}`
	] : [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	];
	const OriginTrafficInfo = linkInfo.linkAdminA ? [
	  `${linkInfo.assetA} ▶`,
	  `Unicast: ${linkInfo.uPaquetsA} Pkts`,
	  `NonUnicast: ${linkInfo.nuPaquetsA} Pkts`,
	  `Error: ${linkInfo.errorPaquetsA} Pkts`,
	] : [];
	const DestinationTrafficInfo = linkInfo.linkAdminB ? [
	  `◀ ${linkInfo.assetB}`,
	  `Unicast: ${linkInfo.uPaquetsB} Pkts`,
	  `NonUnicast: ${linkInfo.nuPaquetsB} Pkts`,
	  `Error: ${linkInfo.errorPaquetsB} Pkts`,
	] : [];

	const OriginLldpInfo = linkInfo.linkAdminA && linkInfo.lldpDataA.length > 2 ? [
	  `LLDP ▶ ${linkInfo.assetB}`,
	  `Activo Remoto: ${linkInfo.lldpDataB[0]}`,
	  `Interface: ${linkInfo.lldpDataB[1]}`,
	  `Descripción: ${linkInfo.lldpDataB[2]}`,
	] : [];
	const DestinationLldpInfo = linkInfo.linkAdminB && linkInfo.lldpDataB.length > 2 ? [
	  `${linkInfo.assetA} ◀ LLDP`,
	  `Activo Remoto: ${linkInfo.lldpDataA[0]}`,
	  `Interface: ${linkInfo.lldpDataA[1]}`,
	  `Descripción: ${linkInfo.lldpDataA[2]}`,
	] : [];

	const foLossA = -(linkInfo.ddmRxB - linkInfo.ddmTxA);
	const foLossB = -(linkInfo.ddmRxA - linkInfo.ddmTxB);
	const foMarginA = (linkInfo.ddmRxB - linkInfo.ddmWlA);
	const foMarginB = (linkInfo.ddmRxA - linkInfo.ddmWlB);
	const foLossTextA = (Math.round(foLossA * 100) / 100).toFixed(2);
	const foLossTextB = (Math.round(foLossB * 100) / 100).toFixed(2);
	const foMarginTextA = (Math.round(foMarginA * 100) / 100).toFixed(2);
	const foMarginTextB = (Math.round(foMarginB * 100) / 100).toFixed(2);
	const rxWarningA = linkInfo.ddmRxA < linkInfo.ddmWlA ? warningColor : fontColor;
	const rxAlarmA = (linkInfo.ddmRxA < linkInfo.ddmAlA || !linkInfo.linkAdminA) ? alarmColor : rxWarningA;
	const rxWarningB = linkInfo.ddmRxB < linkInfo.ddmWlB ? warningColor : fontColor;
	const rxAlarmB = (linkInfo.ddmRxB < linkInfo.ddmAlB || !linkInfo.linkAdminB) ? alarmColor : rxWarningA;
	
	const trafficAlarmA = linkInfo.errorPaquetsA > linkInfo.inPaquetsA * 0.01 ? warningColor : fontColor;
	const trafficAlarmB = linkInfo.errorPaquetsB > linkInfo.inPaquetsB * 0.01 ? warningColor : fontColor;
	const use4Pairs = linkInfo.speedA > 100 && linkInfo.speedB > 100 ? true : false;
	const cableOffset = use4Pairs ? [-9, -3, 3, 9] : [-6, 6, 0, 0];
	const cableColors = isDark ? ['#A44D01', '#58A956', '#557FFF', '#D9AF27'] : ['#FF7F27', '#24AB00', '#6C63FE', '#562236'];

	const lldpAlarmA = (linkInfo.lldpDataA[0] !== linkInfo.assetB || linkInfo.lldpDataA[1] !== linkInfo.portB) ?
	  alarmColor : fontColor;
	const lldpAlarmB = (linkInfo.lldpDataB[0] !== linkInfo.assetA || linkInfo.lldpDataB[1] !== linkInfo.portA) ?
	  alarmColor : fontColor;

    return (
      <svg width={totalWidth} height={totalHeight}>
        {/* Línea de FO o UTP */}
        {isUTP && (
		  <g>
		    <line
              x1={rectPositionsX[3] + rectWidth[3] / 2}
              y1={rectPositionsY[3] + (rectHeight[3] / 2)}
              x2={rectPositionsX[8] + rectWidth[8] / 2}
              y2={rectPositionsY[8] + (rectHeight[8] / 2)}
              stroke={lineAColor}
              strokeWidth={lineWidth}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
            />
		    <line
              x1={rectPositionsX[2] + rectWidth[2] / 2}
              y1={rectPositionsY[2] + (rectHeight[2] / 2)}
              x2={rectPositionsX[5] + rectWidth[5] / 2}
              y2={rectPositionsY[5] + (rectHeight[5] / 2)}
              stroke={lineAColor}
              strokeWidth={lineWidth}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
            />
		    <line
              x1={rectPositionsX[5] + rectWidth[5] / 2}
              y1={rectPositionsY[5] + (rectHeight[5] / 2) + cableOffset[0]}
              x2={rectPositionsX[8] + rectWidth[8] / 2}
              y2={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[0]}
              stroke={cableColors[0]}
              strokeWidth={lineWidth}
			  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
            />
		    <line
              x1={rectPositionsX[5] + rectWidth[5] / 2}
              y1={rectPositionsY[5] + (rectHeight[5] / 2) + cableOffset[1]}
              x2={rectPositionsX[8] + rectWidth[8] / 2}
              y2={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[1]}
              stroke={cableColors[1]}
              strokeWidth={lineWidth}
			  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
            />
		  </g>
		)}
		{isUTP && use4Pairs && (
		  <g>
			<line
			  x1={rectPositionsX[5] + rectWidth[5] / 2}
			  y1={rectPositionsY[5] + (rectHeight[5] / 2) + cableOffset[2]}
			  x2={rectPositionsX[8] + rectWidth[8] / 2}
			  y2={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[2]}
			  stroke={cableColors[2]}
			  strokeWidth={lineWidth}
			  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
			  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
			/>
			<line
			  x1={rectPositionsX[5] + rectWidth[5] / 2}
			  y1={rectPositionsY[5] + (rectHeight[5] / 2) + cableOffset[3]}
			  x2={rectPositionsX[8] + rectWidth[8] / 2}
			  y2={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[3]}
			  stroke={cableColors[3]}
			  strokeWidth={lineWidth}
			  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
			  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
			/>
		  </g>
		)}
		{!isUTP && (
		  <line
            x1={rectPositionsX[2] + rectWidth[2] / 2}
            y1={rectPositionsY[2] + (rectHeight[2] / 2)}
            x2={rectPositionsX[3] + rectWidth[3] / 2}
            y2={rectPositionsY[3] + (rectHeight[3] / 2)}
            stroke={lineAColor}
            strokeWidth={lineWidth}
		    style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
          />
		)}
		{/* Elementos */}
        {rectWidth.map((width, index) => {
		  const iconX = index === 1 ? (width * 0.4) : 2;
		  const rectX = index === 1 || index === 3 ? -2 : 0;
		  return (
            <g key={'I_' + index}>
		      <rect
                x={index === 0 || index === 2 ? (rectPositionsX[4] + rectWidth[4] - width + 2) : rectPositionsX[index] + rectX}
                y={rectPositionsY[index]}
				rx={width * 0.05 > 2 ? width * 0.05 : 2}	
                width={width}
                height={rectHeight[index]}
                fill={rectColors[index]}
				stroke={shadowColor}
				stroke-width="1"
              />
              {icons[index] !== '' && (
			    <image
                  href={`/public/img/icons/unicons/${icons[index]}.svg`}
                  x={index === 0 ? (rectPositionsX[4] + rectWidth[4] - width) : rectPositionsX[index] + iconX}
                  y={rectPositionsY[index] + 5}
				  style={isDark && index < 2 ? { filter: 'invert(1)' } : { filter: 'none' }}
				  width={index < 2 ? width * 0.6 : width - 4}
                  height={index < 2 ? rectHeight[index] * 0.6 : rectHeight[index] - 8}
                />
			  )}
			  <title>{tooltips[index]}</title>
		    </g>
		  );
        })}
		{/* Testo de leyendas */}
		<g>
		  {legends.map((legend, index) => {
            const isTop = (index === 0 || index === 1) ? true : false;
            const isLeft = (index === 2 || index === 6 || index === 8 || index === 9) ? true : false; 
			const yGap = (index === 5 || index  === 8) ? 10 : 0;
			const textY = isTop ? 20 : (rectPositionsY[index] + rectHeight[index] + yGap);
            let textX = isTop ? (rectPositionsX[index] + (rectWidth[index] / 2)) : (rectPositionsX[index] + rectWidth[index] + 5);
            let textAnchor = isTop ? 'middle' : 'start';
			if (isLeft) {
			  textX = index === 2 ? (rectPositionsX[index] - 10) : ((rectPositionsX[index]) - 5);
			  textAnchor = 'end';
			}
            return (
              <text
                key={index}
                x={index === 0 ? (rectPositionsX[4] + rectWidth[2] - (rectWidth[0] / 2)) : textX}
                y={textY}
                textAnchor={textAnchor}
                fill={fontColor}
                fontSize={isTop ? this.props.fontSize + 2 : this.props.fontSize}
                fontWeight={isTop ? 'bold' : 'normal'}
              >
                {legend}
              </text>
            );
		  })}
		  {isUTP && OriginInfoUTP.map((line, index) => (
		    <text
			  key={'OriginInfo'}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === OriginInfoUTP.length - 1 ? rxAlarmA : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {isUTP && DestinationInfoUTP.map((line, index) => (
		    <text
			  key={'DestinationInfo'}
			  x={rectPositionsX[3] + (rectWidth[3] / 2) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === DestinationInfoUTP.length - 1 ? rxAlarmB : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 995 && DestinationLldpInfo.map((line, index) => (
		    <text
			  key={'DestinationLldpInfo'}
			  x={rectPositionsX[2] + rectWidth[2] + 25}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index < 1 ? lldpAlarmA : fontColor}
			  fontSize={index < 1 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 995 && OriginLldpInfo.map((line, index) => (
		    <text
			  key={'OriginLldpInfo'}
			  x={rectPositionsX[3] - 25}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index < 1 ? lldpAlarmB : fontColor}
			  fontSize={index < 1 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 1195 && isUTP && OriginInfoTDR.length > 3 && OriginInfoTDR.map((line, index) => (
		    line.map((text, colIndex) => (
			  <text
			    key={`oInfoTDR-${index}-${colIndex}`}
			    x={rectPositionsX[5] + (rectWidth[5] / 2) + sourceTdrInfoY[colIndex]}
			    y={rectPositionsY[5] + rectHeight[5] + 35 + (index * 15)}
			    textAnchor={'start'}
			    fill={fontColor}
			    fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			    fontWeight={index < 2 ? 'bold' : 'normal'}
			  >
			    {text}
			  </text>
		    ))
		  ))}
		  {totalWidth > 1195 && isUTP && DestinationInfoTDR.length > 3 && DestinationInfoTDR.map((line, index) => (
		    line.map((text, colIndex) => (
			  <text
			    key={`dInfoTDR-${index}-${colIndex}`}
			    x={rectPositionsX[8] + (rectWidth[8] / 2) + destTdrInfoY[colIndex]}
			    y={rectPositionsY[8] + rectHeight[8] + 35 + (index * 15)}
			    textAnchor={'start'}
			    fill={fontColor}
			    fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			    fontWeight={index < 2 ? 'bold' : 'normal'}
			  >
			    {text}
			  </text>
		    ))
		  ))}
		  {!isUTP && OriginInfo.map((line, index) => (
		    <text
			  key={'OriginInfo'}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === OriginInfo.length - 1 ? rxAlarmA : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {!isUTP && DestinationInfo.map((line, index) => (
		    <text
			  key={'DestinationInfo'}
			  x={rectPositionsX[3] + (rectWidth[3] / 2) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === DestinationInfo.length - 1 ? rxAlarmB : fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1390 && linkInfo.linkAdminA && linkInfo.linkAdminB) && OriginTrafficInfo.map((line, index) => (
		    <text
			  key={'OriginTrafficInfo'}
			  x={(totalWidth * 0.5) - 5}
			  y={rectPositionsY[8] + (rectHeight[8] / 2) + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={(index === OriginTrafficInfo.length - 1) ? trafficAlarmA : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1390 && linkInfo.linkAdminA && linkInfo.linkAdminB) && DestinationTrafficInfo.map((line, index) => (
		    <text
			  key={'DestinationTrafficInfo'}
			  x={(totalWidth * 0.55) + 5}
			  y={rectPositionsY[8] + (rectHeight[8] / 2) + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={(index === DestinationTrafficInfo.length - 1) ? trafficAlarmB : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 695 && linkInfo.linkAdminA && linkInfo.linkAdminB && linkInfo.odfTypeB !== 'RJ-45' && (
		    <g>
			  <text
			    key={'foStatusA'}
			    x={totalWidth / 2}
			    y={rectPositionsY[4] + (rectHeight[4] / 2) - 20}
			    textAnchor={'middle'}
			    fill={foLossA > foLossB * 1.2 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1}
			    fontWeight={foLossA > foLossB * 1.2 ? 'bold': 'normal'}
		      >
			    {`Link Loss: ${foLossTextA} dB`}
		      </text>
			  <text
			    key={'foStatusA'}
			    x={totalWidth / 2}
			    y={rectPositionsY[4] + (rectHeight[4] / 2) - 5}
			    textAnchor={'middle'}
			    fill={foMarginA < 0 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1}
			    fontWeight={foMarginA < 0 ? 'bold': 'normal'}
		      >
			    {`Margen: ${foMarginTextA} dB`}
		      </text>
		      <text
			    key={'foStatusB'}
			    x={totalWidth / 2}
			    y={rectPositionsY[8] + (rectHeight[8] / 2) + 15}
			    textAnchor={'middle'}
			    fill={foLossB > foLossA * 1.2 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1}
			    fontWeight={foLossB > foLossA * 1.2 ? 'bold': 'normal'}
		      >
			    {`Link Loss: ${foLossTextB} dB`}
		      </text>
		      <text
			    key={'foStatusB'}
			    x={totalWidth / 2}
			    y={rectPositionsY[8] + (rectHeight[8] / 2) + 30}
			    textAnchor={'middle'}
			    fill={foMarginB < 0 ? warningColor : fontColor}
			    fontSize={this.props.fontSize - 1}
			    fontWeight={foMarginB < 0 ? 'bold': 'normal'}
		      >
			    {`Margen: ${foMarginTextB} dB`}
		      </text>
			</g>
		  )}
		</g>
		{/* Lineas de leyendas */}
		{totalWidth > 640 && (
		  <line
            x1={rectPositionsX[2] + rectWidth[2] / 2}
            y1={rectPositionsY[2] + rectHeight[2]}
            x2={rectPositionsX[2] + rectWidth[2] / 2}
            y2={rectPositionsY[2] + rectHeight[2] + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
        )}
		{totalWidth > 640 && (
		  <line
            x1={rectPositionsX[3] + rectWidth[3] / 2}
            y1={rectPositionsY[3] + rectHeight[3]}
            x2={rectPositionsX[3] + rectWidth[3] / 2}
            y2={rectPositionsY[3] + rectHeight[3] + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
        )}
		{isUTP && OriginInfoTDR.length > 3 && totalWidth > 1195 && (
		  <line
            x1={rectPositionsX[5] + rectWidth[5] / 2}
            y1={rectPositionsY[5] + rectHeight[5]}
            x2={rectPositionsX[5] + rectWidth[5] / 2}
            y2={rectPositionsY[5] + rectHeight[5] + 20}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="2,2"
          />
        )}
		{isUTP && DestinationInfoTDR.length > 3 && totalWidth > 1195 && (
          <line
            x1={rectPositionsX[8] + rectWidth[8] / 2}
            y1={rectPositionsY[8] + rectHeight[8]}
            x2={rectPositionsX[8] + rectWidth[8] / 2}
            y2={rectPositionsY[8] + rectHeight[8] + 20}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="2,2"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1390 && ( 
		  <line
            x1={totalWidth * 0.5}
            y1={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[0]}
            x2={totalWidth * 0.5}
            y2={rectPositionsY[8] + (rectHeight[8] / 2) + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1390 && ( 
          <line
            x1={totalWidth * 0.55}
            y1={rectPositionsY[8] + (rectHeight[8] / 2) + cableOffset[3]}
            x2={totalWidth * 0.55}
            y2={rectPositionsY[8] + (rectHeight[8] / 2) + 40}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
      </svg>
    );
  }
}

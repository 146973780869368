import React, { useState } from 'react';
import { css } from '@emotion/css';
import { useFormContext, FieldError, FieldErrors, DeepMap } from 'react-hook-form';
import { config } from '@grafana/runtime';
import { Button, Field, Input } from '@grafana/ui';
import { NotificationChannelOption, NotificationChannelSecureFields } from 'app/types';

import { ChannelValues, ReceiverFormValues } from 'app/features/alerting/unified/types/receiver-form';
import { OptionField } from 'app/features/alerting/unified/components/receivers/form/fields/OptionField';

export interface Props<R extends ChannelValues> {
  defaultValues: R;
  selectedChannelOptions: NotificationChannelOption[];
  secureFields: NotificationChannelSecureFields;
  onResetSecureField: (key: string) => void;
  errors?: FieldErrors<R>;
  pathPrefix?: string;
  readOnly?: boolean;
  mediaType?: string;
}

export function ChannelOptions<R extends ChannelValues>({
  defaultValues,
  selectedChannelOptions,
  onResetSecureField,
  secureFields,
  errors,
  pathPrefix = '',
  readOnly = false,
  mediaType = '',
}: Props<R>): JSX.Element {
  const isDark = config.theme.isDark;
  const { watch, register, setValue } = useFormContext<ReceiverFormValues<R>>();
  const currentFormValues = watch() as Record<string, any>;
  const [subjectText, setSubjectText] = useState(null);
  const [subjectTemplate, setSubjectTemplate] = useState(null);
  const [messageText, setMessageText] = useState(null);
  const [messageTemplate, setMessageTemplate] = useState(null);
  const [titleText, setTitleText] = useState(null);
  const [titleTemplate, setTitleTemplate] = useState(null);
  const mediaTypeToUse = mediaType !== '' ? mediaType : 'mail';
  return (
    <>
      {selectedChannelOptions.map((channel: NotificationChannelOption, index: number) => {
        const key = `${channel.label}-${index}`;
        const selectedOptionValue = currentFormValues[`${pathPrefix}settings.${channel.showWhen.field}`];

        if (channel.showWhen.field && selectedOptionValue !== channel.showWhen.is) {
          return null;
        }
        if (secureFields && secureFields[channel.propertyName]) {
          return (
            <Field key={key} label={channel.label} description={channel.description || undefined}>
              <Input
                readOnly={true}
                value="Configured"
                suffix={
                  readOnly ? null : (
                    <Button 
                      onClick={() => onResetSecureField(channel.propertyName)}
                      fill="text"
                      type="button"
                      size="md"
                      icon="redo"
                    />
                  )
                }
              />
            </Field>
          );
        }

        const error: FieldError | DeepMap<any, FieldError> | undefined = (
          (channel.secure ? errors?.secureSettings : errors?.settings) as DeepMap<any, FieldError> | undefined
        )?.[channel.propertyName];

		if (channel.propertyName !== 'subject' && channel.propertyName !== 'message' && channel.propertyName !== 'title') {
          const defaultValue = defaultValues?.settings?.[channel.propertyName];
		  return (
            <OptionField
              defaultValue={defaultValue}
              readOnly={readOnly}
              key={key}
              error={error}
              pathPrefix={pathPrefix}
              pathSuffix={channel.secure ? 'secureSettings.' : 'settings.'}
              option={channel}
            />
          );
		} else if (channel.propertyName === 'message') {
		  const defaultValue = defaultValues?.settings?.message;
		  const regex = /({{.*?}})/;
		  const match = defaultValue ? defaultValue.match(regex) : null;
		  const oldMessageText = match ? defaultValue.substring(0, match.index) : '';
		  const oldMessageTemplate = match ? match[0] : `{{ template "__${mediaTypeToUse}_message.empty" . }}`;
		  if (!messageText && oldMessageText) {
		    setMessageText(oldMessageText);
		  }
		  if (!messageTemplate && oldMessageTemplate) {
		    setMessageTemplate(oldMessageTemplate);
		  }
		  return (
            <div key={key}>
			  <div><label>{'Cuerpo del Mensaje'}</label></div>
              <div><label className={isDark ? styles.subLabel_dark : styles.subLabel}>{channel.description}</label></div>
			  <div>
			    <input
			      type="text"
			      value={messageText ? messageText : oldMessageText}
			      onChange={(e) => {
					const newValue = messageTemplate ? 
					  e.target.value + messageTemplate : 
					  e.target.value + oldMessageTemplate;
				    setMessageText(e.target.value);
				    setValue(`${pathPrefix}settings.message`, newValue);
				  }}
				  className={isDark ? styles.input_dark : styles.input}
			    />
                <select
				  value={messageTemplate ? messageTemplate : oldMessageTemplate}
			      onChange={(e) => {
				    const newValue = messageText ? 
					  messageText + e.target.value : 
					  oldMessageText + e.target.value;
				    setMessageTemplate(e.target.value);
				    setValue(`${pathPrefix}settings.message`, newValue);
				  }}
				  className={isDark ? styles.select_dark : styles.select}
                >
                  <option value={`{{ template "__${mediaTypeToUse}_message.empty" . }}`}>Solo enviar el texto ingresado</option>
				  <option value={`{{ template "__${mediaTypeToUse}_message.short" . }}`}>Enviar Texto + Información abreviada del evento</option>
                  <option value={`{{ template "__${mediaTypeToUse}_message.normal" . }}`}>Enviar Texto + Información del evento</option>
                  <option value={`{{ template "__${mediaTypeToUse}_message.long" . }}`}>Enviar Texto + Información completa del evento</option>
                </select>
              </div>
			</div>
          );
		} else if (channel.propertyName === 'subject') {
		  const defaultValue = defaultValues?.settings?.subject;
		  const regex = /({{.*?}})/;
		  const match = defaultValue ? defaultValue.match(regex) : null;
		  const oldSubjectText = match ? defaultValue.substring(0, match.index) : '';
		  const oldSubjectTemplate = match ? match[0] : '{{ template "_subject.empty" . }}';
		  const enableSelect = (!subjectText && oldSubjectText === '') ? true : false;
		  if (!subjectText && oldSubjectText) {
		    setSubjectText(oldSubjectText);
		  }
          return (
            <div key={key}>
			  <div><label>{channel.label}</label></div>
              <div><label className={isDark ? styles.subLabel_dark : styles.subLabel}>{channel.description}</label></div>
			  <div>
			    <input
			      type="text"
			      value={subjectText ? subjectText : oldSubjectText}
			      onChange={(e) => {
					if (e.target.value !== '') {
				      setSubjectText(e.target.value);
					  setSubjectTemplate('{{ template "_subject.empty" . }}');
				      setValue(`${pathPrefix}settings.subject`, e.target.value + '{{ template "_subject.empty" . }}');
					} else {
				      setSubjectText(null);
					  setSubjectTemplate('{{ template "_subject.empty" . }}');
				      setValue(`${pathPrefix}settings.subject`, '{{ template "_subject.empty" . }}');
					}
				  }}
				  className={isDark ? styles.input_dark : styles.input}
			    />
                {enableSelect && (
				  <select
				    value={subjectTemplate ? subjectTemplate : oldSubjectTemplate}
				    onChange={(e) => {
				      setSubjectTemplate(e.target.value);
				      setValue(`${pathPrefix}settings.subject`, e.target.value);
				    }}
				    className={isDark ? styles.select_dark : styles.select}
                  >
                    <option value={'{{ template "_subject.empty" . }}'}>No enviar asunto</option>
				    <option value={'{{ template "_subject.short" . }}'}>Enviar Información abreviada del evento</option>
                    <option value={'{{ template "_subject.normal" . }}'}>Enviar Información del evento</option>
                    <option value={'{{ template "_subject.long" . }}'}>Enviar Información completa del evento</option>
                  </select>
				)}
              </div>
			</div>
          );
		} else if (channel.propertyName === 'title') {
		  const defaultValue = defaultValues?.settings?.title;
		  const regex = /({{.*?}})/;
		  const match = defaultValue ? defaultValue.match(regex) : null;
		  const oldTitleText = match ? defaultValue.substring(0, match.index) : '';
		  const oldTitleTemplate = match ? match[0] : '{{ template "_title.empty" . }}';
		  const enableSelect = (!titleText && oldTitleText === '') ? true : false;
		  if (!titleText && oldTitleText) {
		    setTitleText(oldTitleText);
		  }
		  return (
            <div key={key}>
			  <div><label>{channel.label}</label></div>
              <div><label className={isDark ? styles.subLabel_dark : styles.subLabel}>{channel.description}</label></div>
			  <div>
			    <input
			      type="text"
			      value={titleText ? titleText : oldTitleText}
			      onChange={(e) => {
					if (e.target.value !== '') {
				      setTitleText(e.target.value);
					  setTitleTemplate('{{ template "_title.empty" . }}');
				      setValue(`${pathPrefix}settings.title`, e.target.value + '{{ template "_title.empty" . }}');
					} else {
				      setTitleText(null);
					  setTitleTemplate('{{ template "_title.empty" . }}');
				      setValue(`${pathPrefix}settings.title`, '{{ template "_title.empty" . }}');
					}

				  }}
				  className={isDark ? styles.input_dark : styles.input}
			    />
				{enableSelect && (
                  <select
				    value={titleTemplate ? titleTemplate : oldTitleTemplate}
				    onChange={(e) => {
				      setTitleTemplate(e.target.value);
				      setValue(`${pathPrefix}settings.title`, e.target.value);
				    }}
				    className={isDark ? styles.select_dark : styles.select}
                  >
                    <option value={'{{ template "_title.empty" . }}'}>No enviar titulo</option>
				    <option value={'{{ template "_title.short" . }}'}>Enviar Información abreviada del evento</option>
                    <option value={'{{ template "_title.normal" . }}'}>Enviar Información del evento</option>
                    <option value={'{{ template "_title.long" . }}'}>Enviar Información completa del evento</option>
                  </select>
				)}
              </div>
			</div>
          );
		}
      })}
    </>
  );
}

const styles = {
  subLabel: css`
    font-size: 12px;
	font-weight: 300;
	color: #24292ebf;
  `,
  subLabel_dark: css`
    font-size: 12px;
	font-weight: 300;
	color: #c8c8c8a6;
  `,
  select: css`
    background: white;
    color: #24292e;
    border: 1px solid #24292e4d;
    border-radius: 2px;
    padding: 1px 8px;
	min-width: 100px;
	width: 38%;
    font-size: 12px;
    margin-left: 2%;
    height: 22px;
  `,
  input: css`
    background: white;
    line-height: 1.5;
    color: #24292e;
    border: 1px solid #24292e4d;
    border-radius: 2px;
    padding: 1px 8px;
    min-width: 150px;
    width: 60%;
    font-size: 12px;
    height: 22px;
  `,
  select_dark: css`
    background: black;
    color: #c8c8c8;
    border: 1px solid #c8c8c826;
    border-radius: 2px;
    padding: 1px 8px;
	min-width: 100px;
	width: 38%;
    font-size: 12px;
    margin-left: 2%;
    height: 22px;
  `,
  input_dark: css`
    background: black;
    line-height: 1.5;
    color: #c8c8c8;
    border: 1px solid #c8c8c826;
    border-radius: 2px;
    padding: 1px 8px;
    min-width: 150px;
    width: 60%;
    font-size: 12px;
    height: 22px;
  `,
};
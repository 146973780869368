import { css, cx } from '@emotion/css';
import React, { FC } from 'react';
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  Select,
  Switch,
  useStyles2,
} from '@grafana/ui';

import { CustomFormSmSign } from './CustomSmSigns';
import { severityFieldOptions } from 'app/features/alerting/unified/utils/alertmanager';
import { valueTypeOptions } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface SmSignsExpandedFormProps {
  onCancel: () => void;
  onSave: (data: CustomFormSmSign[]) => void;
  sign: CustomFormSmSign;
  isDark: boolean;
  isAdmin: boolean;
  isAddMode: boolean;
  width: number;
  signs: CustomFormSmSign[];
  assets: [];
  signTypeList: [];
  types: [];
  pluginVariables: [];
}

export const CustomSmSignsExpandedForm: FC<SmSignsExpandedFormProps> = ({ 
  onCancel, 
  onSave, 
  sign, 
  isDark, 
  isAdmin,
  isAddMode,
  width, 
  signs,
  assets,
  signTypeList,
  types,
  pluginVariables
}) => {
  const styles = useStyles2(getStyles(isDark, width));

  const valueToString = (field: CustomFormSmSign) => {
	const severities = severityFieldOptions;
	const valueSelected = severities.find(severity => severity.value === field.value);
	return valueSelected;
  }

  const eventToString = (field: CustomFormSmSign) => {
	const events = signTypeList;
	const valueSelected = events.find(event => event.value === field.value);
	return valueSelected;
  }

  const typeToString = (field: CustomFormSmSign) => {
	const variableTypes = valueTypeOptions;
	const valueSelected = variableTypes.find(types => types.value === field.value);
	return valueSelected;
  }

  const assetToString = (field: CustomFormSmSign) => {
	const assetNames = assets;
	const valueSelected = assetNames.find(asset => asset.value === field.value);
	return valueSelected;
  }

  const assetTypeToString = (field: CustomFormSmSign) => {
	const assetTypes = types;
	const valueSelected = assetTypes.find(assetType => assetType.value === field.value);
	return valueSelected;
  }

  return (
    <Form defaultValues={sign} onSubmit={onSave} className={styles.formContainer}>
      {({ control, register, watch }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  <Field label="Procesar firma" className={styles.switchContainer}>
            <Switch id="alarmState" {...register('state')} />
          </Field>
          {watch().state && (
		    <>
			  <HorizontalGroup key={'mainOptions'} align="flex-start" height="55px">
                <Field 
		          label="Tipo de Activo:"
				  className={styles.nameContainer}
		        >
			      <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="assetType"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={types}
				        defaultValue={assetTypeToString(field)}
						title="Selecciones un tipo de activo a monitorear."
					  />
				    )}
				    control={control}
				    name="assetType"
				  />
                </Field>
                {watch().assetType && (
				  <Field 
		            label="ID:"
				    className={styles.nameContainer}
		          >
			        <InputControl
			  	      render={({ field: { onChange, ref, ...field } }) => (
				        <Select
					      aria-label="asset"
					      {...field}
					      className={styles.input}
					      onChange={(value) => onChange(mapSelectValueToString(value))}
					      options={assets.filter(asset => asset.family === watch().assetType)}
				          defaultValue={assetToString(field)}
						  title="Seleccione el ID de Activo que se monitoreará."
					    />
				      )}
				      control={control}
				      name="asset"
				    />
                  </Field>
				)}
                <Field 
		          label="OID:"
				  className={styles.nameContainer}
		        >
                  <InputControl
                    render={({ field }) => (
                      <Input
                        aria-label="oid"
                        {...field}
				        className={styles.input}
				        placeholder="OID"
						title="OID SNMP que consulta la firma."
                      />
                    )}
                    control={control}
                    name="oid"
                  />
                </Field>
			    <Field 
			      label="Asignado a:"
				  className={styles.nameContainer}
			    >
			      <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="oidType"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={signTypeList}
				        defaultValue={eventToString(field)}
						title="Naturaleza del objeto o variable SNMP que se monitorea a traves del OID indicado."
					  />
				    )}
				    control={control}
				    name="signType"
				  />
			    </Field>
			  </HorizontalGroup>
              <HorizontalGroup key={'thresholdOptions'} align="flex-start" height="55px">  
			    <Field 
			      label="Tipo de OID:"
				  className={styles.nameContainer}
			    >
			      <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="ValueType"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={valueTypeOptions}
				        defaultValue={typeToString(field)}
						title="Tipo de variable que será devuelto por el OID indicado."
					  />
				    )}
				    control={control}
				    name="oidValueType"
				  />
			    </Field>
				{watch().oidValueType === 'boolean' && (
				  <Field 
				    label="Activar alerta en verdadero:" 
				    cclassName={styles.switchContainer}
				  >
                    <Switch 
				      id="oidThresholdBoolean" {...register('oidThresholdBoolean')}
					  className={styles.switchStyle}
				      title="Activa la alerta en verdadero."
				    />
                  </Field>
				)}
                {watch().oidValueType === 'numeric' && (
				  <Field 
				    label="Utilizar umbral superior e inferior:"
				    className={styles.switchContainer}
				  >
                    <Switch 
				      id="oidDualThreshold" {...register('oidDualThreshold')}
					  className={styles.switchStyle}
				      title="Activa la alerta cuando el valor obtenido es mayor o menor a los umbrales definidos."
				    />
                  </Field>
				)}
                {watch().oidValueType === 'numeric' && !watch().oidDualThreshold && (
				  <Field 
				    label="Activar por umbral superior:"
				    className={styles.switchContainer}
				  >
                    <Switch 
				      id="oidThresholdBelow" {...register('oidThresholdBelow')}
					  className={styles.switchStyle}
				      title="Activa la alerta cuando el valor obtenido es superior al umbral definido."
				    />
                  </Field>
				)}
                {watch().oidValueType === 'numeric' && (watch().oidDualThreshold || watch().oidThresholdBelow) && (
				  <Field 
		            label="Umbral superior"
				    className={styles.switchContainer}
		          >
                    <InputControl
                      render={({ field }) => (
                        <Input
                          aria-label="thresholdUp"
                          {...field}
				          className={styles.input}
						  type="number"
				          placeholder="ingrese un valor"
						  title="Umbral superior que dispara la Alerta."
                        />
                      )}
                      control={control}
                      name="oidThresholdUp"
                    />
                  </Field>
				)}
                {watch().oidValueType === 'numeric' && (watch().oidDualThreshold || !watch().oidThresholdBelow) && (
				  <Field 
		            label="Umbral inferior"
				    className={styles.switchContainer}
		          >
                    <InputControl
                      render={({ field }) => (
                        <Input
                          aria-label="thresholdDown"
                          {...field}
				          className={styles.input}
				          type="number"
						  placeholder="ingrese un valor"
						  title="Umbral inferior que dispara la Alerta."
                        />
                      )}
                      control={control}
                      name="oidThresholdDown"
                    />
                  </Field>
				)}
                {watch().oidValueType === 'string' && !watch().oidDualThreshold && (
				  <Field 
				    label="Activar por distinto:" 
				    className={styles.switchContainer}
				  >
                    <Switch 
				      id="oidThresholdBelow" {...register('oidThresholdBelow')}
					  className={styles.switchStyle}
				      title="Activa la alerta cuando el valor obtenido es diferente al texto ingresado."
				    />
                  </Field>
				)}
                {watch().oidValueType === 'string' && (
				  <Field 
		            label="Cadena"
				    className={styles.nameContainer}
		          >
                    <InputControl
                      render={({ field }) => (
                        <Input
                          aria-label="thresholdUp"
                          {...field}
				          className={styles.input}
				          placeholder="ingrese el texto esperado"
						  title="Cadena de texto que debe coincidir o no con el valor obtenido del OID."
                        />
                      )}
                      control={control}
                      name="oidThresholdUp"
                    />
                  </Field>
				)}
			  </HorizontalGroup>
			  <HorizontalGroup key={'alertOptions'} align="flex-start" height="55px">
			    <Field 
		          label="Nombre:"
				  className={styles.nameContainer2}
		        >
                  <InputControl
                    render={({ field }) => (
                      <Input
                        aria-label="name"
                        {...field}
                        className={styles.input}
				        placeholder="Nombre"
						title="Nombre otorgado a la Alerta que genera la firma. Este nombre es utilizado en las notificaciones."
						size="50"
                      />
                    )}
                    control={control}
                    name="name"
                  />
                </Field>
			    <Field 
			      label="Severidad:"
				  className={styles.nameContainer}
			    >
			      <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="severity"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={severityFieldOptions}
				        defaultValue={valueToString(field)}
						title="Nivel de severidad que se asignara a una Alerta generada por esta firma."
					  />
				    )}
				    control={control}
				    name="severity"
				  />
			    </Field>
				<Field 
				  label="Abrir Ticket:" 
				  className={styles.switchContainer}
				>
                  <Switch 
				    id="openTicket" {...register('openTicket')}
					className={styles.switchStyle}
				    title="Indica a NetMonitor abrir un incidente en una plataforma externa."
				  />
                </Field>
			  </HorizontalGroup>
              <Field 
		        label="Resumen:"
			    description="Breve descripción de la Alerta. Este campo es utilizado en las notificaciones"
		      >
                <InputControl
                  render={({ field }) => (
                    <Input
                      aria-label="summary"
                      {...field}
				      className={styles.input}
				      placeholder="Descripción"
                    />
                  )}
                  control={control}
                  name="summary"
                />
              </Field>
              <Field 
		        label="Resolución:"
			    description="Mensaje utilizado para notificar la resolución de la Alerta."
		      >
                <InputControl
                  render={({ field }) => (
                    <Input
                      aria-label="resolSummary"
                      {...field}
				      className={styles.input}
				      placeholder="Mensaje"
                    />
                  )}
                  control={control}
                  name="resolSummary"
                />
              </Field>
			</>
		  )}
		  <div className={styles.buttonGroup}>
			<button 
			  type="submit"
			  className={styles.okButton} 
			  title={'Guardar modificaciones'}
			>
			  <div className={styles.button_icon}>
				<Icon name={'save'} size="lg" />
			  </div>
			 {isAddMode ? 'Agregar' : 'Guardar'}
			</button>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={() => {
			    onCancel();
				if (pluginVariables[1] !== '' && pluginVariables[2] !== '') {
				  const queryMap = {
					[`var-${pluginVariables[1]}`]: '-',
					[`var-${pluginVariables[2]}`]: '-',
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
				    console.log(error);
				  }
				}
			  }}
			  title={'Cerrar sin modificar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times-fill" size="lg" />
			  </div>
			  {isAddMode ? 'Cancelar' : 'Cerrar'}
			</button>
			{watch().oid !== '' && watch().asset !== '' && (
			  <button 
			    type="button" 
			    className={styles.testButton} 
			    onClick={() => {
			      if (pluginVariables[1] !== '' && pluginVariables[2] !== '') {
				    const queryMap = {
				      [`var-${pluginVariables[1]}`]: watch().asset,
				      [`var-${pluginVariables[2]}`]: watch().oid,
			        };
			        try {
				      locationService.partial(queryMap, true);
			        } catch (error) {
				      console.log(error);
			        }
				  }
				}}
			    title={'Consultar OID en activo configurado'}
			  >
			    <div className={styles.button_icon}>
				  <Icon name="play-fill" size="lg" />
			    </div>
			    {'Consultar OID'}
			  </button>
			)}
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const testButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    formContainer: css`
      max-width: 100%;
    `,
    nameContainer: css`
      width: ${width * 0.22}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    nameContainer2: css`
      width: ${width * 0.44}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    switchContainer: css`
      width: ${width * 0.15}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    switchStyle: css`
	  width: 32px;
      height: 16px;
      position: relative;
	  top: 4px;
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 11px;
    `,
    testButton: css`
      background-color: ${testButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: ${textColor};
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { LinkInspectorPanel } from './LinkInspectorPanel';

export const plugin = new PanelPlugin<SimpleOptions>(LinkInspectorPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'emplazamientoA',
      path: 'emplazamientoA',
      name: 'Origin Asset Site',
      defaultValue: 'empA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'assetA',
      path: 'assetA',
      name: 'Origin Asset ID',
      defaultValue: 'assetA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'assetTypeA',
      path: 'assetTypeA',
      name: 'Origin Asset Type',
      defaultValue: 'assetTypeA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'portA',
      path: 'portA',
      name: 'Origin Port ID',
      defaultValue: 'portA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'moduleTypeA',
      path: 'moduleTypeA',
      name: 'Origin Module Type',
      defaultValue: 'moduleTypeA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'moduleLambdaA',
      path: 'moduleLambdaA',
      name: 'Origin Module Lambda',
      defaultValue: 'moduleLambdaA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'aliasA',
      path: 'aliasA',
      name: 'Origin Port Alias',
      defaultValue: 'aliasA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'speedA',
      path: 'speedA',
      name: 'Origin Port Speed',
      defaultValue: 'speedA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'linkStatusA',
      path: 'linkStatusA',
      name: 'Origin Link Status',
      defaultValue: 'linkStatusA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'linkAdminA',
      path: 'linkAdminA',
      name: 'Origin Link Admin Status',
      defaultValue: 'linkAdminA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'odfTypeA',
      path: 'odfTypeA',
      name: 'Origin ODF Type',
      defaultValue: 'odfTypeA',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'odfA1',
      path: 'odfA1',
      name: 'Origin ODF Port A',
      defaultValue: 'odfA1',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'odfA2',
      path: 'odfA2',
      name: 'Origin ODF Port B',
      defaultValue: 'odfA2',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'emplazamientoB',
      path: 'emplazamientoB',
      name: 'Origin Asset Site',
      defaultValue: 'empB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'assetB',
      path: 'assetB',
      name: 'Destination Asset ID',
      defaultValue: 'assetB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'assetTypeB',
      path: 'assetTypeB',
      name: 'Destination Asset Type',
      defaultValue: 'assetTypeB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'portB',
      path: 'portB',
      name: 'Destination Port ID',
      defaultValue: 'portB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'moduleTypeB',
      path: 'moduleTypeB',
      name: 'Destination Module Type',
      defaultValue: 'moduleTypeB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'moduleLambdaB',
      path: 'moduleLambdaB',
      name: 'Destination Module Lambda',
      defaultValue: 'moduleLambdaB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'aliasB',
      path: 'aliasB',
      name: 'Destination Port Alias',
      defaultValue: 'aliasB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'speedB',
      path: 'speedB',
      name: 'Destination Port Speed',
      defaultValue: 'speedB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'linkStatusB',
      path: 'linkStatusB',
      name: 'Destination Link Status',
      defaultValue: 'linkStatusB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'linkAdminB',
      path: 'linkAdminB',
      name: 'Destination Link Admin Status',
      defaultValue: 'linkAdminB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'odfTypeB',
      path: 'odfTypeB',
      name: 'Destination ODF Type',
      defaultValue: 'odfTypeB',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'odfB1',
      path: 'odfB1',
      name: 'Destination ODF Port A',
      defaultValue: 'odfB1',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'odfB2',
      path: 'odfB2',
      name: 'Destination ODF Port B',
      defaultValue: 'odfB2',
      category: ['Connection Mapping'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })

    .addCustomEditor({
      id: 'uPaquetsA',
      path: 'uPaquetsA',
      name: 'Unicast Paquets',
      defaultValue: 'uPaquetsA',
      category: ['Traffic'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'nuPaquetsA',
      path: 'nuPaquetsA',
      name: 'Non-Unicast Paquets',
      defaultValue: 'nuPaquetsA',
      category: ['Traffic'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'errorPaquetsA',
      path: 'errorPaquetsA',
      name: 'Origin error Paquets',
      defaultValue: 'errorPaquetsA',
      category: ['Traffic'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'uPaquetsB',
      path: 'uPaquetsB',
      name: 'Destination Rx Unicast Paquets',
      defaultValue: 'uPaquetsB',
      category: ['Traffic'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'nuPaquetsB',
      path: 'nuPaquetsB',
      name: 'Destination Rx Non-Unicast Paquets',
      defaultValue: 'nuPaquetsB',
      category: ['Traffic'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })
    .addCustomEditor({
      id: 'errorPaquetsB',
      path: 'errorPaquetsB',
      name: 'Destination Rx error Paquets',
      defaultValue: 'errorPaquetsB',
      category: ['Traffic'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false,
    })

    .addCustomEditor({
      id: 'ddmTxA',
      path: 'ddmTxA',
      name: 'Origin DDM TX dBm',
      defaultValue: 'ddmTxA',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmRxA',
      path: 'ddmRxA',
      name: 'Origin DDM RX dBm',
      defaultValue: 'ddmRxA',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmWlA',
      path: 'ddmWlA',
      name: 'Origin DDM RX Warning Level',
      defaultValue: 'ddmWlA',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmAlA',
      path: 'ddmAlA',
      name: 'Origin DDM RX Alarm Level',
      defaultValue: 'ddmAlA',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'cableLenghtA',
      path: 'cableLenghtA',
      name: 'Origin TDR Pairs Lenght',
      defaultValue: 'cableLenghtA',
      category: ['TDR'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'cableStatusA',
      path: 'cableStatusA',
      name: 'Origin TDR Pairs Status',
      defaultValue: 'cableStatusA',
      category: ['TDR'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'radioDataA',
      path: 'radioDataA',
      name: 'Origin Radio Data',
      defaultValue: 'radioDataA',
      category: ['Radio'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'lldpDataA',
      path: 'lldpDataA',
      name: 'Origin LLDP Data',
      defaultValue: 'lldpDataA',
      category: ['LLDP'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmTxB',
      path: 'ddmTxB',
      name: 'Destination DDM TX dBm',
      defaultValue: 'ddmTxB',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmRxB',
      path: 'ddmRxB',
      name: 'Destination DDM RX dBm',
      defaultValue: 'ddmRxB',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmWlB',
      path: 'ddmWlB',
      name: 'Destination DDM RX Warning Level',
      defaultValue: 'ddmWlB',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'ddmAlB',
      path: 'ddmAlB',
      name: 'Destination DDM RX Alarm Level',
      defaultValue: 'ddmAlB',
      category: ['DDM'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'cableLenghtB',
      path: 'cableLenghtB',
      name: 'Destination TDR Pairs Lenght',
      defaultValue: 'cableLenghtB',
      category: ['TDR'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'cableStatusB',
      path: 'cableStatusB',
      name: 'Destination TDR Pairs Status',
      defaultValue: 'cableStatusB',
      category: ['TDR'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'radioDataB',
      path: 'radioDataB',
      name: 'Remote Radio Data',
      defaultValue: 'radioDataB',
      category: ['Radio'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addCustomEditor({
      id: 'lldpDataB',
      path: 'lldpDataB',
      name: 'Remote LLDP Data',
      defaultValue: 'lldpDataB',
      category: ['LLDP'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })

    .addCustomEditor({
      id: 'tunnelStatusA',
      path: 'tunnelStatusA',
      name: 'Tunnel Local Status',
      defaultValue: 'tunelStatusA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelIndexA',
      path: 'tunnelIndexA',
      name: 'Tunnel Local Index',
      defaultValue: 'tunelIndexA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelPhase1A',
      path: 'tunnelPhase1A',
      name: 'Tunnel Local Phase 1 name',
      defaultValue: 'tunelPhase1A',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelIpA',
      path: 'tunnelIpA',
      name: 'Tunnel Local IP',
      defaultValue: 'tunelIpA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwNameA',
      path: 'tunnelGwNameA',
      name: 'Tunnel Local Gateway Name',
      defaultValue: 'tunnelGwNameA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwIpA',
      path: 'tunnelGwIpA',
      name: 'Tunnel Local Gateway IP',
      defaultValue: 'tunnelGwIpA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwStatusA',
      path: 'tunnelGwStatusA',
      name: 'Tunnel Local GW Status',
      defaultValue: 'tunnelGwStatusA',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2IdsA',
      path: 'tunnelP2IdsA',
      name: 'Tunnel local Phase 2 IDs',
      defaultValue: 'tunelPhase2Ids',
	  description: 'All the tunnel Phase 2 Ids (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2StatusA',
      path: 'tunnelP2StatusA',
      name: 'Tunnel local Phase 2 ID Status',
      defaultValue: 'tunnelP2StatusA',
	  description: 'The tunnel Phase 2 Id Status (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2BeginIPA',
      path: 'tunnelP2BeginIPA',
      name: 'Tunnel local Phase 2 Begin IPs',
      defaultValue: 'tunnelP2BeginIPA',
	  description: 'All the tunnel Phase 2 Begin IPs (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2EndIPA',
      path: 'tunnelP2EndIPA',
      name: 'Tunnel local Phase 2 End IPs',
      defaultValue: 'tunnelP2EndIPA',
	  description: 'All the tunnel Phase 2 End IPs (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelStatusB',
      path: 'tunnelStatusB',
      name: 'Tunnel Remote Status',
      defaultValue: 'tunelStatusB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelIndexB',
      path: 'tunnelIndexB',
      name: 'Tunnel Remote Index',
      defaultValue: 'tunelIndexB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelPhase1B',
      path: 'tunnelPhase1B',
      name: 'Tunnel Remote Phase 1 name',
      defaultValue: 'tunelPhase1B',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelIpB',
      path: 'tunnelIpB',
      name: 'Tunnel Remote IP',
      defaultValue: 'tunelIpB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwNameB',
      path: 'tunnelGwNameB',
      name: 'Tunnel Remote Gateway Name',
      defaultValue: 'tunnelGwNameB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwIpB',
      path: 'tunnelGwIpB',
      name: 'Tunnel remote Gateway IP',
      defaultValue: 'tunnelGwIpB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelGwStatusB',
      path: 'tunnelGwStatusB',
      name: 'Tunnel remote GW Status',
      defaultValue: 'tunnelGwStatusB',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2IdsB',
      path: 'tunnelP2IdsB',
      name: 'Tunnel Remote Phase 2 IDs',
      defaultValue: 'tunelPhase2IdsB',
	  description: 'All the remote tunnel Phase 2 Ids (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2StatusB',
      path: 'tunnelP2StatusB',
      name: 'Tunnel remote Phase 2 ID Status',
      defaultValue: 'tunnelP2StatusB',
	  description: 'The tunnel Phase 2 Id Status (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2BeginIPB',
      path: 'tunnelP2BeginIPB',
      name: 'Tunnel remote Phase 2 Begin IPs',
      defaultValue: 'tunnelP2BeginIPB',
	  description: 'All the remote tunnel Phase 2 Begin IPs (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })
    .addCustomEditor({
      id: 'tunnelP2EndIPB',
      path: 'tunnelP2EndIPB',
      name: 'Tunnel remote Phase 2 End IPs',
      defaultValue: 'tunnelP2EndIPB',
	  description: 'All the remote tunnel Phase 2 End IPs (comma separated)',
      category: ['VPN'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isVpnLink === true,
    })

    .addCustomEditor({
      id: 'inetLabel',
      path: 'inetLabel',
      name: 'Etiqueta',
      defaultValue: 'inetLabel',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetIp',
      path: 'inetIp',
      name: 'Direccionamiento IP',
      defaultValue: 'inetIp',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetMask',
      path: 'inetMask',
      name: 'Mascara',
      defaultValue: 'inetMask',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetIpv6',
      path: 'inetIpv6',
      name: 'Direccionamiento IPv6',
      defaultValue: 'inetIpv6',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetMaskv6',
      path: 'inetMaskv6',
      name: 'Mascara',
      defaultValue: 'inetMaskv6',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetProvider',
      path: 'inetProvider',
      name: 'Operador',
      defaultValue: 'inetProvider',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetRef',
      path: 'inetRef',
      name: 'Referencia',
      defaultValue: 'inetRef',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetBwUpMax',
      path: 'inetBwUpMax',
      name: 'BW Up contratado (Kbps)',
      defaultValue: 'inetBwUpMax',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetBwDownMax',
      path: 'inetBwDownMax',
      name: 'BW Down contratado (Kbps)',
      defaultValue: 'inetBwDownMax',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetBwUpActual',
      path: 'inetBwUpActual',
      name: 'BW Up actual (Kbps)',
      defaultValue: 'inetBwUpActual',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetBwDownActual',
      path: 'inetBwDownActual',
      name: 'BW Down actual (Kbps)',
      defaultValue: 'inetBwDownActual',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetGwIp',
      path: 'inetGwIp',
      name: 'Gateway IP',
      defaultValue: 'inetGwIp',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })
    .addCustomEditor({
      id: 'inetGwStatus',
      path: 'inetGwStatus',
      name: 'GW Status',
      defaultValue: 'inetGwStatus',
      category: ['Internet'],
      editor: FieldSelectEditor,
	  showIf: (config) => config.isInetLink === true,
    })

    .addBooleanSwitch({
      path: 'isVpnLink',
      name: 'Is VPN link?',
      description: 'If true, you must map IPSec VPN Info',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: (config) => config.isInetLink === false && config.isSipTrunk === false,
    })
    .addBooleanSwitch({
      path: 'isInetLink',
      name: 'Is Internet access link?',
      description: 'If true, you must map Internet Info',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: (config) => config.isVpnLink === false && config.isSipTrunk === false,
    })
    .addBooleanSwitch({
      path: 'isSipTrunk',
      name: 'Is SIP trunk?',
      description: 'If true, you must map SIP Info',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: (config) => config.isVpnLink === false && config.isInetLink === false,
    })
    .addTextInput({
      path: 'resultSeparator',
      name: 'Result separator character',
      description: 'Character use to present the result next to the text. If none, it uses a new line',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addNumberInput({
      path: 'lineWidth',
      name: 'On Chart lineWidth',
      category: ['General Settings'],
      defaultValue: 1,
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'On Chart Text Font Size',
      category: ['General Settings'],
      defaultValue: 12,
    })
    .addTextInput({
      path: 'iconSize',
      name: 'On Chart Icon Size',
	  description: 'Icon size used by indicator. Must be: xs, sm, md, lg, xl, xxl, xxxl',
      category: ['General Settings'],
      defaultValue: 'xl',
    })
    .addTextInput({
      path: 'metricUnit',
      name: 'Metric Unit',
      category: ['General Settings'],
      defaultValue: '',
    })

    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { useEffect, useState, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon } from '@grafana/ui';
import { SimpleOptions, GenericUrlData} from './types';
import './css/IPCheckPanel.css';

interface Props extends PanelProps<SimpleOptions> {}

export const IPCheckPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark || false;
  const checkPanel_container = isDark ? 'checkPanel_container_dark' : 'checkPanel_container';
  const mainFont = options.mainFont;
  const secFont = options.secFont;
  const mainColor = options.mainColor;
  const secColor = options.secColor;

  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');

  useEffect(() => {
    if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));
	  setInitialCalculationsDone(true);
	}
  }, [options, replaceVariables, initialCalculationsDone]);

  if (data.state === 'Error') {
    return (
	  <div className="checkPanelErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  const generateChart = useMemo(() => {
    const urls: UrlQueryResult[] = [];
    if (data.series[0].length > 0) {
	  data.series.forEach(serie => {
	    const timeVals: GraphSeriesValue[] = serie.fields[0].values.toArray();
	    for (let i = 0; i < timeVals.length; i++) {
		  const urlData: UrlQueryResult = {
		    id: i,
		    url: serie.fields.find(field => field.name === options.urlOption)?.values.get(i),
		    success: serie.fields.find(field => field.name === options.successOption)?.values.get(i),
		    rtt: serie.fields.find(field => field.name === options.rttOption)?.values.get(i),
		    checkType: serie.fields.find(field => field.name === options.typeOption)?.values.get(i),
		    result: serie.fields.find(field => field.name === options.resultOption)?.values.get(i),
		  };
		  urls.push(urlData);
	    }
      });
	}
    return urls;
  }, [options, data]);

  if (width < 350) {
    return (
      <div>
        <div className="checkPanelErrorContainer">{error4}</div>
      </div>
    );
  } else if (generateChart.length > 0) {
	return (
	  <div className={checkPanel_container}>
		{rowFactory(generateChart, height, width, mainFont, secFont, mainColor, secColor)}
	  </div>
	);
  } else {
	const rowsHeight = (height / 3) - 5;
	const containerHeight = rowsHeight - 5;
	const containerWidth = width - 5;
	const panelRowTitle = isDark ? 'panel-rowTitle_dark' : 'panel-rowTitle';
	const panelRowContainer = isDark ? 'panel-rowContainer_dark' : 'panel-rowContainer';
	return (
	  <div className={checkPanel_container}>
		<div key={id} className={panelRowContainer} style={{ height: rowsHeight }}>
		  <a
			rel="nofollow noopener noreferrer"
			className="rowReportContainer"
			style={{ width: containerWidth, height: containerHeight }}
		  >
			<span className="panelIcon"><Icon name={'network_report'} size="xxxl" /></span>
			<span className="panelTitleContanier">
			  <div
				className={panelRowTitle}
				style={{ fontSize: mainFont, color: mainColor }}
				title={'Comprobando ...'}
			  >
			   <b>Ejecutando comprobaciones ...</b>
			  </div>
			</span>
		  </a>
		</div>
	  </div>
	);
  }
});

function rowFactory(urlData: UrlQueryResult[], height: number, width: number, mainFont: number, secFont: number, mainColor: string, secColor: string) {
  const isDark = config.theme.isDark;
  const panelRowTitle = isDark ? 'panel-rowTitle_dark' : 'panel-rowTitle';
  const panelRowsubTitle = isDark ? 'panel-rowsubTitle_dark' : 'panel-rowsubTitle';
  const panelRowContainer = isDark ? 'panel-rowContainer_dark' : 'panel-rowContainer';
  const rows = urlData.length;
  const rowsHeight = (height / rows) - 5;
  const containerHeight = rowsHeight - 5;
  const containerWidth = width - 5;

  return urlData.map(row => (
    <div key={row.id} className={panelRowContainer} style={{ height: rowsHeight }}>
      <a
        rel="nofollow noopener noreferrer"
        className="rowReportContainer"
        style={{ width: containerWidth, height: containerHeight }}
		href={row.url}
        target={row.target}
      >
        {row.checkType === 'url' && (
		  <span className="panelIcon"><Icon name={row.success ? 'en_servicio' : 'caido'} size="xxxl" /></span>
		)}
        {row.checkType === 'ip' && (
		  <span className="panelIcon"><Icon name={'network_report'} size="xxxl" /></span>
		)}
        {row.checkType === 'dns' && (
		  <span className="panelIcon"><Icon name={'interne_report'} size="xxxl" /></span>
		)}
        {row.checkType === 'bw' && (
		  <span className="panelIcon"><Icon name={'sla_report'} size="xxxl" /></span>
		)}
        <span className="panelTitleContanier">
          <div
		    className={panelRowTitle}
			style={{ fontSize: mainFont, color: mainColor }}
			title={row.success ? 'Comprobación exitosa' : 'Comprobación fallida'}
		  >
		    {row.checkType === 'url' && <b>{row.url}</b>}
			{row.checkType === 'ip' && <b>IP Externa: {row.url}</b>}
			{row.checkType === 'dns' && <b>Servicio DNS: Servidor {row.url}</b>}
			{row.checkType === 'bw' && <b>Ancho de banda disponible: {row.result} Mbps</b>}
		  </div>
          <div 
		    className={panelRowsubTitle}
			style={{ fontSize: secFont, color: secColor }}
		  >
		    {row.checkType === 'url' && row.rtt > 0 && <b>Tiempo de carga: {row.rtt.toFixed(2)} ms</b>}
			{row.checkType === 'url' && row.rtt < 0 && <b>Error al comprobar servicio</b>}
			{row.checkType === 'ip' && <b>Calidad de conexión: {row.result}</b>}
			{row.checkType === 'dns' && <b>Tiempo de respuesta: {row.rtt.toFixed(2)} ms.</b>}
			{row.checkType === 'bw' && <b>Tiempo de medición: {(row.rtt / 1000).toFixed(2)} seg</b>}
		  </div>
          <div 
		    className={panelRowsubTitle}
			style={{ fontSize: secFont, color: secColor }}
		  >
			{row.checkType === 'dns' && <b>Dominio de prueba: {row.result}</b>}
		  </div>
        </span>
      </a>
    </div>
  ));
}
import { css } from '@emotion/css';
import React from 'react';

import {
  arrayUtils,
  DataFrame,
  Field,
  formattedValueToString,
  getFieldDisplayName,
  NetMonitorTheme2,
  LinkModel,
} from '@grafana/data';
import { SortOrder } from '@grafana/schema';
import { LinkButton, useStyles2, VerticalGroup } from '@grafana/ui';
import { config } from '@grafana/runtime';

export interface Props {
  data?: DataFrame; // source data
  rowIndex?: number | null; // the hover row
  columnIndex?: number | null; // the hover column
  sortOrder?: SortOrder;
  options: mapOptions[];
}

export const DataHoverViewNormal = ({ data, rowIndex, columnIndex, sortOrder, options }: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles);

  if (!data || rowIndex == null) {
    return null;
  }

  const visibleFields = data.fields.filter((f) => !Boolean(f.config.custom?.hideFrom?.tooltip));

  if (visibleFields.length === 0) {
    return null;
  }

  const displayValues: Array<[string, any, string]> = [];
  var isOnFire = false;
  var nodeUrl = '';
  var nodeTitle = '';
  const linkLookup = new Set<string>();
  const separator = options.resultSeparator;
  for (const f of visibleFields) {
    if (f.name === options.nodeOnFireField) {
      const v = f.values.get(rowIndex);
      isOnFire = v;
    }
    if (f.name === options.nodeTitleField) {
      const v = f.values.get(rowIndex);
      nodeTitle = v;
    }
  }
  for (const f of visibleFields) {
    if (f.name === options.nodeIDField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (nodeTitle !== '') {
        title = nodeTitle;
      }
      if (options.showLinkOnTooltip) {
	    nodeUrl = options.drillDownLinkNode + String(v);
	  }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    } else if (f.name === options.nodeDescriptionField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (options.descriptionTitle !== '') {
        title = options.descriptionTitle;
      }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    } else if (options.showMetricOnTooltip && f.name === options.metricField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (options.metricTitle !== '') {
        title = options.metricTitle;
      }
      const metricLabel = String(v) + ' ' + String(options.metricUnit);
      const disp = f.display(metricLabel);
      displayValues.push([title, metricLabel, formattedValueToString(disp)]);
    } else if (options.showInfoOnTooltip && f.name === options.infoField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (options.infoTitle !== '') {
        title = options.infoTitle;
      }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    }
  }
  return (
    <div className={isOnFire ? styles.boxAlarm : styles.box}>
      {isOnFire && (
        <div key={'header'} className={styles.header}>
          <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
          <b className={styles.headerTitle}>Alarma</b>
        </div>
      )}
      <table className={isOnFire ? styles.infoWrapAlarm : styles.infoWrap}>
        <tbody>
          {displayValues.map((v, i) => (
            <tr key={`${i}/${rowIndex}`}>
              <th>{v[0]}{separator}{' '}
              {v[0] === nodeTitle && nodeUrl !== '' ? (
			    <span><a href={nodeUrl} className={styles.url}>{v[2]}</a></span>
              ) : (
			    <span className={styles.label}>{v[2]}</span>
              )}
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const DataHoverView = ({ data, rowIndex, columnIndex, sortOrder, options }: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles);

  if (!data || rowIndex == null) {
    return null;
  }

  const visibleFields = data.fields.filter((f) => !Boolean(f.config.custom?.hideFrom?.tooltip));

  if (visibleFields.length === 0) {
    return null;
  }

  var isOnFire = false;
  const displayValues: Array<[string, any, string]> = [];
  const links: Array<LinkModel<Field>> = [];
  const linkLookup = new Set<string>();
  var nodeUrl = '';
  var nodeTitle = '';
  const separator = options.resultSeparator;

  for (const f of visibleFields) {
    if (f.name === options.nodeOnFireField) {
      const v = f.values.get(rowIndex);
      isOnFire = v;
    }
    if (f.name === options.nodeTitleField) {
      const v = f.values.get(rowIndex);
      nodeTitle = v;
    }
  }

  for (const f of visibleFields) {
    if (f.name === options.nodeIDField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (nodeTitle !== '') {
        title = nodeTitle;
      }
      if (options.showLinkOnTooltip) {
	    nodeUrl = options.drillDownLinkNode + String(v);
	  }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    } else if (f.name === options.nodeDescriptionField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (options.descriptionTitle !== '') {
        title = options.descriptionTitle;
      }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    } else if (f.name === options.metricField) {
      if (options.showMetricOnTooltip) {
        const v = f.values.get(rowIndex);
        let title = getFieldDisplayName(f, data);
        if (options.metricTitle !== '') {
          title = options.metricTitle;
        }
        const metricLabel = String(v) + ' ' + String(options.metricUnit);
        const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
        displayValues.push([title, metricLabel, formattedValueToString(disp)]);
      }
    } else if (options.showInfoOnTooltip && f.name === options.infoField) {
      const v = f.values.get(rowIndex);
      let title = getFieldDisplayName(f, data);
      if (options.infoTitle !== '') {
        title = options.infoTitle;
      }
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      displayValues.push([title, v, formattedValueToString(disp)]);
    } else if (f.name !== options.nodeOnFireField && f.name !== options.nodeTitleField ) {
      const v = f.values.get(rowIndex);
      const disp = f.display ? f.display(v) : { text: `${v}`, numeric: +v };
      if (f.getLinks) {
        f.getLinks({ calculatedValue: disp, valueRowIndex: rowIndex }).forEach((link) => {
          const key = `${link.title}/${link.href}`;
          if (!linkLookup.has(key)) {
            links.push(link);
            linkLookup.add(key);
          }
        });
      }
      displayValues.push([getFieldDisplayName(f, data), v, formattedValueToString(disp)]);
    }
  }

  if (sortOrder && sortOrder !== SortOrder.None) {
    displayValues.sort((a, b) => arrayUtils.sortValues(sortOrder)(a[1], b[1]));
  }

  return (
    <div className={isOnFire ? styles.boxAlarm : styles.box}>
      {isOnFire && (
        <div key={'header'} className={styles.header}>
          <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
          <b className={styles.headerTitle}>Alarma</b>
        </div>
      )}
      <table className={isOnFire ? styles.infoWrapAlarm : styles.infoWrap}>
        <tbody>
          {displayValues.map((v, i) => (
            <tr key={`${i}/${rowIndex}`}>
              <th>{v[0]}{separator}{' '}
              {v[0] === nodeTitle && nodeUrl !== '' ? (
			    <span><a href={nodeUrl} className={styles.url}>{v[2]}</a></span>
              ) : (
			    <span className={styles.label}>{v[2]}</span>
              )}
              </th>
            </tr>
          ))}
          {links.length > 0 && (
            <tr>
              <td colSpan={2}>
                <VerticalGroup>
                  {links.map((link, i) => (
                    <LinkButton
                      key={i}
                      icon={'external-link-alt'}
                      target={link.target}
                      href={link.href}
                      onClick={link.onClick}
                      fill="text"
                      style={{ width: '100%' }}
                    >
                      {link.title}
                    </LinkButton>
                  ))}
                </VerticalGroup>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const isDark = config.theme.isDark;
const backgroundColor = isDark ? '#161F29' : '#F4F9FF';
const alarmBackground = isDark ? '#F74545' : '#FB3333';
const borderColor = isDark ? '#23282E' : '#E6E9ED';
const boxShadow = isDark ? '#44444C' : '#D8DFD9';
const fontColor = isDark ? '#EFF4FA' : '#1B2733';
const highlightColor = isDark ? 'white' : 'black';
const alarmWrapBackground = isDark ? '#F74545' : '#FB3333';
const urlColor = isDark ? '#557FFF' :  '#6C63FE';


const getStyles = (theme: NetMonitorTheme2) => ({
  infoWrap: css`
    margin-top: 2px;
    width: 100%;
    th {
      font-weight: 500;
      font-size: 11px;
      line-height: 20px;
      padding: 2px 5px;
      vertical-align: top;
    }
    td {
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      padding: 2px 5px;
      vertical-align: top;
    }
  `,
  infoWrapAlarm: css`
    margin-top: 2px;
    width: 100%;
    th {
      font-weight: 600;
      font-size: 11px;
      line-height: 20px;
      padding: 2px 5px;
      vertical-align: top;
    }
    td {
      font-weight: 400;
      font-size: 11px;
      line-height: 20px;
      padding: 2px 5px;
      vertical-align: top;
    }
  `,
  header: css`
    text-align: center;
    font-size: 14px;
    height: 24px;
    width: 100%;
    padding-top: 2px;
    color: ${fontColor};
  `,
  headerTitle: css`
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    color: ${highlightColor};
  `,
  url: css`
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    color: ${urlColor};
  `,
  label: css`
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    padding: 2px 5px;
    vertical-align: top;
  `,
  box: css`
    max-width: 250px;
    margin: -2px;
    padding: 5px;
    width: 100%
    background: ${backgroundColor};
    border: 1px solid ${borderColor};
    border-radius: 10px;
    box-shadow: 3px 3px 15px 0px ${boxShadow};
    color: ${fontColor};
  `,
  boxAlarm: css`
    max-width: 250px;
    margin: -2px;
    padding: 5px;
    width: 100%
    color: ${fontColor};
    background: ${alarmWrapBackground};
    border: 1px solid red;
    border-radius: 10px;
    box-shadow: 3px 3px 15px 0px ${boxShadow}
  `,
  highlight: css`
    background: ${theme.colors.action.hover};
  `,
});

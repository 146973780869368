import { css, cx } from '@emotion/css';
import React, { FC } from 'react';
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  Select,
  Switch,
  useStyles2,
} from '@grafana/ui';

import { AlarmThreshold } from './AlarmThreshold';
import { valueToPercentages, valueToSources } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface AlarmThresholdExpandedFormProps {
  onCancel: () => void;
  onSave: (data: AlarmThreshold[]) => void;
  threshold: AlarmThreshold;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  thresholds: AlarmThreshold[];
  assets: [];
  thresholdTypeList: [];
  types: [];
  pluginVariables: [];
}

export const AlarmThresholdExpandedForm: FC<AlarmThresholdExpandedFormProps> = ({ 
  onCancel, 
  onSave, 
  threshold, 
  isDark, 
  isAdmin,
  width, 
  thresholds,
  assets,
  thresholdTypeList,
  types,
  pluginVariables
}) => {
  const styles = useStyles2(getStyles(isDark, width));

  const stringToAsset = (field: AlarmThreshold) => {
	const assetNames = assets;
	const valueSelected = assetNames.find(asset => asset.value === field.value);
	return valueSelected;
  }

  const stringToPercentages = (field: AlarmThreshold) => {
	const actualValue = isNaN(field.value) ? '80' : String(field.value);
	const percentages = valueToPercentages;
	const valueSelected = percentages.find(percentage => percentage.value === actualValue);
	return valueSelected;
  }

  return (
    <Form defaultValues={threshold} onSubmit={onSave} className={styles.formContainer}>
      {({ control, register, watch }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  <Field label="Habilitar umbral" className={styles.switchContainer}>
            <Switch id="state" className={styles.switchStyle} {...register('state')} />
          </Field>
          {watch().state && (
		    <>
              <HorizontalGroup key={'thresholdOptions'} align="flex-start" height="55px">  
				{!watch().isBoolean && (
				  <Field 
				    label="Utilizar umbral superior e inferior:"
				    className={styles.nameContainer}
				  >
                    <Switch 
				      id="thresholdDual" {...register('thresholdDual')}
					  className={styles.switchStyle}
				      title="Activa la Alarma/Alerta cuando el valor es mayor o menor a los umbrales definidos."
				    />
                  </Field>
				)}
                {!watch().thresholdDual && (
				  <Field 
				    label={watch().isBoolean ?  'Activar por fuera de servicio:' : 'Activar por umbral superior:'}
				    className={styles.nameContainer}
				  >
                    <Switch 
				      id="thresholdBelow" {...register('thresholdBelow')}
					  className={styles.switchStyle}
				      title={watch().isBoolean ? 
					    'Activa la Alarma/Alerta cuando entra el aspecto monitoreado deja de funcionar.' :
						'Activa la Alarma/Alerta cuando el valor obtenido es inferior al umbral definido.' } 
				    />
                  </Field>
				)}
                {!watch().isBoolean && (watch().thresholdDual || watch().thresholdBelow) && (
				  <Field 
		            label="Umbral superior"
				    className={styles.nameContainer}
		          >
                    <InputControl
                      render={({ field }) => (
                        <Input
                          aria-label="thresholdUp"
                          {...field}
				          className={styles.input}
						  type="number"
				          placeholder="ingrese un valor"
						  title="Umbral superior que dispara la Alarma/Alerta."
                        />
                      )}
                      control={control}
                      name="thresholdUp"
                    />
                  </Field>
				)}
                {!watch().isBoolean && (watch().thresholdDual || !watch().thresholdBelow) && (
				  <Field 
		            label="Umbral inferior"
				    className={styles.nameContainer}
		          >
                    <InputControl
                      render={({ field }) => (
                        <Input
                          aria-label="thresholdDown"
                          {...field}
				          className={styles.input}
				          type="number"
						  placeholder="ingrese un valor"
						  title="Umbral inferior que dispara la Alarma/Alerta."
                        />
                      )}
                      control={control}
                      name="thresholdDown"
                    />
                  </Field>
				)}
			  </HorizontalGroup>
              <HorizontalGroup key={'notificationOptions'} align="flex-start" height="55px">  
				<Field 
		          label="Notificar cuando:"
				  className={styles.nameContainer2}
		        >
                  <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="percentFail"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={valueToPercentages}
				        defaultValue={stringToPercentages(field)}
						title="Seleccione el ID de Activo que se monitoreará."
					  />
                    )}
                    control={control}
                    name="percentFail"
                  />
                </Field>
				<Field 
		          label="Intervalo entre notificaciones recurrentes"
				  className={styles.nameContainer2}
		        >
                  <InputControl
                    render={({ field }) => (
                      <Input
                        aria-label="notifRepeat"
                        {...field}
				        className={styles.input}
				        type="text"
						pattern="([0-1]{1}[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}"
						placeholder="ingrese un valor... [00:00:00] para desactivar."
						title="Intervalo de tiempo minimo en que el sistema volverá a notifcar un Alarma/Alerta. Ingrese 0 para desactivar."
                      />
                    )}
                    control={control}
                    name="notifRepeat"
                  />
                </Field>
			  </HorizontalGroup>
			</>
		  )}
		  <div className={styles.buttonGroup}>
			<button 
			  type="submit"
			  className={styles.okButton} 
			  title={'Guardar modificaciones'}
			>
			  <div className={styles.button_icon}>
				<Icon name={'save'} size="lg" />
			  </div>
			 {'Guardar'}
			</button>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={() => {
			    onCancel();
			  }}
			  title={'Cerrar sin modificar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times-fill" size="lg" />
			  </div>
			  {'Cerrar'}
			</button>
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const testButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    formContainer: css`
      max-width: 100%;
    `,
    nameContainer: css`
      width: ${width * 0.22}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    nameContainer2: css`
      width: ${width * 0.44}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    switchContainer: css`
      width: ${width * 0.15}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    switchStyle: css`
	  width: 32px;
      height: 16px;
      position: relative;
	  top: 4px;
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 11px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

import React from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { VerticalGroup } from '@grafana/ui';
import { GenericIndicatorData } from 'types';
import { SimpleOptions } from './types';
import { GenericIndicator } from './IndicatorButton';

interface Props extends PanelProps<SimpleOptions> {}

export const IndicatorPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const indicators: GenericIndicatorData[] = [];

  if (width < 150) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          var indicator: GenericIndicatorData = {
            id: String(i + 1),
            width: width,
            height: height,
            fontSize: options.fontSize,
            iconSize: options.iconSize,
            textCentered: false,
            iconCentered: false,
            text: series.fields.find(field => field.name === options.indicatorTextOption)?.values.get(i),
            style: series.fields.find(field => field.name === options.indicatorStyleOption)?.values.get(i),
            icon: series.fields.find(field => field.name === options.indicatorIconOption)?.values.get(i),
			iconColor: options.iconColor,
            result: series.fields.find(field => field.name === options.indicatorResultOption)?.values.get(i),
            title: series.fields.find(field => field.name === options.indicatorTitleOption)?.values.get(i),
            evolutionText: options.showMoreInfo ? series.fields.find(field => field.name === options.evolutionTextOption)?.values.get(i) : '',
            evolutionLabel: options.showMoreInfo ? series.fields.find(field => field.name === options.evolutionLabelOption)?.values.get(i) : '',
            url: series.fields.find(field => field.name === options.indicatorUrlOption)?.values.get(i),
          };
          if (options.textCentered) {
            indicator.textCentered = true;
          }
          if (options.iconCentered) {
            indicator.iconCentered = true;
          }
          if (indicator.text === undefined || indicator.text === null) {
            indicator.text = '';
          }
          if (indicator.style === undefined || indicator.style === null) {
            indicator.style = 'default';
          }
          if (indicator.icon === undefined || indicator.icon === null) {
            indicator.icon = '-';
          }
          if (indicator.result === undefined || indicator.result === null || indicator.result === '') {
            indicator.result = '';
          } else {
            if (options.resultSeparator !== undefined && options.resultSeparator !== null) {
              indicator.result = options.resultSeparator + ' ' + String(indicator.result);
            }
          }
          if (indicator.title === undefined || indicator.title === null) {
            indicator.title = '';
          }
          if (indicator.url === undefined || indicator.url === null) {
            indicator.url = '';
          }
          indicators.push(indicator);
        }
      });
      const buttonQty = indicators.length;
      indicators.forEach(indicator => {
        if (options.displayButtonsHorizontal) {
          indicator.width = (width + 10) / buttonQty;
          indicator.height = height + 15;
        } else {
          indicator.width = width + 15;
          indicator.height = height / buttonQty;
        }
      });

      return (
        <div className="indicator_wrapper">
          <div className="indicator_container">
            <div className="indicator_pagination">
              {!options.displayButtonsHorizontal && (
                  <VerticalGroup spacing={'sm'}>{indicatorFactory(indicators)}</VerticalGroup>
              )}
              {options.displayButtonsHorizontal && (
                <div className="indicator_h">{indicatorFactory(indicators)}</div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
        return (
          <div className="indicator_wrapper" >
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div className="indicator_wrapper" >
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function indicatorFactory(indicators: GenericIndicatorData[]) {
  return indicators.map(indicator => (
    <GenericIndicator
      key={indicator.id}
      id={indicator.id}
      text={indicator.text}
      width={indicator.width}
      height={indicator.height}
      textCentered={indicator.textCentered}
      iconCentered={indicator.iconCentered}
      fontSize={indicator.fontSize}
      iconSize={indicator.iconSize}
      iconColor={indicator.iconColor}
      style={indicator.style}
      icon={indicator.icon}
      title={indicator.title}
      result={indicator.result}
      url={indicator.url || ''}
	  evolutionText={indicator.evolutionText || ''}
	  evolutionLabel={indicator.evolutionLabel || ''}
    />
  ));
}

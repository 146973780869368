import React, { useMemo, useState } from 'react';
import { config } from '@grafana/runtime';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { VerticalGroup, Icon } from '@grafana/ui';
import { LinkInspectorData } from 'types';
import { SimpleOptions } from './types';
import { LinkDescriptor } from './LinkGraph';
import { LinkDescriptorBidi } from './LinkGraphBidi';
import { LinkDescriptorRadio } from './LinkGraphRadio';
import { LinkDescriptorVPN } from './LinkGraphVpn';
import { LinkDescriptorInet } from './LinkGraphInet';
import './css/LinkInspector.css';

interface Props extends PanelProps<SimpleOptions> {}

export const LinkInspectorPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const lineWidth = Number(options.lineWidth);
  const fontSize = Number(options.fontSize);
  const iconSize = Number(options.iconSize);
  const metricUnit = replaceVariables(options.metricUnit);
  var isBIDI = false;
  var isRadio = false;
  var isIPSec = false;

  const memoizedOptions = useMemo(() => ({
    data: data,
  }), [data]);

  if (width < 75 || height < 75) {
    return (
	  <div className="linkErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (memoizedOptions.data.state === 'Error') {
    return (
	  <div className="linkErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (memoizedOptions.data.series[0].length < 1) {
    return (
	  <div className="linkErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  let base_url = replaceVariables(options.drillDownLink);
  if (base_url !== null || base_url !== undefined) {
    base_url = '';
  }
  let separator = options.separator;
  if (separator === null || separator === undefined) {
    separator = '';
  }

  const links: LinkInspectorData[] = [];

  memoizedOptions.data.series.forEach(series => {
	const linkVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < linkVals.length; i++) {
	  var link: LinkInspectorData = {
		id: i,
		siteA: series.fields.find(field => field.name === options.emplazamientoA)?.values.get(i) ?? '-',
		assetA: series.fields.find(field => field.name === options.assetA)?.values.get(i) ?? '-',
		assetTypeA: series.fields.find(field => field.name === options.assetTypeA)?.values.get(i) ?? '-',
		portA: series.fields.find(field => field.name === options.portA)?.values.get(i) ?? '-',
		aliasA: series.fields.find(field => field.name === options.aliasA)?.values.get(i) ?? '-',
		speedA: series.fields.find(field => field.name === options.speedA)?.values.get(i) ?? 0,
		uPaquetsA: series.fields.find(field => field.name === options.uPaquetsA)?.values.get(i) ?? 0,
		nuPaquetsA: series.fields.find(field => field.name === options.nuPaquetsA)?.values.get(i) ?? 0,
		errorPaquetsA: series.fields.find(field => field.name === options.errorPaquetsA)?.values.get(i) ?? 0,
		moduleTypeA: series.fields.find(field => field.name === options.moduleTypeA)?.values.get(i) ?? '-',
		moduleLambdaA: series.fields.find(field => field.name === options.moduleLambdaA)?.values.get(i) ?? '-',
		ddmTxA: series.fields.find(field => field.name === options.ddmTxA)?.values.get(i) ?? 0,
		ddmRxA: series.fields.find(field => field.name === options.ddmRxA)?.values.get(i) ?? 0,
		ddmWlA: series.fields.find(field => field.name === options.ddmWlA)?.values.get(i) ?? 0,
		ddmAlA: series.fields.find(field => field.name === options.ddmAlA)?.values.get(i) ?? 0,
		linkStatusA: series.fields.find(field => field.name === options.linkStatusA)?.values.get(i) ?? false,
		linkAdminA: series.fields.find(field => field.name === options.linkAdminA)?.values.get(i) ?? false,
		odfTypeA: series.fields.find(field => field.name === options.odfTypeA)?.values.get(i) ?? '-',
		odfA1: series.fields.find(field => field.name === options.odfA1)?.values.get(i) ?? '-',
		odfA2: series.fields.find(field => field.name === options.odfA2)?.values.get(i) ?? '-',
		cableLenghtA: [],
		cableStatusA: [],
		radioDataA: [],
		lldpDataA: [],
		tunnelStatusA: false,
		tunnelIndexA: '',
		tunnelPhase1A: '',
		tunnelIpA: '',
		tunnelGwNameA: options.isInetLink ? 'GW' : 'GW A',
		tunnelGwIpA: '',
		tunnelGwStatusA: false,
		tunnelP2IdsA: [],
		tunnelP2StatusA: [],
		tunnelP2BeginIPA: [],
		tunnelP2EndIPA: [],
		siteB: series.fields.find(field => field.name === options.emplazamientoB)?.values.get(i) ?? '-',
		assetB: series.fields.find(field => field.name === options.assetB)?.values.get(i) ?? '-',
		assetTypeB: series.fields.find(field => field.name === options.assetTypeB)?.values.get(i) ?? '-',
		portB: series.fields.find(field => field.name === options.portB)?.values.get(i) ?? '-',
		aliasB: series.fields.find(field => field.name === options.aliasB)?.values.get(i) ?? '-',
		speedB: series.fields.find(field => field.name === options.speedB)?.values.get(i) ?? 0,
		uPaquetsB: series.fields.find(field => field.name === options.uPaquetsB)?.values.get(i) ?? 0,
		nuPaquetsB: series.fields.find(field => field.name === options.nuPaquetsB)?.values.get(i) ?? 0,
		errorPaquetsB: series.fields.find(field => field.name === options.errorPaquetsB)?.values.get(i) ?? 0,
		moduleTypeB: series.fields.find(field => field.name === options.moduleTypeB)?.values.get(i) ?? '-',
		moduleLambdaB: series.fields.find(field => field.name === options.moduleLambdaB)?.values.get(i) ?? '-',
		ddmTxB: series.fields.find(field => field.name === options.ddmTxB)?.values.get(i) ?? 0,
		ddmRxB: series.fields.find(field => field.name === options.ddmRxB)?.values.get(i) ?? 0,
		ddmWlB: series.fields.find(field => field.name === options.ddmWlB)?.values.get(i) ?? 0,
		ddmAlB: series.fields.find(field => field.name === options.ddmAlB)?.values.get(i) ?? 0,
		linkStatusB: series.fields.find(field => field.name === options.linkStatusB)?.values.get(i) ?? false,
		linkAdminB: series.fields.find(field => field.name === options.linkAdminB)?.values.get(i) ?? false,
		odfTypeB: series.fields.find(field => field.name === options.odfTypeB)?.values.get(i) ?? '-',
		odfB1: series.fields.find(field => field.name === options.odfB1)?.values.get(i) ?? '-',
		odfB2: series.fields.find(field => field.name === options.odfB2)?.values.get(i) ?? '-',
		cableLenghtB: [],
		cableStatusB: [],
		radioDataB: [],
		lldpDataB: [],
		tunnelStatusB: false,
		tunnelIndexB: '',
		tunnelPhase1B: '',
		tunnelIpB: '',
		tunnelGwNameB: 'GW B',
		tunnelGwIpB: '',
		tunnelGwStatusB: false,
		tunnelP2IdsB: [],
		tunnelP2StatusB: [],
		tunnelP2BeginIPB: [],
		tunnelP2EndIPB: [],
	    inetLabel: '',
	    inetIp: '',
	    inetMask: '',
	    inetProvider: '',
	    inetRef: '',
	    inetBwUpMax: 0,
	    inetBwDownMax: 0,
	    inetBwUpActual: 0,
	    inetBwDownActual: 0,
	    inetGwIp: '',
	    inetGwStatus: false,
	  };
	  const lldpDataA = series.fields.find(field => field.name === options.lldpDataA)?.values.get(i);
	  const lldpDataB = series.fields.find(field => field.name === options.lldpDataB)?.values.get(i);
	  if (lldpDataA) {
		link.lldpDataA = lldpDataA.split('|', 3).map(String);
	  }
	  if (lldpDataB) {
		link.lldpDataB = lldpDataB.split('|', 3).map(String);
	  }
	  if (options.isVpnLink) {
	    isBIDI = true;
		isRadio = false;
		isIPSec = true;
		link.tunnelStatusA = series.fields.find(field => field.name === options.tunnelStatusA)?.values.get(i) ?? false;
		link.tunnelIndexA = series.fields.find(field => field.name === options.tunnelIndexA)?.values.get(i) ?? '-';
		link.tunnelPhase1A = series.fields.find(field => field.name === options.tunnelPhase1A)?.values.get(i) ?? '-';
		link.tunnelIpA = series.fields.find(field => field.name === options.tunnelIpA)?.values.get(i) ?? '-';
		link.tunnelGwNameA = series.fields.find(field => field.name === options.tunnelGwNameA)?.values.get(i) ?? '-';
		link.tunnelGwIpA = series.fields.find(field => field.name === options.tunnelGwIpA)?.values.get(i) ?? '-';
		link.tunnelGwStatusA = series.fields.find(field => field.name === options.tunnelGwStatusA)?.values.get(i) ?? false;
		const tunnelP2IdsA = series.fields.find(field => field.name === options.tunnelP2IdsA)?.values.get(i) ?? '';
		const tunnelP2StatusA = series.fields.find(field => field.name === options.tunnelP2StatusA)?.values.get(i) ?? '';
		const tunnelP2BeginIPA = series.fields.find(field => field.name === options.tunnelP2BeginIPA)?.values.get(i) ?? '';
		const tunnelP2EndIPA = series.fields.find(field => field.name === options.tunnelP2EndIPA)?.values.get(i) ?? '';
		link.tunnelP2IdsA = tunnelP2IdsA.split(',').map(String);
		link.tunnelP2StatusA = tunnelP2StatusA.split(',').map(Number);
		link.tunnelP2BeginIPA = tunnelP2BeginIPA.split(',').map(String);
		link.tunnelP2EndIPA = tunnelP2EndIPA.split(',').map(String);
		link.tunnelStatusB = series.fields.find(field => field.name === options.tunnelStatusB)?.values.get(i) ?? false;
		link.tunnelIndexB = series.fields.find(field => field.name === options.tunnelIndexB)?.values.get(i) ?? '-';
		link.tunnelPhase1B = series.fields.find(field => field.name === options.tunnelPhase1B)?.values.get(i) ?? '-';
		link.tunnelIpB = series.fields.find(field => field.name === options.tunnelIpB)?.values.get(i) ?? '-';
		link.tunnelGwNameB = series.fields.find(field => field.name === options.tunnelGwNameB)?.values.get(i) ?? '-';
		link.tunnelGwIpB = series.fields.find(field => field.name === options.tunnelGwIpB)?.values.get(i) ?? '-';
		link.tunnelGwStatusB = series.fields.find(field => field.name === options.tunnelGwStatusB)?.values.get(i) ?? '-';
		const tunnelP2IdsB = series.fields.find(field => field.name === options.tunnelP2IdsB)?.values.get(i) ?? '';
		const tunnelP2StatusB = series.fields.find(field => field.name === options.tunnelP2StatusB)?.values.get(i) ?? '';
		const tunnelP2BeginIPB = series.fields.find(field => field.name === options.tunnelP2BeginIPB)?.values.get(i) ?? '';
		const tunnelP2EndIPB = series.fields.find(field => field.name === options.tunnelP2EndIPB)?.values.get(i) ?? '';
		link.tunnelP2IdsB = tunnelP2IdsB.split(',').map(String);
		link.tunnelP2StatusB = tunnelP2StatusB.split(',').map(Number);
		link.tunnelP2BeginIPB = tunnelP2BeginIPB.split(',').map(String);
		link.tunnelP2EndIPB = tunnelP2EndIPB.split(',').map(String);
	  } else  if (options.isInetLink) {
		link.inetLabel = series.fields.find(field => field.name === options.inetLabel)?.values.get(i) ?? '';
		link.inetIp = series.fields.find(field => field.name === options.inetIp)?.values.get(i) ?? '-';
		link.inetMask = series.fields.find(field => field.name === options.inetMask)?.values.get(i) ?? '-';
		link.inetIpv6 = series.fields.find(field => field.name === options.inetIpv6)?.values.get(i) ?? '-';
		link.inetMaskv6 = series.fields.find(field => field.name === options.inetMaskv6)?.values.get(i) ?? '-';
		link.inetProvider = series.fields.find(field => field.name === options.inetProvider)?.values.get(i) ?? '-';
		link.inetRef = series.fields.find(field => field.name === options.inetRef)?.values.get(i) ?? '-';
		link.inetGwIp = series.fields.find(field => field.name === options.inetGwIp)?.values.get(i) ?? '-';
		link.inetGwStatus = series.fields.find(field => field.name === options.inetGwStatus)?.values.get(i) ?? false;
		const bwUpMax = series.fields.find(field => field.name === options.inetBwUpMax)?.values.get(i) ?? 0;
		const bwDownMax = series.fields.find(field => field.name === options.inetBwDownMax)?.values.get(i) ?? 0;
		const bwUpActual = series.fields.find(field => field.name === options.inetBwUpActual)?.values.get(i) ?? 0;
		const bwDownActual = series.fields.find(field => field.name === options.inetBwDownActual)?.values.get(i) ?? 0;
		link.inetBwUpMax = bwUpMax < 0 ? 0 : bwUpMax;
		link.inetBwDownMax = bwDownMax < 0 ? 0 : bwDownMax;
		link.inetBwUpActual = bwUpActual < 0 ? 0 : bwUpActual;
		link.inetBwDownActual = bwDownActual < 0 ? 0 : bwDownActual;
	  } else {
	    if (link.odfA1 === undefined || link.odfA1 === null || link.odfB1 === undefined || link.odfB1 === null) {
		  link.odfA1 = '';
		  link.odfA2 = '';
		  link.odfB1 = '';
		  link.odfB2 = '';
	    } else if (link.odfA2 === undefined || link.odfA2 === null || link.odfB2 === undefined || link.odfB2 === null) {
		  link.odfA2 = '';
		  link.odfB2 = '';
	    } else if (link.odfA1 === link.odfA2 || link.odfB1 === link.odfB2) {
	      isBIDI = true;
	    }
	    if (link.odfTypeA === 'RJ-45' || link.odfTypeB === 'RJ-45') {
		  isBIDI = true;
		  isRadio = false;
		  link.ddmTxA = 0;
		  link.ddmRxA = 0;
		  link.ddmTxB = 0;
		  link.ddmRxB = 0;
		  const cableLenghtA = series.fields.find(field => field.name === options.cableLenghtA)?.values.get(i);
		  const cableStatusA = series.fields.find(field => field.name === options.cableStatusA)?.values.get(i);
		  const cableLenghtB = series.fields.find(field => field.name === options.cableLenghtB)?.values.get(i);
		  const cableStatusB = series.fields.find(field => field.name === options.cableStatusB)?.values.get(i);
		  if (cableLenghtA) {
		    link.cableLenghtA = cableLenghtA.split(',', 4).map(Number);
		  }
		  if (cableStatusA) {
		    link.cableStatusA = cableStatusA.split(',', 4);
		  }
		  if (cableLenghtB) {
		    link.cableLenghtB = cableLenghtB.split(',', 4).map(Number);
		  }
		  if (cableStatusB) {
		    link.cableStatusB = cableStatusB.split(',', 4);
		  }
	    } else if (
	      link.odfTypeA === 'RE-UTP' || 
		  link.odfTypeB === 'RE-UTP' || 
		  link.odfTypeA === 'RE-LC' || 
		  link.odfTypeB === 'RE-LC' ||
		  link.odfTypeA === 'RE' || 
		  link.odfTypeB === 'RE'
	    ) {
	      isBIDI = true;
		  isRadio = true;
		  const radioDataA = series.fields.find(field => field.name === options.radioDataA)?.values.get(i);
		  const radioDataB = series.fields.find(field => field.name === options.radioDataB)?.values.get(i);
		  if (radioDataA) {
		    link.radioDataA = radioDataA.split(',', 5).map(Number);
		  }
		  if (radioDataB) {
		    link.radioDataB = radioDataB.split(',', 5).map(Number);
		  }
		} else {
		  link.ddmTxA = link.ddmTxA > 100 ? 0 : link.ddmTxA;
		  link.ddmRxA = link.ddmRxA < -100 ? 0 : link.ddmRxA;
		  link.ddmTxB = link.ddmTxB > 100 ? 0 : link.ddmTxB;
		  link.ddmRxB = link.ddmRxB < -100 ? 0 : link.ddmRxB;
		}
	  }
	  links.push(link);
	}
  });
  const linksQty = links.length;
  const linkWidth = width;
  const linkHeight = height / linksQty;

  if (options.isVpnLink) {
    return (
	  <div className="link_wrapper">
	    <div className="link_container">
		  <div className="link_pagination">
		    <VerticalGroup spacing={'sm'}>
		      {vpnFactory(id, links, linkWidth, linkHeight, lineWidth, fontSize, iconSize, metricUnit, isIPSec)}
		    </VerticalGroup>
		  </div>
	    </div>
	  </div>
    );
  } else if (options.isInetLink) {
    return (
	  <div className="link_wrapper">
	    <div className="link_container">
		  <div className="link_pagination">
		    <VerticalGroup spacing={'sm'}>
		      {linkFactory2(id, links, linkWidth, linkHeight, lineWidth, fontSize, iconSize, metricUnit)}
		    </VerticalGroup>
		  </div>
	    </div>
	  </div>
    );
  } else {
    return (
	  <div className="link_wrapper">
	    <div className="link_container">
		  <div className="link_pagination">
		    <VerticalGroup spacing={'sm'}>
		      {linkFactory(id, links, linkWidth, linkHeight, lineWidth, fontSize, iconSize, metricUnit, isBIDI, isRadio)}
		    </VerticalGroup>
		  </div>
	    </div>
	  </div>
    );
  }
});

function linkFactory(
  id: number,
  links: LinkInspectorData[],
  linkWidth: number,
  linkHeight: number,
  lineWidth: number,
  fontSize: number,
  iconSize: number,
  metricUnit: string,
  isBIDI: boolean,
  isRadio: boolean,
) {
  if (isBIDI && !isRadio) {
    return links.map(link => (
      <LinkDescriptorBidi
        id={id}
        link={link}
        width={linkWidth}
        height={linkHeight}
	    lineWidth={lineWidth}
		fontSize={fontSize}
	    iconSize={iconSize}
	    metricUnit={metricUnit}
      />
    ));
  } if (isBIDI && isRadio) {
    return links.map(link => (
      <LinkDescriptorRadio
        id={id}
        link={link}
        width={linkWidth}
        height={linkHeight}
	    lineWidth={lineWidth}
	    fontSize={fontSize}
	    iconSize={iconSize}
	    metricUnit={metricUnit}
      />
    ));
  } else {
    return links.map(link => (
      <LinkDescriptor
        id={id}
        link={link}
        width={linkWidth}
        height={linkHeight}
	    lineWidth={lineWidth}
	    fontSize={fontSize}
	    iconSize={iconSize}
	    metricUnit={metricUnit}
      />
    ));
  }
}

function linkFactory2(
  id: number,
  links: LinkInspectorData[],
  linkWidth: number,
  linkHeight: number,
  lineWidth: number,
  fontSize: number,
  iconSize: number,
  metricUnit: string,
) {
  return links.map(link => (
    <LinkDescriptorInet
      id={id}
      link={link}
      width={linkWidth}
      height={linkHeight}
	  lineWidth={lineWidth}
	  fontSize={fontSize}
	  iconSize={iconSize}
	  metricUnit={metricUnit}
    />
  ));
}

function vpnFactory(
  id: number,
  links: LinkInspectorData[],
  linkWidth: number,
  linkHeight: number,
  lineWidth: number,
  fontSize: number,
  iconSize: number,
  metricUnit: string,
  isIPSec: boolean,
) {
  if (isIPSec) {
    return links.map(link => (
      <LinkDescriptorVPN
        id={id}
        link={link}
        width={linkWidth}
        height={linkHeight}
	    lineWidth={lineWidth}
	    fontSize={fontSize}
	    iconSize={iconSize}
	    metricUnit={metricUnit}
      />
    ));
  }
}
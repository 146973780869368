import { css, cx } from '@emotion/css';
import React, { FC } from 'react';
import { NetMonitorTheme2 } from '@grafana/data';
import {
  Icon,
  Field,
  Form,
  HorizontalGroup,
  Input,
  InputControl,
  Select,
  Switch,
  useStyles2,
} from '@grafana/ui';

import { FormSmSign } from './SmSigns';
import { severityFieldOptions } from 'app/features/alerting/unified/utils/alertmanager';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface SmSignsExpandedFormProps {
  onCancel: () => void;
  onSave: (data: FormSmSign) => void;
  sign: FormSmSign;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  signs: FormSmSign[];
}

export const SmSignsExpandedForm: FC<SmSignsExpandedFormProps> = ({ 
  onCancel, 
  onSave, 
  sign, 
  isDark, 
  isAdmin, 
  width, 
  signs 
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const valueToString = (field: FormSmSign) => {
	const severities = severityFieldOptions;
	const valueSelected = severities.find(severity => severity.value === field.value);
	return valueSelected;
  }

  return (
    <Form defaultValues={sign} onSubmit={onSave} className={styles.formContainer}>
      {({ control, register, watch }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  <Field label="Procesar firma" >
            <Switch id="alarmState" {...register('state')} />
          </Field>
          {watch().state && (
		    <>
			  <HorizontalGroup key={'options'} align="flex-start" height="55px">
			    <Field 
		          label="Nombre:"
				  className={styles.nameContainer}
		        >
                  <InputControl
                    render={({ field }) => (
                      <Input
                        aria-label="name"
                        {...field}
                        className={styles.input}
				        placeholder="Nombre"
						title="Nombre otorgado a la regla. Este nombre es utilizado en las notificaciones."
						size="50"
                      />
                    )}
                    control={control}
                    name="name"
                  />
                </Field>
			    <Field 
			      label="Severidad:"
				  className={styles.nameContainer}
			    >
			      <InputControl
			  	    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="severity"
					    {...field}
					    className={styles.input}
					    onChange={(value) => onChange(mapSelectValueToString(value))}
					    options={severityFieldOptions}
				        defaultValue={valueToString(field)}
						title="Nivel de severidad asignado a esta regla."
					  />
				    )}
				    control={control}
				    name="severity"
				  />
			    </Field>
				<Field 
				  label="Abrir Ticket:" 
				  className={styles.nameContainer}
				>
                  <Switch 
				    id="openTicket" {...register('openTicket')}
					className={styles.switch}
				    title="Indica a NetMonitor abrir un incidente en una plataforma externa."
				  />
                </Field>
			  </HorizontalGroup>
              <Field 
		        label="Resumen:"
			    description="Breve descripción de la alarma. Este campo es utilizado en las notificaciones"
		      >
                <InputControl
                  render={({ field }) => (
                    <Input
                      aria-label="summary"
                      {...field}
				      className={styles.input}
				      placeholder="Descripción"
                    />
                  )}
                  control={control}
                  name="summary"
                />
              </Field>
			</>
		  )}
		  <div className={styles.buttonGroup}>
			<button 
			  type="submit"
			  className={styles.okButton} 
			  title={'Guardar modificaciones'}
			>
			  <div className={styles.button_icon}>
				<Icon name="save" size="lg" />
			  </div>
			 Guardar
			</button>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={onCancel}
			  title={'Cerrar sin modificar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times" size="lg" />
			  </div>
			  Cancelar
			</button>
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    formContainer: css`
      max-width: 100%;
    `,
    nameContainer: css`
      width: ${width * 0.25}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 11px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

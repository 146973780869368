import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { infoTablePanel } from './InfoTablePanel';

export const plugin = new PanelPlugin<TableOptions>(infoTablePanel).setPanelOptions(builder => {
  return builder

    .addTextInput({
      path: 'minWidth',
      name: 'Table mode min width',
      description: 'Switch to dropdown mode when panel width in minor to this threshold',
      category: ['General Settings'],
      defaultValue: '250',
    })

    .addCustomEditor({
      id: 'tableTagOption',
      path: 'tableTagOption',
      name: 'Table Tag Field',
      description: 'The field that will be used as Tag',
      defaultValue: 'tag',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableValueOption',
      path: 'tableValueOption',
      name: 'Table Value Field',
      description: 'The cell value to be asigned',
      defaultValue: 'value',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableThresholdOption',
      path: 'tableThresholdOption',
      name: 'Table Threshold Field',
      description: 'The threshold field to be asigned. When is set, is posible to change de row background if value cross this threshold.',
      defaultValue: 'threshold',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableStyleOption',
      path: 'tableStyleOption',
      name: 'Table Style Field',
      description: 'The value color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableIconOption',
      path: 'tableIconOption',
      name: 'Cell Icon Field',
      description: 'Icon to shows in the first column',
      defaultValue: 'icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableTitleOption',
      path: 'tableTitleOption',
      name: 'Cell Tooltip Field',
      description: 'The tooltip shows within the value',
      defaultValue: 'title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableUrlOption',
      path: 'tableUrlOption',
      name: 'Cell Url Field',
      description: 'The Url to use in value content',
      defaultValue: 'url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableUrlTargetOption',
      path: 'tableUrlTargetOption',
      name: 'Cell Url target Field',
      description: 'Target to use in value url',
      defaultValue: '_blank',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableVariableNameOption',
      path: 'tableVariableNameOption',
      name: 'Cell Variable Name Field',
      description: 'The Variable name to use instead of url value option. Url Field must set to be undefined to use variable name.',
      defaultValue: 'variable',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableVariableValueOption',
      path: 'tableVariableValueOption',
      name: 'Cell Variable Value Field',
      description: 'The Variable value to use instead of url value option. Url Field must set to be undefined to use variable name.',
      defaultValue: 'variableValue',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useHeader',
      name: 'useHeader',
      name: 'First Row is a Header?',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'alignLeft',
      name: 'alignLeft',
      name: 'Align cell content to Left?',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'selectFirst',
      name: 'selectFirst',
      name: 'Select first row automatically?',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'separator',
      name: 'Character to use as separator betwen tag and value',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'On Chart Text Font Size',
      category: ['General Settings'],
      defaultValue: 12,
    })
    .addNumberInput({
      path: 'rowHeight',
      name: 'Gap betwen rows',
      category: ['General Settings'],
      defaultValue: 24,
    })
    .addNumberInput({
      path: 'rowSpacer',
      name: 'Space betwen rows',
      category: ['General Settings'],
      defaultValue: 0,
    })
    .addNumberInput({
      path: 'rowRadius',
      name: 'Row Radius',
      category: ['General Settings'],
      defaultValue: 10,
    })
    .addTextInput({
      path: 'metricUnit',
      name: 'Metric Unit',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'absoluteThreshold',
      name: 'Absolute Threshold',
      description: 'Absolute Threshold use to alarm a Bar (ex: 90)',
      category: ['Thresholds'],
      defaultValue: '90',
    })
    .addBooleanSwitch({
      path: 'invertThreshold',
      name: 'Invert Threshold',
      category: ['Thresholds'],
      defaultValue: false,
    })
    .addColorPicker({
      path: 'warningColor',
      name: 'Warning Color',
      description: 'Color use when value is above threshold',
      category: ['Thresholds'],
      defaultValue: '#FF0000',
    })
    .addTextInput({
      path: 'drillDownLink',
      name: 'infoTable base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

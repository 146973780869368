import { Alert } from 'app/types/unified-alerting';

export enum SortOrder {
  AlphaAsc = 1,
  AlphaDesc,
  Importance,
  TimeAsc,
  TimeDesc,
}

export enum ShowOption {
  Current = 'current',
  RecentChanges = 'changes',
}

export enum GroupMode {
  Default = 'default',
  Custom = 'custom',
}

export type GroupedRules = Map<string, Alert[]>;

export interface AlertListOptions {
  datasource: string;
  showTitle: boolean;
  pluginTitle: string;
  noAlertMessage: string;
  error4: string;  
  editorCanAdmin: boolean;
  groupMode: GroupMode;
  maxItems: number;
  sortOrder: SortOrder;
  alertName: string;
  showOptions: ShowOption;
}

export type alertlistItems = {
  id: number;
  title: string;
};

export type alertlistData = {
  id: number;
  activeAt: string;
  cardTitle: string;
  title: string;
  cardSubtitle: string;
  cardDetailedText: string;
  ruleName: string;
  category: string;
  operationGroup: string;
  manteinanceGroup: string;
  type: string;
  severity: string;
};
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { ReportOptions, ReportData } from 'types';
import { config } from '@grafana/runtime';
import './css/netmonitor-reportManager.css';

interface Props extends PanelProps<ReportOptions> {}

export const reportManagerPanel: React.FC<Props> = React.memo(({ width, height, options, data, replaceVariables }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const renderCount = useRef(0);

  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');
  const [base_url, setBase_url ] = useState('');
  const [separator, setSeparator ] = useState('');

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));
	  const baseUrl = options.drillDownLink !== null ? replaceVariables(options.drillDownLink) : '';
	  setBase_url(replaceVariables(baseUrl));   
      setSeparator(options.separator);
	}
	setInitialCalculationsDone(true);
  }, [options, replaceVariables]);

  if (width < 150 || height < 75) {
    return (
	  <div className="reportErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="reportErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="reportErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  const isDark = config.theme.isDark;
  const reportManager_container = isDark ? 'reportManager_container_dark' : 'reportManager_container';

  const generateReports = useMemo(() => {
    const reports: ReportData[] = [];
    data.series.forEach(series => {
	  const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	  for (let i = 0; i < timeVals.length; i++) {
	    let reportData: ReportData = {
		  id: i,
		  tag: series.fields.find(field => field.name === options.reportTagOption)?.values.get(i),
		  value: series.fields.find(field => field.name === options.reportValueOption)?.values.get(i),
		  style: series.fields.find(field => field.name === options.reportStyleOption)?.values.get(i),
		  title: series.fields.find(field => field.name === options.reportTitleOption)?.values.get(i),
		  url: series.fields.find(field => field.name === options.reportUrlOption)?.values.get(i),
		  icon: series.fields.find(field => field.name === options.reportIconOption)?.values.get(i),
		  target: series.fields.find(field => field.name === options.reportUrlTargetOption)?.values.get(i),
	    };
	    if (reportData.style === null || reportData.style === undefined || reportData.style === '') {
		  reportData.style = 'default';
	    }
	    if (reportData.icon === null || reportData.icon === undefined || reportData.icon === '') {
		  reportData.icon = 'file-graph';
	    }
	    if (reportData.title === null || reportData.title === undefined) {
		reportData.title = reportData.tag;
	    }  
	    if (reportData.target === null || reportData.target === undefined || reportData.target !== '_self') {
		  reportData.target = '_blank';
	    }
	    if (reportData.url === null || reportData.url === undefined || String(reportData.url).length < 1) {
		  reportData.url = '';
		  reportData.target = '';
	    }
	    if (base_url !== '') {
		  reportData.url = base_url + reportData.url;
	    }
	    reports.push(reportData);
	  }
    });
    return reports;
  }, [width, height, options, data, replaceVariables]);

  return <div className={reportManager_container}>{generateReports.length > 0 && rowFactory(generateReports)}</div>;
});

function rowFactory(reportData: ReportData[]) {
  const isDark = config.theme.isDark;
  const reportManagerRowTitle = isDark ? 'reportManager-rowTitle_dark' : 'reportManager-rowTitle';
  const reportManagerRowsubTitle = isDark ? 'reportManager-rowsubTitle_dark' : 'reportManager-rowsubTitle';
  const reportManagerRowValue = isDark ? 'reportManager-rowValue_dark' : 'reportManager-rowValue';
  const reportManagerRowContainer = isDark ? 'reportManager-rowContainer_dark' : 'reportManager-rowContainer';
  const reportTheme = isDark ? '_dark' : '_light';

  return reportData.map(row => (
    <div key={row.id} className={reportManagerRowContainer}>
      <a
        rel="nofollow noopener noreferrer"
        title="Clic para generar informe"
        className="rowReportContainer"
        href={row.url}
        target={row.target}
      >
        <span className="reportManagerIcon">
          <Icon
            name={row.icon}
            size="xl"
          />
        </span>
        <span className="reportManagerTitleContanier">
          <div className={reportManagerRowTitle}><b>{row.value}</b></div>
          <div className={reportManagerRowsubTitle}>{row.title}</div>
        </span>
        <span className="reportManagerTag">
          <span className={reportManagerRowValue + ' reportTag' + reportTheme + ' reportManager_' + row.style + reportTheme}>
		    {row.tag}
		  </span>
        </span>
      </a>
    </div>
  ));
}

import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import { config } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';
import { getSignFilters } from 'app/features/alerting/unified/utils/misc';
import { SmSignsTable } from './SmSignsTable';
import { FormSmSign } from './SmSigns';

type Props = {
  onChange: (data: FormSmSign) => void;
  isAdmin: boolean;
  showTitle: boolean;
  pluginTitle: string;
  id: string;
  width: number;
  height: number;
  signs: FormSmSign[];
}

interface Filters {
  sign?: string;
  name?: string;
  summary?: string;
}

export const SignGroup = ({
  id,
  showTitle,
  pluginTitle,
  isAdmin,
  onChange,
  width,
  height,
  signs
}: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width));
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { sign, name, summary } = getSignFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ sign, name, summary });

  useDebounce(
    () => {
      setSearchParams({ sign: filters.sign, name: filters.name, summary: filters.summary });
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ sign: undefined, name: undefined, summary: undefined });
    setSearchParams({ sign: undefined, name: undefined, summary: undefined });
  };

  return (
    <div key={id} id={id} className={styles.wrapper}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
		<div className={styles.channelContainer}>
		  <div className={styles.searchContainer}>
			<div className={styles.filterInput}>
			  <Icon name="search" size="xs" />
			  <input
				className={styles.inputBox}
				onChange={({ currentTarget }) =>
				  setFilters((currentFilters) => ({ ...currentFilters, sign: currentTarget.value }))
				}
				value={filters.sign ?? ''}
				placeholder="Filtrar por firma..."
			  />
			</div>
			{width > 550 && (
			  <div className={styles.filterInput}>
			    <Icon name="search" size="xs" />
			    <input
				  className={styles.inputBox}
				  onChange={({ currentTarget }) =>
				    setFilters((currentFilters) => ({ ...currentFilters, name: currentTarget.value }))
				  }
				  value={filters.name ?? ''}
				  placeholder="Filtrar por nombre de regla ..."
			    />
			  </div>
			)}
			{width > 550 && (
			  <div className={styles.filterInput}>
			    <Icon name="search" size="xs" />
			    <input
				  className={styles.inputBox}
				  onChange={({ currentTarget }) =>
				    setFilters((currentFilters) => ({ ...currentFilters, summary: currentTarget.value }))
				  }
				  value={filters.summary ?? ''}
				  placeholder="Filtrar por resumen ..."
			    />
			  </div>
			)}
			{(sign || name || summary) && (
			  <div className={styles.addMatcherBtnRow}>
			    <button 
				  type="button" 
				  className={styles.cancelButton} 
				  onClick={clearFilters}
				  title={'Limpiar filtros aplicados'}
				>
				  <div className={styles.button_icon}>
				    <Icon name="times-fill" size="lg" />
				  </div>
				  Limpiar
				</button>
			  </div>
			)}
		  </div>
		</div>
		<div className={styles.signContainer}>
		  <SmSignsTable
		    id={id}
			isAdmin={isAdmin}
			onChange={onChange}
			filters={{ sign, name, summary }}
			isDark={isDark}
			width={width}
			height={showTitle ? height - 100 : height - 65}
			signs={signs}
		  />
		</div>
      </div>
    </div>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: 99%;
    `,
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    signContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    filterInput: css`
      max-width: 400px;
	  width: 28%;
	  height: 22px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    inputBox: css`
      width: 90%;
	  margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
      min-width: 100px;
	  margin-left: 15px;
      }
    `,
    title: css`
      display: inline-flex;
      margin: 12px 20px 0px 20px;
	  color: ${fontColor};
	  border-bottom: 1px solid ${borderTitle};
	  width: Calc(100% - 30px);
	  margin-left: 15px;
	  margin-bottom: 5px;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

const options = {
  name: 'breadthfirst',
  fit: false,
  ungrabifyWhileSimulating: false,
  spacingFactor: 1.75,
  padding: 5,
  refresh: 1,
  infinite: true, // overrides all other options for a forces-all-the-time mode
};

export default options;

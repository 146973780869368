import { css } from '@emotion/css';
import React, { useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'react-use';
import { config, getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2, Select } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';
import { getSignFilters } from 'app/features/alerting/unified/utils/misc';
import { HelpTable, L1OyMMap } from './HelpTable';
import { HelpData } from './L1OyMManager';
import { valueToType, valueToSubtype, valueToStatus } from './types';

type L1OyMGroupProps = {
  isAdmin: boolean;
  showTitle: boolean;
  showSelector: boolean;
  showTable: boolean;
  pluginTitle: string;
  id: string;
  width: number;
  height: number;
  elements: HelpData[];
  pluginVariables: [];
  onSaveChanges: (savedElements: HelpData[]) => void;
}

interface Filters {
  title?: string;
  docType?: string;
  subType?: string;
  section?: string;
}

export const HelpGroup = ({
  id,
  showTitle,
  showSelector,
  showTable,
  pluginTitle,
  isAdmin,
  width,
  height,
  elements,
  pluginVariables,
  onSaveChanges
}: L1OyMGroupProps) => {
  const isDark = config.theme.isDark;
  const isNetMonitorAdmin = contextSrv.isNetMonitorAdmin;
  const styles = useStyles2(getStyles(isDark, width, height));
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { title, docType, subType, section, chapter } = getSignFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ title, docType, subType, section, chapter });
  const [actualElements, setActualElements] = useState([...elements]);
  const [renderId, setrenderId] = useState(id);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const userName = contextSrv.user.name;
  const d = Date.now();
  const actualElementsLength = actualElements.length;
  const newId = generarID();
  if (renderId !== id) {
    setrenderId(id);
	setActualElements([...elements]);
  }
  const emptyObject = {
	  id: actualElementsLength,
	  uid: newId,
	  title: 'Nuevo objeto ' + String(actualElementsLength + 1),
	  state: false,
	  created: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  updated: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  user: userName,
	  subType: 'function',
	  docType: 'help',
	  docId: newId,
	  status: 'under_construction',
	  category: 'l1',
	  chapter: '',
	  section: '',
	  content: '',
	  technicalDetails: '',
	  lastEditor: '',
	  editionNotes: '',
	  mediaData: '',
	  relatedArticles: '',
	  references: '',
	  dashboardId: '',
	  alarmId: '',
	  version: 0,
  };
  const subtypeIds: [] = [];
  const sectionIds: [] = [];
  const chapterIds: [] = [];
  const helpIDs: [] = [];
  const idList = elements.filter(value => value.docType === 'help' && value.version > 0);

  for (let i = 0; i < idList.length; i++) {
	let newHelpId = {
	  label: idList[i].docId,
	  description: idList[i].title,
	  subType: idList[i].subType,
	  value: idList[i].uid,
	};
	helpIDs.push(newHelpId);
	const subTypeExist = subtypeIds.find(value => value.value === idList[i].subType);
	if (!subTypeExist) {
	  const newId = {
	    label: idList[i].subType,
	    family: 'help',
	    value: idList[i].subType,
	  };
	  subtypeIds.push(newId);
    }
	const sectionExist = sectionIds.find(value => value.value === idList[i].subType);
	if (!sectionExist) {
	  const newId = {
	    label: idList[i].section,
	    family: 'help',
	    value: idList[i].section,
	  };
	  sectionIds.push(newId);
    }
	const chapterExist = chapterIds.find(value => value.value === idList[i].subType);
	if (!chapterExist) {
	  const newId = {
	    label: idList[i].chapter,
	    family: 'help',
	    value: idList[i].chapter,
	  };
	  chapterIds.push(newId);
    }
  }

  const noteIDs: [] = [];
  const idNotes = elements.filter(value => value.docType === 'notes' && value.version > 0);

  for (let i = 0; i < idNotes.length; i++) {
	const newNoteId = {
	  label: idNotes[i].docId,
	  description: idNotes[i].title,
	  subType: idNotes[i].subType,
	  value: idNotes[i].uid,
	};
	noteIDs.push(newNoteId);
	const subTypeExist = subtypeIds.find(value => value.value === idNotes[i].subType);
	if (!subTypeExist) {
	  const newId = {
	    label: idNotes[i].subType,
	    family: 'notes',
	    value: idNotes[i].subType,
	  };
	  subtypeIds.push(newId);
	}
  }

  const stringToSection = (value: string) => {
    const valueSelected = sectionIds.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  const stringToChapter = (value: string) => {
    const valueSelected = chapterIds.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  const stringToType = (value: string) => {
    const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  const stringToSubtype = (value: string) => {
    const valueSelected = subtypeIds.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  useDebounce(() => {
      setSearchParams({ title: filters.title, docType: filters.docType, subType: filters.subType, section: filters.section, chapter: filters.chapter});
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ title: undefined, docType: undefined, subType: undefined, section: undefined, chapter: undefined  });
    setSearchParams({ title: undefined, docType: undefined, subType: undefined, section: undefined, chapter: undefined  });
  };

  const addNewElement = () => {
    setIsAddMode(true);
    setActualElements(() => [
      ...elements,
      {
        ...emptyObject,
      },
    ]);
  };

  const onCancel = () => {
	setIsAddMode(false);
    setActualElements(() => [...elements]);
  };

  const onSave = (savedElements: HelpData[]) => {
	setIsAddMode(false);
	const updatedElements = onSaveChanges(savedElements);
	setActualElements(() => [...updatedElements]);
  };
  
  let groupHeight = height - 35;
  if (showTitle) {
    groupHeight = groupHeight - 40;
  }
  if (showFilter) {
    if (showTable)  {
      groupHeight = groupHeight - 35;
	} else {
	  groupHeight = groupHeight - 70;
	}
  }
  
  return (
    <div key={id} id={id} className={styles.wrapper}>
      {showTitle && (!showSelector || pluginVariables[7] || pluginVariables[9] || pluginVariables[13]) && (
	    <div className={styles.titleContainer}>
		  <div className={styles.title}>
            <div className={styles.titleText}>{pluginTitle}</div>
          </div>
		</div>
	  )}
	  {showTitle && showSelector && !pluginVariables[7] && !pluginVariables[9] && !pluginVariables[13] && (
        <div className={styles.titleContainerWithFilter}>
		  <div className={styles.titleWithFilter}>
            <div className={styles.titleTextWithFilter}>{pluginTitle}</div>
          </div>
		  <div className={styles.addMatcherBtnRow}>
			<button 
			  type="button"
			  className={showFilter ? styles.filterButtonDisable : styles.filterButton }
			  onClick={() => {
				setShowFilter(!showFilter);
			  }}
			>
			  <div className={styles.button_icon}>
				<Icon name="ellipsis-h" size="lg" />
			  </div>
			  {showFilter ? 'Ocultar opciones y filtros' : 'Ver opciones y filtros'}
			</button>
          </div>
		</div>
      )}
	  {showFilter && showSelector && !pluginVariables[7] && !pluginVariables[9] && !pluginVariables[13] && (
	    <div className={styles.container}>
		  {!pluginVariables[5] && !showTable && (
		    <div className={styles.filterContainer}> 
			  <legend className={styles.legend}>
			    {'Tipo de Documento: '}
			  </legend>
			  <div className={styles.selectBox}>
			    <Select
				  aria-label="docType"
				  onChange={(currentTarget) => {
				    setFilters((currentFilters) => ({ ...currentFilters, docType: currentTarget.value, subType: undefined }));
				  }}
				  options={isNetMonitorAdmin ? valueToType : valueToType.filter(ele => ele.showToViewer === true)}
				  defaultValue={stringToType(filters.docType)}
			  	  title={'Seleccione un tipo de elemento para filtrar.'}
			    />
			  </div>
			  <div className={styles.selectBox}>
				<Select
				  aria-label="subType"
				  onChange={(currentTarget) => {
					setFilters((currentFilters) => ({ ...currentFilters, subType: currentTarget.value }));
				  }}
				  options={valueToSubtype.filter(subType => subType.family === filters.docType)}
				  defaultValue={stringToSubtype(filters.subType)}
				  title={'Seleccione un subtipo de elemento para filtrar.'}
				/>
			  </div>
		    </div>
		  )}
		  {filters.docType === 'help' || (isNetMonitorAdmin && filters.docType === 'internal') && (
		    <div className={styles.filterContainer}> 
			  <legend className={styles.legend}>
			    {'Filtrar por '}
			  </legend>
			  <div className={styles.selectBox}>
			    <Select
				  aria-label="section"
				  onChange={(currentTarget) => {
				    setFilters((currentFilters) => ({ ...currentFilters, section: currentTarget.value, chapter: undefined }))
				  }}
				  options={sectionIds}
				  defaultValue={stringToSection(filters.section)}
				  title="Seleccione la sección asignada al documento"
				  placeholder="Sección"
			    />
			  </div>
			  <div className={styles.selectBox}>
			    <Select
				  aria-label="chapter"
				  onChange={(currentTarget) => {
				    setFilters((currentFilters) => ({ ...currentFilters, chapter: currentTarget.value }))
				  }}
				  options={chapterIds}
				  defaultValue={stringToChapter(filters.chapter)}
				  title="Seleccione una capitulo"
				  placeholder="Capitulo"
			    />
			  </div>
		    </div>
		  )}
		</div>
	  )}
      <div className={styles.container} >
		{showSelector && !pluginVariables[7] && !pluginVariables[9] && (
		  <div className={styles.container}>
			<div className={styles.filterContainer}>
			  <div className={styles.filterInput}>
				<Icon name="search" size="xs" />
				<input
				  className={styles.inputBox}
				  onChange={({ currentTarget }) =>
					setFilters((currentFilters) => ({ ...currentFilters, title: currentTarget.value }))
				  }
				  value={filters.title ?? ''}
				  placeholder="Buscar por título de documento ..."
				/>
			  </div>
			  {(filters.title || filters.section || filters.docType || filters.subType) && (
			    <div className={styles.addMatcherBtnRow}>
			      <button 
				    type="button" 
				    className={styles.cancelButton} 
				    onClick={clearFilters}
				    title={'Limpiar filtros aplicados'}
				  >
				    <div className={styles.button_icon}>
				      <Icon name="times-fill" size="lg" />
				    </div>
				    Limpiar
				  </button>
				</div>
			  )}
			  {isAdmin && !isAddMode && !showTable && !pluginVariables[5] && (
			    <div className={styles.addMatcherBtnRow}>
				  <button 
				    type="button"
				    className={styles.okButton} 
				    onClick={addNewElement}
				    title={'Agregar nuevo Documento'}
				  >
				    <div className={styles.button_icon}>
				      <Icon name="plus-circle-fill" size="lg" />
				    </div>
				    Agregar
				  </button>
			    </div>
			  )}
			</div>
          </div>
		)}
		<div>
		  <HelpTable
		    id={id}
			isAdmin={isAdmin}
			onSave={onSave}
			onCancel={onCancel}
			filters={filters}
			isDark={isDark}
			width={width}
			height={groupHeight}
			elements={actualElements}
			pluginVariables={pluginVariables}
			showTable={showTable}
		  />
		</div>
	  </div>
    </div>
  );
};

const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 8; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
};

const getStyles = (isDark: boolean, width: number, height: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: ${width}px;
	  height: ${height}px;
    `,
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
	titleContainer: css`
      display: contents;
	  align-items: center;
	  width: 100%;
	  border: 1px solid ${borderTitle};
	  border-radius: 10px;
	  box-shadow: ${borderTitle} 0px 2px 6px 0px;
    `,
	titleContainerWithFilter: css`
      display: inline-flex;
	  align-items: center;
      justify-content: space-between;
	  width: 100%;
	  border: 1px solid ${borderTitle};
	  border-radius: 10px;
	  box-shadow: ${borderTitle} 0px 2px 6px 0px;
    `,
	filterContainer: css`
      display: inline-flex;
	  width: 100%;
	  margin-bottom: 5px;
      align-items: center;
    `,
    elementContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    filterInput: css`
	  min-width: 350px;
	  width: -webkit-fill-available;
	  height: 24px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  margin-right: 15px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
	legend: css`
	  display: block;
	  width: max-content;
      font-size: 12px;
	  font-weight: 500;
      margin-right: 5px;
	  margin-top: 5px;
    `,
    inputBox: css`
      width: Calc(100% - 20px);
	  margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    selectBox: css`
      margin-right: 15px;
      width: -webkit-fill-available;
      max-width: 350px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
	  margin-left: 15px;
      }
    `,
    title: css`
      display: inline-flex;
      justify-content: center;
	  color: ${fontColor};
	  width: 100%;
    `,
    titleWithFilter: css`
      display: inline-flex;
	  flex-grow: 0;
      flex-wrap: nowrap;
      margin: 0px 20px 0px 20px;
	  color: ${fontColor};
	  justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
	  width: 100%;
      white-space: nowrap;
	  align-items: center;
    `,
    titleTextWithFilter: css`
	  display: contents;
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 0px 20px 0px 5px;
	  font-size: 12px;
	  font-weight: 500;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
	filterButton: css`
	  display: inline-flex;
	  background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      border-radius: 0 10px 10px 0;
      box-shadow: 1px 1px 10px 0 ${okButtonBack};
      color: #fff;
      font-weight: 500;
      width: max-content;
	  height: 24px;
      opacity: .85;
      padding-left: 5px;
      padding-right: 15px;

	    &:hover {
		  opacity: 1;
		}
    `,
	filterButtonDisable: css`
	  display: inline-flex;
	  background-color: ${buttonBorder};
      border: 1px solid ${fontColor}A0;
      border-radius: 0 10px 10px 0;
      box-shadow: 1px 1px 10px 0 ${buttonBorder};
      color: #fff;
      font-weight: 500;
      width: max-content;
	  height: 24px;
      opacity: .85;
      padding-left: 5px;
      padding-right: 15px;

	    &:hover {
		  opacity: 1;
		}
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { locationService } from '@grafana/runtime';
import { LoadingPlaceholder } from '@grafana/ui';
import {
  AlertManagerCortexConfig,
  NetMonitorManagedReceiverConfig,
  Receiver,
  TestReceiversAlert,
} from 'app/plugins/datasource/alertmanager/types';

import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import {
  fetchNetMonitorNotifiersAction,
  testReceiversAction,
  updateAlertManagerConfigAction,
} from 'app/features/alerting/unified/state/actions';
import { NetMonitorChannelValues, ReceiverFormValues } from 'app/features/alerting/unified/types/receiver-form';
import { NETMONITOR_RULES_SOURCE_NAME, isVanillaPrometheusAlertManagerDataSource } from 'app/features/alerting/unified/utils/datasource';
import {
  formChannelValuesToNetMonitorChannelConfig,
  formValuesToNetMonitorReceiver,
  netmonitorReceiverToFormValues,
  updateConfigWithReceiver,
} from 'app/features/alerting/unified/utils/receiver-form';
import { ProvisionedResource, ProvisioningAlert } from 'app/features/alerting/unified/components/Provisioning';

import { NetMonitorCommonChannelSettings } from './NetMonitorCommonChannelSettings';
import { ReceiverForm } from './ReceiverForm';
import { TestContactPointModal } from './TestContactPointModal';
import { setNetMonitorVariableValue } from '../utils';

interface Props {
  onCancel: () => void;
  existing?: Receiver;
  alertManagerSourceName: string;
  config: AlertManagerCortexConfig;
  isAdmin: boolean;
  isDark: boolean;
  width: number;
}

const defaultChannelValues: NetMonitorChannelValues = Object.freeze({
  __id: '',
  secureSettings: {},
  settings: {},
  secureFields: {},
  disableResolveMessage: true,
  type: 'email',
});

export const NetMonitorReceiverForm: FC<Props> = ({ 
  onCancel, 
  existing, 
  alertManagerSourceName, 
  config, 
  isAdmin, 
  isDark, 
  width,
}) => {

  const netmonitorNotifiers = useUnifiedAlertingSelector((state) => state.netmonitorNotifiers);
  const [testChannelValues, setTestChannelValues] = useState<NetMonitorChannelValues>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!(netmonitorNotifiers.result || netmonitorNotifiers.loading)) {
      dispatch(fetchNetMonitorNotifiersAction());
    }
  }, [netmonitorNotifiers, dispatch]);

  const [existingValue, id2original] = useMemo((): [
    ReceiverFormValues<NetMonitorChannelValues> | undefined,
    Record<string, NetMonitorManagedReceiverConfig>
  ] => {
    if (!existing || !netmonitorNotifiers.result) {
      return [undefined, {}];
    }
    return netmonitorReceiverToFormValues(existing, netmonitorNotifiers.result!);
  }, [existing, netmonitorNotifiers.result]);

  const onSubmit = (values: ReceiverFormValues<NetMonitorChannelValues>) => {
    const newReceiver = formValuesToNetMonitorReceiver(values, id2original, defaultChannelValues);
    dispatch(
      updateAlertManagerConfigAction({
        newConfig: updateConfigWithReceiver(config, newReceiver, existing?.name),
        oldConfig: config,
        alertManagerSourceName: NETMONITOR_RULES_SOURCE_NAME,
        successMessage: existing ? 'El Canal fue actualizado' : 'El canal fue creado con éxito',
        refetch: true,
      })
    );
	onCancel(existing?.id);
  };

  const onTestChannel = (values: NetMonitorChannelValues) => {
    setTestChannelValues(values);
  };

  const testNotification = (alert?: TestReceiversAlert) => {
    if (testChannelValues) {
      const existing: NetMonitorManagedReceiverConfig | undefined = id2original[testChannelValues.__id];
      const chan = formChannelValuesToNetMonitorChannelConfig(testChannelValues, defaultChannelValues, 'test', existing);
      const payload = {
        alertManagerSourceName,
        receivers: [
          {
            name: 'test',
            netmonitor_managed_receiver_configs: [chan],
          },
        ],
        alert,
      };
      dispatch(testReceiversAction(payload));
    }
  };

  const takenReceiverNames = useMemo(
    () => config.alertmanager_config.receivers?.map(({ name }) => name).filter((name) => name !== existing?.name) ?? [],
    [config, existing]
  );

  // if any receivers in the contact point have a "provenance", the entire contact point should be readOnly
  const hasProvisionedItems = existing
    ? (existing.netmonitor_managed_receiver_configs ?? []).some((item) => Boolean(item.provenance))
    : false;

  const isManageableAlertManagerDataSource = !isVanillaPrometheusAlertManagerDataSource(alertManagerSourceName);

  const isEditable = isManageableAlertManagerDataSource && !hasProvisionedItems;
  const isTestable = isManageableAlertManagerDataSource || hasProvisionedItems;

  if (netmonitorNotifiers.result) {
    return (
      <>
        {hasProvisionedItems && <ProvisioningAlert resource={ProvisionedResource.ContactPoint} />}
        <ReceiverForm<NetMonitorChannelValues>
          isEditable={isEditable}
          isTestable={isTestable}
          config={config}
          onSubmit={onSubmit}
          initialValues={existingValue}
          onTestChannel={onTestChannel}
          notifiers={netmonitorNotifiers.result}
          alertManagerSourceName={alertManagerSourceName}
          defaultItem={defaultChannelValues}
          takenReceiverNames={takenReceiverNames}
          commonSettingsComponent={NetMonitorCommonChannelSettings}
          isAdmin={isAdmin}
		  isDark={isDark}
		  width={width}
        />
        <TestContactPointModal
          onDismiss={() => setTestChannelValues(undefined)}
          isOpen={!!testChannelValues}
          onTest={(alert) => testNotification(alert)}
        />
      </>
    );
  } else {
    return <LoadingPlaceholder text="Cargando canales..." />;
  }
};

import React, { LegacyRef, Component, PureComponent } from 'react';
import _, { isEqual }  from 'lodash';
import {
  AbsoluteTimeRange,
  DataFrame,
  FieldConfigSource,
  InterpolateFunction,
  PanelProps,
  TimeRange,
} from '@grafana/data';
import { locationService, getTemplateSrv, TimeRangeUpdatedEvent, config  } from '@grafana/runtime';
import cytoscape from 'cytoscape';
import { ServiceDependencyGraph } from './serviceDependencyGraph/ServiceDependencyGraph';
import GraphGenerator from '../processing/graph_generator';
import PreProcessor from '../processing/pre_processor';
import { CurrentData, IntGraph, PanelSettings } from '../types';
import '../css/netmonitor-topology-map.css';

interface Props extends PanelProps<PanelSettings> {}

interface PanelState {
  id: string | number;
  fieldConfig: FieldConfigSource<any>;
  height: number;
  width: number;
  onChangeTimeRange: (timeRange: AbsoluteTimeRange) => void;
  onFieldConfigChange: (config: FieldConfigSource<any>) => void;
  onOptionsChange: (options: PanelSettings) => void;
  renderCounter: number;
  replaceVariables: InterpolateFunction;
  timeRange: TimeRange;
  timeZone: string;
  title: string;
  transparent: boolean;
  options: PanelSettings;
}

export class PanelController extends Component<Props, PanelState> {
  private subscriber: any;
  cy: cytoscape.Core | undefined;
  ref: LegacyRef<HTMLDivElement>;
  validQueryTypes: boolean;
  isGeoMap: boolean;
  graphGenerator: GraphGenerator;
  preProcessor: PreProcessor;
  currentData: CurrentData;
  mustUpdate: string;
  valueTracked: string;
  panelName: string;

  constructor(props: Props) {
    super(props);
    const { addMap } = this.getSettings(props, false);
	const { addMapVariable, variableToTrack } = this.getSettings(props, true);
	this.ref = React.createRef();
	this.isGeoMap = addMap && addMapVariable === '1' ? true : false;
	this.panelName = this.isGeoMap ? 'geoMap' : 'topologyMap';
	this.valueTracked = variableToTrack;

    this.graphGenerator = new GraphGenerator(this);
    this.preProcessor = new PreProcessor(this);
	this.mustUpdate = 'full';	
	
	this.state = ({ 
	  ...props,
	  mustUpdate: false,
	});
  }

  getSettings(props: any, resolveVariables: boolean): PanelSettings {
    if (resolveVariables) {
      return this.resolveVariables(this.props.options);
    }
    return this.props.options;
  }

  resolveVariables(element: any) {
    if (element instanceof Object) {
      const newObject: any = {};
      for (const key of Object.keys(element)) {
        newObject[key] = this.resolveVariables(element[key]);
      }
      return newObject;
    }

    if (element instanceof String || typeof element === 'string') {
      return getTemplateSrv().replace(element.toString());
    }
    return element;
  }

  resolveTemplateVars(input: any, copy: boolean) {
    var value = input;
    if (copy) {
      value = _.cloneDeep(value);
    }

    if (typeof value === 'string' || value instanceof String) {
      value = getTemplateSrv().replace(value.toString());
    }
    if (value instanceof Object) {
      for (const key of Object.keys(value)) {
        value[key] = this.resolveTemplateVars(value[key], false);
      }
    }
    return value;
  }

  shouldComponentUpdate(nextProps: Props, nextState: PanelState): boolean {
    const { addMap } = this.getSettings(nextProps, false);
	const { addMapVariable, variableToTrack } = this.getSettings(nextProps, true);
	const netxGeoMap = addMap && addMapVariable === '1' ? true : false;
	this.mustUpdate = 'none';

	if (this.isGeoMap !== netxGeoMap) {
	  this.mustUpdate = 'full';
	  return true;
	} else if (this.valueTracked !== variableToTrack) {
	  this.mustUpdate = 'partial';
	}

	for (let x = 0; x < nextProps.data.series.length; x++) {
	  if (nextProps.data.series[x].fields[0].values.length !== this.props.data.series[x].fields[0].values.length) {
		this.mustUpdate = 'full';
	    break;
	  } else if (this.mustUpdate !== 'full') {
	    for (let i = 0; i < nextProps.data.series[x].fields.length; i++) {
		  if (!isEqual(nextProps.data.series[x].fields[i].values, this.props.data.series[x].fields[i].values)) {
			this.mustUpdate = 'partial';
			break;
		  }
	    }
	  }
	}

    if (this.mustUpdate !== 'none' || this.state.mustUpdate !== nextState.mustUpdate) {
	  return true;
	} else {
	  return false;
	}
  }

  componentDidUpdate(prevProps: Props, prevState: PanelState) {
    this.mustUpdate === 'none'
  }

  componentDidMount() {
    const { eventBus } = this.props;
	if (eventBus) {
  	  this.subscriber = eventBus.getStream(TimeRangeUpdatedEvent).subscribe(event => {
		this.setState({ 
		  mustUpdate: !this.state.mustUpdate,
		});
      });
	}
  }

  componentWillUnmount() {
    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }
  }

  processQueryData(data: DataFrame[]) {
    this.validQueryTypes = this.hasOnlyTableQueries(data);
    const graphData = this.preProcessor.processData(data);

    this.currentData = graphData;
  }

  hasOnlyTableQueries(inputData: DataFrame[]) {
    var result = true;

    _.each(inputData, dataElement => {
      if (!_.has(dataElement, 'columns')) {
        result = false;
      }
    });

    return result;
  }

  processData() {
    var inputData: DataFrame[] = this.props.data.series;
    this.processQueryData(inputData);
    const graph: IntGraph = this.graphGenerator.generateGraph(this.currentData.graph);
	return graph;
  }

  getError(): string | null {
    const { error2 } = this.getSettings(this.props, true);
	if (!this.isDataAvailable()) {
      return error2;
    }
    return null;
  }

  isDataAvailable() {
    const dataExist =
      !_.isUndefined(this.currentData) && !_.isUndefined(this.currentData.graph) && this.currentData.graph.length > 0;
    return dataExist;
  }

  render() {
    const isDark = config.theme.isDark || false;
	const { error2, variableToTrack } = this.getSettings(this.props, true);
	const data = this.processData();
	const error = this.getError();

	if (error === null) {
      return (
        <div style={{ height: '100%', width: '100%' }}>
          <div
            className="netmonitor-topology-map-panel"
            ref={this.ref}
            id="cy"
			key={this.panelName}
          >
            <ServiceDependencyGraph
              data={data}
              controller={this}
              showStatistics={false}
              showEdgeStatistics={false}
              options={this.props.options}
              isLock={false}
              isCollapsed={false}
              initResize={this.mustUpdate !== 'none' ? true : false}
			  isGeoMap={this.state.showGeoMap}
			  mustUpdate={this.mustUpdate}
            />
          </div>
        </div>
      );
    } else {
      return (
	  	<div className={isDark ? 'topologyErrorContainer' : 'topologyErrorContainer_light'} title={error2}>
	      <Icon name={'image-slash'} size="xxl" />
	    </div>
	  );
    }
  }
}
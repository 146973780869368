import { css } from '@emotion/css';
import React, { useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'react-use';

import { config, getTemplateSrv, locationService } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2, Select } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { AlarmThresholdTable } from './AlarmThresholdTable';
import { AlarmThreshold } from './AlarmThreshold';

type Props = {
  isAdmin: boolean;
  showTitle: boolean;
  showSelector: boolean;
  pluginTitle: string;
  id: string;
  width: number;
  height: number;
  thresholds: AlarmThreshold[];
  assets: [];
  thresholdTypeList: [];
  types: [];
  pluginVariables: [];
  onSaveChanges: (newThresholdConfigured: AlarmThreshold[]) => void;
}

interface Filters {
  assetType?: string;
  asset?: string;
}

export const AlarmThresholdGroup = ({
  id,
  showTitle,
  showSelector,
  pluginTitle,
  isAdmin,
  width,
  height,
  thresholds,
  assets,
  thresholdTypeList,
  types,
  pluginVariables,
  onSaveChanges
}: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width));
  const asset = getTemplateSrv().replace(`$${pluginVariables[1]}`);
  const [actualThresholds, setActualThresholds] = useState([...thresholds]);
  const [actualAsset, setActualAsset] = useState(asset);

  if (asset !== actualAsset) {
    setActualAsset(asset);
  }
  const stringToAsset = (name: string) => {
	const assetsDefined = assets;
	const assetSelected = assetsDefined.find(element => element.value === name);
	return assetSelected;
  }

  const stringToType = (name: string) => {
	const typesDefined = types;
	const typeSelected = typesDefined.find(element => element.value === name);
	return typeSelected;
  }

  const assetId = stringToAsset(actualAsset);
  const assetType = assetId ? assetId.family : '';  
  const [filters, setFilters] = useState<Filters>({ assetType: assetType, asset: actualAsset});

  const onCancel = () => {
    setActualThresholds(() => [...thresholds]);
  };

  const onSave = (savedThresholds: AlarmThreshold[]) => {
	const updatedThresholds = onSaveChanges(savedThresholds);
	setActualThresholds(() => [...updatedThresholds]);
  };

  return (
    <div key={id} id={id} className={styles.wrapper}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
		<div className={styles.channelContainer}>
		  {showSelector && (
		    <div className={styles.searchContainer}>
			  <Select
			    aria-label="assetType"
			    className={styles.filterInput}
			    onChange={(currentTarget) => {
			      setFilters((currentFilters) => ({ ...currentFilters, assetType: currentTarget.value }))
			    }}
			    options={types}
			    defaultValue={stringToType(filters.assetType)}
			    title="Seleccione un tipo de Activo."
			  />
			  {width > 550 && (
			    <Select
				  aria-label="asset"
				  className={styles.filterInput}
				  onChange={(currentTarget) => {
			        setFilters((currentFilters) => ({ ...currentFilters, asset: currentTarget.value }))
				    const queryMap = {
					  [`var-${pluginVariables[1]}`]: currentTarget.value,
				    };
				    locationService.partial(queryMap, true);
			      }}
				  options={assets.filter(asset => asset.family === filters.assetType)}
				  defaultValue={stringToAsset(filters.asset)}
				  title="Seleccione el ID de Activo al cual se le modificarán los umbrales."
			    />
			  )}
		    </div>
		  )}
		</div>
		<div className={styles.thresholdContainer}>
		  <AlarmThresholdTable
		    id={id}
			isAdmin={isAdmin}
			onSave={onSave}
			onCancel={onCancel}
			isDark={isDark}
			width={width}
			height={showTitle ? height - 100 : height - 65}
			thresholds={actualThresholds}
			assets={assets}
			thresholdTypeList={thresholdTypeList}
			types={types}
			pluginVariables={pluginVariables}
		  />
		</div>
      </div>
    </div>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: 99%;
    `,
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    thresholdContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    filterInput: css`
      max-width: 400px;
	  width: 28%;
	  height: 22px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    inputBox: css`
      width: 90%;
	  margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
      min-width: 100px;
	  margin-left: 15px;
      }
    `,
    title: css`
      display: inline-flex;
      margin: 12px 20px 0px 20px;
	  color: ${fontColor};
	  border-bottom: 1px solid ${borderTitle};
	  width: Calc(100% - 30px);
	  margin-left: 15px;
	  margin-bottom: 5px;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

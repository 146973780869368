import { locationService, getTemplateSrv } from '@grafana/runtime';

export function changeUrl(queryMap) {
  locationService.partial(queryMap, true);
}

export function getNetMonitorVariableValue(variableName: any) {
  return getTemplateSrv().replace(`$${variableName}`);
}


import { css } from '@emotion/css';
import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';

import { config } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';

import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { Authorize } from 'app/features/alerting/unified/components/Authorize';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';
import { AlertManagerCortexConfig } from 'app/plugins/datasource/alertmanager/types';
import { getNotificationChannelsFilters } from 'app/features/alerting/unified/utils/misc';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { ReceiversTable } from './receivers/ReceiversTable';

type Props = {
  id: string;
  showTitle: boolean;
  pluginTitle: string;  
  isAdmin: boolean;
  configuration: AlertManagerCortexConfig;
  alertManagerSourceName: string;
  readOnly?: boolean;
  rows: any;
  width: number;
  height: number;
}

interface Filters {
  contactPoint?: string;
  contactType?: string;
}

export const ContactGroup = ({
  id,
  showTitle,
  pluginTitle,
  isAdmin,
  configuration,
  alertManagerSourceName,
  readOnly,
  rows,
  width,
  height
}: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width));
  const permissions = getNotificationsPermissions(alertManagerSourceName);
  const [actualRows, setActualRows] = useState([...rows]);

  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { contactPoint, contactType } = getNotificationChannelsFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ contactPoint, contactType });

  const emptyRow = {
	address: [],
	name: 'Nuevo Canal',
	provisioned: false,
	types:[],
  };

  useDebounce(
    () => {
      setSearchParams({ contactPoint: filters.contactPoint, contactType: filters.contactType });
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ contactPoint: undefined, contactType: undefined });
    setSearchParams({ contactPoint: undefined, contactType: undefined });
  };

  const onCancelAdd = () => {
    setIsAddMode(false);
  };

  const addNewChannel = () => {
    clearFilters();
    setIsAddMode(true);
    setActualRows(() => [
      ...rows,
      {
        ...emptyRow,
      },
    ]);
  };

  return (
    <div key={id} className={styles.wrapper}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
        {actualRows.length > 0 ? (
          <>
            <div className={styles.channelContainer}>
              {!isAddMode && (
                <div className={styles.searchContainer}>
                  <div className={styles.filterInput}>
                    <Icon name="search" size="xs" />
					<input
					  className={styles.inputBox}
                      onChange={({ currentTarget }) =>
                        setFilters((currentFilters) => ({ ...currentFilters, contactPoint: currentTarget.value }))
                      }
                      value={filters.contactPoint ?? ''}
                      placeholder="Buscar por Nombre ..."
                    />
                  </div>
                  <div className={styles.filterInput}>
                    <Icon name="search" size="xs" />
					<input
					  className={styles.inputBox}
                      onChange={({ currentTarget }) =>
						setFilters((currentFilters) => ({ ...currentFilters, contactType: currentTarget.value }))
                      }
                      value={filters.contactType ?? ''}
                      placeholder="Buscar por Tipo ..."
                    />
                  </div>
                  {(contactPoint || contactType) && (
                    <div className={styles.addMatcherBtnRow}>
					  <button 
					    type="button" 
					    className={styles.cancelButton} 
					    onClick={clearFilters}
					    title={'Limpiar filtros aplicados'}
					  >
					    <div className={styles.button_icon}>
						  <Icon name="times-fill" size="lg" />
					    </div>
					    Limpiar
					  </button>
                    </div>
                  )}
                  {!readOnly && isAdmin && !isAddMode && (
                    <Authorize actions={[permissions.create]}>
                      <div className={styles.addMatcherBtnRow}>
					    <button 
						  type="button"
						  className={styles.okButton} 
						  onClick={addNewChannel}
						  title={'Agregar nuevo canal de comunicación'}
					    >
						  <div className={styles.button_icon}>
						    <Icon name="plus-circle-fill" size="lg" />
						  </div>
						  Agregar
					    </button>
                      </div>
                    </Authorize>
                  )}
                </div>
              )}
            </div>
            <div className={styles.channelContainer}>
              <ReceiversTable
                id={id}
				isAddMode={isAddMode}
                isAdmin={isAdmin}
				readOnly={readOnly}
                onCancelAdd={onCancelAdd}
                rows={actualRows}
                filters={{ contactPoint, contactType }}
                alertManagerSourceName={alertManagerSourceName}
				configuration={configuration}
				isDark={isDark}
				width={width}
				height={showTitle ? height - 100 : height - 65}
              />
            </div>
          </>
        ) : (
          <EmptyArea>
            <p>No existe ningún canal de comunicación configurado</p>
          </EmptyArea>
        )}
      </div>
    </div>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: 99%;
    `,
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    channelContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    clearFilterBtn: css`
      align-self: flex-end;
      margin-left: ${theme.spacing(1)};
    `,
    filterInput: css`
      max-width: 300px;
	  height: 22px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    inputBox: css`
      margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
      min-width: 100px;
	  margin-left: 15px;
      }
    `,
    addMatcherBtn: css`
      align-self: flex-end;
    `,
    title: css`
      display: inline-flex;
      margin: 12px 20px 0px 20px;
	  color: ${fontColor};
	  border-bottom: 1px solid ${borderTitle};
	  width: Calc(100% - 30px);
	  margin-left: 15px;
	  margin-bottom: 5px;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import { LinkInspectorData } from 'types';
import { Icon } from '@grafana/ui';
import './css/LinkInspector.css';

interface LinkDescriptorVPNProps {
  id: string;
  link: LinkInspectorData[];
  width: number;
  height: number;
  lineWidth: number;
  fontSize: number;
  iconSize: number;
  metricUnit: string;
}

export class LinkDescriptorVPN extends Component<LinkDescriptorVPNProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
	const linkInfo = this.props.link;
    const isDark = config.theme.isDark || false;
	const fontColor = isDark ? '#E6E9ED' : '#23282E';
	const fontColorP1 = isDark ? '#E6E9ED' : '#23282E';
    const okColor = isDark ? '#936BFF' : '#5D2BE9';
    const phase2Color = isDark ? '#EFF4FA' : '#1B2733';
    const warningColor = isDark ? '#D9AF27' : '#FF7F27';
	const alarmColor = isDark ? '#F74545' : '#FB3333';
	const sfpCage = isDark ? '#58A95680' : '#D8DFE9';
	const borderColor = isDark ? '#585A5E' : '#9DA5B8';
	const tunnelColor = isDark ? '#557FFF' : '#6C63FE';
	const tunnelEndColor = isDark ? '#646A73' : '#5A6171';
	const gwStatusOk = isDark ? '#58A956' : '#D8DFE9';
    const markColor = isDark ? '#E6E9ED' : '#44444C';
    const shadowColor = isDark ? '#5A6171' : '#646A73';
    const lineWidth = this.props.lineWidth;
    const totalWidth = this.props.width;
	const totalHeight = this.props.height;
	const rectWidth = [110, 110, 35, 35, 30, 30, 30, 30];
	const rectHeight = [100, 100, 50, 50, 30, 30, 30, 30];
	const icons = [
	  linkInfo.assetTypeA,
	  linkInfo.assetTypeB,
	  '',
	  '',
	  'exchange',
	  'exchange-alt',
	  'modem',
	  'modem',
	];

	const legends = [
	  `${linkInfo.assetA} [${linkInfo.siteA}]`,
	  `${linkInfo.assetB} [${linkInfo.siteB}]`,
	  '',
	  '',
	  '',
	  '',
	  totalWidth > 795 ? linkInfo.tunnelGwNameA : '',
	  totalWidth > 795 ? linkInfo.tunnelGwNameB : '',
	];

    const radioColorA = linkInfo.linkAdminA ? sfpCage : alarmColor;
	const radioColorB = linkInfo.linkAdminB ? sfpCage : alarmColor;
    const gwColorA = linkInfo.tunnelGwStatusA ? gwStatusOk : alarmColor;
	const gwColorB = linkInfo.tunnelGwStatusB ? gwStatusOk : alarmColor;

	const rectColors = isDark ?
	  ['#5A6171', '#5A6171', radioColorA, radioColorB, '#D9AF27', '#D9AF27', gwColorA, gwColorB]:
	  ['#F4F9FF', '#F4F9FF', radioColorA, radioColorB, '#FF7F27', '#FF7F27', gwColorA, gwColorB];

	const lineAColor = linkInfo.linkStatusA && linkInfo.linkStatusB ? okColor : alarmColor;
    const lineBColor = linkInfo.linkStatusA && linkInfo.linkStatusB ? okColor : alarmColor;

    const linePhase2ColorA = linkInfo.tunnelStatusA ? phase2Color : alarmColor;
    const linePhase2ColorB = linkInfo.tunnelStatusB ? phase2Color : alarmColor;

    const rectPositionsX = totalWidth > 1195 ? 
	[320, totalWidth - 370, 320, totalWidth - 370, 320, totalWidth - 370, 420, totalWidth - 470] :
	[120, totalWidth - 170, 120, totalWidth - 170, 120, totalWidth - 170, 220, totalWidth - 270];

	const rectPositionsY = [80, 80, 105, 105, 115, 115, 115, 115];

	const convertUnit = (kbps) => {
	  if (kbps >= 1000000000) {
		const tbps = kbps / 1000000000;
		return `${tbps.toFixed(0)} Tbps`;
	  } else if (kbps >= 1000000) {
		const gbps = kbps / 1000000;
		return `${gbps.toFixed(0)} Gbps`;
	  } else if (kbps >= 1000) {
		const mbps = kbps / 1000;
		return `${mbps.toFixed(0)} Mbps`;
	  } else {
		return `${kbps} Kbps`;
	  }
	};

	const tooltips = [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  linkInfo.assetTypeB + ' ' + linkInfo.assetB,
	  `Estado Administrativo: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Estado Administrativo: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `Puerto ${linkInfo.portA} - ${linkInfo.linkStatusA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Puerto ${linkInfo.portB} - ${linkInfo.linkStatusB ? 'Habilitado' : 'Deshabilitado'}`,
	  `${linkInfo.tunnelGwNameA}`,
	  `${linkInfo.tunnelGwNameB}`
	];

	const OriginPhase1Info = linkInfo.tunnelStatusA ? [
	  `Phase 1: ${linkInfo.tunnelPhase1A}`,
	  `IP: ${linkInfo.tunnelIpA}`,
	] : [];
	const DestinationPhase1Info = linkInfo.tunnelStatusB ? [
	  `Phase 1: ${linkInfo.tunnelPhase1B}`,
	  `IP: ${linkInfo.tunnelIpB}`,
	] : [];

	const OriginPhase2Info: [] = [];
	const netOriginInfo = {
	  label: `Phase 2 Selectors`,
	  color: `${fontColor}`,
	};
	OriginPhase2Info.push(netOriginInfo);
	for (let i = 0; i < linkInfo.tunnelP2IdsA.length; i++) {
	  let info = `${linkInfo.tunnelP2IdsA[i]}:`;
	  info = linkInfo.tunnelP2BeginIPA.length > i ? `${info} ${linkInfo.tunnelP2BeginIPA[i]}` : info + ' -';
	  info = linkInfo.tunnelP2EndIPA.length > i ? `${info} / ${linkInfo.tunnelP2EndIPA[i]}` : info + ' / -';
	  const netInfo = {
	    label: info,
		color: linkInfo.tunnelP2StatusA[i] > 1 && linkInfo.tunnelStatusA ? fontColor : alarmColor,
	  }
	  OriginPhase2Info.push(netInfo);
	}

	const DestinationPhase2Info: [] = [];
	const netDestinationInfo = {
	  label: `Phase 2 Selectors`,
	  color: `${fontColor}`
	};
	DestinationPhase2Info.push(netDestinationInfo);
	for (let i = 0; i < linkInfo.tunnelP2IdsB.length; i++) {
	  let info = `${linkInfo.tunnelP2IdsB[i]}:`;
	  info = linkInfo.tunnelP2BeginIPB.length > i ? `${info} ${linkInfo.tunnelP2BeginIPB[i]}` : info + ' -';
	  info = linkInfo.tunnelP2EndIPB.length > i ? `${info} / ${linkInfo.tunnelP2EndIPB[i]}` : info + ' / -';
	  const netInfo = {
	    label: info,
		color: linkInfo.tunnelP2StatusB[i] > 1 && linkInfo.tunnelStatusB ? fontColor : alarmColor,
	  }
	  DestinationPhase2Info.push(netInfo);
	}

	const OriginInfo = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const DestinationInfo = linkInfo.linkAdminB ? [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedB)}`
	] : [
	  `Puerto ${linkInfo.portB}`,
	  `[${linkInfo.aliasB}]`,
	  `Admin: ${linkInfo.linkAdminB ? 'Habilitado' : 'Deshabilitado'}`
	];
	const OriginTrafficInfo = linkInfo.linkAdminA ? [
	  `${linkInfo.assetA} ▶`,
	  `Total: ${linkInfo.uPaquetsA} Bytes`
	] : [];
	const DestinationTrafficInfo = linkInfo.linkAdminB ? [
	  `◀ ${linkInfo.assetB}`,
	  `Total: ${linkInfo.uPaquetsB} Bytes`
	] : [];

	const OriginGWInfo = [
	  `IP: ${linkInfo.tunnelGwIpA}`,
	  `Estado: ${linkInfo.tunnelGwStatusA ? 'Alcanzable' : 'Inalcanzable'}`
	];
	const DestinationGWInfo = [
	  `IP: ${linkInfo.tunnelGwIpB}`,
	  `Estado: ${linkInfo.tunnelGwStatusB ? 'Alcanzable' : 'Inalcanzable'}`
	];
	
    return (
      <svg width={totalWidth} height={totalHeight}>
        {/* Línea de Radio */}
		{totalWidth > 695 && (
		  <image
		    href={`/public/img/icons/unicons/cloud.svg`}
		    x={(totalWidth * 0.5) - 25}
		    y={rectPositionsY[6] - 10}
		    style={isDark ? { filter: 'invert(1)' } : { filter: 'opacity(0.3)' }}
		    width={50}
		    height={50}
		  />
		)}
		<line
		  x1={rectPositionsX[3] + rectWidth[3] / 2}
		  y1={rectPositionsY[3] + (rectHeight[3] / 2)}
		  x2={rectPositionsX[7] + rectWidth[7] / 2}
		  y2={rectPositionsY[7] + (rectHeight[7] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={rectPositionsX[2] + rectWidth[2] / 2}
		  y1={rectPositionsY[2] + (rectHeight[2] / 2)}
		  x2={rectPositionsX[6] + rectWidth[6] / 2}
		  y2={rectPositionsY[6] + (rectHeight[6] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={rectPositionsX[6] + rectWidth[6] / 2}
		  y1={rectPositionsY[6] + (rectHeight[6] / 2)}
		  x2={(totalWidth * 0.5) + 5}
		  y2={rectPositionsY[6] + (rectHeight[6] / 2) + 5}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={(totalWidth * 0.5) + 5}
		  y1={rectPositionsY[6] + (rectHeight[6] / 2) + 5}
		  x2={(totalWidth * 0.5) - 5}
		  y2={rectPositionsY[7] + (rectHeight[7] / 2) - 5}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={(totalWidth * 0.5) - 5}
		  y1={rectPositionsY[7] + (rectHeight[7] / 2) - 5}
		  x2={rectPositionsX[7] + rectWidth[7] / 2}
		  y2={rectPositionsY[7] + (rectHeight[7] / 2)}
		  stroke={lineBColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		{totalWidth > 795 && (
		  <g>
		    <ellipse 
		      cx={rectPositionsX[7] + rectWidth[7] + 5} 
		      cy={40} 
		      rx={5} 
		      ry={10}
		      style={{ fill: tunnelColor, stroke: borderColor, strokeWidth: '1'  }}
		    />
		    <rect
		      x={rectPositionsX[6] - 5}
		      y={30}
              width={
			    totalWidth > 1195 ? 
				totalWidth - rectPositionsX[6] - 415 - (rectWidth[7] / 2) : 
				totalWidth - rectPositionsX[6] - 215 - (rectWidth[7] / 2)
			  }
              height={20}
		      fill={linkInfo.tunnelStatusA && linkInfo.tunnelStatusB ? tunnelColor : warningColor}
		      stroke={borderColor}
		      strokeWidth={1}
		      strokeDasharray={linkInfo.linkStatusA && linkInfo.linkStatusB ? 'none' : '2,2'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		    />
		    <ellipse 
		      cx={rectPositionsX[6] - 5} 
		      cy={40} 
		      rx={4} 
		      ry={9}
		      style={{ fill: tunnelEndColor, stroke: borderColor, strokeWidth: '1' }}
		    />
		    <ellipse 
		      cx={rectPositionsX[7] + rectWidth[7] + 5} 
		      cy={40} 
		      rx={4} 
		      ry={9}
		      style={{ fill: tunnelColor, strokeWidth: '0' }}
		    />
		  </g>
		)}
		{totalWidth > 1195 && (
		  <g>
		    <line
		      x1={200}
		      y1={40}
		      x2={rectPositionsX[6] - 5}
		      y2={40}
		      stroke={linePhase2ColorA}
		      strokeWidth={linkInfo.tunnelStatusA ? lineWidth + 1 : lineWidth}
		      strokeDasharray={linkInfo.tunnelStatusA ? '1,1' : '3,3'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		    />
		    <line
		      x1={totalWidth - 200}
		      y1={40}
		      x2={rectPositionsX[7] + rectWidth[7] + 10}
		      y2={40}
		      stroke={linePhase2ColorA}
		      strokeWidth={linkInfo.tunnelStatusB ? lineWidth + 1 : lineWidth}
		      strokeDasharray={linkInfo.tunnelStatusB ? '1,1' : '3,3'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		    />
		    <line
		      x1={200}
		      y1={10}
		      x2={200}
		      y2={100}
		      stroke={linePhase2ColorA}
		      strokeWidth={linkInfo.tunnelStatusA ? lineWidth + 1 : lineWidth}
		      strokeDasharray={linkInfo.tunnelStatusA ? '1,1' : '3,3'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		    />
		    <line
		      x1={totalWidth - 200}
		      y1={10}
		      x2={totalWidth - 200}
		      y2={100}
		      stroke={linePhase2ColorA}
		      strokeWidth={linkInfo.tunnelStatusB ? lineWidth + 1 : lineWidth}
		      strokeDasharray={linkInfo.tunnelStatusB ? '1,1' : '3,3'}
		      style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		    />
		  </g>
		)}
		{/* Elementos */}
        {rectWidth.map((width, index) => {
		  const iconX = index === 1 ? (width * 0.4) : 2;
		  const rectX = index === 1 || index === 3 ? -2 : 0;
		  return (
            <g key={'I_' + index}>
		      <rect
                x={index === 0 || index === 2 ? (rectPositionsX[4] + rectWidth[4] - width + 2) : rectPositionsX[index] + rectX}
                y={rectPositionsY[index]}
				rx={width * 0.05 > 2 ? width * 0.05 : 2}	
                width={width}
                height={rectHeight[index]}
                fill={rectColors[index]}
				stroke={shadowColor}
				stroke-width="1"
              />
              {icons[index] !== '' && (
			    <image
                  href={`/public/img/icons/unicons/${icons[index]}.svg`}
                  x={index === 0 ? (rectPositionsX[4] + rectWidth[4] - width) : rectPositionsX[index] + iconX}
                  y={rectPositionsY[index] + 5}
				  style={isDark && index < 2 ? { filter: 'invert(1)' } : { filter: 'none' }}
				  width={index < 2 ? width * 0.6 : width - 4}
                  height={index < 2 ? rectHeight[index] * 0.6 : rectHeight[index] - 8}
                />
			  )}
			  <title>{tooltips[index]}</title>
		    </g>
		  );
        })}
		{/* Testo de leyendas */}
		<g>
		  {legends.map((legend, index) => {
            const isTop = (index === 0 || index === 1) ? true : false;
            const isLeft = (index === 7) ? true : false; 
			const yGap = (index === 6 || index === 7) ? 10 : 0;
			const textY = isTop ? 70 : (rectPositionsY[index] + rectHeight[index] + yGap);
            let textX = isTop ? (rectPositionsX[index] + (rectWidth[index] / 2)) : (rectPositionsX[index] + rectWidth[index] + 5);
            let textAnchor = isTop ? 'middle' : 'start';
			if (isLeft) {
			  textX = index === 2 ? (rectPositionsX[index] - 10) : ((rectPositionsX[index]) - 5);
			  textAnchor = 'end';
			}
            return (
              <text
                key={index}
                x={index === 0 ? (rectPositionsX[4] + rectWidth[2] - (rectWidth[0] / 2)) : textX}
                y={textY}
                textAnchor={textAnchor}
                fill={fontColor}
                fontSize={isTop ? this.props.fontSize + 2 : this.props.fontSize}
                fontWeight={isTop ? 'bold' : 'normal'}
              >
                {legend}
              </text>
            );
		  })}
		  {totalWidth > 1195 && OriginPhase2Info.map((line, index) => (
		    <text
			  key={'OriginPhase2Info' + index}
			  x={190}
			  y={20 + (index * 15)}
			  textAnchor={'end'}
			  fill={line.color}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index === 0 ? 'bold' : 'normal'}
		    >
			  {line.label}
		    </text>
		  ))}
		  {totalWidth > 1195 && DestinationPhase2Info.map((line, index) => (
		    <text
			  key={'DestinationPhase2Info' + index}
			  x={totalWidth - 190}
			  y={20 + (index * 15)}
			  textAnchor={'start'}
			  fill={line.color}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index === 0 ? 'bold' : 'normal'}
		    >
			  {line.label}
		    </text>
		  ))}
		  {totalWidth > 795 && OriginPhase1Info.map((line, index) => (
		    <text
			  key={'OriginPhase1Info' + index}
			  x={rectPositionsX[6] + 15}
			  y={45 + (index * 25)}
			  textAnchor={'start'}
			  fill={index === 0 ? '#E6E9ED' : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 795 && DestinationPhase1Info.map((line, index) => (
		    <text
			  key={'DestinationPhase1Info' + index}
			  x={rectPositionsX[7] + rectWidth[7] - 15}
			  y={45 + (index * 25)}
			  textAnchor={'end'}
			  fill={index === 0 ? '#E6E9ED' : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {OriginInfo.map((line, index) => (
		    <text
			  key={'OriginInfo' + index}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 40 + (index * 15)}
			  textAnchor={'end'}
			  fill={fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {DestinationInfo.map((line, index) => (
		    <text
			  key={'DestinationInfo' + index}
			  x={rectPositionsX[3] + (rectWidth[3] / 2) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 40 + (index * 15)}
			  textAnchor={'start'}
			  fill={fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 795 && OriginGWInfo.map((line, index) => (
		    <text
			  key={'OriginGWInfo' + index}
			  x={rectPositionsX[6] + (rectWidth[6] / 2) + 5}
			  y={rectPositionsY[6] + rectHeight[6] + 35 + (index * 15)}
			  textAnchor={'start'}
			  fill={linkInfo.tunnelGwStatusA ? fontColor : warningColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {totalWidth > 795 && DestinationGWInfo.map((line, index) => (
		    <text
			  key={'DestinationGWInfo' + index}
			  x={rectPositionsX[7] + (rectWidth[7] / 2) - 5}
			  y={rectPositionsY[7] + rectHeight[7] + 35 + (index * 15)}
			  textAnchor={'end'}
			  fill={linkInfo.tunnelGwStatusB ? fontColor : warningColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1395 && linkInfo.linkAdminA && linkInfo.linkAdminB) && OriginTrafficInfo.map((line, index) => (
		    <text
			  key={'OriginTrafficInfo' + index}
			  x={(totalWidth * 0.45) - 5}
			  y={100 + (index * 15)}
			  textAnchor={'end'}
			  fill={fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 1395 && linkInfo.linkAdminA && linkInfo.linkAdminB) && DestinationTrafficInfo.map((line, index) => (
		    <text
			  key={'DestinationTrafficInfo' + index}
			  x={(totalWidth * 0.55) + 5}
			  y={100 + (index * 15)}
			  textAnchor={'start'}
			  fill={fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		</g>
		{/* Lineas de leyendas */}
		<line
          x1={rectPositionsX[2] + rectWidth[2] / 2}
          y1={rectPositionsY[2] + rectHeight[2]}
          x2={rectPositionsX[2] + rectWidth[2] / 2}
          y2={rectPositionsY[2] + rectHeight[2] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		<line
          x1={rectPositionsX[3] + rectWidth[3] / 2}
          y1={rectPositionsY[3] + rectHeight[3]}
          x2={rectPositionsX[3] + rectWidth[3] / 2}
          y2={rectPositionsY[3] + rectHeight[3] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		{totalWidth > 795 && (
		  <line
            x1={rectPositionsX[6] + rectWidth[6] / 2}
            y1={rectPositionsY[6] + rectHeight[6]}
            x2={rectPositionsX[6] + rectWidth[6] / 2}
            y2={rectPositionsY[6] + rectHeight[6] + 35}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{totalWidth > 795 && (
		  <line
            x1={rectPositionsX[7] + rectWidth[7] / 2}
            y1={rectPositionsY[7] + rectHeight[7]}
            x2={rectPositionsX[7] + rectWidth[7] / 2}
            y2={rectPositionsY[7] + rectHeight[7] + 35}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1395 && ( 
		  <line
            x1={totalWidth * 0.45}
            y1={50}
            x2={totalWidth * 0.45}
            y2={100}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.linkAdminB && totalWidth > 1395 && ( 
          <line
            x1={totalWidth * 0.55}
            y1={50}
            x2={totalWidth * 0.55}
            y2={100}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
      </svg>
    );
  }
}

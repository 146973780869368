import { cx, css, keyframes } from '@emotion/css';
import React, { FC, useEffect, useState } from 'react';

import { NetMonitorTheme2 } from '@grafana/data';
import { useStyles2, styleMixins } from '@grafana/ui';

import { Branding } from '../Branding/Branding';
import { Footer } from '../Footer/Footer';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox: FC<InnerBoxProps> = ({ children, enterAnimation = true }) => {
  const loginStyles = useStyles2(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export const LoginLayout: FC = ({ children }) => {
  const loginStyles = useStyles2(getLoginStyles);
  const subTitle = Branding.GetLoginSubTitle();
  const [startAnim, setStartAnim] = useState(false);

  useEffect(() => setStartAnim(true), []);

  return (
    <Branding.LoginBackground className={cx(loginStyles.container, startAnim && loginStyles.loginAnim)}>
      <div className={cx(loginStyles.loginContent, Branding.LoginBoxBackground(), 'login-content-box')}>
        <div className={loginStyles.loginLogoWrapper}>
          <Branding.LoginLogo className={loginStyles.loginLogo} />
          <Branding.LoginSubLogo className={loginStyles.loginSubLogo} />
          <div className={loginStyles.titleWrapper}>
            <div className={loginStyles.mainTitle}>{Branding.LoginTitle}</div>
          </div>
        </div>
		<div className={loginStyles.loginOuterBox}>
          <div className={loginStyles.loginStatusBox}>
		    <img className={loginStyles.loginStatusIcon} src="public/img/frontend-status.svg" title="Estado de NetMonitor"/>
		    <img className={loginStyles.loginStatusIcon} src="public/img/database-status.svg" title="Estado de Base de Datos"/>
		  </div>
		  {children}
		</div>
      </div>
      <Footer />
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
}

to{
  opacity: 1;
}`;

export const getLoginStyles = (theme: GrafanaTheme2) => {
  const textColor = theme.isDark ? '#e5e5e5' : '#222';

  return {
    container: css`
      min-height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      min-width: 100%;
      margin-left: 0;
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    submitButton: css`
      justify-content: center;
      width: 50%;
      font-color: #000000;
      background-color: #325096;
    `,
    loginLogo: css`
      width: 80%;
      max-width: 50px;
      margin-top: 25px;
      margin-bottom: 10px;
      z-index: 12;
    `,
    loginSubLogo: css`
      width: 80%;
      max-width: 220px;
      margin-bottom: 10px;
      z-index: 12;
    `,
    loginName: css`
      width: 80%;
      max-width: 220px;
      margin-bottom: 10px;
      z-index: 12;
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing.sm} ${theme.spacing.lg};
      z-index: 11;
    `,
    titleWrapper: css`
      text-align: center;
      z-index: 12;
    `,
    mainTitle: css`
      font-size: 16px;
      padding-top: 12px;
      padding-bottom: 50px;
      color: ${theme.colors.text.primary};
      z-index: 12;
    `,
    loginContent: css`
      max-width: 400px;
      width: 100%;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: absolute;
      justify-content: center;
      z-index: 10;
      min-height: 300px;
      border: 1px solid #325096;
      background: ${theme.colors.background.primary};
      opacity: 1;
      border-radius: 10px;
      padding: 8px 0;
	  filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.5));
    0`,
    loginStatusBox: css`
	  display: flex;
      position: fixed;
      left: 50px;
      bottom: 28px;
      overflow: hidden;
      justify-content: left;
    `,
    loginStatusIcon: css`
      margin-left: 5px;
      height: 18px;
      z-index: 12;
    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: hidden;
      align-items: center;
      justify-content: center;
      font-size: '14px';
      font-color: ${theme.colors.text.primary};
      z-index: 12;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing.xl};
      @media (max-width: 320px) {
        padding: ${theme.spacing.lg};
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 300px;
      width: 100%;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
  };
};

import { css } from '@emotion/css';
import React, { useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'react-use';

import { config, getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2, Select } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';
import { getSignFilters } from 'app/features/alerting/unified/utils/misc';
import { L1OyMTable, L1OyMMap, generarID } from './L1OyMTable';
import { L1OyMData } from './L1OyMManager';
import { valueToType, valueToSubtype, valueToStatus } from './types';

type L1OyMGroupProps = {
  isAdmin: boolean;
  showTitle: boolean;
  showSelector: boolean;
  showTable: boolean;
  pluginTitle: string;
  id: string;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  onSaveChanges: (savedElements: L1OyMData[]) => void;
  onSaveImportData: (elementsUpdated: []) => void;
}

interface Filters {
  title?: string;
  elementType?: string;
  subType?: string;
  place?: string;
}

export const L1OyMGroup = ({
  id,
  showTitle,
  showSelector,
  showTable,
  pluginTitle,
  isAdmin,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  onSaveChanges,
  onSaveImportData,
}: L1OyMGroupProps) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width, height));
  const emp = getTemplateSrv().replace(`$${pluginVariables[1]}`);
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { title, elementType, subType, place } = getSignFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ title, elementType, subType, place });
  const [actualElements, setActualElements] = useState([...elements]);
  const [renderId, setrenderId] = useState(id);
  const [actualPlace, setActualPlace] = useState(emp);
  const [placeType, setPlaceType] = useState('');
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [isKmzAddMode, setIsKmzAddMode] = useState<any>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const userName = contextSrv.user.name;
  const d = Date.now();
  const actualElementsLength = actualElements.length;
  const newId = generarID();
  //console.log(renderId, id, sites);
  if (renderId !== id) {
    setrenderId(id);
	setActualElements([...elements]);
  }
  const emptyObject = {
	  id: actualElementsLength,
	  uid: newId,
	  idx: newId,
	  title: 'Nuevo objeto ' + String(actualElementsLength + 1),
	  style: '',
	  state: false,
	  created: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  updated: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  user: userName,
	  subType: 'path',
	  elementType: 'path',
	  elementId: '',
	  siteId: '',
	  pathId: newId,
	  spanId: '',
	  sectionType: '',
	  status: 'out_of_use',
	  installationDate: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  installationNotes: '',
	  technician: '',
	  lastInspection: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  nextMaintenance: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  owner: '',
	  technicalDetails: '',
	  origin: '',
	  destination: '',
	  coordinates: [],
	  address: '',
	  elevation: 0,
	  parentsIds: [],
	  relationIds: [],
	  relationDistances: [],
	  relationCapacities: [],
	  sublinkIds: [],
	  sublinkBuffer: [],
	  sublinkColor: [],
	  sublinkAttenuation: [],
	  sublinkDistance: [],
	  segmentId: '',
	  pathEventId: '',
	  capacity: 0,
	  fiberIds: [],
	  fiberAttenuation: [],
	  lastInspector: '',
	  inspectionReport: '',
	  inspectionNotes: '',
	  version: 0,
  };

  const pathIDs: [] = [];
  const idList = elements.filter(value => value.elementType === 'path' && value.version > 0);

  for (let i = 0; i < idList.length; i++) {
	let newId = {
	  label: idList[i].title,
	  description: idList[i].title,
	  value: idList[i].pathId,
	};
	pathIDs.push(newId);
  }

  const linkIDs: [] = [];
  const linkList = elements.filter(value => value.elementType === 'link' && value.version > 0);

  for (let i = 0; i < linkList.length; i++) {
	let newId = {
	  label: linkList[i].title,
	  description: linkList[i].title,
	  value: linkList[i].linkId,
	};
	linkIDs.push(newId);
  }

  if (emp !== actualPlace) {
    setActualPlace(emp);
  }

  const stringToPlace = (name: string) => {
	const placesDefined = places;
	const placeSelected = placesDefined.find(element => element.value === name);
	return placeSelected;
  }

  const stringToPlaceType = (name: string) => {
	const typesDefined = types;
	const typeSelected = typesDefined.find(element => element.value === name);
	return typeSelected;
  }

  const stringToPath = (name: string) => {
	const pathsDefined = pathIDs;
	const pathSelected = pathsDefined.find(element => element.value === name);
	return pathSelected;
  }
  
  const stringToLink = (name: string) => {
	const linksDefined = linkIDs;
	const linkSelected = linksDefined.find(element => element.value === name);
	return linkSelected;
  }

  useDebounce(() => {
      setSearchParams({ title: filters.title, elementType: filters.elementType, subType: filters.subType, place: filters.place});
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ title: undefined, elementType: undefined, subType: undefined, place: undefined });
    setSearchParams({ title: undefined, elementType: undefined, subType: undefined, place: undefined });
  };

  const addNewElement = () => {
    setIsAddMode(true);
    setActualElements(() => [
      ...elements,
      {
        ...emptyObject,
      },
    ]);
  };

  const addNewKmz = (event) => {
    const file = event.target.files[0];
    const reader = file ? new FileReader() : undefined;
	if (reader) {
	  reader.readAsDataURL(file);
      setIsKmzAddMode(reader);
	}
  };

  const onSaveNewKmz = (elementsUpdated: L1OyMData[]) => {
	setIsKmzAddMode(undefined);
	onSaveImportData(elementsUpdated);
	setActualElements(() => [...elementsUpdated]);
  };

  const onCancel = () => {
	setIsAddMode(false);
	setIsKmzAddMode(undefined);
    setActualElements(() => [...elements]);
  };

  const onSave = (savedElements: L1OyMData[]) => {
	setIsAddMode(false);
	const updatedElements = onSaveChanges(savedElements);
	setActualElements(() => [...updatedElements]);
  };

  const showInTableMode = isKmzAddMode ? false : showTable;
  //console.log(showTable, isKmzAddMode, actualElements, showInTableMode);
  return (
    <div key={id} id={id} className={styles.wrapper}>
      {showTitle && !showSelector && (
	    <div className={styles.titleContainer}>
		  <div className={styles.title}>
            <div className={styles.titleText}>{pluginTitle}</div>
          </div>
		</div>
	  )}
	  {showTitle && showSelector && (
        <div className={styles.titleContainerWithFilter}>
		  <div className={styles.titleWithFilter}>
            <div className={styles.titleTextWithFilter}>{pluginTitle}</div>
          </div>
		  <div className={styles.addMatcherBtnRow}>
			<button 
			  type="button"
			  className={showFilter ? styles.filterButtonDisable : styles.filterButton }
			  onClick={() => {
				setShowFilter(!showFilter);
			  }}
			>
			  <div className={styles.button_icon}>
				<Icon name="ellipsis-h" size="lg" />
			  </div>
			  {showFilter ? 'Ocultar opciones y filtros' : 'Ver opciones y filtros'}
			</button>
          </div>
		</div>
      )}
	  {showSelector && showFilter && (
	    <div className={styles.container}>
		  <div className={styles.filterContainer}> 
			<legend className={styles.legend}>
			  {'Filtrar por Emplazamiento: '}
			</legend>
			<div className={styles.selectBox}>
			  <Select
				aria-label="placeType"
				onChange={(currentTarget) => {
				  setPlaceType(currentTarget.value);
				}}
				options={types}
				defaultValue={stringToPlaceType(placeType)}
				title="Seleccione un tipo de Emplazamiento"
				placeholder="Tipo"
			  />
			</div>
			{placeType && placeType !== '' && (
			  <div className={styles.selectBox}>
				<Select
				  aria-label="place"
				  onChange={(currentTarget) => {
					setFilters((currentFilters) => ({ ...currentFilters, place: currentTarget.value }))
				  }}
				  options={places.filter(place => place.family === placeType)}
				  defaultValue={stringToPlace(filters.place)}
				  title="Seleccione el ID de emplazamiento donde se registra el elemento o evento."
				  placeholder="ID"
				/>
			  </div>
			)}
		  </div>
		  <div className={styles.filterContainer}> 
			<legend className={styles.legend}>
			  {'Filtrar por tipo: '}
			</legend>
			<div className={styles.selectBox}>
			  <Select
				aria-label="elementType"
				onChange={(currentTarget) => {
				  setFilters((currentFilters) => ({ ...currentFilters, elementType: currentTarget.value }));
				}}
				options={valueToType}
				defaultValue={valueToType.find(eleType => eleType.value === filters.elementType)}
				title={'Seleccione un tipo de elemento para filtrar.'}
			  />
			</div>
			{filters.elementType && filters.elementType !== 'path' && filters.elementType !== 'link' && filters.elementType !== '' && (
			  <div className={styles.selectBox}>
				<Select
				  aria-label="subType"
				  onChange={(currentTarget) => {
					setFilters((currentFilters) => ({ ...currentFilters, subType: currentTarget.value }));
				  }}
				  options={valueToSubtype.filter(subType => subType.family === filters.elementType)}
				  defaultValue={valueToSubtype.find(eleSubtype => eleSubtype.value === filters.subType)}
				  title={'Seleccione un subtipo de elemento para filtrar.'}
				/>
			  </div>
			)}
			{filters.elementType && (filters.elementType === 'path' || filters.elementType === 'link') && (
			  <div className={styles.selectBox}>
				<Select
				  aria-label="subType"
				  onChange={(currentTarget) => {
					setFilters((currentFilters) => ({ ...currentFilters, subType: currentTarget.value }));
					onCancel();
				  }}
				  options={filters.elementType === 'path' ? pathIDs : linkIDs}
				  defaultValue={filters.elementType === 'path' ? stringToPath(filters.subType) : stringToLink(filters.subType)}
				  title={filters.elementType === 'path' ? 'Seleccione un ID de trayecto' : 'Seleccione un ID de enlace'}
				/>
			  </div>
			)}
		  </div>
		  {isAdmin && !isAddMode && !isKmzAddMode && (
			<div className={styles.addMatcherBtnRow}>
			  <div title={'Importar objetos GIS'}>
				<input
				  type="file" 
				  accept=".kmz" 
				  onChange={(event) => {
				    addNewKmz(event);
				  }}
				  style={{ display: 'none' }} 
				  id="kmz-upload"
				/>
				<label htmlFor="kmz-upload" className={styles.importButton}>
				  <div className={styles.button_icon}>
				    <Icon name="up-round" size="lg" />
				  </div>
				  Importar
				</label>
			  </div>
			</div>
		  )}
		</div>
	  )}
      <div className={styles.mainContainer}>
		{showSelector && (
		  <div className={styles.filterContainer}>
			<div className={styles.filterInput}>
			  <Icon name="search" size="xs" />
			  <input
				className={styles.inputBox}
				onChange={({ currentTarget }) =>
				  setFilters((currentFilters) => ({ ...currentFilters, title: currentTarget.value }))
				}
				value={filters.title ?? ''}
				placeholder="Buscar por designación ..."
			  />
			</div>
			{(filters.title || filters.place || filters.elementType || filters.subType) && (
			  <div className={styles.addMatcherBtnRow}>
			    <button 
				  type="button" 
				  className={styles.cancelButton} 
				  onClick={clearFilters}
				  title={'Limpiar filtros aplicados'}
				>
				  <div className={styles.button_icon}>
				    <Icon name="times-fill" size="lg" />
				  </div>
				  Limpiar
				</button>
			  </div>
			)}
			{isAdmin && !isAddMode && !isKmzAddMode && (
			  <div className={styles.addMatcherBtnRow}>
				<button 
				  type="button"
				  className={styles.okButton} 
				  onClick={addNewElement}
				  title={'Agregar nuevo objeto GIS'}
				>
				  <div className={styles.button_icon}>
				    <Icon name="plus-circle-fill" size="lg" />
				  </div>
				  Agregar
				</button>
			  </div>
			)}
		  </div>
		)}
		{showInTableMode && (
		  <div className={styles.elementContainer}>
		    <L1OyMTable
		      id={id}
			  isAdmin={isAdmin}
			  onSave={onSave}
			  onCancel={onCancel}
			  filters={filters}
			  isDark={isDark}
			  width={width}
			  height={showTitle ? height - 100 : height - 65}
			  elements={actualElements}
			  places={places}
			  types={types}
			  sites={sites}
			  pluginVariables={pluginVariables}
			  isKmzAddMode={isKmzAddMode}
		    />
		  </div>
		)}
		{!showInTableMode && (
		  <div className={styles.elementContainer}>
		    <L1OyMMap
		      id={id}
			  isAdmin={isAdmin}
			  onImportData={onSaveNewKmz}
			  onSave={onSave}
			  onCancel={onCancel}
			  filters={filters}
			  isDark={isDark}
			  width={width}
			  height={showTitle ? height - 100 : height - 65}
			  elements={actualElements}
			  places={places}
			  types={types}
			  sites={sites}
			  pluginVariables={pluginVariables}
			  isKmzAddMode={isKmzAddMode}
		    />
		  </div>
		)}
	  </div>
    </div>
  );
};

const getStyles = (isDark: boolean, width: number, height: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const importButtonBack = isDark ? '#58A956' : '#24AB00';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: ${width}px;
	  height: ${height}px;
    `,
    mainContainer: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    container: css`
      display: flex;
      flex-flow: row;
      padding: 5px;
	  align-items: center;
    `,
	titleContainer: css`
      display: inline-flex;
	  align-items: center;
	  width: 100%;
    `,
	titleContainerWithFilter: css`
      display: inline-flex;
	  align-items: center;
      justify-content: space-between;
	  width: 100%;
	  border: 1px solid ${borderTitle};
	  border-radius: 10px;
	  box-shadow: ${borderTitle} 0px 2px 6px 0px;
    `,
	filterContainer: css`
      display: flex;
	  flex-flow: row;
	  width: 100%;
	  margin-bottom: 5px;
      align-items: center;
    `,
    elementContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    filterInput: css`
	  min-width: 350px;
	  width: -webkit-fill-available;
	  height: 24px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  margin-right: 15px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
	legend: css`
	  display: block;
	  width: max-content;
      font-size: 12px;
	  font-weight: 500;
      margin-right: 5px;
	  margin-top: 5px;
    `,
    inputBox: css`
      width: Calc(100% - 20px);
	  margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    selectBox: css`
	  margin-right: 15px;
      max-width: 350px;
	  min-width: 200px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
	  margin-left: 15px;
      }
    `,
    title: css`
      display: inline-flex;
      margin: 12px 20px 0px 20px;
	  color: ${fontColor};
	  border-bottom: 1px solid ${borderTitle};
	  width: Calc(100% - 30px);
	  margin-left: 15px;
	  margin-bottom: 5px;
    `,
    titleWithFilter: css`
      display: inline-flex;
	  flex-grow: 0;
      flex-wrap: nowrap;
      margin: 0px 20px 0px 20px;
	  color: ${fontColor};
	  justify-content: center;
      overflow: hidden;
      text-overflow: ellipsis;
	  width: 100%;
      white-space: nowrap;
	  align-items: center;
    `,
    titleTextWithFilter: css`
	  display: contents;
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 0px 20px 0px 5px;
	  font-size: 12px;
	  font-weight: 500;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    importButton: css`
      background-color: ${importButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
	  font-size: 12px;
	  font-weight: 400;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xl};
	  height: 24px;
	  padding: 0px 10px 0px 2px;
    `,
	okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
	filterButton: css`
	  display: inline-flex;
	  background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      border-radius: 0 10px 10px 0;
      box-shadow: 1px 1px 10px 0 ${okButtonBack};
      color: #fff;
      font-weight: 500;
      width: max-content;
	  height: 24px;
      opacity: .85;
      padding-left: 5px;
      padding-right: 15px;

	    &:hover {
		  opacity: 1;
		}
    `,
	filterButtonDisable: css`
	  display: inline-flex;
	  background-color: ${buttonBorder};
      border: 1px solid ${fontColor}A0;
      border-radius: 0 10px 10px 0;
      box-shadow: 1px 1px 10px 0 ${buttonBorder};
      color: #fff;
      font-weight: 500;
      width: max-content;
	  height: 24px;
      opacity: .85;
      padding-left: 5px;
      padding-right: 15px;

	    &:hover {
		  opacity: 1;
		}
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

import React, { PureComponent, useEffect } from 'react';
import _, { isEqual }  from 'lodash';
import { Icon } from '@grafana/ui';
import { TemplateSrv, locationService, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

import axios from 'axios';
import cytoscape, { EdgeCollection, EdgeSingular, NodeSingular } from 'cytoscape';
import L, {LatLng} from 'leaflet';
import cytoscapeLeaflet from 'cytoscape-leaflet';

import cyCanvas from 'cytoscape-canvas';
import dagre from 'cytoscape-dagre';
import avsdf from 'cytoscape-avsdf';
import cise from 'cytoscape-cise';
import coseBilkent from 'cytoscape-cose-bilkent';
import fcose from 'cytoscape-fcose';
import cola from 'cytoscape-cola';
import breadthfirst from 'cytoscape-breadthfirst';
import qtip from 'cytoscape-qtip';
import html2canvas from 'html2canvas';
import svg from 'cytoscape-svg';

import expandCollapse from 'cytoscape-expand-collapse';
import contextMenus from 'cytoscape-context-menus';

import infinite from '../infinite';
import layoutOptions from '../layout_options';
import layoutOptions_dagre from '../layout_options_dagre';
import layoutOptions_avsdf from '../layout_options_avsdf';
import layoutOptions_cola from '../layout_options_cola';
import layoutOptions_cise from '../layout_options_cise';
import layoutOptions_fcose from '../layout_options_fcose';

import { Statistics } from '../statistics/Statistics';
import { PanelController } from '../PanelController';
import CanvasDrawer from '../canvas/graph_canvas';
import {
  TableContent,
  TableHeader,
  Table2Content,
  Table2Header,
  Table3Content,
  Table3Header,
  IntGraphMetrics,
  IntGraph,
  GraphDataType,
  PanelSettings,
  IntSelectionStatistics,
} from '../../types';

import 'cytoscape-context-menus/cytoscape-context-menus.css';
import './ServiceDependencyGraph.css';
import '../../css/netmonitor-topology-map-qtip.css';
import '../../css/netmonitor-topology-map-cytoscape.css';
import '../../css/leaflet.css';
import '../../css/cytoscape-leaf.css';

interface PanelState {
  controller: PanelController;
  cy?: cytoscape.Core | undefined;
  leaf?: cytoscape.Leaflet | undefined;
  graphCanvas?: CanvasDrawer | undefined;
  showStatistics: boolean;
  showEdgeStatistics: boolean;
  data: IntGraph;
  options: PanelSettings;
  isLock: boolean;
  isCollapsed: boolean;
  initResize: boolean;
  isGeoMap: boolean;
  mustUpdate: string;
}

cyCanvas(cytoscape);
expandCollapse(cytoscape);
contextMenus(cytoscape);

cytoscape.use(cytoscapeLeaflet);
cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(avsdf);
cytoscape.use(cise);
cytoscape.use(coseBilkent);
cytoscape.use(fcose);
cytoscape.use(qtip);
cytoscape.warnings(false);

export class ServiceDependencyGraph extends PureComponent<PanelState, PanelState> {
  ref: any;
  selectionId: string;
  sys_header: Table3Header[];
  currentDescription: Table3Content[];
  currentLabel: string;
  currentSite: string;
  currentType: string;
  selectionStatistics: IntSelectionStatistics;
  receiving: TableContent[];
  sending: TableContent[];
  table_header: TableHeader[];
  node_title: string;
  edge_title: string;
  links: Table2Content[];
  linksHeader: Table2Header[];
  resolvedDrillDownLink_asset: string;
  resolvedDrillDownLink_site: string;
  templateSrv: TemplateSrv;
  layout: string;
  toolbar: boolean;
  showStatTables: boolean;
  cyhasInitialPosition: boolean;
  mustUpdate: string;

  zoomValue: number;
  lonValue: number;
  latValue: number;
  zValue: number;
  xValue: number;
  yValue: number;

  constructor(props: PanelState) {
    super(props);
    const { initBlock, allCollapsed } = this.getSettings(false);
	const { initialCyZoom, initialX, initialY } = this.getSettings(false);
	const { initialZoom, initialLon, initialLat } = this.getSettings(false);
	const { useZoomVariable, zoomVariable, latVariable, lonVariable } = this.getSettings(false);
	const { useZoomCyVariable, zoomCyVariable, xVariable, yVariable } = this.getSettings(false);
	/*GeoMode*/
	const initZoom = !isNaN(initialZoom) ? initialZoom : 0;
	const initLat = !isNaN(initialLat) ? initialLat : 0;
	const initLon = !isNaN(initialLon) ? initialLon : 0;
	const zoomVariableValue = useZoomVariable ? Number(getTemplateSrv().replace(`$${zoomVariable}`)) : 0;
	const latVariableValue = useZoomVariable ? Number(getTemplateSrv().replace(`$${latVariable}`)) : 0;
	const lonVariableValue = useZoomVariable ? Number(getTemplateSrv().replace(`$${lonVariable}`)) : 0;
	const geoZoomValue = useZoomVariable && zoomVariableValue !== null && !isNaN(zoomVariableValue) ? zoomVariableValue : initZoom;
	const geoLatValue = useZoomVariable && latVariableValue !== null && !isNaN(latVariableValue) ? latVariableValue : initLat;
    const geoLonValue = useZoomVariable && lonVariableValue !== null && !isNaN(lonVariableValue) ? lonVariableValue : initLon;
	/*TopologyMode*/
	const initZ = !isNaN(initialCyZoom) ? initialCyZoom : 0;
	const initY = !isNaN(initialY) ? initialY : 0;
	const initX = !isNaN(initialX) ? initialX : 0;
	const zVariableValue = useZoomCyVariable ? Number(getTemplateSrv().replace(`$${zoomCyVariable}`)) : 0;
	const xVariableValue = useZoomCyVariable ? Number(getTemplateSrv().replace(`$${xVariable}`)) : 0;
	const yVariableValue = useZoomCyVariable ? Number(getTemplateSrv().replace(`$${yVariable}`)) : 0;
	const cyZoomValue = useZoomCyVariable && zVariableValue !== null && !isNaN(zVariableValue) ? zVariableValue : initZ;
	const cyXValue = useZoomCyVariable && xVariableValue !== null && !isNaN(xVariableValue) ? xVariableValue : initX;
    const cyYValue = useZoomCyVariable && yVariableValue !== null && !isNaN(yVariableValue) ? yVariableValue : initY;

	this.ref = React.createRef();
    this.templateSrv = getTemplateSrv();

    this.state = {
      ...props,
	  leafLet: this.props.leaf,
	  showStatistics: false,
      showEdgeStatistics: false,
	  isLock: initBlock,
      isCollapsed: allCollapsed,
	  isGeoMap: this.props.isGeoMap,
    };

	this.mustUpdate = this.props.mustUpdate;
	this.zoomValue = (geoZoomValue < 19 && geoZoomValue > 4) ? geoZoomValue : 12;
	this.lonValue = geoLonValue;
	this.latValue = geoLatValue;
	this.zValue = cyZoomValue < 3 && cyZoomValue > 0 ? cyZoomValue : 1;
	this.xValue = cyXValue;
	this.yValue = cyYValue;
    this.viewportTimeout = null;
    this.repaintTimeout = null;
    this.selectionId = null;
  }

  componentDidMount() {
	const isDark = config.theme.isDark;
	const { layoutFormat } = this.getSettings(false);
    const { drillDownLink_asset, drillDownLink_site } = this.getSettings(true);
	const { healthyColor, warningColor, dangerColor, noDataColor } = this.getSettings(false).style;
	const { healthyThreshold, warningThreshold } = this.getSettings(false).style;
	const { showToolbar, showStatTables, useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);
	const { mapSource, addMapVariable, addMap } = this.getSettings(false);
    const { sysHeader, nodeHeader } = this.getSettings(true);
    const { showSysInfo, showConnectionStats} = this.getSettings(false);
	const { initialCyZoom, initialX, initialY } = this.getSettings(false);
	const { initialZoom, initialLon, initialLat } = this.getSettings(false);
	const { useZoomVariable, zoomVariable, latVariable, lonVariable } = this.getSettings(false);
	const { useZoomCyVariable, zoomCyVariable, xVariable, yVariable } = this.getSettings(false);

    this.toolbar = showToolbar;
    this.showStatTables = showStatTables;
	var tooltip = document.getElementById('tooltip-edge');
	var tooltipNode = document.getElementById('tooltip-node');

	const base = 'https://services.arcgisonline.com/ArcGIS/rest/services/';
    const arcgisUrl = `${base}${mapSource}/MapServer/tile/{z}/{y}/{x}`;
	const attribution = `Tiles © <a href="${base}${mapSource}/MapServer">ArcGIS</a>`;
    const colors = {
	  line: isDark ? '#E6E9ED' : '#23282E',
	  shadow: isDark ? '#44444C' : '#D8DFE9',
      label: isDark ? '#F4F9FF' : '#161F29',
      background: isDark ? '#1B2733' : '#EFF4FA',
	  border: isDark ? '#585A5E' : '#9DA5B8',
      warning: isDark ? '#E5CD6B' : '#FFC530',
      danger: isDark ? '#F74545' : '#FB3333',
      ok: isDark ? '#58A956' : '#24AB00',
      edge: isDark ? '#936BFF' : '#5D2BE9',
	  edgeOver: isDark ? '#557FFF' : '#6C63FE',
      disable: isDark ? '#585A5E' : '#9DA5B8',
    };

	const cyStyleOptions: any = [
        { selector: 'core', style: { 'active-bg-opacity': 0 }},
		{ selector: 'node', style: { 'background-opacity': 0, 'visibility': 'visible' }},
        { selector: 'node.hover', style: { 'border-color': colors.line }},
        { selector: 'node.cy-expand-collapse-collapsed-node', style: { 'width': '60px', 'height': '60px', 'border-width': '1px', 
		    'border-color': colors.shadow, 'background-color': colors.background }},
        { selector: '$node > node', style: { 'border-width': '1px', 'border-color': colors.line, 
			'background-color': colors.background, 'color': colors.label, 'shape': 'rectangle', 'padding-top': '14px',
			'padding-left': '14px', 'padding-bottom': '14px', 'padding-right': '14px' }},
        { selector: ':parent', style: { 'text-valign': 'top', 'text-halign': 'center', 'border-width': '1px', 'border-cap': 'round', 
		    'border-color': colors.shadow, 'background-color': colors.background, 'background-opacity': 0.25 }},
        { selector: 'edge', style: { 'width': 1 }},
        { selector: 'edge.hover', style: { 'width': 2, 'line-outline-color': colors.edgeOver }},        
		{ selector: '.hidden', style: { 'display': 'none' }},
		{ selector: '.leaflet-viewport', style: { 'opacity': 0.333, 'transition-duration': '0ms' }},
        // edgehandles
        { selector: '.eh-handle', style: { 'background-color': colors.danger, 'background-image': [], 'width': 12, 'height': 12,
			'shape': 'ellipse', 'overlay-opacity': 0, 'border-width': 12, 'border-opacity': 0, 'label': '' }},
        { selector: '.eh-hover',  style: { 'background-color': colors.danger }},
        { selector: '.eh-source', style: { 'border-width': 2, 'border-color': colors.danger }},
        { selector: '.eh-target', style: { 'border-width': 2, 'border-color': colors.danger }},
        { selector: '.eh-preview, .eh-ghost-edge', style: { 'background-color': colors.danger, 'line-color': colors.danger,
		  'target-arrow-color': colors.danger, 'source-arrow-color': colors.danger }},
        { selector: '.eh-ghost-edge.eh-preview-active', style: { 'opacity': 0 }}
    ];

	this.cyhasInitialPosition = false;
	const initZoom = this.state.isGeoMap ? this.zoomValue : this.zValue;
	if (this.state.isGeoMap) {
	  if (this.props.initResize && this.latValue !== 0 && this.lonValue !== 0) {
		if (initZoom >= 4 && initZoom <= 19) {
		  this.cyhasInitialPosition = true;
		} else if (initialZoom >= 4 && initialZoom <= 19) {
		  this.cyhasInitialPosition = true;
		  this.zoomValue = initialZoom;
		}
	  }
	} else {
	    if (this.props.initResize && this.xValue !== 0 && this.yValue !== 0) {
		  if (initZoom >= 0 && initZoom <= 3) {
			this.cyhasInitialPosition = true;
		  } else if (initialCyZoom >= 0 && initialCyZoom <= 3) {
			this.cyhasInitialPosition = true;
			this.zValue = initialCyZoom;
		  }
		}
	}
		
    const cyOptions: any = !this.state.isGeoMap && this.cyhasInitialPosition ? {
      container: this.ref,
      elements: this.props.data,
	  pan: { x: this.xValue, y: this.yValue },
      zoom: this.zValue,
	  fit: false,
      minZoom: 0.25,
      maxZoom: 3,
      userZoomingEnabled: false,
	  selectionType: 'single',
	  boxSelectionEnabled: false,
	  style: cyStyleOptions
    } : {
      container: this.ref,
      elements: this.props.data,
	  fit: true,
      minZoom: 0.25,
      maxZoom: 3,
      userZoomingEnabled: false,
	  selectionType: 'single',
	  boxSelectionEnabled: false,
	  style: cyStyleOptions
    };
	var cy: any = cytoscape(cyOptions);

	const mapOptions = this.cyhasInitialPosition ? {
	  center: { lat: this.latValue, lng: this.lonValue },
	  zoom: this.zoomValue,
	  minZoom: 4,
	  maxZoom: 19,
	  zoomControl: false,
	  boxZoom: false,
	  scrollWheelZoom: false,
	  touchZoom: false,
	} : {
	  minZoom: 4,
	  maxZoom: 19,
	  zoomControl: false,
	  boxZoom: false,
	  scrollWheelZoom: false,
	  touchZoom: false,
	};

    var leaf: any = this.props.leaf;

	let hasPosition = layoutFormat === 'preset' ? true : false;

	if (hasPosition && this.state.isGeoMap) {
	  hasPosition = cy.nodes().every((node: any) => {
		const lat = node.data('lat');
		const lng = node.data('lng');
		if (!!lat && !!lng) {
		  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
		}
		return false;
	  });
	} else if (hasPosition) {
	  hasPosition = cy.nodes().every((node: any) => {
	    const x = node.data('x');
        const y = node.data('y');
		if (x != null && y != null) {
		  node.position({ x, y });
		  return true;
		}
		return false;
	  });
	}

	this.layout = !hasPosition && layoutFormat === 'preset' ? 'cola' : layoutFormat;

    const colapseOptions: any = {
      layoutBy: {
        name: this.layout,
        animate: this.layout === 'preset' ? false : 'end',
        randomize: false,
        fit: false,
      },
      fisheye: false,
      animate: this.layout === 'preset' ? false : 'end',
      animationDuration: 200,
      ready: function() {},
      undoable: false,
      cueEnabled: true,
      expandCollapseCuePosition: 'top-left',
      expandCollapseCueSize: 12,
      expandCollapseCueSensitivity: 1,
      edgeTypeInfo: 'edgeType',
      groupEdgesOfSameTypeOnCollapse: false,
      allowNestedEdgeCollapse: true,
      zIndex: 900,
    };

	const toggleMap = (isGeo: boolean) => {
	  if (isGeo && this.layout === 'preset' && !leaf) {
        cy.container().setAttribute("id", "topologyMapInfo");
		leaf = cy.L(mapOptions, {
          getPosition: (node) => {
            const lng = node.data('lng');
            const lat = node.data('lat');
            return typeof lng === "number" && typeof lat === "number"
              ? { lat, lng }
              : null;
          },
          setPosition: (node, lngLat) => {
            node.data('lng', lngLat.lng);
            node.data('lat', lngLat.lat);
          },
          animate: true,
          animationDuration: 200,
        });

        window.cyMap = leaf;

		leaf.map.on('zoomend', (e) => {
		  const { useZoomVariable, zoomVariable } = this.getSettings(false);
		  if (!this.props.initResize && this.state.leafLet && useZoomVariable && zoomVariable) {
			const zoom = this.state.leafLet.map.getZoom();
		    if (this.zoomValue !== zoom) {
			  let queryMap = {
				[`var-${zoomVariable}`]: zoom.toFixed(1),
			  };
			  try {
				locationService.partial(queryMap, true);
			  } catch (error) {
				console.log(error);
			  }
			  this.zoomValue = zoom;
			}
		  }
		});

		leaf.map.on('moveend', (e) => {
		  const { useZoomVariable, latVariable, lonVariable } = this.getSettings(false);
		  if (!this.props.initResize && this.state.leafLet && useZoomVariable && lonVariable && latVariable) {
			const center = this.state.leafLet.map.getCenter();
			if ( (this.lonValue !== center.lng || this.latValue !== center.lat)) {
			  const queryMap = {
				[`var-${lonVariable}`]: center.lng.toFixed(7),
				[`var-${latVariable}`]: center.lat.toFixed(7),
			  };
			  try {
				locationService.partial(queryMap, true);
			  } catch (error) {
				console.log(error);
			  }
			  this.latValue = center.lat;
			  this.lonValue = center.lng;
			}
		  }
		});

	    L.tileLayer(arcgisUrl, {
		  attribution: attribution,
		  minZoom: 4,
		  maxZoom: 19,
	    }).addTo(leaf.map);

		this.setState({
	      leafLet: leaf,
        });
      } else {
		if (leaf) {
          cy.autoungrabify(false);
		  leaf.destroy();
          leaf = undefined;
		}
      }
    };

	this.ref.addEventListener('wheel', (event) => {
	  const isAnimated = this.state.cy.animated();
	  if (!isAnimated) {
	    event.preventDefault();
		const delta = event.deltaY > 0 ? -1 : 1;
	    this.zoom(delta);
	  }
	});

	cy.one('render', () => {
	  cy.nodes().forEach(function(node: any) {
        if (node.data('node_visible') === true) {
          cy.elements(node.union(node.siblings()).addClass('hidden'));
          let menuItem3 = {
            id: node.data('id'),
            content: node.data('id'),
            show: true,
            onClickFunction: function(event: any) {
              node = cy.getElementById(node.data('id'));
              cy.elements(node.union(node.siblings()).removeClass('hidden'));
              let group = node.data('parent');
              cy.getElementById(group).removeClass('hidden');
              contextMenu.removeMenuItem(node.data('id'));
              const eles = cy.$('.hidden');
              if (eles.empty()) {
                contextMenu.hideMenuItem('add-node');
              }
            },
          };
          contextMenu.appendMenuItem(menuItem3, 'add-node');
          contextMenu.showMenuItem('add-node');
        } else if (node.data('type') === 'GROUP_EXP') {
		  node.ungrabify();
		}
      });
      api.collapse(cy.collection('[type = "GROUP_COL"]'));
    });

    cy.on('repaint', () => {
	  if (this.state.isGeoMap) {
	    graphCanvas.repaint(this.zoomValue);
	  } else {
	    graphCanvas.repaint(this.zValue);
	  }
	});

    cy.on('setInitialPosition', () => {
	  if (this.state.isGeoMap && this.state.cy && this.state.leafLet) {
		if (!this.cyhasInitialPosition) {
		  this.state.leafLet.fit(cy.nodes());
		} else {
		  this.state.leafLet.map.flyTo({ lat: this.latValue, lng: this.lonValue }, this.zoomValue, {
			animate: true, duration: 0.5
		  });
		}
	  } else if (!this.state.isGeoMap && this.state.cy) {
		if (!this.cyhasInitialPosition) {
		  this.state.cy.fit();
		} else {
		  this.state.cy.animate({zoom: this.zValue, pan: { x: this.xValue, y: this.yValue }}, {
			duration: 500
		  });
		}
	  }
	  cy.emit('repaint');
	  this.onSelectionChange();
    });

    if (useTrafficMetric || useMetricOne || useMetricTwo) {
      cy.on('select', 'edge', () => {
	    tooltipNode.classList.add('graph-tooltip-hidden');
		tooltip.classList.add('graph-tooltip-hidden');
		this.onEdgeSelectionChange();
	  });
      cy.on('unselect', 'edge', () => {
		this.onEdgeSelectionChange();
	  });
    }
    cy.on('select', 'node', (event) => {
	  const { hideNodes, hideThreshold } = this.getSettings(false);
	  const nodeSelected = event.target;
	  (nodeSelected.length > 0)
	  const node = nodeSelected[0];
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  tooltip.classList.add('graph-tooltip-hidden');
	  const nodeEdges = nodeSelected.connectedEdges();
	  let isVisible = true;
	  if (this.state.isGeoMap && hideNodes && this.zoomValue < hideThreshold && nodeEdges.size() < 2) {
	    isVisible = false;
	  }
	  if (isVisible && node.parent) {
		this.onSelectionChange(node);
	  } else {
		node.unselect();
	  }
	});

    cy.on('unselect', 'node', () => {
	  this.onSelectionChange();
	});

    cy.on('grab', 'node', (event) => {
	  const { hideNodes, hideThreshold } = this.getSettings(false);
	  const node = event.target;
	  const nodeEdges = node.connectedEdges();
	  let isVisible = true;
	  if (this.state.isGeoMap && hideNodes && this.zoomValue < hideThreshold && nodeEdges.size() < 2) {
	    isVisible = false;
	  }
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  if (isVisible && (node.parent() && node.data('type') !== 'GROUP_EXP') || node.data('type') === 'GROUP_COL') {
		node.grabify();
		event.cy.container().style.cursor = 'move';
	  } else {
		node.ungrabify();
	  }
    });

    cy.on('changeMode', () => {
      toggleMap(!this.state.isGeoMap);
	  if (addMapVariable !== '' && addMap) {
	    let queryMap = {
		  [`var-${addMapVariable}`]: this.state.isGeoMap ? '0' : '1',
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	  }
	  this.props.initResize = true;
	  this.setState({
        isGeoMap: !this.state.isGeoMap,
      });
    });

    cy.on('refreshView', () => {
	  if (this.state.isGeoMap) {
		if (useZoomVariable && lonVariable && latVariable && zoomVariable) {
		  const queryMap = {
		    [`var-${zoomVariable}`]: 12,
		    [`var-${lonVariable}`]: 0,
		    [`var-${latVariable}`]: 0,
		  };
		  try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
		this.zoomValue = 12;
		this.latValue = 0;
		this.lonValue = 0;
	  } else {
		if (useZoomCyVariable && zoomCyVariable && xVariable && yVariable) {
		  const queryMap = {
		    [`var-${zoomCyVariable}`]: 1,
		    [`var-${xVariable}`]: 0,
		    [`var-${yVariable}`]: 0,
		  };
		  try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
		this.zValue = 1;
		this.yValue = 0;
		this.xValue = 0;
	  }
	  this.cyhasInitialPosition = false;
	  this.props.initResize = true;
	  this.onSelectionChange();
    });

	cy.on('exportCyMap', () => {
	  const { map } = this.getSettings(false);
	  if (!this.props.initResize && this.state.cy) {
		const mapElement = this.ref;
		try {
		  html2canvas(mapElement, { scale: 2, useCORS: true }).then((canvas) => {
		    try {
			  canvas.toBlob((blob) => {
			    if (blob) {
			      const url = URL.createObjectURL(blob);
			      const link = document.createElement('a');
			      link.href = url;
			      link.download = 'NM' + map + '.png';
			      link.click();
			      URL.revokeObjectURL(url);
			    } else {
				  SystemJS.load('app/core/app_events').then((appEvents: any) => {
					appEvents.emit(AppEvents.alertSuccess, ['No fue posible generar una imagen del panel']);
				  });
			    }
		      });
			} catch (error) {
			  SystemJS.load('app/core/app_events').then((appEvents: any) => {
				appEvents.emit(AppEvents.alertSuccess, ['Error al generar la imagen']);
			  });
			} 
		  });
		} catch (error) {
		  SystemJS.load('app/core/app_events').then((appEvents: any) => {
			appEvents.emit(AppEvents.alertSuccess, ['Error al generar la imagen']);
		  });
		} 
	  }
    });

    cy.on('mouseover', 'node', (event) => {
	  const { hideNodes, hideThreshold, showSysInfo } = this.getSettings(false);
	  let node = event.target;
	  var backgroundColor = isDark ? '#141618' : '#F4F9FF';
	  var fontColor = isDark ? '#F4F9FF' : '#141618' ;
	  const pos = cy.container().getBoundingClientRect();
	  const x = event.originalEvent.clientX - pos.left;
	  const y = event.originalEvent.clientY - pos.top;

      const item_link = drillDownLink_asset + node.data('id');
      const nodeEdges = node.connectedEdges();
	  var isVisible = !node.hidden();
	  if (this.state.isGeoMap && hideNodes && this.zoomValue < hideThreshold && nodeEdges.size() < 2) {
	    isVisible = false;
	  }
      var text_color = isDark ? 'qtip-bootstrap qtip-dark' : 'qtip-bootstrap qtip-normal';

      const isGroup = (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') ? true : false;

      if (!isGroup && isVisible) {
	    event.cy.container().style.cursor = 'pointer';
		const slaMetric = _.defaultTo(node.data('node_sla'), -1);
		if (slaMetric > 100 || slaMetric < 0) {
		  backgroundColor = isDark ? '#23282E' : '#E6E8ED';
		} else {
		  if (slaMetric >= healthyThreshold) {
			backgroundColor = isDark ? '#141618' : '#F4F9FF';
		  } else {
			if (slaMetric >= warningThreshold) {
			  backgroundColor = warningColor;
			} else {
			  backgroundColor = dangerColor;
			  text_color = 'qtip-bootstrap qtip-danger';;
			}
		  }
		}
        let description_header = '-';
        let time_header = '-';
        let location_header = '-';

        if (sysHeader !== undefined) {
          const header3: any[] = sysHeader.split(',');
          if (header3[0] !== undefined) {
            description_header = header3[0];
          }
          if (header3[1] !== undefined) {
            time_header = header3[1];
          }
          if (header3[2] !== undefined) {
            location_header = header3[2];
          }
        }

        let nodeTooltip = '<b>Activo: </b>' + node.data('id') + '<br/><b>Emplazamiento: </b>' + node.data('site');
        if (showSysInfo) {
		  const nodeDescription = node.data('node_description');
          const sysInfo: any[] = nodeDescription.split(',');
          nodeTooltip =
            '<b>Activo: <a href="' +
            item_link +
            '" target="_blank">' +
            node.data('id') +
            '</a></b> - <b>' +
            time_header +
            ': </b>';
          if (sysInfo[0] !== null && sysInfo[1] !== null && sysInfo[2] !== null) {
            nodeTooltip =
              nodeTooltip +
              sysInfo[1] +
              '<br/><b>' +
              description_header +
              ': </b>' +
              sysInfo[0] +
              ' - <b>' +
              location_header +
              ': </b>' +
              sysInfo[2];
          }
        }
        tooltipNode.style.left = x + 'px';
		tooltipNode.style.top = y + 'px';
		tooltipNode.style.background = backgroundColor;
		tooltipNode.style.color = text_color;
		tooltipNode.innerHTML = nodeTooltip;
		tooltipNode.classList.remove('graph-tooltip-hidden');
      } else if (node.data('type') === 'GROUP_COL') {
	    event.cy.container().style.cursor = 'pointer';
	  }
    });

     cy.on('move cxttap click mouseout', 'node', function(event: any) {
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  event.cy.container().style.cursor = 'default';
    });

    if (showConnectionStats && (useTrafficMetric || useMetricOne || useMetricTwo)) {
      cy.on('mouseover', 'edge', function(event: any) {
        event.cy.container().style.cursor = 'pointer';
		const edge = event.target;
        const isVisible = edge.hidden();

		var pos = cy.container().getBoundingClientRect();
		var x = event.originalEvent.clientX - pos.left;
		var y = event.originalEvent.clientY - pos.top;

        var hasTrafficMetric = true;
        var Traffic_g = 0;
        var Traffic_m = 0;
        const edgeMetrics: IntGraphMetrics = edge.data('metrics');
        var {
          traffic_out,
          traffic_in,
          metricTwo_max,
          metricTwo_actual,
          metricTwo_threshold,
          metricOne_max,
          metricOne_actual,
          metricOne_threshold,
        } = edgeMetrics;
        if (nodeHeader !== undefined) {
          var traffic_header = '-';
          var metricOne_header = '-';
          var metricTwo_header = '-';
          const header: any[] = nodeHeader.split(',');
          if (header[3] !== undefined) {
            traffic_header = header[3];
          }
          if (header[4] !== undefined) {
            metricOne_header = header[4];
          }
          if (header[5] !== undefined) {
            metricTwo_header = header[5];
          }
        }
        var edgeLabel = '-';
        var edgeLabel2 = '-';
        var edgeLabel3 = '-';
        var text_color = 'qtip-bootstrap qtip-normal';
        if (isDark) {
          text_color = 'qtip-bootstrap qtip-blue';
        }

        if (!useTrafficMetric || isNaN(traffic_out) || traffic_out === undefined || traffic_out < 0) {
          hasTrafficMetric = false;
          traffic_out = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_out / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_out / 1000, 0);
          if (traffic_out >= 1000000) {
            edgeLabel = Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_out >= 1000) {
              edgeLabel = Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = traffic_out.toFixed(2) + ' Kbps';
            }
          }
        }

        if (!useTrafficMetric || isNaN(traffic_in) || traffic_in === undefined || traffic_in < 0) {
          hasTrafficMetric = false;
          traffic_in = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_in / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_in / 1000, 0);
          if (traffic_in >= 1000000) {
            edgeLabel = edgeLabel + ' / ' + Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_in >= 1000) {
              edgeLabel = edgeLabel + ' / ' + Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = edgeLabel + ' / ' + traffic_in.toFixed(2) + ' Kbps';
            }
          }
        }

        if (useMetricOne && !isNaN(metricOne_actual) && !isNaN(metricOne_threshold) && !isNaN(metricOne_max)) {
          if (metricOne_actual > 0 && metricOne_threshold > 0) {
            if (metricOne_actual > metricOne_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricOne_actual === metricOne_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricOne_actual >= 0 && metricOne_max >= 0) {
            edgeLabel2 = metricOne_actual.toString() + ' / ' + metricOne_max.toString();
          }
        } else if (useMetricTwo && !isNaN(metricTwo_actual) && !isNaN(metricTwo_threshold) && !isNaN(metricTwo_max)) {
          if (metricTwo_actual > 0 && metricTwo_threshold > 0) {
            if (metricTwo_actual > metricTwo_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricTwo_actual === metricTwo_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricTwo_actual >= 0 && metricTwo_max >= 0) {
            edgeLabel3 = metricTwo_actual.toString() + ' / ' + metricTwo_max.toString();
          }
        }

        var content_label = '';
        if (useTrafficMetric && traffic_header !== '-' && hasTrafficMetric) {
          content_label = '<b>' + traffic_header + ':</b> ' + edgeLabel + '<br />';
        }
        if (useMetricOne && metricOne_header !== '-') {
          content_label = content_label + '<b>' + metricOne_header + ':</b> ' + edgeLabel2;
          if (metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        } else {
          if (useMetricTwo && metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        }
        if (!isVisible) {
          tooltip.style.left = x + 'px';
		  tooltip.style.top = y + 'px';
		  tooltip.style.color = text_color;
		  tooltip.innerHTML = content_label;
		  tooltip.classList.remove('graph-tooltip-hidden');
        }
      });
	  cy.on('mouseout', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
		event.cy.container().style.cursor = 'default';
	  });
	  cy.on('click', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
	  });
      cy.on('cxttap', 'edge', function(event: any) {
        tooltip.classList.add('graph-tooltip-hidden');
      });
    }

    cy.on('layoutstop', () => {
	  if (this.props.initResize) {
	    if (this.layout === 'preset') {
	      cy.emit('setInitialPosition');
		}
	    this.props.initResize = false;
	  }
    });

    cy.on('render', (event: any) => {
	  event.preventDefault();
	  clearTimeout(this.repaintTimeout);
	  this.repaintTimeout = setTimeout(() => {
	    cy.emit('repaint');
	  }, 10);
    });

    cy.on('viewport', (event: any) => {
	  clearTimeout(this.viewportTimeout);
	  this.viewportTimeout = setTimeout(() => {
	    const { useZoomCyVariable, xVariable, yVariable, zoomCyVariable } = this.getSettings(false);
		if (!this.props.initResize && !this.state.isGeoMap && this.selectionId === null) {
		  const zoom = this.state.cy.zoom();
	      const center = this.state.cy.pan();
          if (useZoomCyVariable && xVariable && yVariable) {
		    if (this.xValue !== center.x || this.yValue !== center.y) {
		      const queryMap = {
                [`var-${xVariable}`]: center.x.toFixed(1),
                [`var-${yVariable}`]: center.y.toFixed(1),
              };
              try {
		        locationService.partial(queryMap, true);
		      } catch (error) {
		        console.log(error);
		      }
		      this.xValue = center.x;
		      this.yValue = center.y;
			}
	        if (zoomCyVariable && this.zValue !== zoom) {
		      const queryMap2 = {
                [`var-${zoomCyVariable}`]: zoom.toFixed(1),
              };
              try {
		        locationService.partial(queryMap2, true);
		      } catch (error) {
		        console.log(error);
		      }
			  this.zValue = zoom;
			}
		  }
        }
	  }, 250);
	});

    cy.on('zoom', () => {
	  tooltip.classList.add('graph-tooltip-hidden');
	  tooltipNode.classList.add('graph-tooltip-hidden');
    });

    cy.on('pan', () => {
	  tooltip.classList.add('graph-tooltip-hidden');
	  tooltipNode.classList.add('graph-tooltip-hidden');
    });

    cy.expandCollapse(colapseOptions);
    var api = cy.expandCollapse('get');

    cy.on('expandcollapse.afterexpand', 'node', () => {
	  if (this.state.cy) {
	    this.onTapNode();
	  }
	});

	cy.on('expandcollapse.aftercollapse', 'node', () => {
	  if (this.state.cy) {
	    this.onTapNode();
	  }
	});

    cy.on('expandcollapse.beforeexpand', 'node', (event) => {
      let node = event.target;
      node.data('type', 'GROUP_EXP');
	  node.ungrabify();
    });

    cy.on('expandcollapse.beforecollapse', 'node', (event) => {
      let node = event.target;
      node.data('type', 'GROUP_COL');
	  node.grabify();
    });

    cy.on('nodesCollapsed', () => {
      if (this.state.isCollapsed) {
        api.collapseAll();
      } else {
        api.expandAll();
      }
    });

    cy.on('dbltap', 'node', (event) => {
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  let node = event.target;
      if (node.data('type') === 'GROUP_EXP') {
        if (api.isCollapsible(node)) {
          api.collapse(node);
        }
      } else if (node.data('type') === 'GROUP_COL') {
        if (api.isExpandable(node)) {
          api.expand(node);
        }
      }
	  this.runLayout();
    });

    var contextMenu = cy.contextMenus({
      evtType: 'cxttap',
      menuItems: [
        {
          id: 'add-node',
          content: 'Agregar activo o grupo',
          tooltipText: 'Agregar un activo o grupo que fue removido',
          show: false,
          coreAsWell: true,
          submenu: [],
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'remove',
          content: 'Eliminar activo o grupo',
          selector: 'node',
          show: true,
          onClickFunction: function(event: any) {
            let node = event.target;
            cy.elements(node.union(node.siblings()).addClass('hidden'));
            let group = node.data('parent');
            cy.getElementById(group).addClass('hidden');
            let menuItem3 = {
              id: node.data('id'),
              content: node.data('id'),
              show: true,
              onClickFunction: function(event: any) {
				node = cy.getElementById(node.data('id'));
                cy.elements(node.union(node.siblings()).removeClass('hidden'));
                let group = node.data('parent');
                cy.getElementById(group).removeClass('hidden');
                contextMenu.removeMenuItem(node.data('id'));
                const eles = cy.$('.hidden');
                if (eles.empty()) {
                  contextMenu.hideMenuItem('add-node');
                }
              },
            };
            contextMenu.appendMenuItem(menuItem3, 'add-node');
            contextMenu.showMenuItem('add-node');
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'open',
          content: 'Más información...',
          tooltipText: 'Acceder a informacion detallada',
          selector: 'node',
          show: true,
          coreAsWell: true,
          onClickFunction: function(event: any) {
			let node = event.target;
            let linkAsset = drillDownLink_site + node.data('id');
            if (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') {
              window.open(linkAsset, '_blank');
            } else if (node.data('type') === GraphDataType.INTERNAL) {
              linkAsset = drillDownLink_asset + node.data('id');
              window.open(linkAsset, '_blank');
            }
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'save-map',
          content: 'Guardar mapa',
          tooltipText: 'Guardar mapa de Topología',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.saveLayout(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
			this.forceUpdate();
          },
        },
        {
          id: 'exp-map',
          content: 'Expandir/Contraer grupos',
          tooltipText: 'Expande o Contrae el grupo',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.muxCollapse(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
			this.forceUpdate();
          },
        },
      ],
      menuItemClasses: ['custom-menu-item'],
      contextMenuClasses: ['custom-context-menu'],
      submenuIndicator: { src: 'public/img/icons/unicons/subject.svg', width: 14, height: 14 },
    });

    var graphCanvas = new CanvasDrawer(
      this,
      cy,
      cy.cyCanvas({
        zIndex: 10,
      }),
	  this.state.isGeoMap ? this.zoomValue : this.zValue,
    );

    this.setState({
      cy: cy,
      graphCanvas: graphCanvas,
    });

	if (this.props.isGeoMap) {
	  cy.emit('changeMode');
	}
  }

  shouldComponentUpdate(nextProps: Props, nextState: PanelState): boolean {
	if (!this.props.initResize && nextProps.initResize && nextProps.mustUpdate !== 'none') {
	  if (nextProps.mustUpdate === 'full') {
		this._updateGraph(nextProps.data, true);
	    return true;
      } else {
	    this._updateGraph(nextProps.data, false);
		return true;
	  }
	}
    return false;
  }

  componentDidUpdate(prevProps: Props, prevState: PanelState) {
	this.props.mustUpdate = 'none';
	this.props.initResize = false;
  }

  getSettings(resolveVariables: boolean): PanelSettings {
    if (resolveVariables) {
      return this.resolveVariables(this.props.options);
    }
    return this.props.options;
  }

  resolveVariables(element: any) {
    if (element instanceof Object) {
      const newObject: any = {};
      for (const key of Object.keys(element)) {
        newObject[key] = this.resolveVariables(element[key]);
      }
      return newObject;
    }

    if (element instanceof String || typeof element === 'string') {
      return getTemplateSrv().replace(element.toString());
    }
    return element;
  }

  _updateGraph(graph: IntGraph, reload: boolean) {
	const actualLayout = this.state.isGeoMap ? 'preset' : this.layout;
	const cy = this.state.cy;
    const existingElements = cy.elements();
	const existingNodes = cy.nodes();
	const nodePositions = {};
	existingNodes.forEach(node => {
	  nodePositions[node.id()] = node.position();
	});
    const newElements = cytoscape({ elements: graph, headless: true }).elements();
	if (reload) {
      cy.elements().remove();
      cy.add(newElements);
	  cy.nodes().forEach(node => {
	    if (nodePositions[node.data('id')]) {
		  node.position(nodePositions[node.data('id')]);
		} else {
		  const x = node.data('x');
		  const y = node.data('y');
		  if (x != null && y != null) {
		    node.position({ x, y });
		  }
		}
	  });
    } else {
	  cy.nodes().forEach(node => {
	    const newNodeData = newElements.getElementById(node.data('id'));
		node.data(newNodeData.data());
		if (nodePositions[node.data('id')]) {
		  node.position(nodePositions[node.data('id')]);
		} else {
		  const x = node.data('x');
		  const y = node.data('x');
		  if (x != null && y != null) {
			node.position({ x, y });
		  }
		}
	  });
    }
	if (this.state.isCollapsed) {
	  cy.emit('nodesCollapsed');
	}
	this.runLayout();
  }


  runLayout() {
	const { infiniteSimulation } = this.getSettings(false);
	const unlockNodes = !this.state.isLock;
	const that = this;
    var options = {
      ...layoutOptions,
        stop: function() {
          if (that.state.isLock) {
		    that.lockNodes();
		  } else {
            that.unlockNodes();
          }
        },
		animated: this.layout === 'preset' ? false : 'end',
    };
	if (infiniteSimulation && this.layout !== 'preset') {
	  options = {
		...infinite,
	  };
	} else {
	  const Layout = this.state.isGeoMap ? 'preset' : this.layout;
      switch (Layout) {
        case 'cola':
          options = {
            ...layoutOptions_cola,
            stop: function() {
			  if (that.state.isLock) {
				that.lockNodes();
			  } else {
				that.unlockNodes();
			  }
            },
			animated: this.layout === 'preset' ? false : 'end',
          };
          break;
        case 'cise':
          options = {
            ...layoutOptions_cise,
            stop: function() {
			  if (that.state.isLock) {
				that.lockNodes();
			  } else  {
				that.unlockNodes();
			  }
            },
			animated: this.layout === 'preset' ? false : 'end',
          };
          break;
        case 'avsdf':
          options = {
            ...layoutOptions_avsdf,
            stop: function() {
			  if (that.state.isLock) {
				that.lockNodes();
			  } else  {
				that.unlockNodes();
			  }
            },
			animated: this.layout === 'preset' ? false : 'end',
          };
          break;
        case 'dagre':
          options = {
            ...layoutOptions_dagre,
            stop: function() {
			  if (that.state.isLock) {
				that.lockNodes();
			  } else {
				that.unlockNodes();
			  }
            },
			animated: this.layout = 'preset' ? false : 'end',
          };
          break;
        case 'fcose':
          options = {
            ...layoutOptions_fcose,
            stop: function() {
			  if (that.state.isLock) {
				that.lockNodes();
			  } else {
				that.unlockNodes();
			  }
            },
			animated: this.layout === 'preset' ? false : 'end',
          };
          break;
      }
	}
    this.state.cy.layout(options).run();
  }

  onTapNode() {
	const selection = this.state.cy.$(':selected');
	const { nodeVariable, groupVariable, edgeVariable } = this.getSettings(false);
	if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
	  let queryMap = { 
		[`var-${groupVariable}`]: '',
		[`var-${nodeVariable}`]: '', 
		[`var-${edgeVariable}`]: ''
	  };
	  try {
		locationService.partial(queryMap, true);
	  } catch (error) {
		console.log(error);
	  }
	  this.runLayout();
	}
  }

  onSelectionChange(selectedNode: NodeSingular) {
	const selection = selectedNode !== null ? selectedNode : this.state.cy.$(':selected');
	const { nodeVariable, groupVariable, edgeVariable } = this.getSettings(false);
	if (selection) {
	  if (selection.length === 1) {
        const currentNode: NodeSingular = selection[0];
	    this.selectionId = currentNode.id().toString();
	    const nodeLabel = currentNode.data('label');
        if (selection.data('type') !== 'GROUP_EXP' && selection.data('type') !== 'GROUP_COL' && nodeLabel !== undefined) {
          if (this.showStatTables) {
		    this.updateStatisticTable();
            this.setState({
              showStatistics: true,
            });
			if (!this.state.isGeoMap) {
			  const container = this.state.cy.container();
			  this.state.cy.center(currentNode);
			  this.state.cy.panBy({x: -(container.clientWidth * 0.25), y: 0});
			}
		  }
		  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		    let queryMap = {
		      [`var-${groupVariable}`]: '',
			  [`var-${nodeVariable}`]: currentNode.data('id'),
			  [`var-${edgeVariable}`]: ''
		    };
            try {
		      locationService.partial(queryMap, true);
		    } catch (error) {
		      console.log(error);
		    }
		  }
        } else if (selection.data('type') === 'GROUP_COL' && selection.data('type') !== 'GROUP_EXP' && nodeLabel !== undefined) {
		  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		    let queryMap = { 
		      [`var-${groupVariable}`]: currentNode.data('id'),
			  [`var-${nodeVariable}`]: '', 
			  [`var-${edgeVariable}`]: ''
		    };
            try {
		      locationService.partial(queryMap, true);
		    } catch (error) {
		      console.log(error);
		    }
		  }
	    } else {
          if (this.showStatTables) {
		    this.setState({
              showStatistics: false,
            });
		  }
		  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		    let queryMap = { 
		      [`var-${groupVariable}`]: '',
		      [`var-${nodeVariable}`]: '', 
		      [`var-${edgeVariable}`]: ''
		    };
            try {
		      locationService.partial(queryMap, true);
		    } catch (error) {
		      console.log(error);
		    }
		    this.selectionId = null;
		  }
        }
	  }
    } else {
	  this.clearSelectedNode();
	}
	this.forceUpdate();
  }
  
  clearSelectedNode() {
	const { nodeVariable, groupVariable, edgeVariable } = this.getSettings(false);
	if (this.showStatTables) {
      this.setState({
        showStatistics: false,
      });
	}
	if (groupVariable !== '' && nodeVariable !== '' && edgeVariable !== '') {
	  let queryMap = { 
		[`var-${groupVariable}`]: '',
		[`var-${nodeVariable}`]: '', 
		[`var-${edgeVariable}`]: ''
	  };
      try {
	    locationService.partial(queryMap, true);
	  } catch (error) {
		console.log(error);
	  }
	  this.selectionId = null;
	}
	this.forceUpdate();
  }
  
  onEdgeSelectionChange() {
    const selection = this.state.cy.$(':selected');
	const { edgeVariable, nodeVariable, groupVariable } = this.getSettings(false);
    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const edgeLabel = actualEdge.data('label');
      this.selectionId = edgeLabel;
	  if (edgeLabel !== undefined && edgeLabel !== null) {
        if (this.showStatTables) {
		  this.updateEdgeStatisticTable();
          this.setState({
            showEdgeStatistics: true,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = {
 		    [`var-${edgeVariable}`]: actualEdge.data('source') + '-' + actualEdge.data('target'),
			[`var-${nodeVariable}`]: '',
			[`var-${groupVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
      } else {
        if (this.showStatTables) {
		  this.setState({
            showEdgeStatistics: false,
          });
		}
		if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		  let queryMap = { 
		    [`var-${groupVariable}`]: '',
		    [`var-${nodeVariable}`]: '', 
		    [`var-${edgeVariable}`]: ''
		  };
          try {
		    locationService.partial(queryMap, true);
		  } catch (error) {
		    console.log(error);
		  }
		}
		this.selectionId = null;
      }
	  if (!this.state.isGeoMap) {
		const container = this.state.cy.container();
	    const width = container.clientWidth;
		this.state.cy.center(actualEdge);
		this.state.cy.panBy({x: -(width * 0.25), y: 0});
	  }
    } else {
      if (this.showStatTables) {
	    this.setState({
          showEdgeStatistics: false,
        });
	  }
	  if (nodeVariable !== '' && edgeVariable !== '' && groupVariable !== '') {
		let queryMap = { 
		  [`var-${groupVariable}`]: '',
		  [`var-${nodeVariable}`]: '', 
		  [`var-${edgeVariable}`]: ''
		};
        try {
		  locationService.partial(queryMap, true);
		} catch (error) {
		  console.log(error);
		}
	  }
	  this.selectionId = null;
    }
	this.forceUpdate();
  }

  unlockNodes() {
    if (this.state.cy) {
	  this.state.cy.nodes().forEach((node: { unlock: () => void }) => {
        node.unlock();
      });
	}
  }

  lockNodes() {
    if (this.state.cy) {
	  this.state.cy.nodes().forEach((node: { lock: () => void }) => {
        node.lock();
      });
	}
  }

  saveLayout() {
    const { map, api } = this.getSettings(true);
	const isGeoMap = this.state.isGeoMap;
    const resolverApi = this.templateSrv.replace(api);
	const resolverMap = isGeoMap ? this.templateSrv.replace(map) + '_geo' : this.templateSrv.replace(map);
	var position = '{"dest": "topology", "params":"';
    var elem = 0;
    this.state.cy.nodes().forEach(function(n) {
      elem = elem + 1;
      const asset = n.data('id');
      const type = n.data('type');
      const user = n.data('user');
      const visible = n.hasClass('hidden');
      const x = isGeoMap ? n.data('lat') : n.position().x.toFixed(0);
      const y = isGeoMap ? n.data('lng') : n.position().y.toFixed(0);
      if (elem < 2) {
        position =
          position +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          resolverMap +
          ';' +
          visible.toString();
      } else {
        position =
          position +
          ',' +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          resolverMap +
          ';' +
          visible.toString();
      }
    });
    position = position + '"}';
    axios.defaults.baseURL = resolverApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post(resolverApi, position).then(
      response => {
		if (response.statusText === 'OK') {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, ['Mapa guardado correctamente']);
          });
        } else {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
          });
        }
      },
      error => {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
          appEvents.emit(AppEvents.alertError, ['Error al guardar el mapa' + error.response.status]);
        });
      }
    );
  }

  zoom(factor: number) {
	if (this.state.isGeoMap) {
	  if (!this.props.initResize && this.state.leafLet) {
	    const currentZoom = this.state.leafLet.map.getZoom();
	    const zoomStep = currentZoom < 12 ? 1 * factor : 0.25 * factor;
	    this.state.leafLet.map.flyTo(this.state.leafLet.map.getCenter(), currentZoom + zoomStep, {
		  animate: true,
		  duration: 0.5
	    });
	  }
	} else {
	  const actualZoom: number = this.state.cy.zoom();
	  const zoomStep = actualZoom < 2 ? 0.5 * factor : 0.125 * factor;
      const cyZoomLevel: number = actualZoom >= 0 && actualZoom <= 3 ? actualZoom + zoomStep : actualZoom;
	  this.state.cy.animate({zoom: cyZoomLevel}, { duration: 500 });
	}
  }

  updateStatisticTable() {
    const selection = this.state.cy.$(':selected');
	const { useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);

    if (selection.length === 1) {
      const currentNode: NodeSingular = selection[0];
      this.selectionId = currentNode.id().toString();
      this.currentLabel = currentNode.data('label');
      this.currentSite = currentNode.data('site');
      this.currentType = currentNode.data('type');
      const nodeDescription = currentNode.data('node_description');
      const receiving: TableContent[] = [];
      const sending: TableContent[] = [];
      const table_header: TableHeader[] = [];
      const systemDescription: Table3Content[] = [];
      const sys_header: Table3Header[] = [];
      const edges: EdgeCollection = selection.connectedEdges();
      let targetLabel = currentNode.data('target');
      let targetSite = currentNode.data('site');
      let metricOneMax = -1;
      let metricOneActual = -1;
      let metricOneThreshold = -1;
      let metricTwoActual = -1;
      let traffic_in = -1;
      let traffic_out = -1;
      if (useTrafficMetric || useMetricOne || useMetricTwo) {
        let metrics: IntGraphMetrics = selection.nodes()[0].data('metrics');
        targetLabel = metrics.target_label;
        targetSite = metrics.target_site;
        if (useMetricOne) {
          metricOneMax = metrics.metricOne_max;
          metricOneActual = metrics.metricOne_actual;
          metricOneThreshold = metrics.metricOne_threshold;
        }
        if (useMetricTwo) {
          metricTwoActual = metrics.metricTwo_actual;
        }
        if (useTrafficMetric) {
          traffic_in = metrics.traffic_in;
          traffic_out = metrics.traffic_out;
        }
      }
      this.selectionStatistics = {};

      this.selectionStatistics.target_label = targetLabel;
      this.selectionStatistics.target_site = targetSite;

      if (metricOneMax >= 0) {
        this.selectionStatistics.metricOne_max = Math.floor(metricOneMax);
      }
      if (metricOneThreshold >= 0) {
        this.selectionStatistics.metricOne_threshold = Math.floor(metricOneThreshold);
        this.selectionStatistics.thresholdViolation = metricOneActual > metricOneThreshold;
      }
      if (metricOneActual >= 0) {
        this.selectionStatistics.metricOne_actual = Math.floor(metricOneActual);
      } else {
        this.selectionStatistics.metricOne_actual = 0;
      }
      if (metricTwoActual >= 0) {
        this.selectionStatistics.metricTwo_actual = Math.floor(metricTwoActual);
      } else {
        this.selectionStatistics.metricTwo_actual = 0;
      }
      if (traffic_in >= 0) {
        this.selectionStatistics.traffic_in = Math.floor(traffic_in);
      }
      if (traffic_out >= 0) {
        this.selectionStatistics.traffic_out = Math.floor(traffic_out);
      }

      if (nodeDescription !== undefined && nodeDescription !== null && nodeDescription !== '') {
        const sendingsysDescription: Table3Content = {
          sysDescription: '-',
          sysTime: '-',
          sysLocation: '-',
        };
        const system: any[] = nodeDescription.split(',');
        if (system[0] !== undefined) {
          sendingsysDescription.sysDescription = system[0];
        } else {
          sendingsysDescription.sysDescription = '-';
        }
        if (system[1] !== undefined) {
          sendingsysDescription.sysTime = system[1];
        } else {
          sendingsysDescription.sysTime = '-';
        }
        if (system[2] !== undefined) {
          sendingsysDescription.sysLocation = system[2];
        } else {
          sendingsysDescription.sysLocation = '-';
        }
        systemDescription.push(sendingsysDescription);
      }

      const { sysHeader } = this.getSettings(true);
      if (sysHeader !== undefined) {
        const sendingSysHeader: Table3Header = {
          description_header: '-',
          time_header: '-',
          location_header: '-',
        };
        const header3: any[] = sysHeader.split(',');
        if (header3[0] !== undefined) {
          sendingSysHeader.description_header = header3[0];
        } else {
          sendingSysHeader.description_header = '-';
        }
        if (header3[1] !== undefined) {
          sendingSysHeader.time_header = header3[1];
        } else {
          sendingSysHeader.time_header = '-';
        }
        if (header3[2] !== undefined) {
          sendingSysHeader.location_header = header3[2];
        } else {
          sendingSysHeader.location_header = '-';
        }
        sys_header.push(sendingSysHeader);
      }

      for (let i = 0; i < edges.length; i++) {
        const actualEdge: EdgeSingular = edges[i];
        const sendingCheck: boolean = actualEdge.source().id() === this.selectionId;
        let node: NodeSingular;

        if (sendingCheck) {
          node = actualEdge.target();
        } else {
          node = actualEdge.source();
        }

        const sendingObject: TableContent = {
          name: node.id(),
          label: '-',
          site: '-',
          traffic_in: '-',
          traffic_out: '-',
          metricOneMax: '-',
          metricTwoMax: '-',
          metricTwoActual: '-',
          metricOneActual: '-',
        };

        const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

        if (edgeMetrics !== undefined) {
          const {
            target_label,
            target_site,
            traffic_out,
            traffic_in,
            metricOne_max,
            metricTwo_max,
            metricTwo_actual,
            metricOne_actual,
          } = edgeMetrics;
          const source_label = actualEdge.data('label');
          const source_site = actualEdge.data('site');

          if (sendingCheck) {
            sendingObject.label = source_label;
            sendingObject.site = source_site;
          } else {
            sendingObject.label = target_label;
            sendingObject.site = target_site;
          }
          if (metricOne_max !== undefined) {
            sendingObject.metricOneMax = Math.floor(metricOne_max).toString();
          }
          if (metricTwo_max !== undefined) {
            sendingObject.metricTwoMax = Math.floor(metricTwo_max).toString();
          }
          if (traffic_in >= 0) {
            const TrafficIn_g = traffic_in / 1000000;
            const TrafficIn_m = traffic_in / 1000;
            if (sendingCheck) {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_in = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_in = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_in) + ' Kbps';
                }
              }
            } else {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_out = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_out = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_in) + ' Kbps';
                }
              }
            }
          }
          if (traffic_out >= 0) {
            const Traffic_g = traffic_out / 1000000;
            const Traffic_m = traffic_out / 1000;
            if (sendingCheck) {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_out = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_out = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_out) + ' Kbps';
                }
              }
            } else {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_in = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_in = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_out) + ' Kbps';
                }
              }
            }
          }
          if (metricTwo_actual !== undefined) {
            sendingObject.metricTwoActual = Math.floor(metricTwo_actual).toString();
          } else {
            sendingObject.metricTwoActual = '0';
          }
          if (metricOne_actual !== undefined) {
            sendingObject.metricOneActual = Math.floor(metricOne_actual).toString();
          } else {
            sendingObject.metricOneActual = '0';
          }
        }
        if (sendingCheck) {
          sending.push(sendingObject);
        } else {
          receiving.push(sendingObject);
        }
      }
      const { nodeHeader, nodeTitle } = this.getSettings(true);
      var node_header = nodeHeader;
      var node_title = nodeTitle;
      const iconMappings = this.getSettings(true).icons;
      const mapping = _.find(iconMappings, ({ pattern }) => {
        try {
          return new RegExp(pattern).test(selection.id().toString());
        } catch (error) {
          return false;
        }
      });

      if (mapping) {
        if (mapping.node_title !== '-') {
          node_title = mapping.node_title;
        }
        if (mapping.node_header !== '-') {
          node_header = mapping.node_header;
        }
      }
      const sendingHeader: TableHeader = {
        name_header: '-',
        label_header: '-',
        site_header: '-',
        traffic_header: '-',
        metricOne_header: '-',
        metricTwo_header: '-',
      };
      if (node_header !== undefined) {
        const header: any[] = node_header.split(',');
        if (header[0] !== undefined && header[0] !== '') {
          sendingHeader.name_header = header[0];
        } else {
          sendingHeader.name_header = '-';
        }
        if (header[1] !== undefined && header[1] !== '') {
          sendingHeader.label_header = header[1];
        } else {
          sendingHeader.label_header = '-';
        }
        if (header[2] !== undefined && header[2] !== '') {
          sendingHeader.site_header = header[2];
        } else {
          sendingHeader.site_header = '-';
        }
        if (header[3] !== undefined && header[3] !== '') {
          sendingHeader.traffic_header = header[3];
        } else {
          sendingHeader.traffic_header = '-';
        }
        if (header[4] !== undefined && header[4] !== '') {
          sendingHeader.metricOne_header = header[4];
        } else {
          sendingHeader.metricOne_header = '-';
        }
        if (header[5] !== undefined && header[5] !== '' && header[5] !== null) {
          sendingHeader.metricTwo_header = header[5];
        } else {
          sendingHeader.metricTwo_header = '-';
        }
      }
      table_header.push(sendingHeader);

      this.currentDescription = systemDescription;
      this.sys_header = sys_header;
      this.node_title = node_title;
      this.table_header = table_header;
      this.receiving = receiving;
      this.sending = sending;
      this.generateDrillDownLink('asset');
      this.generateDrillDownLink('site');
    }
  }

  updateEdgeStatisticTable() {
	const selection = this.state.cy.$(':selected');
	const { isStpTopology } = this.getSettings(false);

    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const links: Table2Content[] = [];
      const linksHeader: Table2Header[] = [];
      const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

      if (edgeMetrics !== undefined) {
        const { target_label, description } = edgeMetrics;
        var temp: any[] = [];
		if (description !== undefined && description !== null) {
          temp = description.split(',');
        }
        const source_Label = actualEdge.data('label');
        const target_Label = target_label;
        let i = 0;
        const length = temp.length;

        for (; i < length; ) {
          const sendingObject: Table2Content = {
            name: '-',
            label: '-',
            source_port: '-',
            source_lag: '-',
            target_label: '-',
            target_port: '-',
            target_lag: '-',
            stat: '-',
          };
          const port: any[] = temp[i].split('|');
          sendingObject.name = actualEdge.source().id();
          sendingObject.label = source_Label;
          if (port[0] !== undefined) {
            sendingObject.source_port = port[0];
          } else {
            sendingObject.source_port = 'S/D';
          }
          if (port[1] !== undefined) {
            sendingObject.source_lag = port[1];
          } else {
            sendingObject.source_lag = 'S/D';
          }
          sendingObject.target_label = target_Label;
          if (port[2] !== undefined) {
            sendingObject.target_port = port[2];
          } else {
            sendingObject.target_port = 'S/D';
          }
          if (port[3] !== undefined) {
            sendingObject.target_lag = port[3];
          } else {
            sendingObject.target_lag = 'S/D';
          }
          if (isStpTopology) {
		    if (port[4] !== undefined) {
              switch (port[4]) {
                case '0':
                  sendingObject.stat = 'Ena';
                  break;
                case '1':
                  sendingObject.stat = 'Dis';
                  break;
                case '2':
                  sendingObject.stat = 'Blk';
                  break;
                case '3':
                  sendingObject.stat = 'Lis';
                  break;
                case '4':
                  sendingObject.stat = 'Lea';
                  break;
                case '5':
                  sendingObject.stat = 'Fwd';
                  break;
                default:
                  sendingObject.stat = 'Fail';
              }
            } else {
              sendingObject.stat = 'Err';
			}
		  } else {
		    if (port[4] !== undefined) {
              switch (port[4]) {
                case '0':
                  sendingObject.stat = 'S/D';
                  break;
                case '1':
                  sendingObject.stat = 'Up';
                  break;
                case '2':
                  sendingObject.stat = 'Down';
                  break;
                case '3':
                  sendingObject.stat = 'Alarm';
                  break;
                default:
                  sendingObject.stat = 'S/D';
              }
            } else {
              sendingObject.stat = 'Down';
			}
          }
          links.push(sendingObject);
          i++;
        }
        this.links = links;
        const { edgeHeader, edgeTitle } = this.getSettings(true);
        var edge_header = edgeHeader;
        var edge_title = edgeTitle;
        const iconMappings = this.getSettings(true).icons;
        const mapping = _.find(iconMappings, ({ pattern }) => {
          try {
            return new RegExp(pattern).test(selection.id().toString());
          } catch (error) {
            return false;
          }
        });

        if (mapping) {
          if (mapping.links_title !== '-') {
            edge_title = mapping.links_title;
          }
          if (mapping.links_header !== '-') {
            edge_header = mapping.links_header;
          }
        }
        const sendingHeader: Table2Header = {
          label_header: '-',
          source_port_header: '-',
          source_type_header: '-',
          target_label_header: '-',
          target_port_header: '-',
          target_type_header: '-',
          status_header: '-',
        };
        if (edge_header !== undefined) {
		  const header: any[] = edge_header.split(',');
          if (header[0] !== undefined || header[0] !== '') {
            sendingHeader.label_header = header[0];
          } else {
            sendingHeader.label_header = '-';
          }
          if (header[1] !== undefined || header[1] !== '') {
            sendingHeader.source_port_header = header[1];
          } else {
            sendingHeader.source_port_header = '-';
          }
          if (header[2] !== undefined || header[2] !== '') {
            sendingHeader.source_type_header = header[2];
          } else {
            sendingHeader.source_type_header = '-';
          }
          if (header[3] !== undefined || header[3] !== '') {
            sendingHeader.target_label_header = header[3];
          } else {
            sendingHeader.target_label_header = '-';
          }
          if (header[4] !== undefined || header[4] !== '') {
            sendingHeader.target_port_header = header[4];
          } else {
            sendingHeader.target_port_header = '-';
          }
          if (header[5] !== undefined || header[5] !== '') {
            sendingHeader.target_type_header = header[5];
          } else {
            sendingHeader.target_type_header = '-';
          }
          if (header[6] !== undefined || header[6] !== '') {
            sendingHeader.status_header = header[6];
          } else {
            sendingHeader.status_header = '-';
          }
        }
        linksHeader.push(sendingHeader);
        this.linksHeader = linksHeader;
        this.edge_title = edge_title;
      }
    }
  }

  muxCollapse() {
    if (this.state.isCollapsed) {
      this.handleCollapse('GROUP_EXP');
    } else {
      this.handleCollapse('GROUP_COL');
    }
	this.forceUpdate();
  }

  handleCollapse(action: string) {
    this.unlockNodes();
    if (action === 'GROUP_EXP') {
      this.setState({
        isCollapsed: false,
      });
    } else {
      this.setState({
        isCollapsed: true,
      });
    }
    this.state.cy.emit('nodesCollapsed');
    if (this.state.isLock) {
      this.lockNodes();
    }
  }

 
  generateDrillDownLink(type: string) {
    if (type === 'asset') {
      const { drillDownLink_asset } = this.getSettings(true);
      if (drillDownLink_asset !== undefined) {
        const link = drillDownLink_asset.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_asset = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    } else {
      const { drillDownLink_site } = this.getSettings(true);
      if (drillDownLink_site !== undefined) {
        const link = drillDownLink_site.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_site = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    }
  }

  render() {
	const { addMap } = this.getSettings(false);
	const isDark = config.theme.isDark;
	const show_toolbar = this.toolbar ? 'zoom-button-container' : 'zoom-button-container-hide';

    return (
      <>
	  <div 
	    id="tooltip-node"
		className={isDark ? 'graph-tooltip_dark graph-tooltip-hidden' : 'graph-tooltip graph-tooltip-hidden'}
	  ></div>
	  <div 
	    id="tooltip-edge"
		className={isDark ? 'graph-tooltip_dark graph-tooltip-hidden' : 'graph-tooltip graph-tooltip-hidden'}
	  ></div>
	  <div className={'topology-container'} id={this.state.isGeoMap ? 'geoMap' : 'topologyMap'}>
        <div className="map-container">
          <div 
			className={this.state.isLock ? 'canvas-container cursor_pan' : 'canvas-container'}
			id={'topologyMapInfo'}
			ref={ref => (this.ref = ref)}
		  ></div>
          <div className={show_toolbar}>
            {this.layout === 'preset' && addMap && (
			  <button
                className={isDark ? 'btn button-map_dark' : 'btn button-map'}
                title={this.state.isGeoMap ? 'Ver como esquema de topología' : 'Ver esquema en Mapa'}
                id={'changeMode'}
				onClick={() => this.state.cy.emit('changeMode')}
              >
                <Icon name={this.state.isGeoMap ? 'diagram-3-fill' : 'geo-fill'} size="26"/>
              </button>
			)}
            {!this.state.isLock && (
			  <button
                className={isDark ? 'btn button-map_dark' : 'btn button-map'}
                title="Guardar mapa de Topología"
                onClick={() => this.saveLayout()}
              >
                <Icon name={'save-fill'} size="26"/>
              </button>
			)}
			<button
              className={isDark ? 'btn button-map_dark' : 'btn button-map'}
               itle="Ver toda la Topología"
               onClick={() => this.state.cy.emit('refreshView')}
            >
              <Icon name={'fit-page-round'} size="26" />
            </button>
			<button
              className={isDark ? 'btn button-map_dark' : 'btn button-map'}
               itle="Exportar Imagen de topología"
               onClick={() => this.state.cy.emit('exportCyMap')}
            >
              <Icon name={'download-round'} size="26" />
            </button>
			<button className={isDark ? 'btn button-map_dark' : 'btn button-map'} title="Zoom In" onClick={() => this.zoom(+1)}>
			  <Icon name={'plus-circle-fill'} size="26" />
			</button>
			<button className={isDark ? 'btn button-map_dark' : 'btn button-map'}title="Zoom Out" onClick={() => this.zoom(-1)}>
			  <Icon name={'minus-circle-fill'} size="26" />
			</button>
          </div>
        </div>
        <Statistics
          show={this.state.showStatistics}
          showEdge={this.state.showEdgeStatistics}
          selectionId={this.selectionId}
          currentDescription={this.currentDescription}
          sysHeader={this.sys_header}
          resolvedDrillDownLink_asset={this.resolvedDrillDownLink_asset}
          resolvedDrillDownLink_site={this.resolvedDrillDownLink_site}
          selectionStatistics={this.selectionStatistics}
          currentType={this.currentType}
          showBaselines={this.getSettings(false).showBaselines}
          receiving={this.receiving}
          sending={this.sending}
          nodeTitle={this.node_title}
          edgeTitle={this.edge_title}
          nodeHeader={this.table_header}
          links={this.links}
          linksHeader={this.linksHeader}
          showEdgeStatus={this.getSettings(false).showEdgeStatus}
		  isL1Topology={this.getSettings(false).isL1Topology}
        />
      </div>
	  </>
    );
  }
}

import { PanelPlugin } from '@grafana/data';
import { FieldSelectEditor } from './FieldSelectEditor';
import { LineChartOptions } from './types';
import { LineChartPanel } from './LineChartPanel';

export const plugin = new PanelPlugin<LineChartOptions>(LineChartPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'dateField',
      path: 'dateField',
      name: 'Date Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.timeSeriesChart === true,
      defaultValue: 'date',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'serieField',
      path: 'serieField',
      name: 'Serie Field',
      description: 'The name of the Main serie. Value Fields with same Serie Field/Date Field (time series), will be generated dinmically.',
      category: ['Data Map Settings'],
      defaultValue: 'serie',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'valueField',
      path: 'valueField',
      name: 'Main Serie value Field',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'colorField',
      path: 'colorField',
      name: 'Main serie color Field',
      description: 'This field is used to colorize the main line or the lines defined dinamically.',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'labelField',
      path: 'labelField',
      name: 'Main serie label Field',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'stackValues',
      name: 'Stack values?',
      description: 'If true, all the Bar value Field with same Serie Field/Date Field (time series), will be stacked.',
      category: ['Data Map Settings'],
      defaultValue: true,
    })
    .addCustomEditor({
      id: 'stackField',
      path: 'stackField',
      name: 'Dinamyc Lines name',
      description: 'Use to generate lines dynamically.',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'value2Field',
      path: 'value2Field',
      name: 'Second Serie value Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.valueField !== undefined,
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'label2Field',
      path: 'label2Field',
      name: 'Second serie label Field',
      description: 'If has a valid label, the line will be show.',
	  showIf: (config) => config.value2Field !== undefined && config.value2Field !== '',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'value3Field',
      path: 'value3Field',
      name: 'Thirth Serie value Field',
      showIf: (config) => config.value2Field !== undefined && config.value2Field !== '',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'label3Field',
      path: 'label3Field',
      name: 'Thirth serie label Field',
      description: 'If has a valid label, the line will be show.',
	  showIf: (config) => config.value3Field !== undefined && config.value3Field !== '',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'showAreaOnChart',
      name: 'Show auxiliar area in the chart',
      showIf: (config) => config.timeSeriesChart === true,
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'areaField',
      path: 'areaField',
      name: 'Auxiliar area Field',
      showIf: (config) => config.showAreaOnChart === true,
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'showLineOnChart',
      name: 'Show auxiliar line in the chart',
      showIf: (config) => config.timeSeriesChart === true,
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTendence',
      name: 'Show auxiliar line with the evolución of the main serie.',
      showIf: (config) => config.timeSeriesChart === true,
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'lineField',
      path: 'lineField',
      name: 'Auxiliar line Field',
      showIf: (config) => config.showLineOnChart === true,
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useMetricField',
      name: 'Use Threshold',
      description: 'Use a metric Threshold to highlight a line in the Chart',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'metricField',
      path: 'metricField',
      name: 'Average Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.useMetricField === true,
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'showMetricOnChart',
      name: 'Show average line in the chart',
      showIf: (config) => config.useMetricField === true,
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'accumulate',
      name: 'Must accumulate line, area and metric samples?',
      category: ['Data Map Settings'],
       showIf: (config) => config.stackValues === true,
	  defaultValue: false,
    })
    .addCustomEditor({
      id: 'metricThresholdField',
      path: 'metricThresholdField',
      name: 'Threshold Field',
      description: 'Threshold (expressed in % of average field) use to alarm a line (ex: 20)',
      category: ['Data Map Settings'],
      showIf: (config) => config.useMetricField === true,
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useInfoField',
      name: 'Use aditional info field',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'infoField',
      path: 'infoField',
      name: 'Aditional info Field',
      description: 'Aditional info to show in tooltip.',
      category: ['Data Map Settings'],
      showIf: (config) => config.useInfoField === true,
      defaultValue: '',
      editor: FieldSelectEditor,
    })

    .addBooleanSwitch({
      path: 'timeSeriesChart',
      name: 'Chart used to represent time series?',
      category: ['Chart Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'timeZone',
      name: 'Time Zone',
      category: ['Chart Settings'],
      showIf: (config) => config.timeSeriesChart === true,
      defaultValue: 'es-AR',
    })
    .addTextInput({
      path: 'syncId',
      name: 'Sync ID',
      description:
        'Setting this ID in thow or more plugins sync the position tooltip, the startIndex and endIndex of zoom.',
      category: ['Chart Settings'],
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'showTooltip',
      name: 'Show Tooltip on chart Chart',
      category: ['Chart Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'Show Title beside de Chart',
      category: ['Chart Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'chartSubTitle',
      name: 'Chart Subtitle',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'chartIcon',
      name: 'Chart Icon',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: '',
    })
    .addColorPicker({
      path: 'iconColor',
      name: 'Chart Icon',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true && config.chartIcon !== '',
      defaultValue: 'orange',
    })
    .addNumberInput({
      path: 'titleFontSize',
      name: 'Chart Title Font Size',
      category: ['Chart Settings'],
      showIf: (config) => config.showTitle === true,
      defaultValue: 16,
    })
    .addBooleanSwitch({
      path: 'isAnimationActive',
      name: 'Animation Active',
      category: ['Chart Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showDots',
      name: 'Draw dots over the line',
      category: ['Axis'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'fillLine',
      name: 'Fill the area below the main serie line.',
      category: ['Axis'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'fillLine2',
      name: 'Fill the area below the second serie line.',
      category: ['Axis'],
      showIf: (config) => config.value2Field !== null && config.value2Field !== '',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'fillLine3',
      name: 'Fill the area below the thirth serie line.',
      category: ['Axis'],
      showIf: (config) => config.value3Field !== null && config.value3Field !== '',
      defaultValue: false,
    })
    .addNumberInput({
      path: 'lineWidth',
      name: 'Line Width',
      description: 'Line width used in Areas and Lines.',
      category: ['Axis'],
      defaultValue: 1,
    })
    .addSelect({
      path: 'lineType',
      name: 'Line type',
      category: ['Axis'],
      settings: {
        options: [
          { value: 'basis', label: 'Base' },
          { value: 'linear', label: 'Linear' },
          { value: 'natural', label: 'Natural' },
          { value: 'monotone', label: 'Monotone' },
          { value: 'Step', label: 'Step' },
        ],
      },
      defaultValue: 'basis',
    })
    .addNumberInput({
      path: 'minValue',
      name: 'Axis Y Min Value',
      category: ['Axis'],
      showIf: (config) => config.scaleType !== 'log' && config.scaleTypeVertical !== 'log',
      defaultValue: 0,
    })
    .addNumberInput({
      path: 'maxValue',
      name: 'Axis Y Max Value',
      description:
        'The Axis Y maximum value. If 0 will be the max value (line, aux line, aux area, average) in the data set.',
      category: ['Axis'],
      showIf: (config) => config.scaleType !== 'log' && config.scaleTypeVertical !== 'log',
      defaultValue: 100,
    })
    .addBooleanSwitch({
      path: 'sameYAxis',
      name: 'Use same Y axis for all',
      description: 'if selected, all the lines, bar and area uses the same Y axis scale',
      category: ['Axis'],
      showIf: (config) => config.timeSeriesChart === true,
      defaultValue: false,
    })
    .addNumberInput({
      path: 'axisLineWidth',
      name: 'Axis Line width',
      description:
        'If 0, the axis line will be hide.',
      category: ['Axis'],
      defaultValue: 1,
    })
    .addSelect({
      path: 'scaleType',
      name: 'Axis Scale',
      category: ['Axis'],
      showIf: (config) => config.orientation === 'horizontal',
      settings: {
        options: [
          { value: 'auto', label: 'Auto' },
          { value: 'linear', label: 'Linear' },
          { value: 'sqrt', label: 'Cuadratic' },
          { value: 'log', label: 'Logaritmic' },
          { value: 'ordinal', label: 'Ordinal' },
        ],
      },
      defaultValue: 'auto',
    })
    .addSelect({
      path: 'scaleTypeVertical',
      name: 'Axis Scale',
      category: ['Axis'],
      showIf: (config) => config.orientation === 'vertical',
      settings: {
        options: [
          { value: 'auto', label: 'Auto' },
          { value: 'linear', label: 'Linear' },
          { value: 'sqrt', label: 'Cuadratic' },
          { value: 'log', label: 'Logaritmic' },
        ],
      },
      defaultValue: 'auto',
    })
    .addBooleanSwitch({
      path: 'useZoom',
      name: 'Show zoom controls',
      description: 'Show axis X zoom controls (This disable the zoom by select on chart).',
      category: ['Axis'],
      showIf: (config) => config.timeSeriesChart === true,
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'allowZoom',
      name: 'Allow zoom on Chart',
      description: 'If true, allow to select zoom window directly on chart.',
      category: ['Axis'],
      showIf: (config) => config.timeSeriesChart === true && config.useZoom === false,
      defaultValue: false,
    })
    .addNumberInput({
      path: 'startIndex',
      name: 'Axis X zoom start Index',
      description:
        'The default zoom start index. If the option is 0, the start index will be 0. If options is X, the start will be last timestamp - start Index.',
      showIf: (config) => config.timeSeriesChart === true && config.useZoom === true,
      category: ['Axis'],
      defaultValue: 0,
    })
    .addNumberInput({
      path: 'endIndex',
      name: 'Axis X zoom end Index',
      description: 'The default zoom end index. If the option is 0, the end will be the last timestamp.',
      showIf: (config) => config.timeSeriesChart === true && config.useZoom === true,
      category: ['Axis'],
      defaultValue: 0,
    })

    .addSelect({
      path: 'orientation',
      name: 'Orientation',
      description: 'Set the line orientation uses by the chart',
      category: ['General Settings'],
      settings: {
        options: [
          { value: 'horizontal', label: 'Horizontal' },
          { value: 'vertical', label: 'Vertical' },
        ],
      },
      defaultValue: 'horizontal',
    })
    .addBooleanSwitch({
      path: 'useCartesianGrid',
      name: 'Use Cartesian Grid',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showMetricOnTooltip',
      name: 'Show metric on Tooltip',
      category: ['General Settings'],
      showIf: (config) => config.useMetricField === true,
      defaultValue: false,
    })
    .addTextInput({
      path: 'resultSeparator',
      name: 'Result separator character',
      description: 'Character use to present the result next to the text',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'valueTitle',
      name: 'Main Line Title',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'value2Title',
      name: 'Second Line Title',
      category: ['General Settings'],
      defaultValue: '',
	  showIf: (config) => config.value2Field !== null && config.value2Field !== '',
    })
    .addTextInput({
      path: 'value3Title',
      name: 'Thirth Line Title',
      category: ['General Settings'],
      defaultValue: '',
	  showIf: (config) => config.value3Field !== null && config.value3Field !== '',
    })
    .addTextInput({
      path: 'dateTitle',
      name: 'Date Title',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'metricTitle',
      name: 'Metric Title',
      showIf: (config) => config.useMetricField === true && config.showMetricOnTooltip,
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'metricUnit',
      name: 'Main Line Metric Unit',
      showIf: (config) => config.valueField !== null && config.valueField !== '',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'metricUnit2',
      name: 'Second Line Metric Unit',
      showIf: (config) => config.value2Field !== null && config.value2Field !== '',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'metricUnit3',
      name: 'Thirth Line Metric Unit',
      showIf: (config) => config.value3Field !== null && config.value3Field !== '',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'auxLineTitle',
      name: 'Aux Line Title',
      category: ['General Settings'],
      showIf: (config) => config.showLineOnChart === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'auxLineUnit',
      name: 'Aux Line Unit',
      showIf: (config) => config.showLineOnChart === true,
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'auxAreaTitle',
      name: 'Aux Area Title',
      showIf: (config) => config.showAreaOnChart === true,
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'auxAreaUnit',
      name: 'Aux Area Unit',
      showIf: (config) => config.showAreaOnChart === true,
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Info Title',
      category: ['General Settings'],
      showIf: (config) => config.useInfoField === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'clicTitle',
      name: 'Clic Title',
      showIf: (config) => config.timeSeriesChart === false,
      category: ['General Settings'],
      defaultValue: 'Clic para más detalles ...',
    })

    .addColorPicker({
      path: 'auxAreaColor',
      name: 'Auxiliar area Color',
      category: ['Chart Colors'],
      showIf: (config) => config.timeSeriesChart === true && config.showAreaOnChart === true,
      defaultValue: '',
    })
    .addColorPicker({
      path: 'lineColor',
      name: 'Main serie line Color',
      category: ['Chart Colors'],
      showIf: (config) => config.valueField !== null && config.valueField !== '',
      defaultValue: '',
    })
    .addColorPicker({
      path: 'line2Color',
      name: 'Second serie line Color',
      category: ['Chart Colors'],
      showIf: (config) => config.value2Field !== null && config.value2Field !== '',
      defaultValue: '',
    })
    .addColorPicker({
      path: 'line3Color',
      name: 'Thirth serie line Color',
      category: ['Chart Colors'],
      showIf: (config) => config.value3Field !== null && config.value3Field !== '',
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'hideFillLine',
      name: 'Hide Line',
      description: 'Hide the line when the line is filled',
      showIf: (config) => config.fillLine === true || config.fillLine2 === true || config.fillLine3 === true,
      category: ['Chart Colors'],
      defaultValue: false,
    })
    .addNumberInput({
      path: 'fillOpacityStart',
      name: 'Fill initial opacity',
      showIf: (config) => config.fillLine === true || config.fillLine2 === true || config.fillLine3 === true,
      category: ['Chart Colors'],
      defaultValue: 0.8,
    })
    .addNumberInput({
      path: 'fillOpacityEnd',
      name: 'Fill end opacity',
      showIf: (config) => config.fillLine === true || config.fillLine2 === true || config.fillLine3 === true,
      category: ['Chart Colors'],
      defaultValue: 0,
    })
    .addColorPicker({
      path: 'metricLineColor',
      name: 'Metric Line Color',
      category: ['Chart Colors'],
      showIf: (config) => config.showMetricOnChart === true,
      defaultValue: 'OrangeRed',
    })
    .addColorPicker({
      path: 'auxLineColor',
      name: 'Auxiliar line Color',
      category: ['Chart Colors'],
      showIf: (config) => config.showLineOnChart === true,
      defaultValue: '',
    })

    .addBooleanSwitch({
      path: 'showAuxToolbar',
      name: 'Show Aux Toolbar',
      category: ['Toolbar'],
      defaultValue: false,
	  showIf: (config) => config.timeSeriesChart === false && config.showTitle === false,
    })
    .addTextInput({
      path: 'toolbarAuxButtonIcon1',
      name: '1st Aux Button Icon',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.timeSeriesChart === false,
    })
    .addTextInput({
      path: 'toolbarAuxButtonTitle1',
      name: '1st Aux Button Title',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.timeSeriesChart === false && config.toolbarAuxButtonIcon1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonVariable1',
      name: '1st Aux Button variable',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.timeSeriesChart === false && config.toolbarAuxButtonIcon1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonValue1',
      name: '1st Aux Button value',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.timeSeriesChart === false && config.toolbarAuxButtonVariable1 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonIcon2',
      name: '2nd Aux Button Icon',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true && config.timeSeriesChart === false,
    })
    .addTextInput({
      path: 'toolbarAuxButtonTitle2',
      name: '1st Aux Button Title',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true &&  config.timeSeriesChart === false && config.toolbarAuxButtonIcon2 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonVariable2',
      name: '2nd Aux Button variable',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true &&  config.timeSeriesChart === false && config.toolbarAuxButtonIcon2 !== '',
    })
    .addTextInput({
      path: 'toolbarAuxButtonValue2',
      name: '2nd Aux Button value',
      category: ['Toolbar'],
      defaultValue: '',
	  showIf: (config) => config.showAuxToolbar === true &&  config.timeSeriesChart === false && config.toolbarAuxButtonVariable2 !== '',
    })
    .addBooleanSwitch({
      path: 'showToolbar',
      name: 'Show Toolbar',
      category: ['Toolbar'],
      defaultValue: false,
	  showIf: (config) => config.timeSeriesChart === true && config.showTitle === false,
    })
    .addBooleanSwitch({
      path: 'showTimeZoomButtons',
      name: 'Show Time Controls',
      category: ['Toolbar'],
      showIf: (config) => config.showToolbar === true,
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showRealTimeButton',
      name: 'Show RealTime',
      category: ['Toolbar'],
      showIf: (config) => config.timeSeriesChart === true && config.showToolbar === true,
      defaultValue: false,
    })
    .addTextInput({
      path: 'realTimeTitle',
      name: 'RealTime Tooltip',
      category: ['Toolbar'],
      showIf: (config) => config.showRealTimeButton === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'realTimeButtonLink',
      name: 'RealTime Button URL base',
      category: ['Toolbar'],
      showIf: (config) => config.timeSeriesChart === true && config.showRealTimeButton === true,
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'showAnaliticButton',
      name: 'Show Analitic button',
      category: ['Toolbar'],
      showIf: (config) => config.timeSeriesChart === true && config.showToolbar === true,
      defaultValue: false,
    })
    .addTextInput({
      path: 'analiticButtonTitle',
      name: 'Analitic Button Tooltip',
      category: ['Toolbar'],
      showIf: (config) => config.showToolbar === true && config.showZoomButton === true,
      defaultValue: '',
    })
    .addTextInput({
      path: 'analiticButtonLink',
      name: 'Analitic Button URL base',
      category: ['Toolbar'],
      showIf: (config) => config.showToolbar === true && config.showZoomButton === true,
      defaultValue: '',
    })

    .addBooleanSwitch({
      path: 'showLegend',
      name: 'Show Legends',
      category: ['Legends'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showAxisX',
      name: 'Show Y Axis',
      category: ['Legends'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showAxisY',
      name: 'Show X Axis',
      category: ['Legends'],
      defaultValue: true,
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'On Chart Text Font Size',
      category: ['Legends'],
      defaultValue: 12,
    })

    .addTextInput({
      path: 'absoluteThreshold',
      name: 'Absolute Threshold',
      description: 'Absolute Threshold use to alarm a Line (ex: 90)',
      category: ['Thresholds'],
      defaultValue: '90',
    })
    .addBooleanSwitch({
      path: 'invertThreshold',
      name: 'Invert Threshold',
      category: ['Thresholds'],
      defaultValue: false,
    })
    .addColorPicker({
      path: 'warningColor',
      name: 'Warning Color',
      description: 'Color use when value is above threshold',
      category: ['Thresholds'],
      defaultValue: '#FF0000',
    })

    .addTextInput({
      path: 'drillDownLink',
      name: 'Chart base URL',
      description: 'Only available if LineChart in not use for Time Series.',
      category: ['Link to other features'],
      showIf: (config) => config.timeSeriesChart === false,
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen eventos para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';

import { FormSmSign } from './SmSigns';
import { Text } from './Tags';
import { severityFieldOptions } from 'app/features/alerting/unified/utils/alertmanager';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { DynamicTable, DynamicTableColumnProps, DynamicTableItemProps } from './SmDynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';

export interface SmSignsTableProps {
  id: string;
  isAdmin: boolean;
  onChange: (routes: FormSmSign[]) => void;
  filters?: { sign?: string; name?: string ; summary?: string };
  isDark:boolean;
  width: number;
  height: number;
  signs: FormSmSign[];
}

type RouteTableColumnProps = DynamicTableColumnProps<FormSmSign>;
type RouteTableItemProps = DynamicTableItemProps<FormSmSign>;

export const getFilteredSigns = (
  signs: FormSmSign[],
  signQuery?: string,
  nameQuery?: string,
  summaryQuery?: string
) => {
  let filteredSigns = signs;

  if (signQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.sign.toLowerCase().includes(signQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }

  if (nameQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.name.toLowerCase().includes(nameQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }

  if (summaryQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.summary.toLowerCase().includes(summaryQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }
  return filteredSigns;
};

export const cleanSigns = (signs: FullFormAmRoute[]): FormSmSign[] => {
  const cleannerSigns: FormSmSign[] = [];

  signs.forEach((sign, index) => {
	const alertSign = {
	  id: sign.id,
	  idx: sign.signId,
	  sign: sign.signName,
	  state: sign.signState,
	  date: sign.signDate,
	  user: sign.signUser,
	  name: sign.alarmName,
	  summary: sign.alarmSummary,
	  type: sign.alarmType,
	  severity: sign.alarmSeverity,
	  severityName: '',
	  scope: sign.alarmScope,
	  openTicket: sign.openTicket
	};
	cleannerSigns.push(alertSign);
  });

  return cleannerSigns;
};
  
export const updatedSign = (signs: FormSmSign[], updatedSign: FormSmSign): FormSmSign[] => {
  const newSigns = [...signs];
  const editIndex = newSigns.findIndex((sign) => sign.id === updatedSign.id);

  if (editIndex >= 0) {
    newSigns[editIndex] = {
      ...newSigns[editIndex],
      ...updatedSign,
    };
  }
  return newSigns;
};


export const SmSignsTable: FC<SmSignsTableProps> = ({
  id,
  isAdmin,
  onChange,
  filters,
  isDark,
  width,
  height,
  signs
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const alertSigns: FormSmSign[] = [];
  const severities = severityFieldOptions;
  const valueToString = (value: string) => {
	const valueSelected = severities.find(severity => severity.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  signs.forEach((sign, index) => {
	const alertSign = {
	  id: sign.id,
	  idx: sign.signId,
	  sign: sign.signName,
	  state: sign.signState,
	  date: sign.signDate,
	  user: sign.signUser,
	  name: sign.alarmName,
	  summary: sign.alarmSummary,
	  type: sign.alarmType,
	  severity: sign.alarmSeverity,
	  severityName: valueToString(sign.alarmSeverity),
	  scope: sign.alarmScope,
	  openTicket: sign.openTicket
	};
	alertSigns.push(alertSign);
  });

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'sign',
      label: 'Firma',
      renderCell: (item) => {
		return item.data.sign.length ? (
          <Text text={item.data.sign} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'type',
      label: 'Tipo',
      renderCell: (item) => {
        return item.data.type.length ? (
          <Text text={item.data.type} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 4,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} enable={item.data.state} />
        ) : (
          <Text text={'Deshabilitada'} enable={item.data.state} />
        );
      },
      size: 4,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'sign',
      label: 'Firma',
      renderCell: (item) => {
		return item.data.sign.length ? (
          <Text text={item.data.sign} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'type',
      label: 'Tipo',
      renderCell: (item) => {
        return item.data.type.length ? (
          <Text text={item.data.type} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 4,
    },
    {
      id: 'name',
      label: 'Nombre de Regla',
      renderCell: (item) => {
		return item.data.name.length ? (
          <Text text={item.data.name} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'summary',
      label: 'Resumen',
      renderCell: (item) => {
        return item.data.summary ? (
		  <Text text={item.data.summary} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 10,
    },
    {
      id: 'scope',
      label: 'Ambito',
      renderCell: (item) => {
		return item.data.scope? (
		  <Text text={item.data.scope} enable={item.data.state} />
		) : (
		  <Text text={'Todos'} />
		);
      },
      size: 4,
    },
    {
      id: 'severityName',
      label: 'Severidad',
      renderCell: (item) => {
        return item.data.severityName ? (
		  <Text text={item.data.severityName} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 4,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} enable={item.data.state} />
        ) : (
          <Text text={'Deshabilitada'} enable={item.data.state} />
        );
      },
      size: 4,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 4,
	});
	cols.push({
	  id: 'date',
	  label: 'Desde',
	  renderCell: (item) => {
		return item.data.date ? (
		  <Text text={item.data.date} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 4,
	});
  }

  const filteredSigns = useMemo(
    () => getFilteredSigns(alertSigns, filters?.sign, filters?.name, filters?.summary),
    [alertSigns, filters]
  );
  const dynamicTableSigns = useMemo(
    () => prepareItems(filteredSigns),
    [filteredSigns]
  );

  if (alertSigns.length > 0 && filteredSigns.length === 0) {
    return (
      <EmptyArea>
        <p>No existen reglas configuradas</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        isExpandable={true}
        items={dynamicTableSigns}
		onChange={onChange}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		signs={signs}
      />
    </>
  );
};
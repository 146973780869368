import { capitalize } from 'lodash';

import { receiverTypeNames } from 'app/plugins/datasource/alertmanager/consts';
import { NetMonitorManagedReceiverConfig, Receiver } from 'app/plugins/datasource/alertmanager/types';
import { NotifierDTO } from 'app/types';

// extract notifier type name to count map, eg { Slack: 1, Email: 2 }

type NotifierTypeCounts = Record<string, number>; // name : count

export function extractNotifierTypeCounts(receiver: Receiver, netmonitorNotifiers: NotifierDTO[]): NotifierTypeCounts {
  if (receiver['netmonitor_managed_receiver_configs']) {
    return getNetMonitorNotifierTypeCounts(receiver.netmonitor_managed_receiver_configs ?? [], netmonitorNotifiers);
  }
  return getCortexAlertManagerNotifierTypeCounts(receiver);
}

export function extractNotifierSettings(receiver: Receiver) {
  let addresses = [];
  if (receiver.hasOwnProperty('netmonitor_managed_receiver_configs')) {
    receiver.netmonitor_managed_receiver_configs.forEach(config => {
      if (config.settings && config.settings.hasOwnProperty('addresses')) {
        addresses.push(config.settings.addresses);
      }
    });
  }
  return addresses;
}

function getCortexAlertManagerNotifierTypeCounts(receiver: Receiver): NotifierTypeCounts {
  return Object.entries(receiver)
    .filter(([key]) => key !== 'netmonitor_managed_receiver_configs' && key.endsWith('_configs')) // filter out only properties that are alertmanager notifier
    .filter(([_, value]) => Array.isArray(value) && !!value.length) // check that there are actually notifiers of this type configured
    .reduce<NotifierTypeCounts>((acc, [key, value]) => {
      const type = key.replace('_configs', ''); // remove the `_config` part from the key, making it intto a notifier name
      const name = receiverTypeNames[type] ?? capitalize(type);
      return {
        ...acc,
        [name]: (acc[name] ?? 0) + (Array.isArray(value) ? value.length : 1),
      };
    }, {});
}

function getNetMonitorNotifierTypeCounts(
  configs: NetMonitorManagedReceiverConfig[],
  netmonitorNotifiers: NotifierDTO[]
): NotifierTypeCounts {
  return configs
    .map((recv) => recv.type) // extract types from config
    .map((type) => netmonitorNotifiers.find((r) => r.type === type)?.name ?? capitalize(type)) // get readable name from notifier cofnig, or if not available, just capitalize
    .reduce<NotifierTypeCounts>(
      (acc, type) => ({
        ...acc,
        [type]: (acc[type] ?? 0) + 1,
      }),
      {}
    );
}
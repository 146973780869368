import { css } from '@emotion/css';
import React, { useState } from 'react';
import moment from 'moment';
import { useDebounce } from 'react-use';

import { config } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';
import { getSignFilters } from 'app/features/alerting/unified/utils/misc';
import { CustomSmSignsTable } from './CustomSmSignsTable';
import { CustomFormSmSign } from './CustomSmSigns';

type Props = {
  isAdmin: boolean;
  showTitle: boolean;
  pluginTitle: string;
  id: string;
  width: number;
  height: number;
  signs: CustomFormSmSign[];
  assets: [];
  signTypeList: [];
  types: [];
  pluginVariables: [];
  onSaveChanges: (newSignsConfigured: CustomFormSmSign[]) => void;
}

interface Filters {
  asset?: string;
  name?: string;
  oid?: string;
}

export const CustomSignGroup = ({
  id,
  showTitle,
  pluginTitle,
  isAdmin,
  width,
  height,
  signs,
  assets,
  signTypeList,
  types,
  pluginVariables,
  onSaveChanges
}: Props) => {
  const isDark = config.theme.isDark;
  const styles = useStyles2(getStyles(isDark, width));
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { asset, name, oid } = getSignFilters(searchParams);
  const [filters, setFilters] = useState<Filters>({ asset, name, oid });
  const [actualSigns, setActualSigns] = useState([...signs]);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const userName = contextSrv.user.name;
  const d = Date.now();
  const actualSignsLength = actualSigns.length;

  const emptySign = {
	  id: actualSignsLength,
	  signId: actualSignsLength + 1,
	  signName: 'Custom Sign ' + String(actualSignsLength + 1),
	  signState: true,
	  signDate: moment(d).format('DD/MM/YYYY HH:mm:ss'),
	  signUser: userName,
	  signAsset: '',
	  signOid: '',
	  signOidValueType: 'boolean',
	  signThresholdBelow: false,
	  signDualThreshold: false,
	  signOidThresholdBoolean: false,
	  signOidThresholdUp: '',
	  signOidThresholdDown: '',
	  signType: 'custom',
	  alertName: 'Nueva Alerta',
	  alertSummary: '',
	  resolSummary: '',
	  alertSeverity: 'alarm',
	  alertScope: 'nodo',
	  openTicket: false
  };

  useDebounce(
    () => {
      setSearchParams({ asset: filters.asset, name: filters.name, oid: filters.oid });
    },
    400,
    [filters]
  );

  const clearFilters = () => {
    setFilters({ asset: undefined, name: undefined, oid: undefined });
    setSearchParams({ asset: undefined, name: undefined, oid: undefined });
  };

  const addNewSign = () => {
    clearFilters();
    setIsAddMode(true);
    setActualSigns(() => [
      ...signs,
      {
        ...emptySign,
      },
    ]);
  };

  const onCancel = () => {
    clearFilters();
	if (isAddMode) {
	  setIsAddMode(false);
      setActualSigns(() => [...signs]);
 	}
  };

  const onSave = (savedSigns: CustomFormSmSign[]) => {
	const updatedSigns = onSaveChanges(savedSigns);
	setActualSigns(() => [...updatedSigns]);
	if (isAddMode) {
	  setIsAddMode(false);
	}
  };

  return (
    <div key={id} id={id} className={styles.wrapper}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
		<div className={styles.channelContainer}>
		  <div className={styles.searchContainer}>
			<div className={styles.filterInput}>
			  <Icon name="search" size="xs" />
			  <input
				className={styles.inputBox}
				onChange={({ currentTarget }) =>
				  setFilters((currentFilters) => ({ ...currentFilters, asset: currentTarget.value }))
				}
				value={filters.asset ?? ''}
				placeholder="Filtrar por activo..."
			  />
			</div>
			{width > 550 && (
			  <div className={styles.filterInput}>
			    <Icon name="search" size="xs" />
			    <input
				  className={styles.inputBox}
				  onChange={({ currentTarget }) =>
				    setFilters((currentFilters) => ({ ...currentFilters, oid: currentTarget.value }))
				  }
				  value={filters.oid ?? ''}
				  placeholder="Filtrar por OID ..."
			    />
			  </div>
			)}
			{width > 550 && (
			  <div className={styles.filterInput}>
			    <Icon name="search" size="xs" />
			    <input
				  className={styles.inputBox}
				  onChange={({ currentTarget }) =>
				    setFilters((currentFilters) => ({ ...currentFilters, name: currentTarget.value }))
				  }
				  value={filters.name ?? ''}
				  placeholder="Filtrar por nombre de Alerta ..."
			    />
			  </div>
			)}
			{(asset || name || oid) && (
			  <div className={styles.addMatcherBtnRow}>
			    <button 
				  type="button" 
				  className={styles.cancelButton} 
				  onClick={clearFilters}
				  title={'Limpiar filtros aplicados'}
				>
				  <div className={styles.button_icon}>
				    <Icon name="times-fill" size="lg" />
				  </div>
				  Limpiar
				</button>
			  </div>
			)}
			{isAdmin && !isAddMode && (
			  <div className={styles.addMatcherBtnRow}>
				<button 
				  type="button"
				  className={styles.okButton} 
				  onClick={addNewSign}
				  title={'Agregar nueva firma personalizada'}
				>
				  <div className={styles.button_icon}>
				    <Icon name="plus-circle-fill" size="lg" />
				  </div>
				  Agregar
				</button>
			  </div>
			)}
		  </div>
		</div>
		<div className={styles.signContainer}>
		  <CustomSmSignsTable
		    id={id}
			isAdmin={isAdmin}
			isAddMode={isAddMode}
			onSave={onSave}
			onCancel={onCancel}
			filters={{ asset, name, oid }}
			isDark={isDark}
			width={width}
			height={showTitle ? height - 100 : height - 65}
			signs={actualSigns}
			assets={assets}
			signTypeList={signTypeList}
			types={types}
			pluginVariables={pluginVariables}
		  />
		</div>
      </div>
    </div>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const borderTitle = isDark ? '#1B2733' : '#EFF4FA';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    wrapper: css`
      width: 99%;
    `,
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 5px;
    `,
    signContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: 100%;
	  justify-content: space-between;
    `,
    filterInput: css`
      max-width: 400px;
	  width: 28%;
	  height: 22px;
	  border: 1px solid ${borderColor};
	  background: ${background};
	  color: ${fontColor};
	  border-radius: 10px;
	  padding: 0px 5px 0px 5px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    inputBox: css`
      width: 90%;
	  margin-left: 5px;
	  height: 18px;
	  font-size: 12px;
    `,
    addMatcherBtnRow: css`
      display: inline-flex;
      min-width: 100px;
	  margin-left: 15px;
      }
    `,
    title: css`
      display: inline-flex;
      margin: 12px 20px 0px 20px;
	  color: ${fontColor};
	  border-bottom: 1px solid ${borderTitle};
	  width: Calc(100% - 30px);
	  margin-left: 15px;
	  margin-bottom: 5px;
    `,
    titleText: css`
	  font-family: Roboto, Helvetica, Arial, sans-serif;
	  margin: 7px 20px 0px 5px;
	  font-size: 16px;
	  font-weight: 500;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

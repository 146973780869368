import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { IndicatorPanel } from './IndicatorPanel';

export const plugin = new PanelPlugin<SimpleOptions>(IndicatorPanel).setPanelOptions(builder => {
  return builder

    .addBooleanSwitch({
      path: 'displayButtonsHorizontal',
      name: 'Horizontal Buttons',
      description: 'Display indicators horizontally.',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'indicatorTextOption',
      path: 'indicatorTextOption',
      name: 'Indicator Text Field',
      description: 'The field that will be used as the Text for the indicator',
      defaultValue: 'indicator_text',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'indicatorStyleOption',
      path: 'indicatorStyleOption',
      name: 'Tab Style Field',
      description: 'The indicator color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'indicator_style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'indicatorIconOption',
      path: 'indicatorIconOption',
      name: 'IndicatorIcon Field',
      description: 'The field that will be used as the Icon for the tab',
      defaultValue: 'indicator_icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'indicatorResultOption',
      path: 'indicatorResultOption',
      name: 'Indicator Result Field',
      description: 'The field that will be used as value inside the indicator',
      defaultValue: 'indicator_result',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'indicatorTitleOption',
      path: 'indicatorTitleOption',
      name: 'Indicator Tooltip Field',
      description: 'The tooltip shows with the indicator',
      defaultValue: 'indicator_title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'indicatorUrlOption',
      path: 'indicatorUrlOption',
      name: 'Indicator Url Field',
      description: 'The tooltip shows with the indicator',
      defaultValue: 'indicator_title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'showMoreInfo',
      name: 'Show evolution info',
      description: 'Display two aditional indicator with variable evolution',
      category: ['Data Mapping'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'evolutionTextOption',
      path: 'evolutionTextOption',
      name: 'Evolution Text  Field',
      description: 'The evolution text to show',
      defaultValue: 'evolution_title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
	  showIf: config => config.showMoreInfo === true,
    })
    .addCustomEditor({
      id: 'evolutionLabelOption',
      path: 'evolutionLabelOption',
      name: 'Evolution Label Field',
      description: 'Evolution aditional info',
      defaultValue: 'evolution_label',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
	  showIf: config => config.showMoreInfo === true,
    })
    .addBooleanSwitch({
      path: 'iconCentered',
      name: 'Icon on Top',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: config => config.textCentered === false,
    })
    .addBooleanSwitch({
      path: 'textCentered',
      name: 'Text at left',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: config => config.iconCentered === false,
    })
    .addTextInput({
      path: 'resultSeparator',
      name: 'Result separator character',
      description: 'Character use to present the result next to the text. If none, it uses a new line',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'On Chart Text Font Size',
      category: ['General Settings'],
      defaultValue: 12,
    })
    .addTextInput({
      path: 'iconSize',
      name: 'On Chart Icon Size',
	  description: 'Icon size used by indicator. Must be: xs, sm, md, lg, xl, xxl, xxxl',
      category: ['General Settings'],
      defaultValue: 'xl',
    })
    .addColorPicker({
      path: 'iconColor',
      name: 'On Chart Icon Color',
	  description: 'Icon size used by indicator. Must be: xs, sm, md, lg, xl, xxl, xxxl',
      category: ['General Settings'],
      defaultValue: 'text',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

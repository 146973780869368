import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';

import { AmRouteReceiver, FullFormAmRoute, FormAmRoute } from './AmRoutes';
import { Tags, Text, ruleToTag } from './Tags';
import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { severityFieldOptions, matcherFieldToMatcher, parseMatchers } from 'app/features/alerting/unified/utils/alertmanager';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { DynamicTable, DynamicTableColumnProps, DynamicTableItemProps } from './DynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { Matchers } from 'app/features/alerting/unified/components/silences/Matchers';

export interface AmRoutesTableProps {
  id: string;
  isAddMode: boolean;
  isAdmin: boolean;
  onChange: (routes: FormAmRoute[]) => void;
  onCancelAdd: () => void;
  receivers: AmRouteReceiver[];
  routes: FormAmRoute[];
  filters?: { queryString?: string; contactPoint?: string; alarmType?: string };
  readOnly?: boolean;
  alertManagerSourceName: string;
  isDark:boolean;
  width: number;
  height: number;
  options: [];
}

type RouteTableColumnProps = DynamicTableColumnProps<FullFormAmRoute>;
type RouteTableItemProps = DynamicTableItemProps<FullFormAmRoute>;

export const getFilteredRoutes = (routes: FullFormAmRoute[], labelMatcherQuery?: string, contactPointQuery?: string, alarmTypeQuery?: string) => {
  let filteredRoutes = routes;

  if (alarmTypeQuery && alarmTypeQuery.length > 0) {
    filteredRoutes = routes.filter((route) => {
	  for (let matcher of route.object_matchers) {
        if (matcher.name.toLowerCase() === alarmTypeQuery.toLowerCase()) {
          return true;
        }
		if (matcher.name.toLowerCase() === 'tipo' && matcher.value.toLowerCase().includes(alarmTypeQuery.toLowerCase())) {
          return true;
        }
      }
	  return false;
	});
  }

  if (labelMatcherQuery) {
    filteredRoutes = routes.filter((route) => {
	  for (let matcher of route.object_matchers) {
        if (matcher.name.toLowerCase().includes(labelMatcherQuery.toLowerCase())) {
          return true;
        }
		if (matcher.value.toLowerCase().includes(labelMatcherQuery.toLowerCase())) {
          return true;
        }
      }
	  return false;
	});
  }

  if (contactPointQuery && contactPointQuery.length > 0) {
    filteredRoutes = filteredRoutes.filter((route) =>
      route.receiver.toLowerCase().includes(contactPointQuery.toLowerCase())
    );
  }
  return filteredRoutes;
};

export const cleanRoutes = (routes: FullFormAmRoute[]): FormAmRoute[] => {
  const cleannerRoutes: FormAmRoute[] = [];
  routes.forEach((route, index) => {
	const alertRoute = {
	  continue: true,
	  groupBy: route.data.groupBy,
	  groupIntervalValue: route.data.groupIntervalValue,
	  groupIntervalValueType: route.data.groupIntervalValueType,
	  groupWaitValue: route.data.groupWaitValue,
	  groupWaitValueType: route.data.groupWaitValueType,
	  id: route.data.id,
	  receiver: route.data.receiver,
	  muteTimeIntervals: route.data.muteTimeIntervals,
	  object_matchers: route.data.object_matchers,
	  overrideGrouping: route.data.overrideGrouping,
	  overrideTimings: route.data.overrideTimings,
	  repeatIntervalValue: route.data.repeatIntervalValue,
	  repeatIntervalValueType: route.data.repeatIntervalValueType,
	  routes: route.data.routes,
	};
	cleannerRoutes.push(alertRoute);
  });
  return cleannerRoutes;
};
  
export const updatedRoute = (routes: FullFormAmRoute[], updatedRoute: FullFormAmRoute): FullFormAmRoute[] => {
  const newRoutes = [...routes];
  const editIndex = newRoutes.findIndex((route) => route.id === updatedRoute.id);

  if (editIndex >= 0) {
    newRoutes[editIndex] = {
      ...newRoutes[editIndex],
      ...updatedRoute,
    };
  }
  return newRoutes;
};

export const deleteRoute = (routes: FullFormAmRoute[], routeId: string): FullFormAmRoute[] => {
  const restRoutes = routes.filter((route) => route.id !== routeId);
  return restRoutes;
};

export const AmRoutesTable: FC<AmRoutesTableProps> = ({
  id,
  isAddMode,
  onCancelAdd,
  onChange,
  receivers,
  routes,
  filters,
  isAdmin,
  readOnly,
  alertManagerSourceName,
  isDark,
  width,
  height,
  options
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();
  const severities = severityFieldOptions;
  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const alertRoutes: FullFormAmRoute[] = [];
  const alertTypes = {
    alarma: 'Alarma',
    alerta: 'Alerta',
    trap: 'Trap',
    resolucion: 'Resolucion',
    reporte: 'Reporte',
  };

  const valueToString = (value: string) => {
	const valueSelected = severities.find(severity => severity.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  routes.forEach((route, index) => {
	const alertRoute = {
	  continue: true,
	  groupBy: route.groupBy,
	  groupIntervalValue: route.groupIntervalValue,
	  groupIntervalValueType: route.groupIntervalValueType,
	  groupWaitValue: route.groupWaitValue,
	  groupWaitValueType: route.groupWaitValueType,
	  id: route.id,
	  receiver: route.receiver,
	  muteTimeIntervals: route.muteTimeIntervals,
	  object_matchers: route.object_matchers,
	  overrideGrouping: route.overrideGrouping,
	  overrideTimings: route.overrideTimings,
	  repeatIntervalValue: route.repeatIntervalValue,
	  repeatIntervalValueType: route.repeatIntervalValueType,
	  routes: route.routes,
	  category: '',
	  severityName: '',
	  name: '',
	  ruleType: '',
	  severity: '',
	  ruleName: [],
	  operationGroup: [],
	  manteinanceGroup: [],
	};
	if (route.object_matchers.length > 0) {
      route.object_matchers.forEach((label, index) => {
		const labelName = label.name.toLowerCase();
		const operName = label.operator === '=' ? '==' : '!=';
		if (labelName === 'tipo') {
		  alertRoute.ruleType = label.value ?? '';
		} else if (labelName === 'categoria') {
		  alertRoute.category = label.value ?? '';
		} else if (labelName === 'alertname') {
		  alertRoute.name = label.value ?? '';
		} else if (labelName === 'severidad') {
		  alertRoute.severity = label.value ?? '';
		  alertRoute.severityName = valueToString(label.value);
		} else if (labelName === 'operacion') {
		  alertRoute.operationGroup.push('Grupo' + operName + label.value ?? '');
		} else if (labelName === 'mantenimiento') {
		  alertRoute.manteinanceGroup.push('Grupo' + operName + label.value ?? '');
		} else if (labelName !== '') {
		  const ruleType = alertTypes[`${labelName}`] ?? '';
		  if (ruleType !== '') {
		    alertRoute.ruleName.push(ruleType + operName + label.value ?? '');
		  }
		}
      });
    }
	alertRoutes.push(alertRoute);
  });

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'ruleType',
      label: 'Tipo de Regla',
      renderCell: (item) => {
        return item.data.ruleType ? (
		  <Text text={item.data.ruleType} />
		) : (
		  <Text text={'Cualquiera'} />
		);
      },
      size: 4,
    },
    {
      id: 'alertName',
      label: 'Tipo y Nombre de Regla',
      // eslint-disable-next-line react/display-name
      renderCell: (item) => {
		return item.data.ruleName.length ? (
          <Tags tags={item.data.ruleName.map(ruleToTag)} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'receiverChannel',
      label: 'Canal de notificación',
      renderCell: (item) => {
        return item.data.receiver ? (
		  <Text text={item.data.receiver} />
		) : (
		  <Text text={'-'} />
		);
      }, 
	  size: 10,
    },
    ...(readOnly
      ? []
      : [
          {
            id: 'actions',
            label: '',
            // eslint-disable-next-line react/display-name
            renderCell: (item) => {
			  if (item.renderExpandedContent) {
                return null;
              }
              const expandWithCustomContent = () => {
				expandItem(item);
                setEditMode(true);
              };
              if (isAdmin) {
                return (
                  <>
                    <HorizontalGroup>
                      <IconButton
                        aria-label="Delete route"
                        name="trash-circle-fill"
                        size="xl"
                        tooltip="Borrar regla de notificación"
                        onClick={() => {
                          setDeletingRouteId(item.data.id);
                        }}
                        type="button"
                        variant="secondary"
                      />
                    </HorizontalGroup>
                  </>
                );
              } else {
                return null;
              }
            },
            size: '64px',
          } as RouteTableColumnProps,
        ]),
  ];

  const cols: RouteTableColumnProps[] = [
    {
      id: 'category',
      label: 'Categoria',
      renderCell: (item) => {
		return item.data.category? (
		  <Text text={item.data.category} />
		) : (
		  <Text text={'Cualquiera'} />
		);
      },
      size: 4,
    },
    {
      id: 'ruleType',
      label: 'Tipo de Regla',
      renderCell: (item) => {
        return item.data.ruleType ? (
		  <Text text={item.data.ruleType} />
		) : (
		  <Text text={'Cualquiera'} />
		);
      },
      size: 4,
    },
    {
      id: 'alertName',
      label: 'Tipo y Nombre de Regla',
      // eslint-disable-next-line react/display-name
      renderCell: (item) => {
		return item.data.ruleName.length ? (
          <Tags tags={item.data.ruleName.map(ruleToTag)} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'severityName',
      label: 'Severidad',
      renderCell: (item) => {
        return item.data.severityName ? (
		  <Text text={item.data.severityName} />
		) : (
		  <Text text={'Todas'} />
		);
      },
      size: 5,
    },
    {
      id: 'manteinanceGroup',
      label: 'Mantenimiento',
      // eslint-disable-next-line react/display-name
      renderCell: (item) => {
        return item.data.manteinanceGroup.length ? (
          <Tags tags={item.data.manteinanceGroup.map(ruleToTag)} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'operationGroup',
      label: 'Operación',
      // eslint-disable-next-line react/display-name
      renderCell: (item) => {
        return item.data.operationGroup.length ? (
          <Tags tags={item.data.operationGroup.map(ruleToTag)} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'receiverChannel',
      label: 'Canal de notificación',
      renderCell: (item) => {
        return item.data.receiver ? (
		  <Text text={item.data.receiver} />
		) : (
		  <Text text={'-'} />
		);
      }, 
	  size: 10,
    },
    {
      id: 'muteTimings',
      label: 'Durante',
      renderCell: (item) => {
		return item.data.muteTimeIntervals.length > 0 ? (
		  <Text text={'Todo los días salvo: ' + item.data.muteTimeIntervals.join(',')} />
		) : (
		  <Text text={'Todo los días'} />
		);
      }, 
      size: 10,
    },
    ...(readOnly
      ? []
      : [
          {
            id: 'actions',
            label: '',
            // eslint-disable-next-line react/display-name
            renderCell: (item) => {
			  if (item.renderExpandedContent) {
                return null;
              }
              const expandWithCustomContent = () => {
				expandItem(item);
                setEditMode(true);
              };
              if (isAdmin) {
                return (
                  <>
                    <HorizontalGroup>
                      <IconButton
                        aria-label="Delete route"
                        name="trash-circle-fill"
                        size="xl"
                        tooltip="Borrar regla de notificación"
                        onClick={() => {
                          setDeletingRouteId(item.data.id);
                        }}
                        type="button"
                        variant="secondary"
                      />
                    </HorizontalGroup>
                  </>
                );
              } else {
                return null;
              }
            },
            size: '64px',
          } as RouteTableColumnProps,
        ]),
  ];
  
  const filteredRoutes = useMemo(
    () => getFilteredRoutes(alertRoutes, filters?.queryString, filters?.contactPoint, filters?.alarmType),
    [alertRoutes, filters]
  );
  const dynamicTableRoutes = useMemo(
    () => prepareItems(isAddMode ? alertRoutes : filteredRoutes),
    [isAddMode, alertRoutes, filteredRoutes]
  );

  // expand the last item when adding or reset when the length changed
  useEffect(() => {
    if (isAddMode && dynamicTableRoutes.length) {
      setExpandedId(dynamicTableRoutes[dynamicTableRoutes.length - 1].id);
    }
    if (!isAddMode && dynamicTableRoutes.length) {
      setExpandedId(undefined);
    }
  }, [isAddMode, dynamicTableRoutes]);

  if (alertRoutes.length > 0 && filteredRoutes.length === 0) {
    return (
      <EmptyArea>
        <p>No existen reglas configuradas</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        isExpandable={true}
        items={dynamicTableRoutes}
		onChange={onChange}
		onCancelAdd={onCancelAdd}
		receivers={receivers}
		isDark={isDark}
		isAdmin={isAdmin}
		isAddMode={isAddMode}
		width={width}
		height={height}
		options={options}
      />
      <ConfirmModal
        isOpen={!!deletingRouteId}
        title="Borrar regla"
        body="Seguro desea borrar esta regla?"
        confirmText="Si"
        icon="exclamation-triangle"
        onConfirm={() => {
          if (deletingRouteId) {
            const newRoutes = deleteRoute(alertRoutes, deletingRouteId);
            //onChange(newRoutes);
            setDeletingRouteId(undefined);
          }
        }}
        onDismiss={() => setDeletingRouteId(undefined)}
      />
    </>
  );
};
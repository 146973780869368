const options = {
  name: 'cola',
  animate: true,
  refresh: 1,
  maxSimulationTime: 10000,
  ungrabifyWhileSimulating: true,
  fit: false,
  padding: 10,
  nodeDimensionsIncludeLabels: false,
  randomize: false,
  avoidOverlap: true,
  handleDisconnected: true,
  edgeLengthVal: 25,
  nodeSpacing: 10,
};

export default options;

import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect } from 'react';
import { NetMonitorTheme2 } from '@grafana/data';
import {
  Button,
  Icon,
  Field,
  FieldArray,
  Form,
  HorizontalGroup,
  IconButton,
  Input,
  InputControl,
  MultiSelect,
  Select,
  Switch,
  useStyles2,
  Badge,
  VerticalGroup,
} from '@grafana/ui';

import { useMuteTimingOptions } from 'app/features/alerting/unified/hooks/useMuteTimingOptions';
import { MatcherFieldValue } from 'app/features/alerting/unified/types/silence-form';
import { AmRouteReceiver, FullFormAmRoute } from './amroutes';
import { 
  labelTypeOptions,
  ruleTypeOptions,
  matcherFieldSimpleOptions, 
  severityFieldOptions, 
  filteredRoutesByType,
  filteredOptionsByType,
  nameFieldOptions 
} from 'app/features/alerting/unified/utils/alertmanager';
import {
  emptyArrayFieldMatcher,
  mapMultiSelectValueToStrings,
  mapSelectValueToString,
  optionalPositiveInteger,
  stringToSelectableValue,
  stringsToSelectableValues,
  commonGroupByOptions,
} from 'app/features/alerting/unified/utils/amroutes';
import { timeOptions } from 'app/features/alerting/unified/utils/time';
import { getFormStyles } from 'app/features/alerting/unified/components/amroutes/formStyles';
import { usePanelCombinedRules } from 'app/features/alerting/unified/hooks/usePanelCombinedRules';
import { DashboardModel, PanelModel } from 'app/features/dashboard/state';
import { RuleListRaw } from  'app/features/alerting/unified/RuleListRaw';

type Option = {
  id: number;
  idx: number;
  alertType: string;
};

export interface AmRoutesExpandedFormProps {
  onCancel: () => void;
  onSave: (data: FullFormAmRoute) => void;
  receivers: AmRouteReceiver[];
  routes: FullFormAmRoute;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  options: [];
}

export const AmRoutesExpandedForm: FC<AmRoutesExpandedFormProps> = ({ 
  onCancel, 
  onSave, 
  receivers, 
  routes, 
  isDark, 
  isAdmin, 
  width, 
  options 
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const rules = RuleListRaw();
  const formStyles = useStyles2(getFormStyles);
  const [groupByOptions, setGroupByOptions] = useState(stringsToSelectableValues(routes.groupBy));
  const [selectedTypes, setSelectedTypes] = useState<Option[]>([]);
  const [alertTypes, setAlertTypes] = useState({});
  const [selectType, setSelecType] = useState();

  useEffect(() => {
    const alertByTypes = {
	  Categoria: filteredRoutesByType(rules, 'netmonitor_folder'),
	  Tipo: ruleTypeOptions,
	  Alarma: filteredOptionsByType(options, 'Alarma'),
	  Alerta: filteredOptionsByType(options, 'Alerta'),
	  Trap: filteredOptionsByType(options, 'Trap'),
	  Resolucion: filteredOptionsByType(options, 'Resolucion'),
	  Reporte: filteredOptionsByType(options, 'Reporte'),
	  Operacion: filteredOptionsByType(options, 'Operacion'),
	  Mantenimiento: filteredOptionsByType(options, 'Mantenimiento'),
	  Severidad: severityFieldOptions,
	}
	setAlertTypes(alertByTypes);
  }, [setAlertTypes, rules, options]);

  const addArrayFieldMatcher: MatcherFieldValue = {
    name: 'Severidad',
    value: 'Informativo',
    operator: '=',
  };

  const handleTypeChange = (id: number, value: string, fieldId: string, fieldValue: string) => {
	const newValues = selectedTypes;
	if (fieldId) {
	  const editIndex = newValues.findIndex((value) => value.idx === fieldId);
	  const alertValue = alertTypes[`${value}`] ? alertTypes[`${value}`][0].value : fieldValue;
	  const newOption: Option = {
	    id: id,
	    idx: fieldId,
	    alertType: value,
	    alertValue: alertValue,
	  };
	  if (editIndex >= 0) {
	   if (newValues[`${editIndex}`].alertType !== value) {
		  newValues[`${editIndex}`] = newOption;
	      setSelectedTypes(newValues);
	    }
	  } else {
	    newValues.splice(id, 0, newOption);
	    setSelectedTypes(newValues);
	  }
	} else if (id >= 0) {
	  const editIndex = newValues.findIndex((value) => value.id === id);
	  let alertValue = '';
	  if (alertTypes[`${value}`] !== undefined) {
	    alertValue = alertTypes[`${value}`][0].value;
	  }
	  const alertIdx = selectedTypes[`${editIndex}`].idx;
	  const newOption: Option = {
	    id: id,
	    idx: alertIdx,
	    alertType: value,
	    alertValue: alertValue,
	  };
	  if (editIndex >= 0) {
		newValues[`${editIndex}`] = newOption;
	    setSelectedTypes(newValues);
	  }
	}
  };

  const muteTimingOptions = useMuteTimingOptions();

  return (
    <Form defaultValues={routes} onSubmit={onSave} className={styles.formContainer}>
      {({ control, register, errors, setValue, watch }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <FieldArray name="object_matchers" control={control}>
            {({ fields, append, remove }) => (
              <>
                <VerticalGroup justify="flex-start" spacing="md">
                  <div className={styles.titleContainer}>
                    <div className={styles.titleTex}>{'Notificar cuando:'}</div>
					<button 
					  type="button" 
					  className={styles.okButton} 
					  onClick={() => {
					    append(addArrayFieldMatcher);
					  }}
					  title={'Agregar criterio a la regla de notificación'}
					>
					  <div className={styles.button_icon}>
						<Icon name="plus-circle-fill" size="lg" />
					  </div>
					  Criterio
					</button>
                  </div>
                  {fields.length === 0 && (
                    <Badge
                      color="orange"
                      className={styles.noMatchersWarning}
                      icon="exclamation-circle"
                      text="Si no ingresa al menos un criterio, esta regla se utilizará en todas las notificaciones."
                    />
                  )}
                  {fields.length > 0 && (
                    <div className={styles.matchersContainer}>
                      {fields.map((field, index) => {
                        const localPath = `object_matchers[${index}]`;
						let nameLabel = [];
						let valueLabel = [];
						nameLabel.splice(index, 0, '');
						valueLabel.splice(index, 0, '');
						const optionIndex = selectedTypes.findIndex((value) => value.idx === field.id);
						const fieldType = selectedTypes[`${optionIndex}`] ? selectedTypes[`${optionIndex}`].alertType : field.name;						
						if (optionIndex < 0) {
						  handleTypeChange(index, field.name, field.id, field.value);
						} else {
						  if (fieldType === 'Categoria') {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Categoria del evento';
						  } else if (fieldType === 'Severidad') {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Nivel de severidad del evento';
						  } else if (fieldType === 'Tipo') {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Tipo de regla';
						  } else if (fieldType === 'Operacion') {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Nombre del grupo que interviene en la operación';
						  } else if (fieldType === 'Mantenimiento') {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Nombre del grupo que mantiene el equipamiento';
						  } else if (fieldType === 'Trap' ) {
						    nameLabel[index] = 'Criterio';
							valueLabel[index] = 'Nombre del TRAP recibido';
						  } else if (fieldType !== '') {
						    nameLabel[index] = 'Criterio';
						    valueLabel[index] = 'Nombre de la regla de procesamiento';
						  }
						}
                        return (
                          <HorizontalGroup key={field.id} align="flex-start" height="65px">
                            <Field label={nameLabel[index]} className={styles.nameContainer}>
                              <InputControl
                                render={({ field: { onChange, ref, ...field } }) => (
                                  <Select
                                    {...field}
                                    onChange={(value) => {
									  handleTypeChange(index, value?.value, field.id, field.value);
									  onChange(value?.value);
									  setSelecType(value?.value);
									}}
                                    options={labelTypeOptions(options)}
                                    aria-label="Name"
                                  />
                                )}
                                defaultValue={optionIndex < 0 ? field.name : selectedTypes[`${optionIndex}`].alertType}
                                control={control}
                                name={`${localPath}.name` as const}
                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                              />
                            </Field>
                            <Field label={''}>
                              <InputControl
                                render={({ field: { onChange, ref, ...field } }) => (
                                  <Select
                                    {...field}
                                    className={styles.matchersOperator}
                                    onChange={(value) => onChange(value?.value)}
                                    options={matcherFieldSimpleOptions}
                                    aria-label="Operator"
                                  />
                                )}
                                defaultValue={field.operator}
                                control={control}
                                name={`${localPath}.operator` as const}
                                rules={{ required: { value: true, message: 'Operador requerido' } }}
                              />
                            </Field>
                            <Field label={valueLabel[index]} className={styles.valueContainer}>
                              <InputControl
                                render={({ field: { onChange, ref, ...field } }) => (
                                  <Select
                                    {...field}
                                    onChange={(value) => onChange(value?.value)}
                                    options={alertTypes[`${fieldType}`]}
                                    aria-label="Value"
                                  />
                                )}
                                defaultValue={optionIndex < 0 ? field.value : selectedTypes[`${optionIndex}`].alertValue}
                                control={control}
                                name={`${localPath}.value` as const}
                                rules={{ required: { value: true, message: 'Campo requerido' } }}
                              />
                            </Field>
                            <IconButton
                              className={styles.removeButton}
                              tooltip="Eliminar criterio"
                              name={'trash'}
                              onClick={() => remove(index)}
                            />
                          </HorizontalGroup>
                        );
                      })}
                    </div>
                  )}
                </VerticalGroup>
              </>
            )}
          </FieldArray>
          <Field label="Utilizando el Canal de Notificación:">
            {/* @ts-ignore-check: react-hook-form made me do this */}
            <InputControl
              render={({ field: { onChange, ref, ...field } }) => (
                <Select
                  aria-label="Contact point"
                  {...field}
                  className={formStyles.input}
                  onChange={(value) => onChange(mapSelectValueToString(value))}
                  options={receivers}
                />
              )}
              control={control}
              name="receiver"
            />
          </Field>
          <Field
            label="Suspendiento las notificaciones durante:"
            data-testid="am-mute-timing-select"
            invalid={!!errors.muteTimeIntervals}
          >
            <InputControl
              render={({ field: { onChange, ref, ...field } }) => (
                <MultiSelect
                  aria-label="Mute timings"
                  {...field}
                  className={formStyles.input}
                  onChange={(value) => onChange(mapMultiSelectValueToStrings(value))}
                  options={muteTimingOptions}
                />
              )}
              control={control}
              name="muteTimeIntervals"
            />
          </Field>
          <div className={styles.buttonGroup}>
			<button 
			  type="submit"
			  className={styles.okButton} 
			  title={'Guardar modificaciones'}
			>
			  <div className={styles.button_icon}>
				<Icon name="save" size="lg" />
			  </div>
			 Guardar
			</button>
			<button 
			  type="button" 
			  className={styles.cancelButton} 
			  onClick={onCancel}
			  title={'Cerrar sin modificar'}
			>
			  <div className={styles.button_icon}>
				<Icon name="times" size="lg" />
			  </div>
			  Cancelar
			</button>
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const borderColor = isDark ? '#23282E' : '#D8DFE9';
  const background = isDark ? '#141618' : '#F4F9FF'
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const okButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    formContainer: css`
      max-width: 100%;
    `,
    addMatcherBtn: css`
      margin-bottom: 4px;
    `,
    matchersContainer: css`
      background-color: ${background};
      padding: 10px 0px 0px 10px;
      width: ${width}px;
      border-radius: 10px;
      margin-bottom: 10px;
    `,
    nameContainer: css`
      width: ${width * 0.25}px;
	  min-width: 150px;
    `,
    matchersOperator: css`
      width: ${width * 0.2}px;
	  min-width: 100px;
    `,
    valueContainer: css`
	  width: ${width * 0.5}px;
	  min-width: 300px;
    `,
    nestedPolicies: css`
      margin-top: 2px;
    `,
    removeButton: css`
      margin-left: 4px;
      margin-top: 10px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    noMatchersWarning: css`
      padding: 5px 10px;
      margin-bottom: 16px;
    `,
    titleContainer: css`
      margin-top: 16px;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      height: 24px;
    `,
    titleTex: css`
      margin-right: 16px;
      color: ${fontColor};
      font-size: 13px;
      font-weight: 500;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};

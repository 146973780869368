import React, { FC, useMemo } from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { ActionIconBig } from 'app/features/alerting/unified/components/rules/ActionIcon';
import { Alert, PromRuleWithLocation } from 'app/types/unified-alerting';
import { GroupedRules } from './types';
import { filterAlerts } from './utils';

type GroupedModeProps = {
  rules: PromRuleWithLocation[];
  options: UnifiedAlertListOptions;
  isAdmin: boolean;
  width: number;
};

const GroupedModeView: FC<GroupedModeProps> = ({ rules, options, isAdmin, width }) => {
  const styles = useStyles2(getStyles);
  const groupBy = options.groupBy;
  const alerts = [];
  const alarmTitles = {
    alarma: 'Alarma',
    alerta: 'Alerta',
    trap: 'Trap',
    resolucion: 'Resolucion',
    reporte: 'Reporte',
  };

  const alarmSubTitles = {
    alarma: 'Notificando Alarma activa...',
    alerta: 'Notificando Alerta activa...',
    trap: 'Notificando TRAP recibido...',
    resolucion: 'Notificando Resolución...',
    reporte: 'Generando Reporte...',
  };

  const alarmStyles = {
    alarma: 'tdTableAlerting',
    alerta: 'tdTableAlerting',
    trap: 'tdTableTrap',
    resolucion: 'tdTableOk',
    reporte: 'tdTable',
  };

  const groupedRules = useMemo<GroupedRules>(() => {
    const groupedRules = new Map<string, Alert[]>();

    const hasInstancesWithMatchingLabels = (rule: PromRuleWithLocation) =>
      groupBy ? alertHasEveryLabel(rule, groupBy) : true;

    const matchingRules = rules.filter(hasInstancesWithMatchingLabels);
    matchingRules.forEach((rule: PromRuleWithLocation) => {
      (rule.rule.alerts ?? []).forEach((alert) => {
        const mapKey = createMapKey(groupBy, alert.labels);
        const existingAlerts = groupedRules.get(mapKey) ?? [];
        groupedRules.set(mapKey, [...existingAlerts, alert]);
      });
    });
    const filteredGroupedRules = Array.from(groupedRules.entries()).reduce((acc, [groupKey, groupAlerts]) => {
      const filteredAlerts = filterAlerts(options, groupAlerts);
      if (filteredAlerts.length > 0) {
        acc.set(groupKey, filteredAlerts);
      }

      return acc;
    }, new Map<string, Alert[]>());

    return filteredGroupedRules;
  }, [groupBy, rules, options]);

  const rulesGrouped = Array.from(groupedRules).map(([key, alerts]) => ([key, alerts]));

  rulesGrouped.forEach(([key, alarmsArray]) => {
	if (alarmsArray.length > 0) {
      alarmsArray.forEach((alarm, index) => {
		const alert = {
		  id: index,
		  activeAt: alarm.activeAt,
		  ruleDate: formatDateTime(alarm.activeAt),
		  signSummary: alarm.annotations.summary,
		  signName: '',
		  type: alarm.labels.Tipo ?? '',
		  state: alarm.state ?? '',
		  style: styles.tdTable,
		  ruleState: 'Procesando ...',
		  ruleName: alarm.labels.alertname ?? '',
		  ruleCategory: alarm.labels.netmonitor_folder ?? '',
		};
		const alarmType = alert.type.toLowerCase();
		if (alarmTitles.hasOwnProperty(alarmType)) {
		  alert.signName = alarm.labels[`${alarmTitles[alarmType]}`] ?? '';
		  alert.style = alarmStyles[`${alarmType}`] ?? 'tdTable';
		}
		if (alert.state === 'Alerting') {
		  alert.ruleState = alarmSubTitles[`${alarmType}`] ?? 'Procesando ...';
		}
		alerts.push(alert);
      });
    }
  });

  return (
	<div className={styles.tableContainer}>
	  <table className={styles.table} data-testid="rules-table">
		<colgroup>
		  <col />
		  <col />
		  {width > 550 && <col />}
		  <col />
		  {width > 550 && <col />}
		  {width > 550 && <col />}
		</colgroup>
		<thead className={styles.thead}>
		  <tr>
			<th className={styles.thTable}>Proceso</th>
			<th className={styles.thTable}>Firma</th>
			{width > 550 && <th className={styles.thTable}>Resumen</th>}
			<th className={styles.thTable}>Estado</th>
			{width > 550 && <th className={styles.thTable}>Categoria</th>}
			{width > 550 && <th className={styles.thTable}>Proceso activo desde</th>}
		  </tr>
		</thead>
		<tbody>
		  {!alerts.length && (
			<tr className={styles.evenRow}>
			  <td colSpan={8}>No existen Procesos definidos</td>
			</tr>
		  )}
		  {alerts.map((alarm, idx) => (
			<tr key={alarm.name} className={idx % 2 === 0 ? styles.evenRow : styles.oddRow}>
			  <td className={styles.tdTable}>{alarm.ruleName}</td>
			  <td className={styles.tdTable}>{alarm.signName}</td>
			  {width > 550 && <td className={styles.tdTable}>{alarm.signSummary}</td>}
			  <td className={styles[`${alarm.style}`]}>{alarm.ruleState}</td>
			  {width > 550 && <td className={styles.tdTable}>{alarm.ruleCategory}</td>}
			  {width > 550 && <td className={styles.tdTable}>{alarm.ruleDate}</td>}
			</tr>
		  ))}
		</tbody>
	  </table>
	</div>
  );
};

function formatDateTime(date) {
  const fecha = new Date(date);
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
  const anio = fecha.getFullYear();
  const horas = fecha.getHours().toString().padStart(2, '0');
  const minutos = fecha.getMinutes().toString().padStart(2, '0');
  const segundos = fecha.getSeconds().toString().padStart(2, '0');
  return `${dia}-${mes}-${anio} ${horas}:${minutos}:${segundos}`;
}

function createMapKey(groupBy: string[], labels: Record<string, string>): string {
  return new URLSearchParams(groupBy.map((key) => [key, labels[key]])).toString();
}

function parseMapKey(key: string): Array<[string, string]> {
  return [...new URLSearchParams(key)];
}

function alertHasEveryLabel(rule: PromRuleWithLocation, groupByKeys: string[]) {
  return groupByKeys.every((key) => {
    return (rule.rule.alerts ?? []).some((alert) => alert.labels[key]);
  });
}

const getStyles = (theme: NetMonitorTheme2) => {
  const isDark = config.theme.isDark;
  const textColor = isDark ? '#D8DFE9' : '#23282E';
  const textColorBlue = isDark ? '#936BFF' : '#5D2BE9';
  const textColorGreen = isDark ? '#58A956' : '#24AB00';
  const textColorRed = isDark ? '#F74545' : '#FB3333';
  return {
    tableContainer: css`
      width: 100%;
      padding: 1px;
    `,
    table: css`
      width: calc(100% - 12px);
      background-color: ${theme.v1.colors.bg2};
	  color: ${textColor};
      margin: 8px 10px 8px 10px;
    `,
    thead: css`
      height: 24px;
      font-size: 12px;
      font-weight: 600;
	  font-family: Roboto, Helvetica, Arial, sans-serif;
      background-color: ${theme.v1.colors.bg2};
      border-bottom: 1px solid ${theme.colors.border.weak};
    `,
    thTable: css`
      font-weight: 600;
      padding-left: 6px;
    `,
    tdTable: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColor};
    `,
    tdTableAlerting: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColorRed};
    `,
    tdTableTrap: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColorBlue};
    `,
    tdTableOk: css`
      font-weight: 400;
      padding: 6px;
	  color: ${textColorGreen};
    `,
    evenRow: css`
      background-color: ${theme.v1.colors.bg1};
      font-size: 12px;
	  height: 36px;
    `,
    oddRow: css`
      background-color: ${theme.v1.colors.bg1};
      font-size: 12px;
	  height: 36px;
    `,
  };
};

export default GroupedModeView;

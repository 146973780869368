import { SelectableValue } from '@grafana/data';

export interface ThresholdManagerPanelOptions {
  thresholdAsset: string;
  thresholdType: string;
  thresholdIsBoolean: string;
  thresholdState: string;
  thresholdPercentFail: string;
  thresholdDual: string;
  thresholdBelow: string;
  thresholdUp: string;
  thresholdDown: string;
  notifRepeat: string;
  thresholdSource: string;
  thresholdDate: string;
  thresholdUser: string;
  api: string;
  variableToTrack: string;
  assetList: string;
  assetTypes: string;
  thresholdTypes: string;
  assetVariable: string;
  assetTypesVariable: string;
  pluginTitle: string;
  successMessage: string;
  alertmanager: string;
  editorCanAdmin: boolean;
  showTitle: boolean;
  showAssetSelector: boolean;
  error4: string;
}

export const valueToPercentages: SelectableValue[] = [
  { label: 'Cumple 1 de 5 muestras', description: '1 muestra en ultimas 5 cruza los umbrales', value: '80' },
  { label: 'Cumplen 2 de 5 muestras', description: '2 muestras en ultimas 5 cruzan los umbrales', value: '60' },
  { label: 'Cumplen 3 de 5 muestras', description: '3 muestras en ultimas 5 cruzan los umbrales', value: '40' },
  { label: 'Cumplen 4 de 5 muestras', description: '4 muestras en ultimas 5 cruzan los umbrales', value: '20' },
  { label: 'Cumplen 5 de 5 muestras', description: 'Las 5 últimas muestras cruzan los umbrales', value: '0' },
];

export const valueToSources: SelectableValue[] = [
  { label: 'Todos los activos', description: 'Umbral definido por defecto para todos los activos', value: 'default' },
  { label: 'Este producto', description: 'Umbral definido por defecto para esta Marca/Modelo de activo', value: 'product' },
  { label: 'Este activo', description: 'Umbral definido para este activo en particular', value: 'asset' },
  { label: 'Este componente', description: 'Umbral definido para este componente en particular', value: 'variable' },
];
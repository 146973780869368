import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { NetMonitorTheme2 } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { HelpData } from './L1OyMManager';
import { HelpGroup } from './HelpGroup';
import { valueToSubtype } from './types';

export const helpManagerPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const isNetMonitorAdmin = contextSrv.isNetMonitorAdmin;
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : isNetMonitorAdmin;
  const userName = contextSrv.user.name;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen elementos';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);
  const uidVariableValue = options.uidVariable ? getTemplateSrv().replace(`$${options.uidVariable}`) : '';
  const alarmIdVariableValue = options.canAddNotes && options.alarmIdVariable ?
    getTemplateSrv().replace(`$${options.alarmIdVariable}`) : '';
  const typeVariableValue = options.typeVariable ? getTemplateSrv().replace(`$${options.typeVariable}`) : '';
  const hashVariableValue = options.hashVariable ? getTemplateSrv().replace(`$${options.hashVariable}`) : '';
  const hashArray = hashVariableValue ? decodeURIComponent(hashVariableValue) : '[]';
  const [actualUidValue, setActualUidValue] = useState(uidVariableValue);
  const [actualAlarmIdValue, setActualAlarmIdValue] = useState(alarmIdVariableValue);
  const [actualTypeValue, setActualTypeValue] = useState(typeVariableValue);
  const [elementsConfigured, setElementsConfigured] = useState<HelpData[]>([]);
  const filterToTrack = replaceVariables(options.variableToTrack);
  const [valueTracked, setValueTracked] = useState(filterToTrack);
  const [renderCount, setRenderCount] = useState(0);

  const isAddNoteMode = options.canAddNotes && typeVariableValue === 'notes' && uidVariableValue === 'newId' ? true : false;
  const isViewNoteMode = typeVariableValue === 'notes' && uidVariableValue !== 'newId' ? true : false;

  const memoizedOptions = useMemo(() => ({ 
    data: data,
  }), [data]);

  const styles = useStyles2(getStyles(width, height));

  const subTypeToType = (value: string) => {
	const valueSelected = valueToSubtype.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return 'Unknown';
	}
  }

  const newElementsConfigured: HelpData[] = [];
  if (isAddNoteMode) {
    const d = Date.now();
	const newId = generarID();
	let newNote = {
	  id: 0,
	  uid: newId,
	  title: 'Nueva Nota',
	  docId: newId,
	  docType: 'notes',
	  subType: 'alarm',
	  status: 'in_use',
	  state: true,
	  category: 'L1',
	  chapter: '',
	  section: '',
	  technicalDetails: '',
	  content: '',
	  lastEditor: userName,
	  editionNotes: '',
	  mediaData: '',
	  relatedArticles: '',
	  references: '',
	  dashboardId: '',
	  hashIds: hashArray,
	  alarmId: alarmIdVariableValue,
	  user: userName,
	  created: '',
	  updated: '',
	  version: 0,
	  wasChange: false,
	};
	newElementsConfigured.push(newNote);
  } else {
    memoizedOptions.data.series.forEach(series => {
	  const helpDocs: GraphSeriesValue[] = series.fields[0] ? series.fields[0].values.toArray() : [];
	  for (let i = 0; i < helpDocs.length; i++) {
	    let newElement = {
		  id: i,
		  uid: series.fields.find(field => field.name === options.uid)?.values.get(i),
		  title: series.fields.find(field => field.name === options.title)?.values.get(i),
		  docId: '',
		  docType: '',
		  subType: '',
		  status: 'under_construction',
		  state: false,
		  category: '',
		  chapter: '',
		  section: '',
		  technicalDetails: series.fields.find(field => field.name === options.technicalDetails)?.values.get(i),
		  content: '',
		  lastEditor: '',
		  editionNotes: '',
		  mediaData: '',
		  relatedArticles: '',
		  references: series.fields.find(field => field.name === options.reference)?.values.get(i),
		  dashboardId: '',
		  hashIds: '',
		  alarmId: '',
		  user: series.fields.find(field => field.name === options.user)?.values.get(i),
		  created: series.fields.find(field => field.name === options.created_at)?.values.get(i),
		  updated: series.fields.find(field => field.name === options.updated_at)?.values.get(i),
		  version: Number(series.fields.find(field => field.name === options.version)?.values.get(i)),
		  wasChange: false,
	    };
	    const jsonObject = JSON.parse(String(newElement.technicalDetails));
	    newElement.docId = jsonObject.technical_details.docId || '';
	    newElement.status = jsonObject.technical_details.status || 'disable';
	    newElement.state = newElement.status === 'disable' ? false : true;
	    newElement.subType = jsonObject.technical_details.type || '';
	    newElement.dashboardId = jsonObject.technical_details.dashboard_id || '';
	    const docType = subTypeToType(newElement.subType);
	    newElement.docType = docType;
	    if (docType === 'notes') {
	      newElement.alarmId = jsonObject.technical_details.alarm_id || '';
	    }
	    newElement.category = jsonObject.technical_details.category || '';
	    newElement.chapter = jsonObject.technical_details.chapter || '';
	    newElement.section = jsonObject.technical_details.section || '';
	    newElement.content = jsonObject.technical_details.content.markdownContent || '';
	    newElement.relatedArticles = jsonObject.technical_details.related_articles || '';
	    newElement.references = jsonObject.technical_details.references || '';
	    newElement.lastEditor = jsonObject.technical_details.edition_data.last_editor || '';
	    newElement.editionNotes = jsonObject.technical_details.edition_data.notes || '';
	    newElement.mediaData = jsonObject.technical_details.edition_data.media.images || '';
	    const created_at = newElement.created;
	    newElement.created = moment(created_at).format('DD/MM/YYYY HH:mm:ss');
	    const updated_at = newElement.updated;
	    newElement.updated = moment(updated_at).format('DD/MM/YYYY HH:mm:ss');
	    newElementsConfigured.push(newElement);
	  }
    });
  }

  const pluginVariables = [
    pluginApi,
	okMessage,
	noAlertMessage,
	options.saniticeDoc,
	options.enablePreview,
	options.previewOnly,
	options.uidVariable,
	options.viewerMode,
	options.canAddNotes,
	isAddNoteMode,
	options.alarmIdVariable,
	options.typeVariable,
	options.hashVariable,
	isViewNoteMode,
  ];

  if (
    renderCount === 0 || 
    valueTracked !== filterToTrack || 
    uidVariableValue !== actualUidValue || 
	alarmIdVariableValue !== actualAlarmIdValue ||
	typeVariableValue !== actualTypeValue
  ) {
    let elementFiltered = newElementsConfigured;
	if (uidVariableValue !== '' || alarmIdVariableValue !== '') {
	  elementFiltered = newElementsConfigured.filter(element => element.uid === uidVariableValue);
	  if (uidVariableValue !== 'newId' && alarmIdVariableValue !== '' && typeVariableValue === 'notes') {
	    elementFiltered = newElementsConfigured.filter(element => element.alarmId === alarmIdVariableValue);
	  }
    }
	let elementInUse = elementFiltered;
	if (options.showOnlyInUse) {
	  elementFiltered = elementFiltered.filter(element => element.status === 'in_use');
	} else {
	  elementFiltered = elementFiltered.filter(element => element.version > 0);
	}
	let elementsToShow = elementFiltered;
	if (!isNetMonitorAdmin) {
	  if (typeVariableValue === 'notes' || typeVariableValue === 'help') {
	    elementsToShow = elementFiltered.filter(element => element.docType === typeVariableValue);
	  } else {
	    elementsToShow = elementFiltered.filter(element => element.docType !== 'internal');
	  }
	} else if (typeVariableValue === 'notes' || typeVariableValue === 'help' || typeVariableValue === 'internal') {
	  elementsToShow = elementFiltered.filter(element => element.docType === typeVariableValue);
	}
	if (elementsToShow) {
	  setElementsConfigured(elementsToShow);
	} else {
	  setElementsConfigured([]);
	}
	setValueTracked(filterToTrack);
	setActualUidValue(uidVariableValue);
	setActualAlarmIdValue(alarmIdVariableValue);
	setActualTypeValue(typeVariableValue);
	setRenderCount(renderCount + 1);
  }

  const noAlertsMessage = elementsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <HelpGroup
	    id={id + '_' + renderCount}
	    showTitle={options.showTitle}
		showSelector={options.showSelector}
		showTable={options.showTableMode}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    elements={elementsConfigured}
		pluginVariables={pluginVariables}
		onSaveChanges={(elementSaved) => {
		  const actualElements = elementsConfigured;
		  const element = elementSaved.find(element => element.updated === '');
		  const index = element ? element.id : null;
		  if (index !== null) {
			const d = Date.now();
			const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
			let newElement = element;
			newElement.updated = date;
			const jsonContent = JSON.parse(String(element.content));
			newElement.content = jsonContent.markdownContent || '';
			actualElements[index] = newElement;
		    setElementsConfigured(actualElements);			
		  }
		  return actualElements;
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 8; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
};

const getStyles = (width: number, height: number) => {
  return (theme: NetMonitorTheme2) => ({
    section: css`
      margin: 4px;
	  width: ${width}px;
	  height: ${height}px;
    `,
  });
};

import React from 'react';
import { config } from '@grafana/runtime';
import { TableContent, TableHeader } from 'types';

interface NodeStatisticsProps {
  nodeList: TableContent[];
  nodeList2: TableContent[];
  linkAsset: string;
  linkSite: string;
  noDataText: string;
  header: TableHeader[];
}

function showStatisticsTable(
  noDataText: string,
  receiving: TableContent[],
  link_asset: string,
  link_site: string,
  header: TableHeader[]
) {
  let isDark = config.theme.isDark;
  var table_header = 'table--selection';
  var table_tr = 'table--selection--head';
  var table_th = 'table--th--selectionSmall';
  var table_th_left = 'table--th--selectionSmall left';
  var table_td = 'table--td--selection';
  var table_td_left = 'table--td--selection left';
  if (isDark) {
    table_header = 'table--selection--dark';
    table_tr = 'table--selection--head table--selection--head--dark';
    table_th = 'table--th--selectionSmall table--th--selectionSmall--dark';
    table_th_left = 'table--th--selectionSmall left table--th--selectionSmall--dark';
    table_td = 'table--td--selection table--td--selection--dark';
    table_td_left = 'table--td--selection table--td--selection--dark left';
  }

  let metric1 = false;
  let metric2 = false;
  let metric3 = false;
  let metric4 = false;
  let metric5 = false;
  let metric6 = false;
  if (header[0].site_header !== '-') {
    metric1 = true;
  }
  if (header[0].name_header !== '-') {
    metric2 = true;
  }
  if (header[0].label_header !== '-') {
    metric3 = true;
  }
  if (header[0].traffic_header !== '-') {
    metric4 = true;
  }
  if (header[0].metricOne_header !== '-') {
    metric5 = true;
  }
  if (header[0].metricTwo_header !== '-') {
    metric6 = true;
  }
  var metric1_th = table_th_left;
  var metric2_th = table_th_left;
  var metric3_th = table_th_left;
  var metric4_th = table_th;
  var metric5_th = table_th;
  var metric6_th = table_th;
  var metric1_td = table_td_left;
  var metric2_td = table_td_left;
  var metric3_td = table_td_left;
  var metric4_td = table_td;
  var metric5_td = table_td;
  var metric6_td = table_td;
  if (!metric1) {
    metric1_th = 'table--th--hide';
    metric1_td = 'table--td--hide';
  }
  if (!metric2) {
    metric2_th = 'table--th--hide';
    metric2_td = 'table--td--hide';
  }
  if (!metric3) {
    metric3_th = 'table--th--hide';
    metric3_td = 'table--td--hide';
  }
  if (!metric4) {
    metric4_th = 'table--th--hide';
    metric4_td = 'table--td--hide';
  }
  if (!metric5) {
    metric5_th = 'table--th--hide';
    metric5_td = 'table--td--hide';
  }
  if (!metric6) {
    metric6_th = 'table--th--hide';
    metric6_td = 'table--td--hide';
  }
  if (receiving.length > 0) {
    return (
      <table className={table_header}>
        {header.map((table_Header: TableHeader) => (
          <tr key={table_Header.site_header} className={table_tr}>
            <th className={metric1_th}>{table_Header.site_header}</th>
            <th className={metric2_th}>{table_Header.name_header}</th>
            <th className={metric3_th}>{table_Header.label_header}</th>
            <th className={metric4_th}>{table_Header.traffic_header}</th>
            <th className={metric5_th}>{table_Header.metricOne_header}</th>
            <th className={metric6_th}>{table_Header.metricTwo_header}</th>
          </tr>
        ))}
        {receiving.map((node: TableContent) => (
          <tr key={node.site}>
            <td className={metric1_td} title="Click para más información">
              <b>
                <a href={link_site + node.site}>{node.site}</a>
              </b>
            </td>
            <td className={metric2_td} title="Click para más información">
              <b>
                <a href={link_asset + node.name}>{node.name}</a>
              </b>
            </td>
            <td className={metric3_td}>
              <b>{node.label}</b>
            </td>
            <td className={metric4_td}>
              {node.traffic_in} / {node.traffic_out}
            </td>
            <td className={metric5_td}>
              <b>{node.metricOneActual}</b> / <b>{node.metricOneMax}</b>
            </td>
            <td className={metric6_td}>
              <b>{node.metricTwoActual}</b> / <b>{node.metricTwoMax}</b>
            </td>
          </tr>
        ))}
      </table>
    );
  } else {
    return <div className="no-data--selection">{noDataText}</div>;
  }
}

export const NodeStatistics: React.FC<NodeStatisticsProps> = ({
  nodeList,
  nodeList2,
  noDataText,
  linkAsset,
  linkSite,
  header,
}) => {
  const nodos = nodeList.concat(nodeList2);
  var link_asset = './';
  var link_site = './';
  if (linkAsset && linkAsset.length > 0) {
    link_asset = linkAsset;
  }
  if (linkSite && linkSite.length > 0) {
    link_site = linkSite;
  }
  return <div className="statistics">{showStatisticsTable(noDataText, nodos, link_asset, link_site, header)}</div>;
};

import { css } from '@emotion/css';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, FieldErrors } from 'react-hook-form';

import { NetMonitorTheme2, SelectableValue } from '@grafana/data';
import { Alert, Icon, Field, InputControl, Select, useStyles2 } from '@grafana/ui';
import { NotifierDTO } from 'app/types';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { ChannelValues, CommonSettingsComponentType } from 'app/features/alerting/unified/types/receiver-form';

import { ChannelOptions } from './ChannelOptions';
import { CollapsibleSection } from './CollapsibleSection';

interface Props<R> {
  defaultValues: R;
  pathPrefix: string;
  notifiers: NotifierDTO[];
  onDuplicate: () => void;
  onTest?: () => void;
  commonSettingsComponent: CommonSettingsComponentType;
  secureFields?: Record<string, boolean>;
  errors?: FieldErrors<R>;
  onDelete?: () => void;
  isEditable?: boolean;
  isTestable?: boolean;
  isAdmin: boolean;
  isDark: boolean;
  width: number;
}

export function ChannelSubForm<R extends ChannelValues>({
  defaultValues,
  pathPrefix,
  onDuplicate,
  onDelete,
  onTest,
  notifiers,
  errors,
  secureFields,
  commonSettingsComponent: CommonSettingsComponent,
  isEditable = true,
  isTestable,
  isAdmin,
  isDark,
  width,
}: Props<R>): JSX.Element {
  const styles = useStyles2(getStyles(isDark, width));
  const name = (fieldName: string) => `${pathPrefix}${fieldName}`;
  const { control, watch, register, trigger, formState, setValue } = useFormContext();
  const selectedType = watch(name('type')) ?? defaultValues.type;
  const { loading: testingReceiver } = useUnifiedAlertingSelector((state) => state.testReceivers);

  useEffect(() => {
    register(`${pathPrefix}.__id`);
    register(`${pathPrefix}.secureFields`);
  }, [register, pathPrefix]);

  const [_secureFields, setSecureFields] = useState(secureFields ?? {});

  const onResetSecureField = (key: string) => {
    if (_secureFields[key]) {
      const updatedSecureFields = { ...secureFields };
      delete updatedSecureFields[key];
      setSecureFields(updatedSecureFields);
      setValue(`${pathPrefix}.secureFields`, updatedSecureFields);
    }
  };

  const typeOptions = useMemo(
    (): SelectableValue[] =>
      notifiers
        .map(({ name, type }) => ({
          label: name,
          value: type,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [notifiers]
  );

  const handleTest = async () => {
    await trigger();
    const isValid = Object.keys(formState.errors).length === 0;

    if (isValid && onTest) {
      onTest();
    }
  };

  const notifier = notifiers.find(({ type }) => type === selectedType);
  const mandatoryOptions = notifier?.options.filter((o) => o.required);
  const optionalOptions = notifier?.options.filter((o) => !o.required);
  const contactPointTypeInputId = `contact-point-type-${pathPrefix}`;

  return (
    <div className={styles.wrapper} data-testid="item-container">
      <div className={styles.topRow}>
        <div style={{ width: '50%' }}>
          <Field 
		    label="Medio" 
			htmlFor={contactPointTypeInputId}
			data-testid={`${pathPrefix}type`}
			className={styles.fields}
		  >
            <InputControl
              name={name('type')}
              defaultValue={defaultValues.type}
              render={({ field: { ref, onChange, ...field } }) => (
                <Select
				  className={styles.select}
                  disabled={!isAdmin}
                  inputId={contactPointTypeInputId}
                  {...field}
                  options={typeOptions}
                  onChange={(value) => onChange(value?.value)}
                />
              )}
              control={control}
              rules={{ required: true }}
            />
          </Field>
        </div>
        <div className={styles.buttons}>
          {isEditable && isAdmin && onDelete && (
			<button 
			  type="button" 
			  className={styles.cancelButton}
			  onClick={() => onDelete()}
			  title={'Eliminar medio'}
			>
			  <div className={styles.button_icon}>
				<Icon name="trash-circle-fill" size="lg" />
			  </div>
			  Borrar
			</button>
          )}
          {isTestable && onTest && (
			<button 
			  type="button"
			  disabled={testingReceiver}
			  className={styles.testButton} 
			  onClick={() => handleTest()} 
			  title={'Ejecutar prueba con los parámetros configurados'}
			>
			  <div className={styles.button_icon}>
				<Icon name={testingReceiver ? 'loading' : 'play-fill'} size="lg" />
			  </div>
			  Probar Medio
			</button>
          )}
          {isEditable && isAdmin && (
			<button 
			  type="button" 
			  className={styles.button} 
			  onClick={() => onDuplicate()} 
			  title={'Clonar Medio y su configuración'}
			>
			  <div className={styles.button_icon}>
			    <Icon name="copy-fill" size="lg" />
			  </div>
			  Copiar
			</button>
          )}
        </div>
      </div>
      {notifier && (
        <div className={styles.innerContent}>
          <ChannelOptions<R>
            defaultValues={defaultValues}
            selectedChannelOptions={mandatoryOptions?.length ? mandatoryOptions! : optionalOptions!}
            secureFields={_secureFields}
            errors={errors}
            onResetSecureField={onResetSecureField}
            pathPrefix={pathPrefix}
            readOnly={!isAdmin}
			mediaType={selectedType}
          />
          {!!(mandatoryOptions?.length && optionalOptions?.length) && isAdmin && (
            <CollapsibleSection 
			  label={`Configuración adicional del servicio de ${notifier.name}`}
			  className={styles.section}
			>
              {notifier.info !== '' && (
                <Alert title="" severity="info">
                  {notifier.info}
                </Alert>
              )}
			  <div className={styles.settingsContent}>
                <ChannelOptions<R>
				  defaultValues={defaultValues}
                  selectedChannelOptions={optionalOptions!}
                  secureFields={_secureFields}
                  onResetSecureField={onResetSecureField}
                  errors={errors}
                  pathPrefix={pathPrefix}
                  readOnly={!isEditable}
				  mediaType={selectedType}
                />
			  </div>
            </CollapsibleSection>
          )}
        </div>
      )}
    </div>
  );
}

const getStyles = (isDark: boolean, width: number) => {
  const buttonBorder = isDark ? '#23282E' : '#E6E9ED';
  const fontColor = isDark ? '#E6E9ED' : '#23282E';
  const testButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const buttonBack = isDark ? '#1B2733' : '#EFF4FA';
  const cancelButtonBack = isDark ? '#F74545' : '#FB3333';
  const borderColor = isDark ? '#585A5E' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    buttons: css`
      display: flex;
      align-items: center;
	  margin-right: 5%;

	  & > * + * {
        margin-left: ${theme.spacing(1)};
      }
    `,
    fields: css`
      margin-top: ${theme.spacing(2)};
	  margin-left: ${theme.spacing(1)};
	  font-size: 12px;
	  width: ${width * 0.5}};
    `,
    topRow: css`
      max-width: ${width * 0.8}px;
	  margin-left: ${theme.spacing(1)};
    `,
	innerContent: css`
      max-width: ${width * 0.8}px;
	  margin-left: 8px;
    `,
    wrapper: css`
      margin: ${theme.spacing(2, 0)};
      padding: ${theme.spacing(1)};
      border: solid 1px ${borderColor};
      border-radius: 10px;
      max-width: ${width * 0.9}};
	  font-size: 11px;
    `,
    section: css`
      margin-left: 0px;
	  font-size: 12px;
      font-weight: 500;
  	  max-width: ${width * 0.8}px;
    `,
    input: css`
      font-size: 12px;
      font-weight: 500;
  	  border-radius: 10px;
  	  border: 1px solid ${borderColor};
    `,
    select: css`
      font-size: 12px;
      font-weight: 500;
  	  border-radius: 10px;
  	  border: 1px solid ${borderColor};
	  width: ${width * 0.5}};
    `,
    settingsContent: css`
      margin-left: 35px;
    `,
    button: css`
      background-color: ${buttonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${borderColor};
      color: ${fontColor};
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: 3px;
      margin-right: 3px;
	  padding: 0px 10px 0px 0px;
	  height: 24px;
    `,
    testButton: css`
      background-color: ${testButtonBack};
      border: 1px solid ${borderColor};
      box-shadow: 4px 2px 4px 0px ${borderColor};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: 3px;
      margin-right: 3px;
	  padding: 0px 10px 0px 0px;
	  height: 24px;
    `,
    cancelButton: css`
      background-color: ${cancelButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${borderColor};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
    topRow: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
    channelSettingsHeader: css`
      margin-top: ${theme.spacing(2)};
    `,
  });
};

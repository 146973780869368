// @ts-nocheck
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { Chrono } from 'react-chrono';
import { Modal, View } from '@material-ui/core';
import Select, { components } from 'react-select';
import { Icon } from '@grafana/ui';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { AlarmManagerOptions, alarmData, alarmlineItems, optionsData } from 'types';
import { moment } from 'moment';
import { changeUrl, getNetMonitorVariableValue } from './utils';
import './css/AlarmManagerPanel.css';
import { config, SystemJS } from '@grafana/runtime';
import { contextSrv } from 'app/core/services/context_srv';
import axios from 'axios';
import { AppEvents } from '@grafana/data';

interface Props extends PanelProps<AlarmManagerOptions> {}

export const AlarmManagerPanel: React.FC<Props> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const moment = require('moment');
  const renderCount = useRef(0);

  const [error1, setError1 ] = useState('');
  const [error2, setError2 ] = useState('');
  const [error3, setError3 ] = useState('');
  const [error4, setError4 ] = useState('');
  const [pluginApi, setPluginApi] = useState('');
  const [pluginTitle, setPluginTitle] = useState('');
  const [assetFilterValue, setAssetFilterValue] = useState(options.filterDefaultValue);
  const [typeFilterValue, setTypeFilterValue] = useState(options.filterDefaultValue);
  const [alarmFilterValue, setAlarmFilterValue] = useState(options.filterDefaultValue);
  const [readFilterValue, setReadFilterValue] = useState(options.filterDefaultValue);
  const [statusFilterValue, setStatusFilterValue] = useState(options.filterDefaultValue);
  const [assetOptionsEnable, setAssetOptionsEnable] = useState(false);
  const [typeOptionsEnable, setTypeOptionsEnable] = useState(false);
  const [alarmOptionsEnable, setAlarmOptionsEnable] = useState(false);
  const [readOptionsEnable, setReadOptionsEnable] = useState(false);
  const [statusOptionsEnable, setStatusOptionsEnable] = useState(false);

  if (!initialCalculationsDone) {
    renderCount.current = renderCount.current + 1;
	setError1(replaceVariables(options.error1));
    setError2(replaceVariables(options.error2));
    setError3(replaceVariables(options.error3));
    setError4(replaceVariables(options.error4));
    setPluginApi(replaceVariables(options.api));
    setPluginTitle(replaceVariables(options.pluginTitle));

	if (options.assetVariable !== undefined && options.assetVariable !== null && options.assetVariable !== '') {
	  setAssetFilterValue(getNetMonitorVariableValue(options.assetVariable));
	  setAssetOptionsEnable(true);
	}
	if (options.typeVariable !== undefined && options.typeVariable !== null && options.typeVariable !== '') {
	  setTypeFilterValue(getNetMonitorVariableValue(options.typeVariable));
	  setTypeOptionsEnable(true);
	}
	if (options.alarmVariable !== undefined && options.alarmVariable !== null && options.alarmVariable !== '') {
	  setAlarmFilterValue(getNetMonitorVariableValue(options.alarmVariable));
	  setAlarmOptionsEnable(true);
	}
	if (options.alarmReadVariable !== undefined && options.alarmReadVariable !== null && options.alarmReadVariable !== '') {
	  const unreadValue = getNetMonitorVariableValue(options.alarmReadVariable);
	  if ((unreadValue === 'false' || unreadValue === 'true') && unreadValue) {
		setReadFilterValue(unreadValue);
	  }
	  setReadOptionsEnable(true);
	}
	if (options.alarmStatusVariable !== undefined && options.alarmStatusVariable!== null && options.alarmStatusVariable !== '') {
	  const statusValue = getNetMonitorVariableValue(options.alarmStatusVariable);
	  if ((statusValue === 'ok' || statusValue === 'nok' || statusValue === 'pend' || statusValue === 'abs') && statusValue) {
		setStatusFilterValue(statusValue);
	  }
	  setStatusOptionsEnable(true);
	}
	setInitialCalculationsDone(true);
  }
  
  if (width < 150 || height < 75) {
    return (
	  <div className="alarmErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="alarmErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  var legendPosition = String(options.legendPosition);
  if (legendPosition === 'VERTICAL_ALTERNATING' && width < options.alarmMinWidth * 2.5) {
    legendPosition = 'VERTICAL';
  }
  const pluginType = options.alarmManager ? 'Alarma' : 'Alerta';
  const isDark = config.theme.isDark;
  const backgroundColor = isDark ? 'black' : 'white';
  const titleColor = isDark ? 'white' : 'black';
  const textColor = isDark ? '#EFF4FA' : '#141618';
  const primary = isDark ? '#D9AF27' : '#FF7F27';
  const secondary = isDark ? '#E5CD6B' : '#FFC530';
  const alarmBgColor = isDark ? '#141618' : '#F4F9FF';
  const alarmtitle = isDark ? 'alarmtitle_dark' : 'alarmtitle';

  const typeOptions = options.alarmManager ? [
    { id: 0, icon: '', value: 'Todos', label: 'Todos', descriptionDown: '', descriptionUp: '' },
    { id: 1, icon: 'alarm-ring-fill', value: 'critico', label: 'Disponibilidad - Activo Crítico',
	  descriptionDown: 'NetMonitor perdio conectividad con uno o más Activos designados como Crítico.',
	  descriptionUp: 'Netmonitor puede ahora monitorear normalmente uno o más Activos de red que se encontraban fuera de línea.' },
    { id: 2, icon: 'alarm-fill', value: 'disponibilidad', label: 'Disponibilidad - Activo No Crítico', 
	  descriptionDown: 'NetMonitor perdio conectividad con un Activo de red.',
	  descriptionUp: 'Netmonitor puede ahora monitorear normalmente uno o más Activos de red que se encontraban fuera de línea.' },
    { id: 3, icon: 'lightbulb-alt', value: 'alimentacion', label: 'Alimentación Eléctrica', 
	  descriptionDown: 'NetMonitor detecto un fallo de alimentación eléctrica, o que la misma opera fuera de los rangos establecidos, en uno o mas activos.',
	  descriptionUp: 'La alimentación eléctrica nuevamente opera dentro de los rangos establecidos, en uno o mas activos.' }
  ] : [
    { id: 0, icon: '', value: 'Todos', label: 'Todos', descriptionDown: '', descriptionUp: '' },
    { id: 1, icon: 'server-alt', value: 'hw', label: 'Hardware', 
	  descriptionDown: 'NetMonitor detecto uno o más cambios en la configuración, o el fallo en un componente, del HW de uno o más activos.',
	  descriptionUp: 'El HW de uno o más activos que tenian una falla declarada, operan ahora normalmente' },
    { id: 2, icon: 'ethernet', value: 'interfaz', label: 'Interfaces de Red', 
	  descriptionDown: 'NetMonitor detecto una o mas interfaces de red que dejaron de funcionar.',
	  descriptionUp: 'Una o mas interfaces de red funcionan ahora nuevamente.' },
    { id: 3, icon: 'cpu', value: 'cpu', label: 'CPU', 
	  descriptionDown: 'NetMonitor detecto el uso excesivo de CPU en uno o más activos.',
	  descriptionUp: 'El nivel de utilización de CPU en uno o más activos recupero niveles normales.' },
    { id: 4, icon: 'ram-fill', value: 'memoria', label: 'Memoria RAM', 
	  descriptionDown: 'NetMonitor detecto el uso excesivo de Memoria en uno o más activos.',
	  descriptionUp: 'El nivel de utilización de Memoria RAM en uno o más activos volvió a la normalidad.' },
    { id: 5, icon: 'hdd-server', value: 'hdd', label: 'Almacenamiento', 
	  descriptionDown: 'NetMonitor detecto que la capacidad remanente de almacenamiento en uno o mas activos es muy baja.',
	  descriptionUp: 'La capacidad remanente de almacenamiento en uno o mas activos esta por debajo de los umbrales definidos.' },
    { id: 6, icon: 'cloud-block', value: 'enlace', label: 'Enlaces de datos', 
	  descriptionDown: 'NetMonitor perdio conectividad con el gateway declarado para el enlace de datos',
	  descriptionUp: 'Se recupero conectividad con el gateway del enlace de datos' },
    { id: 7, icon: 'topology', value: 'vpn', label: 'VPNs', 
	  descriptionDown: 'NetMonitor detecto la caida de una o más redes virtuales (VPN) establecidas a traves de activos monitoreados.',
	  descriptionUp: 'Una o más redes virtuales (VPN) estan establecidas nuevamente.' },
    { id: 8, icon: 'phone-alt', value: 'sip', label: 'Troncal SIP', 
	  descriptionDown: 'NetMonitor detecto uno o más troncales SIP fuera de servicio en los activos monitoreados',
	  descriptionUp: 'Uno o más troncales SIP vuelven a tener servicio a traves de los activos monitoreados' },
    { id: 9, icon: 'apps', value: 'proc', label: 'Procesos', 
	  descriptionDown: 'NetMonitor detecto uno o más Procesos caidos o con problemas dentro de los activos monitoreados',
	  descriptionUp: 'Uno o más Procesos volvieron a la normalidad' },
    { id: 10, icon: 'layer-group', value: 'svc', label: 'Servicios', 
	  descriptionDown: 'NetMonitor detecto uno o más Servicios caidos o con problemas dentro de los activos monitoreados',
	  descriptionUp: 'Uno o más Servicios volvieron a la normalidad' },
    { id: 11, icon: 'temperature-quarter', value: 'iot', label: 'Sensor IoT', 
	  descriptionDown: 'NetMonitor recibio una alarma de I/O proveniente de un dispositivo IoT monitoreado',
	  descriptionUp: 'Una alarma de I/O proveniente de un dispositivo IoT monitoreado volvio a la normalidad.' }
  ];

  const readOptions = [
    { id: 0, icon: '', value: 'Todos', label: 'Todos' },
    { id: 1, icon: 'eye', value: 'true', label: 'Leidas' },
    { id: 2, icon: 'eye-slash', value: 'false', label: 'No Leidas' }
  ];
  
  const allOption: optionsData = {
    value: 'Todos',
    label: 'Todos',
    icon: '',
  };

  const statusOptions = [
    { id: 0, icon: '', value: 'Todos', label: 'Todos' },
    { id: 1, icon: 'alarm-inactive', value: 'wok', label: 'Ocultar Finalizadas' },
    { id: 2, icon: 'alarm-active', value: 'nok', label: 'Activa' },
    { id: 3, icon: 'checking', value: 'pend', label: 'Activa - En Evaluación' },
    { id: 4, icon: 'recurrence', value: 'abs', label: 'Activa - Recurrente' },
    { id: 5, icon: 'alarm-inactive', value: 'ok', label: 'Finalizada' }
  ];

  const readValueToSet = readOptions.find(options => {
    return options.value === readFilterValue;
  });
  const typeValueToSet = typeOptions.find(options => {
    return options.value === typeFilterValue;
  });
  const statusValueToSet = statusOptions.find(options => {
    return options.value === statusFilterValue;
  });

  var defaultReadOption: optionsData = readValueToSet !== null ? readValueToSet : allOption;
  var defaultTypeOption: optionsData = typeValueToSet !== null ? typeValueToSet : allOption;
  var defaultStatusOption: optionsData = statusValueToSet !== null ? statusValueToSet : allOption;

  const [selectedReadValue, setSelectedReadValue] = useState([]);
  const [selectedTypeValue, setSelectedTypeValue] = useState([]);
  const [selectedStatusValue, setSelectedStatusValue] = useState([]);
  const [assetFilter, setAssetFilter] = useState(assetFilterValue);
  const [alarmFilter, setAlarmFilter] = useState(alarmFilterValue);
  const [alarmFilterChange, setAlarmFilterChange] = useState(false);
  const [alarmAssetChange, setAlarmAssetChange] = useState(false);

  if (renderCount.current === 1 && initialCalculationsDone) {
    if (selectedReadValue !== defaultReadOption) {
	  setSelectedReadValue(defaultReadOption);
    }
    if (selectedTypeValue !== defaultTypeOption) {  
	  setSelectedTypeValue(defaultTypeOption);
    }
    if (selectedStatusValue !== defaultStatusOption) {
	  setSelectedStatusValue(defaultStatusOption);
    }
	if (assetFilterValue !== assetFilter) {
	  setAssetFilter(assetFilterValue);
	  setAlarmAssetChange(true);
	} else if (assetFilter === options.filterDefaultValue) {
	  setAssetFilter('');
	  setAlarmAssetChange(false);
	}
	if (alarmFilterValue !== alarmFilter) {
	  setAlarmFilter(alarmFilterValue);
	  setAlarmFilterChange(true);
	} else if (alarmFilter === options.filterDefaultValue) {
	  setAlarmFilter('');
	  setAlarmFilterChange(false);
	}
	renderCount.current = renderCount.current + 1;
  }

  const onAssetChange = (event) => {
    setAssetFilter(event.target.value);
  };

  const onAlarmChange = (event) => {
    setAlarmFilter(event.target.value);
  };

  const changeAsset = (event) => {
    if (event.key === 'Enter' && assetOptionsEnable) {
	  const queryMap = {
		[`var-${options.assetVariable}`]: assetFilter,
	  };
	  changeUrl(queryMap);
	  if (assetFilter !== options.filterDefaultValue && assetFilter !== '') { 
	    setAlarmAssetChange(true);
	  } else {
	    setAlarmAssetChange(false);
	  }
    }
  };

  const changeAlarm = (event) => {
    if (event.key === 'Enter' && alarmOptionsEnable) {
	  const queryMap = {
		[`var-${options.alarmVariable}`]: alarmFilter,
	  };
	  changeUrl(queryMap);
	  if (alarmFilter !== options.filterDefaultValue && alarmFilter !== '') { 
	    setAlarmFilterChange(true);
	  } else {
	    setAlarmFilterChange(false);
	  }
    }
  };

  // ----------------------- BASE DATA ACQUISITION -----------------------

  const generateChart = useMemo(() => {
    const timeEvents: alarmData[] = [];
    const timeItems: alarmlineItems[] = [];
    if (initialCalculationsDone) {
	  data.series.forEach((series) => {
	    const frame: GraphSeriesValue[] = series.fields[0] ? series.fields[0].values.toArray() : [];
	    for (let i = 0; i < frame.length; i++) {
	      var ticketIdText = '';
	      var ticketStatusText = '';
	      let alarmEvent: alarmData = {
		    id: i,
		    idx: series.fields.find(field => field.name === options.idxField)?.values.get(i),
		    timest: series.fields.find(field => field.name === options.dateField)?.values.get(i),
		    title: '',
		    status: 'Todos',
		    alarmTitle: series.fields.find((field) => field.name === options.alarmTitleField)?.values.get(i),
		    alarmSubtitle: series.fields.find((field) => field.name === options.alarmSubtitleField)?.values.get(i),
		    alarmDetailedText: '',
		    alarmType: series.fields.find((field) => field.name === options.typeField)?.values.get(i),
		    severity: series.fields.find((field) => field.name === options.severityField)?.values.get(i),
		    agent: '-',
		    hasRootCause: false,
		    rootTicketId: '-',
		    rootTicketStatus: '-',
		    isRead: false,
			notes: '-',
			hasNotes: false,
		    readUser: '-',
		    readDate: '-',
		    assetId: [],
		    ticketId: [],
		    ticketStatus: [],
		    ticketIdText: '',
		    ticketStatusText: '',
			hash: series.fields.find((field) => field.name === options.hashField)?.values.get(i),
		    color: 'gray',
		    icon: series.fields.find((field) => field.name === options.iconField)?.values.get(i),
		    iconColor: 'gray',
	      };
	      alarmEvent.alarmSubtitle = alarmEvent.alarmSubtitle.replace(/;/g, '\n');
	      alarmEvent.title = moment.unix(alarmEvent.timest).format('DD-MM-YYYY HH:mm:ss');
	      if (options.statusField && options.statusField !== undefined && options.statusField !== '') {
		    alarmEvent.status = series.fields.find((field) => field.name === options.statusField).values.get(i);
	      }
	      if (options.usealarmDetailedText && options.alarmDetailedTextField !== undefined && options.alarmDetailedTextField !== '') {
		    alarmEvent.alarmDetailedText = series.fields
		      .find((field) => field.name === options.alarmDetailedTextField).values.get(i);
	      }
	      if (options.isReadField !== undefined && options.isReadField !== '') {
		    const read = series.fields.find((field) => field.name === options.isReadField)?.values.get(i);
		    if (read === 'true' || read === true) {
		      alarmEvent.isRead = true;
		    }
		    if (read) {
		      const readUser = series.fields.find((field) => field.name === options.userReadField)?.values.get(i);
		      const readDate = series.fields.find((field) => field.name === options.dateReadField)?.values.get(i);
		      alarmEvent.readUser = readUser ? readUser : '-';
		      alarmEvent.readDate = readDate ? moment.unix(readDate).format('DD-MM-YYYY HH:mm:ss') : '-';
		    }
	      }
		  const notes = series.fields.find((field) => field.name === options.hasNotesField)?.values.get(i);
		  if (notes !== undefined && notes !== '') {
		    alarmEvent.notes = notes !== '-' ? notes : '-';
			alarmEvent.hasNotes = notes !== '-' ? true : false;
		  }
	      if (alarmEvent.status === 'nok') {
		    alarmEvent.color = 'red';
	      } else if (alarmEvent.status === 'abs') {
		    alarmEvent.color = 'orange';
	      } else if (alarmEvent.status === 'ok') {
		    alarmEvent.color = 'blue';
	      } 
	      if (alarmEvent.alarmType === undefined || alarmEvent.alarmType === null) {
		    alarmEvent.alarmType = 'activo_offline';
	      }
	      if (alarmEvent.severity === undefined || alarmEvent.severity === null) {
		    alarmEvent.severity = 'unknow';
	      } else if (alarmEvent.severity === 'Alert') {
		    alarmEvent.iconColor = 'red';
	      } else {
		    alarmEvent.iconColor = 'blue';
	      } 

	      const assetId = options.assetIdField !== undefined && options.assetIdField !== '' ? 
	        series.fields.find((field) => field.name === options.assetIdField).values.get(i) : [];
	      if (assetId !== null && assetId.length > 0) {
		    alarmEvent.assetId = assetId.split(',');
	      }
	      const ticketId = options.ticketIdField !== undefined ?
	        series.fields.find((field) => field.name === options.ticketIdField).values.get(i) : [];
	      if (ticketId !== null && ticketId.length > 0) {
		    alarmEvent.ticketId = ticketId.split(',');
	      }
	      const ticketStatus = options.ticketStatusField !== undefined ?
	        series.fields.find((field) => field.name === options.ticketStatusField).values.get(i) : [];
	      if (ticketStatus !== null && ticketStatus.length > 0) {
		    alarmEvent.ticketStatus = ticketStatus.split(',');
	      }
	      const agent = options.agentField !== undefined ? 
	        series.fields.find((field) => field.name === options.agentField)?.values.get(i) : '-';
	      if (agent !== null && agent !== '' && agent !== '-') {
	        alarmEvent.agent = agent;
		    alarmEvent.hasRootCause = true;
		    alarmEvent.rootTicketId = alarmEvent.ticketId[0].length > 0 ? alarmEvent.ticketId[0] : '-';
		    alarmEvent.rootTicketStatus = alarmEvent.ticketStatus.length > 0 ? alarmEvent.ticketStatus[0] : '-';
	      } else if (alarmEvent.assetId.length > 0) {
		    alarmEvent.agent = alarmEvent.assetId[0];
		    alarmEvent.assetId = alarmEvent.assetId.slice(1);
		    if (alarmEvent.ticketId.length > 0) {
		      alarmEvent.rootTicketId = alarmEvent.ticketId[0];
		      ticketIdText = alarmEvent.rootTicketId !== '-' ? alarmEvent.rootTicketId + ' (' + alarmEvent.agent + ')' : '';
		      alarmEvent.ticketId = alarmEvent.ticketId.slice(1);
		    }
		    if (alarmEvent.ticketStatus.length > 0) {
		      alarmEvent.rootTicketStatus = alarmEvent.ticketStatus[0];
		      ticketStatusText = alarmEvent.rootTicketId !== '-' ? alarmEvent.rootTicketId + ' (' + alarmEvent.rootTicketStatus + ')' : '';
		      alarmEvent.ticketStatus = alarmEvent.ticketStatus.slice(1);
		    }
	      }
	      if (alarmEvent.ticketId.length > 0) {
		    alarmEvent.ticketId.forEach((ticketID, index) => {
		      if (index < alarmEvent.assetId.length) {
		        ticketIdText = ticketID !== '-' ?
		          ticketIdText + ticketID + ' (' + alarmEvent.assetId[index] + ')' :
			      ticketIdText;
		      }
		      if (index < alarmEvent.ticketStatus.length) {
		        ticketStatusText = ticketID !== '-' ?
		          ticketStatusText + ticketID + ' (' + alarmEvent.ticketStatus[index] + ')' :
			      ticketStatusText;
		      }
		    });
	      }
	      alarmEvent.ticketIdText = ticketIdText;
	      alarmEvent.ticketStatusText = ticketStatusText;
	      let timeItem: alarmlineItems = {
		    id: i,
		    timest: alarmEvent.timest,
		    title: alarmEvent.title,
		    idx: alarmEvent.idx,
		    status: alarmEvent.status,
		    alarmType: alarmEvent.alarmType,
	      };
	      timeEvents.push(alarmEvent);
	      timeItems.push(timeItem);
	    }
      });
	}

    if (timeEvents.length < 1) {
	  const alarmDate = new Date().getTime();
	  let timeItem: timelineItems = {
	    id: 1,
	    idx: '0',
	    timest: '',
	    title: moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'),
	    status: statusFilterValue,
	    alarmType: options.alarmManager ? 'Alarma': 'Alerta',
	  };
	  let timelineEvent: timelineData = {
	    id: 1,
	    idx: '0',
	    timest: '',
	    status: statusFilterValue,
	    title: moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'),
	    alarmTitle: 'Error - No existen registros para mostrar',
	    alarmSubtitle: 'No existen registros para mostrar',
	    alarmDetailedText: '',
	    alarmType: options.alarmManager ? 'Alarma': 'Alerta',
	    severity: 'unknow',
	    agent: assetFilterValue,
	    hasRootCause: false,
	    isRead: false,
		notes: '-',
		hasNotes: false,
	    readUser: '-',
	    readDate: '-',
	    assetId: '',
	    ticketId: '-',
	    ticketStatus: '-',
	    ticketIdText: '',
	    ticketStatusText: '',
	    color: 'gray',
	    icon: 'default',
	    iconColor: 'gray',
	  };
	  timeItems.push(timeItem);
	  timeEvents.push(timelineEvent);
	  let queryMap = {
		[`var-${options.selectAlarmVariable}`]: '',
		[`var-${options.selectStatusVariable}`]: '',
		[`var-${options.elementVariable}`]: '',
	  };
	  changeUrl(queryMap);
    }
	return { timeEvents, timeItems };
  }, [data, options, assetFilterValue, statusFilterValue, initialCalculationsDone]);

  // ----------------------- STYLES -----------------------
  var dimensions = {
    width: width,
    height: height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
    cardWidth: width - 150,
  };
  if (options.showToolbar && !options.showTitle) {
    dimensions.height = height - 30;
  } else if (options.showToolbar && options.showTitle) {
    dimensions.height = height - 70;
  } else if (!options.showToolbar && options.showTitle) {
    dimensions.height = height - 40;
  }
  const fontSizes = width > 750 ? {
    alarmSubtitle: String(options.alarmFontSize - 1) + 'px',
    alarmText: String(options.alarmFontSize - 2) + 'px',
    alarmTitle: String(options.alarmFontSize) + 'px',
    title: String(options.alarmFontSize - 1) + 'px',
  } : {
    alarmSubtitle: String(options.alarmFontSize - 3) + 'px',
    alarmText: String(options.alarmFontSize) + 'px',
    alarmTitle: String(options.alarmFontSize - 2) + 'px',
    title: String(options.alarmFontSize - 3) + 'px',
  };
  const filterBox = isDark ? 'filterBox_dark' : 'filterBox';
  const toolbarBox = isDark ? 'alarmToolbar text_dark' : 'alarmToolbar text_light';
  const showMore = options.useReadMore;
  const showCardInfo = options.showCardInfo;
  const alarmSubtitle = 'alarmSubtitle';
  const alarmContainer = isDark ? 'alarmManagerContainer_dark' : 'alarmManagerContainer';
  const alarmID = isDark ? 'alarmline-moreInfoTitles_dark' : 'alarmline-moreInfoTitles';
  const moreInfoContainerId = 'alarmline-moreInfoContainer-' + String(id);
  const confirmId = 'alarmline-confirm-' + String(id);
  const moreInfoId = 'alarmline-moreInfo-' + String(id);
  const confirmInfoId = 'alarmline-confirmInfo-' + String(id);
  const moreTitleId = 'alarmline-moreInfoTitle-' + String(id);
  const confirmTitleId = 'alarmline-confirmTitle-' + String(id);
  const moreCardTitleId = 'alarmline-moreInfoCardTitle-' + String(id);
  const moreSubTitleId = 'alarmline-moreInfoSubTitle-' + String(id);
  const moreAssetsId = 'alarmline-moreInfoAssets-' + String(id);
  const moreInfoTextId = 'alarmline-moreInfoText-' + String(id);
  const moreAgentTitle = 'alarmline-moreInfoAgentTitle-' + String(id);
  const moreAgentId = 'alarmline-moreInfoAgent-' + String(id);
  const moreInfoSeverityId = 'alarmline-moreInfoSeverity-' + String(id);
  const moreInfoStatus = 'alarmline-moreInfoStatus-' + String(id);
  const moreReadStatus = 'alarmline-moreReadStatus-' + String(id);
  const moreUserStatus = 'alarmline-moreUserStatus-' + String(id);
  const moreDateStatus = 'alarmline-moreDateStatus-' + String(id);
  const moreInfoTicketId = 'alarmline-moreInfoTicket-' + String(id);
  const moreInfoTicketStatus = 'alarmline-moreInfoTicketStat-' + String(id);

  if (legendPosition === 'VERTICAL_ALTERNATING') {
    dimensions.cardWidth = (width / 2) - 75;
  }

  let classSelect = 'alarm-select-box';
  var customStyles = {
	menu: provided => ({
	  ...provided,
	  zIndex: 9999,
	  color: '#23282E',
	  background: '#EFF4FA',
	  border: 'none',
	  borderRadius: '10px',
	  marginTop: '5px',
	  marginBottom: '0px',
	}),
	option: (base, state) => ({
	  ...base,
	  height: '30px',
	  marginTop: '0px',
	  marginBottom: '0px',
	  fontSize: '12px',
	  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	  background: state.isSelected ? '#FFC530' : '#EFF4FA',
	  '&:hover': {
		color: 'black',
		background: '#FF7F27',
	  },
	}),
	singleValue: base => ({
	  ...base,
	  color: "#23282E"
	}),
	control: base => ({
	  ...base,
	  border: 0,
	  boxShadow: 'none',
	}),
	indicatorContainer: provided => ({
	  ...provided,
	  paddingLeft: '10px',
	}),
	menuPortal: provided => ({
	  ...provided,
	  zIndex: 9999,
	}),
  };

  if (isDark) {
	classSelect = 'alarm-select-box_dark';
	customStyles = {
	  menu: provided => ({
		...provided,
		zIndex: 9999,
		color: '#F4F9FF',
		background: '#141618',
		border: 'none',
		borderRadius: '10px',
		marginTop: '5px',
		marginBottom: '0px',
		paddingTop: '0px',
		paddingBottom: '0px',
	  }),
	  option: (base, state) => ({
		...base,
		height: '30px',
		marginTop: '0px',
		marginBottom: '0px',
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		borderRadius: '10px',
		background: state.isSelected ? '#E5CD6B' : '#141618',
		'&:hover': {
		  color: 'white',
		  background: '#D9AF27',
		},
	  }),
	  singleValue: base => ({
		...base,
		color: "#EFF4FA"
	  }),
	  control: base => ({
		...base,
		border: 0,
		boxShadow: 'none',
	  }),
	  indicatorContainer: provided => ({
		...provided,
		paddingLeft: '10px',
	  }),
	  menuPortal: provided => ({
		...provided,
		zIndex: 9999,
	  }),
	};
  }

  const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
	return (
	  <components.DropdownIndicator {...props}>
		<Icon name="angle-down" size="xl" />
	  </components.DropdownIndicator>
	);
  };

  const NoOptionsMessage = props => {
	return (
	  <components.NoOptionsMessage {...props}>
		<span className="custom-css-class">{error2}</span> 
	  </components.NoOptionsMessage>
	);
  };
  var selectWidth = (width - 15) * 0.2 > 225 ? (width - 15) * 0.2 : 225;
  var inputWidth = (width - 15 - (3 * selectWidth)) / 2;
  if (width < 480) {
    selectWidth = 150;
	inputWidth = 150;
  }
  if (selectWidth < 150) {
    selectWidth = 150;
  }
  if (inputWidth < 150) {
    inputWidth = 150;
  }

  return (
	<div className="alarmManager" id={'alarmManager' + id}>
	  {options.showTitle && (
		<div className={alarmtitle}>
		  <div className="alarmtitleText">{pluginTitle}</div>
		</div>
	  )}
	  {options.showToolbar && (
		<div className={toolbarBox}>
		  {options.showReadAllOptions && (
			<div className="filterContainer" style={{ width: '55px' }}>
			  <Icon
				className={isDark ? 'alarm-readIcon_dark' : 'alarm-readIcon'}
				name="read-fill"
				size="md"
				title="Marcar todos las alarmas como leidas"
				onClick={(e) => {
				  showConfirm('Realmente desea marcar todos las alarmas como leídos?', id);
				}}
			  />
			</div>
		  )}
		  {readOptionsEnable && (
			<div className="filterContainer" style={{ width: `${selectWidth * 0.75}px` }}>
			  <span className="selectBoxText">{'Ver: '}</span>
              <Select
                instanceId={id + '1'}
                className={classSelect}
                classNamePrefix={classSelect}
                components={{ DropdownIndicator, NoOptionsMessage }}
                options={readOptions}
                value={selectedReadValue}
                isSearchable={false}
                placeholder={
                  <div className="alarmPicker-icon">
                    {defaultReadOption.icon !== '' && <Icon name={defaultReadOption.icon} size="lg" />}
                    <div className="alarmPicker-label">{defaultReadOption.label}</div>
                  </div>
                }
                onChange={e => {
				  if (readOptionsEnable) {
				    setSelectedReadValue(e);
					const queryMap = {
					  [`var-${options.alarmReadVariable}`]: e.value,
				    };
				    changeUrl(queryMap);
				  }
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={customStyles}
                getOptionLabel={e => (
                  <div className="alarmPicker-icon">
                    {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                    <div className="alarmPicker-label">{e.label}</div>
                  </div>
                )}
              />
			</div>
		  )}
		  {statusOptionsEnable && (
			<div className="filterContainer" style={{ width: `${selectWidth}px` }}>
			  <span className="selectBoxText">{'Estado: '}</span>
              <Select
                instanceId={id + '2'}
                className={classSelect}
                classNamePrefix={classSelect}
                components={{ DropdownIndicator, NoOptionsMessage }}
                options={statusOptions}
                value={selectedStatusValue}
                isSearchable={false}
                placeholder={
                  <div className="alarmPicker-icon">
                    {defaultReadOption.icon !== '' && <Icon name={defaultReadOption.icon} size="lg" />}
                    <div className="alarmPicker-label">{defaultReadOption.label}</div>
                  </div>
                }
                onChange={e => {
				  if (statusOptionsEnable) {
				    setSelectedStatusValue(e);
					const queryMap = {
					  [`var-${options.statusVariable}`]: e.value,
				    };
				    changeUrl(queryMap);
				  }
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={customStyles}
                getOptionLabel={e => (
                  <div className="alarmPicker-icon">
                    {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                    <div className="alarmPicker-label">{e.label}</div>
                  </div>
                )}
              />
			</div>
		  )}
		  {typeOptionsEnable && (
			<div className="filterContainer" style={{ width: `${selectWidth}px` }}>
			  <span className="selectBoxText">{options.typeTitle + ': '}</span>
              <Select
                instanceId={id + '3'}
                className={classSelect}
                classNamePrefix={classSelect}
                components={{ DropdownIndicator, NoOptionsMessage }}
                options={typeOptions}
                value={selectedTypeValue}
                isSearchable={false}
                placeholder={
                  <div className="alarmPicker-icon">
                    {defaultTypeOption.icon !== '' && <Icon name={defaultTypeOption.icon} size="lg" />}
                    <div className="alarmPicker-label">{defaultTypeOption.label}</div>
                  </div>
                }
                onChange={e => {
				  if (typeOptionsEnable) {
				    setSelectedTypeValue(e);
					const queryMap = {
					  [`var-${options.typeVariable}`]: e.value,
				    };
				    changeUrl(queryMap);
				  }
                }}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                styles={customStyles}
                getOptionLabel={e => (
                  <div className="alarmPicker-icon">
                    {e.icon !== ''&& <Icon name={e.icon} size="md" />}
                    <div className="alarmPicker-label">{e.label}</div>
                  </div>
                )}
              />
			</div>
		  )}
		  {assetOptionsEnable && options.assetVariable !== '' && options.assetTitle !== '' && (
			<div className="filterContainer" style={{ width: `${inputWidth}px` }}>
			  <span className="selectBoxText">{options.assetTitle + ': '}</span>
			  <input 
				id={'alarm-searchAgent' + id}
				className={filterBox}
				onKeyPress={changeAsset}
				onChange={onAssetChange}
				value={assetFilter}
				placeholder={options.filterDefaultValue}
			  ></input>
			  {alarmAssetChange && (
			    <Icon
				  className={'alarm-filterIcon'}
				  name="trash-circle-fill"
				  size="md"
				  title="Quitar filtro"
				  onClick={(e) => {
				    if (assetOptionsEnable) {
				  	  const queryMap = {
					    [`var-${options.assetVariable}`]: options.filterDefaultValue,
					  };
					  changeUrl(queryMap);
					  setAssetFilter('');
					  setAlarmAssetChange(false);
				    }
				  }}
			    />
			  )}
			</div>
		  )}
		  {alarmOptionsEnable && (
			<div className="filterContainer" style={{ width: `${inputWidth}px` }}>
			  <span className="selectBoxText">{options.subtitleTitle + ': '}</span>
			  <input 
				id={'alarm-searchAlarm'+ id}
				className={filterBox}
				onKeyPress={changeAlarm}
				onChange={onAlarmChange}
				value={alarmFilter}
				placeholder={options.filterDefaultValue}
			  ></input>
			  {alarmFilterChange && (
			    <Icon
				  className={'alarm-filterIcon'}
				  name="trash-circle-fill"
				  size="md"
				  title="Quitar filtro"
				  onClick={(e) => {
				    if (alarmOptionsEnable) {
					  const queryMap = {
					    [`var-${options.alarmVariable}`]: options.filterDefaultValue,
					  };
					  changeUrl(queryMap);
					  setAlarmFilter('');
					  setAlarmFilterChange(false);
				    }
				  }}
			    />
			  )}
			</div>
		  )}
		</div>
	  )}
	  <div className={options.transparent ? 'alarmContainerBasic' : alarmContainer} style={{ width: '100%', height: dimensions.height }}>
		<Chrono
		  items={generateChart.timeItems}
		  mode={legendPosition}
		  cardHeight={String(options.alarmMinHeight)}
		  cardWidth={String(dimensions.cardWidth)}
		  allowDynamicUpdate={true}
		  lineWidth={options.lineSize}
		  hideControls={options.hideControls}
		  scrollable={{ scrollbar: true }}
		  flipLayout={options.flipLayout}
		  timelineCircleDimension={String(options.circleDimension)}
		  onItemSelected={(item) => {
			if (data.series[0].length > 0) {
			  if (item !== undefined && item !== null) {
			    if (item.idx !== '' && options.selectAlarmVariable && options.selectStatusVariable) {
			      let queryMap = {
                    [`var-${options.selectAlarmVariable}`]: item.timest,
                    [`var-${options.selectStatusVariable}`]: item.alarmType,
                  };
			      changeUrl(queryMap);
			    }
			    if (item.idx !== '' && options.elementVariable && options.elementVariable !== '') {
			      try {
				    const idxArray = item.idx.split(',');
			        if (!Number.isNaN(Number(idxArray[0]))) {
			          const queryMap2 = {
                        [`var-${options.elementVariable}`]: idxArray[0],
                      };
				      changeUrl(queryMap2);
			        }
				  } catch {
				    console.error('Error', item);
				  }
				}
			  }
			}
		  }}
		  theme={{
		    iconBackgroundColor: backgroundColor,
			primary: primary,
			secondary: secondary,
			cardBgColor: alarmBgColor,
			cardForeColor: '#9DA5B8',
			cardTitleColor: 'white',
			detailsColor: textColor,
			titleColor: titleColor,
			titleColorActive: backgroundColor,
		  }}
		  buttonTexts={{
			first: 'Primero',
			last: 'Ultimo',
			next: 'Siguiente',
			previous: 'Anterior',
		  }}
		  fontSizes={fontSizes}
		  enableOutline={false}
		  useReadMore={options.displayReadMore}
		>
		  {alarmFactory(
		    generateChart.timeEvents,
			showMore,
			showCardInfo,
			id,
			options,
			assetOptionsEnable,
			typeOptionsEnable,
			alarmOptionsEnable,
			setSelectedTypeValue,
			setAlarmFilter,
			setAlarmFilterChange,
			setAssetFilter,
			setAlarmAssetChange,
			typeOptions,
			pluginApi
		  )}
		  {options.useIcon && ( 
			<div className="chrono-icons">
			  {iconFactory(
			    generateChart.timeEvents,
				options.circleDimension,
				typeOptionsEnable,
				options,
				setSelectedTypeValue,
				typeOptions
			  )}
			</div>
		  )}
		</Chrono>
	  </div>
	  <div id={moreInfoContainerId} className="alarmline-moreInfoContainer alarmline-hide">
		<div id={moreInfoId} className={isDark ? 'alarmline-moreInfo_dark' : 'alarmline-moreInfo'}>
		  <div className="alarmTitleContainer">
			<span id={moreTitleId} className={isDark ? 'alarmline-moreInfoTitles_dark' : 'alarmline-moreInfoTitles'}></span>
			<span id={moreCardTitleId} 
			  className={isDark ? 'alarmline-moreInfoType_dark' : 'alarmline-moreInfoType'}>
			</span>
		  </div>
		  <div className="alarmline-moreDivsInLine">
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{pluginType + ' leida: '}</span>
			<span id={moreReadStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Por: '}</span>
			<span id={moreUserStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Fecha: '}</span>
			<span id={moreDateStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
		  </div>
		  <div className="alarmline-moreDivsInLine">
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{options.subtitleTitle + ': '}</span>
			<b><span id={moreSubTitleId} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span></b>
		  </div>
		  <div className="alarmline-moreDivsInLine">
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Estado actual: '}</span>
			<span id={moreInfoStatus} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Tipo: '}</span>
			<span id={moreInfoSeverityId} className={isDark ? 'alarmline-moreInfoText_dark' : 'alarmline-moreInfoText'}></span>
		  </div>
		  <div className="alarmline-moreDivsInLine">
			<span id={moreAgentTitle} className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}></span>
			<span id={moreAgentId} className={isDark ? 'alarmSubtitle_dark' : 'alarmSubtitle'}></span>
		  </div>
		  <div className="alarmline-moreDivs">
			<span className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Otro/s activo/s afectado/s: '}</span>
			<span id={moreAssetsId} className={isDark ? 'alarmSubtitle_dark' : 'alarmSubtitle'}></span>
		  </div>
		  <div className="alarmline-moreDivs">
			<div className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Ticket/s Asociado/s: '}</div>
			<div id={moreInfoTicketId} className="alarmDetailedTextZoom"></div>
		  </div>
		  <div className="alarmline-moreDivs">
			<div className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{'Estado de/los Ticket/s: '}</div>
			<div id={moreInfoTicketStatus} className="alarmDetailedTextZoom"></div>
		  </div>
		  <div className="alarmline-moreDivs-line" />
		  <div className="alarmline-moreDivs">
			<div className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>{options.infoTitle + ': '}</div>
			<div id={moreInfoTextId} className="alarmDetailedTextZoom"></div>
		  </div>
		  <button
			className={isDark ? 'alarmline-close_button_dark' : 'alarmline-close_button'}
			onClick={(e) => {
			  hideMoreInfo(e, id);
		   }}
		  >
			cerrar
		  </button>
		</div>
	  </div>
	  <div id={confirmId} className="alarmline-moreInfoContainer alarmline-hide">
		<div id={confirmInfoId} className={isDark ? 'alarmline-confirm_dark' : 'alarmline-confirm'}>
		  <div className="alarmline-moreDivs">
			<div id={confirmTitleId} className={isDark ? 'alarmline-moreTitles_dark' : 'alarmline-moreTitles'}>
			  Realmente desea marcar todos los mensajes como leídos?
			</div>
		  </div>
		  <button
			className={isDark ? 'alarmline-ok_button_dark' : 'alarmline-ok_button'}
			onClick={(e) => {
			  checkAllAsRead(generateChart.timeEvents, pluginApi);
			  hideConfirm(e, id);
			}}
		  >
			Aceptar
		  </button>
		  <button
			className={isDark ? 'alarmline-cancel_button_dark' : 'alarmline-cancel_button'}
			onClick={(e) => {
			  hideConfirm(e, id);
			}}
		  >
			Cancelar
		  </button>
		</div>
	  </div>
	</div>
  );
});

function iconFactory(
  icons: alarmData[],
  circleDimension: number,
  typeOptionsEnable: boolean,
  options,
  setSelectedTypeValue,
  typeOptions
) {
  const isDark = config.theme.isDark;
  const iconBackground = isDark ? 'alarmIcon_background_dark' : 'alarmIcon_background';

  let iconSize = 'xs';
  if (circleDimension > 28) {
    iconSize = 'xl';
  } else if (circleDimension > 24) {
    iconSize = 'lg';
  } else if (circleDimension > 20) {
    iconSize = 'md';
  } else if (circleDimension > 18) {
    iconSize = 'sm';
  }



  return icons.map((alarmEvent) => (
    <div className={iconBackground} key={alarmEvent.id}>
      <Icon
        className={'color_' + alarmEvent.iconColor}
        name={alarmEvent.icon.toLowerCase()}
        size={iconSize}
        title={typeOptionsEnable ? 'Aplicar como filtro' : alarmEvent.alarmType}
        onClick={(e) => {
          if (typeOptionsEnable) {
			const typeValueToSet = typeOptions.find(options => {
			  return options.value === alarmEvent.alarmType;
			});
			if (typeValueToSet) {
			  setSelectedTypeValue(typeValueToSet);
			}
            const queryMap = {
              [`var-${options.typeVariable}`]: alarmEvent.alarmType,
            };
            changeUrl(queryMap);
          }
        }}
      />
    </div>
  ));
}

function openNotes(addNote, alarmEvent, url, alarmVariable, hashVariable, assetVariable) {
  let urlToOpen = url;
  let urlParam = '';
  if (alarmEvent.hash ) {
    const hash = String(alarmEvent.hash);
    let arrayStr = hash.replace(/^{/, "[").replace(/}$/, "]");
	urlParam = encodeURIComponent(arrayStr);
  }
  if (addNote) {
    if (urlToOpen !== '') {
	  const firstTag = urlToOpen.includes('?') ? '&var-' : '?var-';
      urlToOpen = urlToOpen + firstTag + alarmVariable + '=' + alarmEvent.timest +
	    '&var-' + hashVariable + '=' + urlParam + 
	    '&var-' + assetVariable + '=' + alarmEvent.idx;
    }
  } else {
    if (urlToOpen !== '') {
      urlToOpen = urlToOpen + alarmEvent.notes + '&var-' + alarmVariable + '=' + alarmEvent.timest;
	  urlToOpen = alarmEvent.hash !== '' ? urlToOpen + '&var-' + hashVariable + '=' + urlParam : urlToOpen;
    }
  }
  if (urlToOpen !== null) {
	window.open(urlToOpen, '_blank');
  }
}

function save(alarmEvent, api, type) {
  const userName = contextSrv.user.name;
  var apiUrl = api + '/insert_alarm_toggle_read';
  var alarm = '{"params" : "' + String(alarmEvent.isRead) + '|' + String(alarmEvent.timest) + '|' + String(alarmEvent.alarmType);
  alarm = alarm + '|' + String(alarmEvent.idx) + '|' + userName + '"}';

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.post(apiUrl, alarm).then(
    response => {
      if (response.statusText !== 'OK') {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
        });
      }
    },
    error => {
      SystemJS.load('app/core/app_events').then((appEvents: any) => {
        console.error('Se produjo un error');
      });
    }
  );
}

function checkAllAsRead(alarmEvents, api) {
  const userName = contextSrv.user.name;
  const moment = require('moment');
  alarmEvents.forEach((alarmEvent, index) => {
    if (alarmEvent.isRead === false) {
      alarmEvent.isRead = true;
	  alarmEvent.readUser = userName;
	  const alarmDate = new Date().getTime();
	  alarmEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss'); 
      let iconId = '#icon_uncheck_' + String(alarmEvent.id);
      $(iconId).removeClass('show_icon');
      $(iconId).addClass('hide_icon');
      iconId = '#icon_check_' + String(alarmEvent.id);
      $(iconId).removeClass('hide_icon');
      $(iconId).addClass('check_icon show_icon');
      save(alarmEvent, api, 'check');
    }
  });
}

function showConfirm(title, id) {
  let divContainerId = '#alarmline-confirm-' + String(id);
  let divTitleId = '#alarmline-confirmTitle-' + String(id);

  $(divContainerId).removeClass('alarmline-hide');
  $(divTitleId).text(title);
}

function showMoreInfo(e, alarmEvent, id, options, typeOptions) {
  let divContainerId = '#alarmline-moreInfoContainer-' + String(id);
  let divTitleId = '#alarmline-moreInfoTitle-' + String(id);
  let divCardTitleId = '#alarmline-moreInfoCardTitle-' + String(id);
  let divSubTitleId = '#alarmline-moreInfoSubTitle-' + String(id);
  let divSubAssetId = '#alarmline-moreInfoAssets-' + String(id);
  let divInfoTextId = '#alarmline-moreInfoText-' + String(id);
  let divAgentTitle = '#alarmline-moreInfoAgentTitle-' + String(id);
  let divAgentId = '#alarmline-moreInfoAgent-' + String(id);
  let divInfoSeverityId = '#alarmline-moreInfoSeverity-' + String(id);
  let divInfoStatus = '#alarmline-moreInfoStatus-' + String(id);
  let divReadStatus = '#alarmline-moreReadStatus-' + String(id);
  let divUserStatus = '#alarmline-moreUserStatus-' + String(id);
  let divDateStatus = '#alarmline-moreDateStatus-' + String(id);
  let divInfoTicketId = '#alarmline-moreInfoTicket-' + String(id);
  let divInfoTicketStatus = '#alarmline-moreInfoTicketStat-' + String(id);

  $(divContainerId).removeClass('alarmline-hide');
  $(divTitleId).text(alarmEvent.title);
  if (alarmEvent.status === 'nok' && (alarmEvent.severity === 'alert' || alarmEvent.severity === 'Alert')) {
    $(divCardTitleId).addClass('alarmline-moreInfoType_fire');
  }
  $(divCardTitleId).text(alarmEvent.alarmTitle);
  $(divSubTitleId).text(alarmEvent.alarmSubtitle);
  $(divAgentTitle).text(alarmEvent.hasRootCause ? 'Causa Posible (ID de Activo): ' : options.assetTitle + ': ');
  $(divAgentId).text(alarmEvent.agent);
  const assetIdText = alarmEvent.assetId.length > 0 ? alarmEvent.assetId.join(',') : 'Ninguno'; 
  $(divSubAssetId).text(assetIdText);
  const ticketIdText = alarmEvent.ticketIdText !== '' ? alarmEvent.ticketIdText : 'No posee'; 
  $(divInfoTicketId).text(ticketIdText);
  const ticketStatText = alarmEvent.ticketStatusText !== '' ? alarmEvent.ticketStatusText : '-'; 
  $(divInfoTicketStatus).text(ticketStatText);
  const typeValue = typeOptions.find(options => {
    return options.value === alarmEvent.alarmType;
  });
  const severityText = typeValue ? typeValue.label : 'Desconocido';
  $(divInfoSeverityId).text(severityText);
  var statusValue = 'Desconocido';
  var infoText = 'NetMonitor detecto una falla';
  if (alarmEvent.status === 'nok') {
    statusValue = 'Activa';
	infoText = typeValue.descriptionDown;
  } else if (alarmEvent.status === 'ok') {
    statusValue = 'Finalizada';
	infoText = typeValue.descriptionUp;
  } else if (alarmEvent.status === 'pend') {
    statusValue = 'Activa - En Evaluación';
	infoText = typeValue.descriptionUp;
	infoText = infoText + ' NetMonitor mantiene aún este evento en observacion antes de declarar la ' + pluginType + ' como Finalizada.';
  } else if (alarmEvent.status === 'abs') {
    statusValue = 'Activa - Recurrente';
	infoText = typeValue.descriptionDown;
	infoText = infoText + ' NetMonitor detecto que este evento sucede recurrentemtne dentro de una ventana de tiempo y no puede dar por finalizada la ' + pluginType + '.'; 
 }
  $(divInfoStatus).text(statusValue);
  const readValue = alarmEvent.isRead ? 'Sí' : 'No'; 
  const userValue = alarmEvent.readUser ? alarmEvent.readUser : '-'; 
  const dateValue = alarmEvent.readDate ? alarmEvent.readDate : '-'; 
  $(divReadStatus).text(readValue);
  $(divUserStatus).text(userValue);
  $(divDateStatus).text(dateValue);
  $(divInfoTextId).text(infoText);
}

function handleUrl(url, target, update) {
  let urlTarget = '_blank';
  if (target === '_self' || update) {
    urlTarget = '_self';
  }
  if (url !== null && url !== '') {
    window.open(url, urlTarget);
  }
}

function hideMoreInfo(e, id) {
  let divID = '#alarmline-moreInfoContainer-' + String(id);
  $(divID).addClass('alarmline-hide');
}

function hideConfirm(e, id) {
  let divID = '#alarmline-confirm-' + String(id);
  $(divID).addClass('alarmline-hide');
}

function alarmFactory(
  alarms: alarmData[],
  showMore,
  showCardInfo,
  id,
  options,
  assetOptionsEnable,
  typeOptionsEnable,
  alarmOptionsEnable,
  setSelectedTypeValue,
  setAlarmFilter,
  setAlarmFilterChange,
  setAssetFilter,
  setAlarmAssetChange,
  typeOptions,
  api
) {
  const userName = contextSrv.user.name;
  const moment = require('moment');
  const agentBaseUrl = options.drillDownLinkAgent !== '' ? options.drillDownLinkAgent : '/d/assets/activos';
  const pluginType = options.alarmManager ? 'Alarma' : 'Alerta';
  const isDark = config.theme.isDark;
  const alarmTheme = isDark ? '_dark' : '';
  const alarmTitle = isDark ? 'alarmTitle_dark' : 'alarmTitle';
  const statusButtonOk = isDark ? 'alarmline-button_dark alarmline-button_green_dark' : 'alarmline-button alarmline-button_green';
  const statusButtonNok = isDark ? 'alarmline-button_dark alarmline-button_red_dark' : 'alarmline-button alarmline-button_red';
  const alarmSubtitle = isDark ? 'alarmSubtitle_dark' : 'alarmSubtitle';
  const alarmDetailedText = isDark ? 'alarmDetailedText_dark' : 'alarmDetailedText';
  const agent = isDark ? 'alarmline-agent_dark' : 'alarmline-agent';


  return alarms.map((alarmEvent) => (
    <div key={alarmEvent.id} className="alarmContainerBasic">
      <div className="alarmTitleContainer">
        <span 
          className={'alarmline-alarmTitle'}
        >
          <button
            className={alarmTitle + ' alarmline-' + alarmEvent.status + alarmTheme}
            onClick={(e) => {
              if (typeOptionsEnable && options.showToolbar) {
				const typeValue = typeOptions.find(options => {
				  return options.value === alarmEvent.alarmType;
				});
                const queryMap = {
                  [`var-${options.typeVariable}`]: typeValue.value,
                };
                changeUrl(queryMap);
				setSelectedTypeValue(typeValue);
              }
            }}
            title={options.showToolbar ? 'Filtrar por tipo de ' + pluginType : pluginType + ' emitida'}
          >
			{alarmEvent.alarmTitle}
          </button>
          {(alarmEvent.severity === 'alert' || alarmEvent.severity === 'Alert') && (
		    <button
              className={(alarmEvent.status === 'ok' || alarmEvent.status === 'pend') ? statusButtonOk : statusButtonNok}
            >
			  {alarmEvent.status === 'nok' && 'Activa'}
			  {alarmEvent.status === 'ok' && 'Finalizada'}
			  {alarmEvent.status === 'pend' && 'Activa - En Evaluación'}
			  {alarmEvent.status === 'abs' && 'Activa - Recurrente'}
            </button>
		  )}
        </span>
        <span>
          <button
            className={isDark ? 'alarmline-url_button_dark' : 'alarmline-url_button'}
            onClick={(e) => {
              if (alarmOptionsEnable && options.showToolbar) {
                const queryMap = {
                  [`var-${options.alarmVariable}`]: alarmEvent.timest,
                };
                changeUrl(queryMap);
				setAlarmFilter(alarmEvent.timest);
				setAlarmFilterChange(true);
              }
            }}
            title={'Filtrar por ID de ' + pluginType}
          >
            {options.subtitleTitle + ': ' + alarmEvent.timest}
          </button>
        </span>
        {alarmEvent.agent !== '' && alarmEvent.agent !== '-' && options.assetTitle !== '' && (
		  <span>
            <button
              className={agent}
              onClick={(e) => {
                handleUrl(agentBaseUrl + alarmEvent.agent, '_blank', false);
              }}
              title={'Ir al ' + options.assetTitle.toLowerCase() + ': ' + alarmEvent.agent}
            >
              {alarmEvent.agent}
            </button>
          </span>
		)}
        {showMore && (
          <spam>
            <button
              className={isDark ? 'alarmline-button_dark' : 'alarmline-button'}
              onClick={(e) => {
                showMoreInfo(e, alarmEvent, id, options, typeOptions);
              }}
              title="Más detalles ..."
            >
              <Icon
                name="ellipsis-h"
                size="md"
              />
            </button>
          </spam>
        )}
		{alarmEvent.alarmSubtitle !== 'No existen registros para mostrar' && (
          <>
		  <span
            className={alarmEvent.hasNotes ? 'noteBox show_icon' : 'noteBox hide_icon'}
            id={'icon_hasnotes_' + String(alarmEvent.id)}
          >
            <Icon
              className={isDark ? 'show_icon note_icon_dark' : 'show_icon note_icon'}
              name={'note-edit'}
              size="xl"
              onClick={(e) => {
			    if (alarmEvent.hasNotes) {
                  openNotes(false, alarmEvent, options.editNotesUrl, options.alarmVariable, options.hashVariable, options.assetVariable);			  
			    }
              }}
              title={'Editar Nota'}
            />
          </span>
          <span 
            className={alarmEvent.hasNotes ? 'noteBox hide_icon' : 'noteBox show_icon'}
            id={'icon_notes_' + String(alarmEvent.id)}
          >
            <Icon
              className={isDark ? 'show_icon editnote_icon_dark' : 'show_icon editnote_icon'}
              name={'note-add'}
              size="xl"
              onClick={(e) => {
			    if (!alarmEvent.hasNotes) {
                  alarmEvent.hasNotes = true;
				  let iconId = '#icon_notes_' + String(alarmEvent.id);
                  $(iconId).removeClass('show_icon');
                  $(iconId).addClass('hide_icon');
                  iconId = '#icon_hasnotes_' + String(alarmEvent.id);
                  $(iconId).removeClass('hide_icon');
                  $(iconId).addClass('note_icon show_icon');
                }
                openNotes(true, alarmEvent, options.addNotesUrl, options.alarmVariable, options.hashVariable, options.assetVariable);
              }}
              title={'Agregar Nota'}
            />
          </span>
          <span
            className={alarmEvent.isRead ? 'checkBox show_icon' : 'checkBox hide_icon'}
            id={'icon_check_' + String(alarmEvent.id)}
          >
           <Icon
              className="show_icon check_icon"
              name={'check-square'}
              size="md"
              onClick={(e) => {
			    if (alarmEvent.isRead) {
                  alarmEvent.isRead = false;
				  alarmEvent.readUser = userName;
				  const alarmDate = new Date().getTime();
				  alarmEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss');
				  let iconId = '#icon_check_' + String(alarmEvent.id);
                  $(iconId).removeClass('show_icon');
                  $(iconId).addClass('hide_icon');
                  iconId = '#icon_uncheck_' + String(alarmEvent.id);
                  $(iconId).removeClass('hide_icon');
                  $(iconId).addClass('check_icon show_icon');
                  save(alarmEvent, api, 'uncheck');			  
			    }
              }}
              title={'Desmarcar'}
            />
          </span>
          <span 
            className={alarmEvent.isRead ? 'checkBox hide_icon' : 'checkBox show_icon'}
            id={'icon_uncheck_' + String(alarmEvent.id)}
          >
            <Icon
              className="show_icon uncheck_icon"
              name={'square'}
              size="md"
              onClick={(e) => {
			    if (alarmEvent.isRead === false) {
                  alarmEvent.isRead = true;
				  alarmEvent.readUser = userName;
				  const alarmDate = new Date().getTime();
				  alarmEvent.readDate = moment.unix(alarmDate).format('DD-MM-YYYY HH:mm:ss');
				  let iconId = '#icon_uncheck_' + String(alarmEvent.id);
                  $(iconId).removeClass('show_icon');
                  $(iconId).addClass('hide_icon');
                  iconId = '#icon_check_' + String(alarmEvent.id);
                  $(iconId).removeClass('hide_icon');
                  $(iconId).addClass('check_icon show_icon');
                  save(alarmEvent, api, 'check');
                }
              }}
              title={'Marcar como leido'}
            />
          </span>
		  </>
		)}
      </div>
      {showCardInfo && alarmEvent.alarmSubtitle !== 'No existen registros para mostrar' && (
        <div className="alarmInfoContainer">
          <div id={'alarmline-wrapper' + alarmEvent.id} className={alarmDetailedText  + ' color_' + alarmEvent.color + alarmTheme}>
            {alarmEvent.alarmDetailedText}
          </div>
        </div>
      )}
    </div>
  ));
}

// @ts-nocheck
import { sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';

import { PanelProps } from '@grafana/data';
import { getTemplateSrv, config } from '@grafana/runtime';
import { LoadingPlaceholder, Icon } from '@grafana/ui';

import { contextSrv } from 'app/core/services/context_srv';
import { labelsMatchMatchers, parseMatchers } from 'app/features/alerting/unified/utils/alertmanager';
import { flattenRules, getFirstActiveAt } from 'app/features/alerting/unified/utils/rules';
import {
  getAllRulesSourceNames,
  NETMONITOR_DATASOURCE_NAME,
  NETMONITOR_RULES_SOURCE_NAME,
} from 'app/features/alerting/unified/utils/datasource';

import { fetchAllPromRulesAction } from 'app/features/alerting/unified/state/actions';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';

import { PromRuleWithLocation } from 'app/types/unified-alerting';

import alertDef from 'app/features/alerting/state/alertDef';

import GroupedModeView from './GroupedView';
import { SortOrder, AlertListOptions } from './types';
import './css/AlertListPanel.css';

interface Props extends PanelProps<AlertListOptions> {}

export const AlertListPanel: React.FC<Props> = React.memo(({ options, width, height, replaceVariables, id }) => {
  let isAdmin = contextSrv.isNetMonitorAdmin;
  if (options.editorCanAdmin && contextSrv.isEditor) {
    isAdmin = true;
  }
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const RULE_LIST_POLL_INTERVAL_MS = 200000;

  const isDark = config.theme.isDark;
  const showTitle = options.showTitle;
  const pluginTitle = replaceVariables(options.pluginTitle);
  var legendPosition = String(options.legendPosition);
  const hideControls = options.hideControls;

  const dispatch = useDispatch();
  const rulesDataSourceNames = useMemo(getAllRulesSourceNames, []);

  const noAlertMessage = options.noAlertMessage || 'No existen procesos definidos';
  const [netmonitorRules, setNetmonitorRules] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllPromRulesAction());
    const interval = setInterval(() => dispatch(fetchAllPromRulesAction()), RULE_LIST_POLL_INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const variableName = replaceVariables(options.alertInstanceLabelFilter);
  const replacedLabelFilter = variableName !== '' ? getNetMonitorVariableValue(variableName, false) : '';
  const promRulesRequests = useUnifiedAlertingSelector((state) => state.promRules);
  const dispatched = rulesDataSourceNames.some((name) => promRulesRequests[name]?.dispatched);
  const loading = rulesDataSourceNames.some((name) => promRulesRequests[name]?.loading);
  const haveResults = rulesDataSourceNames.some(
    (name) => promRulesRequests[name]?.result?.length && !promRulesRequests[name]?.error
  );

  const labels = useMemo(() => ({
    labels: replacedLabelFilter,
  }), [replacedLabelFilter]);

  const rules = filterRules(labels.labels, options.folder, options.datasource, sortRules(
    options.sortOrder,
    Object.values(promRulesRequests).flatMap(({ result = [] }) => flattenRules(result))
  ));

  const noAlertsMessage = rules.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }

  if (noAlertsMessage) {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
	<div className="alertListChart" id={'alertlistChart_' + id}>
	  {showTitle && (
		<div className={isDark ? 'alertListtitle_dark' : 'alertListtitle'} style={{ width: width - 30 }}>
		  <div className={'alertListtitleText'}>{pluginTitle}</div>
		</div>
	  )}
	  <div>
        {dispatched && loading && !haveResults && <LoadingPlaceholder text="Cargando..." />}
        <section style={{ width: width, height: height, overflow: 'auto' }}>
          {haveResults && (
            <GroupedModeView rules={rules} options={options} isAdmin={isAdmin} width={width}/>
          )}
        </section>
	  </div>
	</div>
  );
});

function sortRules(sortOrder: SortOrder, rules: PromRuleWithLocation[]) {
  if (sortOrder === SortOrder.Importance) {
    // @ts-ignore
    return sortBy(rules, (rule) => alertDef.alertStateSortScore[rule.state]);
  } else if (sortOrder === SortOrder.TimeAsc) {
    return sortBy(rules, (rule) => getFirstActiveAt(rule.rule) || new Date());
  } else if (sortOrder === SortOrder.TimeDesc) {
    return sortBy(rules, (rule) => getFirstActiveAt(rule.rule) || new Date()).reverse();
  }
  const result = sortBy(rules, (rule) => rule.rule.name.toLowerCase());
  if (sortOrder === SortOrder.AlphaDesc) {
    result.reverse();
  }

  return result;
}

function filterRules(labelFiltered, folder, datasource, rules: PromRuleWithLocation[]) {
  let filteredRules = [...rules];
  if (labelFiltered) {
    const matchers = parseMatchers(labelFiltered);
    filteredRules = filteredRules.reduce<PromRuleWithLocation[]>((rules, rule) => {
      const filteredAlerts = (rule.rule.alerts ?? []).filter(({ labels }) => labelsMatchMatchers(labels, matchers));
	  if (filteredAlerts.length) {
        rules.push({ ...rule, rule: { ...rule.rule, alerts: filteredAlerts } });
      }
      return rules;
    }, []);
  }

  if (folder) {
    filteredRules = filteredRules.filter((rule) => {
      return rule.namespaceName === folder.title;
    });
  }
  if (datasource) {
    const isNetMonitorDS = datasource === NETMONITOR_DATASOURCE_NAME;

    filteredRules = filteredRules.filter(
      isNetMonitorDS
        ? ({ dataSourceName }) => dataSourceName === NETMONITOR_RULES_SOURCE_NAME
        : ({ dataSourceName }) => dataSourceName === datasource
    );
  }
  return filteredRules;
}

function getNetMonitorVariableValue(variableName: any, isMulti: boolean) {
  if (isMulti) {
    const values: string[] = [];
    getTemplateSrv().replace(`$${variableName}`, {}, (value: string | string[]) => {
      if (Array.isArray(value)) {
        values.push(...value);
      } else {
        values.push(value);
      }
      return '';
    });
    return values;
  } else {
    return getTemplateSrv().replace(`$${variableName}`);
  }
}
import React from 'react';
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
//import { format } from "d3-format";

import GaugeChart from "react-gauge-chart";
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { SpeedometerOptions, SpeedData, Intervals, BateryLines } from 'types';
import { config } from '@grafana/runtime';
import './css/speedometerPanel.css';

import LiquidFillGauge from 'react-liquid-gauge/lib/index';

interface Props extends PanelProps<SpeedometerOptions> {}

export const SpeedometerPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const chartTitle = replaceVariables(options.chartTitle);

  if (
    ((options.chartType === 'Thermometer' && width < 100) || (options.chartType !== 'Thermometer' && width < 120)) ||
    ((options.chartType === 'Thermometer' && height < 120) || (options.chartType !== 'Thermometer' && height < 100))
  ) {
    return (
	  <div className="gaugeChartErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="gaugeChartErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="gaugeChartErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xxl" />
	  </div>
	);
  }

  const needleColor = options.needleColor;
  let fontColor = options.fontColor;
  if (options.useThemeFontColor) {
    fontColor = '#111';
    if (isDark) {
      fontColor = '#eee';
    }
  }
  let onfire = false;
  const drillDownLink = replaceVariables(options.drillDownLink);
  const validUrl = (drillDownLink !== null && drillDownLink !== '') ? true : false;

  let segmentStops: number[] = [0.5, 0.3, 0.2];
  var colors = options.segmentColors;
  if (options.useColorScale) {
    if (options.colorScale === 'Orange') {
      colors = 'Gold,Orange,Red';
    } else if (options.colorScale === 'Blue') {
      colors = 'Violet,MediumPurple,DarkSlateBlue';
    } else {
      colors = 'LightGreen,YellowGreen,DarkGreen';
    }
  }
  const segmentColors = colors.split(',', 3);
  const gaugeData: SpeedData[] = [];

  data.series.forEach(series => {
	const speedValues: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < speedValues.length; i++) {
	  let tableData: SpeedData = {
		id: i,
		width: width,
		height: height,
		maxValue: Number(series.fields.find(field => field.name === options.maxValueField)?.values.get(i)),
		minValue: Number(series.fields.find(field => field.name === options.minValueField)?.values.get(i)),
		valueTitle: String(options.valueTitle),
		value: Number(series.fields.find(field => field.name === options.valueField)?.values.get(i)),
		threshold: Number(series.fields.find(field => field.name === options.thresholdField)?.values.get(i)),
		secondThreshold: Number(
		  series.fields.find(field => field.name === options.secondThresholdField)?.values.get(i)
		),
		currentValueText: String(series.fields.find(field => field.name === options.currentValueField)?.values.get(i)),
		info: String(series.fields.find(field => field.name === options.infoField)?.values.get(i)),
		infoTitle: String(options.infoTitle),
		needleColor: String(needleColor),
		needleSize: Number(options.needleSize),
		startColor: String(options.startColor),
		textColor: String(fontColor),
		endColor: String(options.endColor),
		warningColor: String(options.warningColor),
		labelFontSize: options.labelFontSize > 8 ? options.labelFontSize : 8,
		valueTextFontSize: options.valueFontSize > 12 ? options.valueFontSize : 12,
		ringWidth: 100 - options.ringWidth,
		showSegments: options.showSegments,
		segmentsLength: 3,
		segmentStops: segmentStops,
		segmentColors: segmentColors,
		displaySegmentsLegends: options.displaySegmentsLegends,
		showText: options.showCurrentValue,
	  };
	  if (tableData.maxValue === undefined || tableData.maxValue < tableData.minValue) {
		tableData.maxValue = 100;
	  }
	  if (tableData.minValue === undefined || tableData.minValue > tableData.maxValue) {
		tableData.minValue = 0;
	  }
	  if (tableData.value !== undefined && tableData.value > tableData.maxValue) {
		tableData.value = tableData.maxValue;
	  } else if (tableData.value !== undefined && tableData.value < tableData.minValue) {
		tableData.value = tableData.minValue;
	  }
	  if (tableData.currentValueText === undefined || tableData.currentValueText === null) {
		tableData.currentValueText = String(tableData.value);
	  }
	  if (tableData.showSegments === false && options.chartType === 'BatteryGauge') {
		tableData.segmentsLength = 10;
	  } else if (options.chartType === 'GaugeChart' || options.chartType === 'Speedometer') {
		let newSegmentStops: number[] = [];
		let newSegmentColors: string[] = [];
		if (tableData.showSegments === true && options.segmentsLength > 1 && options.segmentStops !== null) {
		  tableData.segmentsLength = options.segmentsLength;
		  const newStops = options.segmentStops.split(',', options.segmentsLength);
		  const newColors = options.segmentColors.split(',', options.segmentsLength);
		  if (newStops.length < options.segmentsLength) {
			tableData.segmentsLength = newStops.length;
		  }
		  for (let i = 0; i < newStops.length; i++) {
			let stop = Number(newStops[i]);
			if (stop > tableData.maxValue) {
			  stop = tableData.maxValue;
			} else if (stop < tableData.minValue) {
			  stop = tableData.minValue;
			}
			if (i < 1) {
			  newSegmentStops[i] = (stop - tableData.minValue);
			} else {
			  let previousStop = Number(newStops[i - 1]);
			  newSegmentStops[i] = (stop - previousStop) + newSegmentStops[i - 1];
			}
			if (newColors[i] !== undefined && newColors[i] !== null && newColors[i] !== '') {
			  newSegmentColors[i] = newColors[i];
			} else {
			  newSegmentColors[i] = isDark ? 'black' : 'white';
			}
		  }
		} else {
		  tableData.segmentsLength = 3;
		  newSegmentStops[0] = 1;
		  newSegmentStops[1] = 0.75;
		  newSegmentStops[2] = 0;
		  if (options.useColorScale) {
			if (options.colorScale === 'Orange') {
			  colors = 'Gold,Orange,Red';
			} else if (options.colorScale === 'Blue') {
			  colors = 'Violet,MediumPurple,DarkSlateBlue';
			} else {
			  colors = 'LightGreen,YellowGreen,DarkGreen';
			}
		  }
		}
		tableData.segmentColors = newSegmentColors;
		tableData.segmentStops = newSegmentStops;
	  } else if (options.chartType === 'Thermometer') {
		let newSegmentStops: number[] = [];
		let scale = tableData.maxValue - tableData.minValue;
		if (tableData.showSegments && options.segmentStops !== '') {
		  const newStops = options.segmentStops.split(',', options.segmentsLength);
		  for (let i = 0; i < newStops.length; i++) {
			let stop = Number(newStops[i]);
			if (i < 1) {
			  newSegmentStops[i] = (stop - tableData.minValue) / scale;
			} else {
			  let previousStop = Number(newStops[i - 1])
			  newSegmentStops[i] = (stop - previousStop) / scale;
			}
		  }
		} else {
		  newSegmentStops[0] = tableData.minValue;
		  newSegmentStops[1] = (tableData.maxValue - tableData.minValue) / 2;
		  newSegmentStops[2] = tableData.maxValue;
		}
		tableData.segmentStops = newSegmentStops;
	  } else if (options.chartType === 'BatteryGauge') {
		tableData.segmentsLength = 10;
	  }

	  if (tableData.textColor === '' && tableData.textColor === undefined) {
		tableData.textColor = fontColor;
	  }
	  if (tableData.threshold === undefined || tableData.threshold > tableData.maxValue) {
		tableData.threshold = tableData.maxValue;
	  } else if (tableData.threshold !== undefined && tableData.threshold < tableData.minValue) {
		tableData.threshold = tableData.minValue;
	  }
	  if (tableData.secondThreshold === undefined || tableData.secondThreshold > tableData.maxValue) {
		tableData.secondThreshold = tableData.maxValue;
	  } else if (tableData.secondThreshold !== undefined && tableData.secondThreshold < tableData.minValue) {
		tableData.secondThreshold = tableData.minValue;
	  }
	  if (options.useThreshold && !options.useSecondThreshold) {
		onfire = false;
		if (options.reverseThreshold && tableData.value < tableData.threshold) {
		  onfire = true;
		} else if (!options.reverseThreshold && tableData.value > tableData.threshold) {
		  onfire = true;
		}
	  } else if (options.useThreshold && options.useSecondThreshold) {
		onfire = false;
		if (options.reverseThreshold) {
		  if (tableData.value < tableData.threshold || tableData.value > tableData.secondThreshold) {
			onfire = true;
		  }
		} else {
		  if (tableData.value > tableData.threshold || tableData.value < tableData.secondThreshold) {
			onfire = true;
		  }
		}
	  }
	  if (options.chartType !== 'GaugeChart' && options.chartType !== 'Speedometer' && onfire) {
		tableData.textColor = String(options.warningColor);
	  }
	  if (tableData.currentValueText === undefined) {
		tableData.currentValueText = String(tableData.value);
	  }
	  if (tableData.info === undefined) {
		tableData.info = String(tableData.value);
	  }
	  gaugeData.push(tableData);
	}
  });

  return (
	<div>
	  {options.chartType === 'Speedometer' && (
		<div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
		  {speedometerFactory(
			id,
			gaugeData,
			width,
			height,
			options.showCurrentValue,
			onfire,
			validUrl,
			options.valueUnit,
			options.arcPadding,
			options.showInfoField,
			options.warningColor
		  )}
		</div>
	  )}
	  {options.chartType === 'GaugeChart' && (
		<div className="speedometer_container" >
		  {gaugeFactory(
			id,
			gaugeData,
			width,
			height,
			options.show360,
			options.showCurrentValue,
			onfire,
			validUrl,
			options.valueUnit,
			options.backgroundColor,
			options.showInfoField,
			options.warningColor,
			drillDownLink,
			chartTitle
		  )}
		</div>
	  )}
	  {options.chartType === 'Thermometer' && (
		<div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
		  {thermometerFactory(
			id,
			gaugeData,
			width,
			height,
			options.showCurrentValue,
			onfire,
			validUrl,
			options.revertColorScale,
			options.valueUnit,
			options.showInfoField,
			options.displaySegmentsLegends
		  )}
		</div>
	  )}
	  {options.chartType === 'LiquidFillGauge' && (
		<div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
		  {liquidFillGaugeFactory(
			id,
			gaugeData,
			width,
			height,
			options.showCurrentValue,
			options.chartCentered,
			onfire,
			validUrl,
			options.valueUnit,
			options.needleColor,
			options.warningColor,
			options.showInfoField
		  )}
		</div>
	  )}
	  {options.chartType === 'BatteryGauge' && (
		<div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
		  {batteryFactory(
			id,
			gaugeData,
			width,
			height,
			options.showCurrentValue,
			options.chartCentered,
			onfire,
			validUrl,
			options.revertColorScale,
			options.valueUnit,
			options.showInfoField
		  )}
		</div>
	  )}
	</div>
  );
};

function openUrl(url: string) {
  if (url !== null && url !== '') {
    window.open(url, '_self');
  }
}

function speedometerFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  arcPadding: number,
  showInfoField: boolean,
  warningColor: string
) {
  const isDark = config.theme.isDark;
  const theme = isDark ? 'dark' : 'light';
  const tooltipClass = onfire ? 'SpeedOdometerTooltip SpeedOdometerTooltipWarn' : 'SpeedOdometerTooltip';
  const legendClass = isDark ? 'GaugueLegendDark' : 'GaugueLegend'
  const SpeedOdometerLegend = onfire ? 'GaugueLegendFire' : legendClass;
  const lineColor = isDark ? '#555' : '#aaa';

  const svgWidth = width * 0.98;
  const svgHeight = width > (height * 1.5) ? height * 0.75 : height * 0.98;

  const label = tableData.map(data => {
    var tooltipLabel = '<div>';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_center">';
	  tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_left">';
	tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
	tooltipLabel = tooltipLabel + '</span></div>';
    return tooltipLabel;
  });
  
  const showTooltip = (e) => {
	let x = e.pageX > e.view.innerWidth - 160 ? e.view.innerWidth - 160 : e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const gaugeSVG = tableData.map(data => {
	const sortedIndices = data.segmentStops.map((_, index) => index).sort((a, b) => data.segmentStops[b] - data.segmentStops[a]);
    const sortedArray1 = sortedIndices.map(index => data.segmentStops[index]);
    const sortedArray2 = sortedIndices.map(index => data.segmentColors[index]);
	const scale = data.maxValue - data.minValue;
    const scalesValues = [];
    for (let i = 0; i < sortedIndices.length; i++) {
      if (i === 0) {
	  	scalesValues.push({
		  realValue: data.maxValue,
		  segmentStart: (data.minValue + sortedArray1[i]) > data.maxValue ? data.maxValue : (sortedArray1[i] / scale),
		  segmentStop: 1,
          color: sortedArray2[i],
          index: i,
        });
	  } else if (i === sortedIndices.length - 1) {
	    scalesValues.push({
		  realValue: data.minValue + sortedArray1[i-1],
		  segmentStart: 0,
		  segmentStop: sortedArray1[i-1] / scale,
          color: sortedArray2[i],
          index: i,
        });
	  } else {
	    scalesValues.push({
		  realValue: data.minValue + sortedArray1[i-1],
		  segmentStart: (data.minValue + sortedArray1[i]) > data.maxValue ? data.maxValue : (sortedArray1[i] / scale),
		  segmentStop: sortedArray1[i-1] / scale,
          color: sortedArray2[i],
          index: i,
        });
	  }
    }

    const percentScale = scaleLinear()
      .domain([data.minValue, data.maxValue])
      .range([0, 1]);
    const percent = percentScale(data.value);
    const angleScale = scaleLinear()
      .domain([0, 1])
      .range([-Math.PI / 1.8, Math.PI / 1.8])
      .clamp(true);
    const angle = angleScale(percent);
    const getCoordsOnArc = (angle, offset = 1) => [
      Math.cos(angle - Math.PI / 2) * offset,
      Math.sin(angle - Math.PI / 2) * offset
    ];
    const colorScale = scaleLinear()
      .domain([0, 1])
      .range([data.startColor, data.endColor]);

	const ceroText = chekValueUnit(data.minValue);
	const ceroPosition = angleScale(0);
	const ceroIndicator = getCoordsOnArc(ceroPosition, 1);
	const ceroLine = arc()
	  .innerRadius(0.90)
	  .outerRadius(0.98)
	  .startAngle((-Math.PI / 1.8) + 0.01)
	  .endAngle(-Math.PI / 1.8)
	  .cornerRadius(0)();
    
	
	const useThreshold = (data.threshold > data.minValue && data.threshold < data.maxValue) ? true : false;
	const threshold = percentScale(data.threshold);
	const thresholdPosition = angleScale(threshold);
	const thresholdLine = arc()
	  .innerRadius((data.ringWidth - 12) / 95)
	  .outerRadius((data.ringWidth - 8) / 95)
	  .startAngle(thresholdPosition - 0.005)
	  .endAngle(thresholdPosition + 0.005)
	  .cornerRadius(0)();
	
	const useThreshold2 = (data.secondThreshold > data.minValue && data.secondThreshold < data.maxValue) ? true : false;
    const threshold2 = percentScale(data.secondThreshold);
	const threshold2Position = angleScale(threshold2);
	const threshold2Line = arc()
	  .innerRadius((data.ringWidth - 12) / 95)
	  .outerRadius((data.ringWidth - 8) / 95)
	  .startAngle(threshold2Position - 0.005)
	  .endAngle(threshold2Position + 0.005)
	  .cornerRadius(0)();

    const markerLocation = getCoordsOnArc(angle, 1 - (1 - (data.needleSize / 100)) / 1.8);
	const markerRadius = data.maxValue > 99 ? 0.07 : 0.05;

	const diagonalMayor = Math.sqrt(Math.pow(markerLocation[0], 2) + Math.pow(markerLocation[1], 2));
	const diagonalMenor = diagonalMayor / 10;
	const angleRadians = Math.atan2(markerLocation[1], markerLocation[0]);

	const x2 = 0;
	const y2 = 0;
	const x3 = markerLocation[0];
	const y3 = markerLocation[1];
	const x4 = diagonalMenor / 2 * Math.cos(angleRadians + Math.PI / 4);
	const y4 = diagonalMenor / 2 * Math.sin(angleRadians + Math.PI / 4);
	const x5 = diagonalMenor / 2 * Math.cos(angleRadians - Math.PI / 4);
	const y5 = diagonalMenor / 2 * Math.sin(angleRadians - Math.PI / 4);
    const points = `${x2},${y2} ${x4},${y4} ${x3},${y3} ${x5},${y5}`;

    return (
      <div key={id} className="speedometer_box" id={'speedometer_box_' + id}>
        <div
          style={{
            textAlign: 'center',
			marginTop: 10
          }}
        >
          <svg
            width={svgWidth}
            height={svgHeight}
            viewBox={[-1, -1, 2, 1].join(' ')}
            style={{
              overflow: 'visible'
            }}
            onMouseMove={showTooltip}
            onMouseOut={hideTooltip}
          >
            <defs>
			  <linearGradient
			    id={'Line_gradient_' + id}
			    gradientUnits="userSpaceOnUse"
				x1="0"
				y1="0"
				x2={markerLocation[0]}
				y2={markerLocation[1]}
			  >
			    <stop offset="0%" stop-color={onfire ? '#ff0000' : lineColor} stop-opacity="1" />
			    <stop offset="100%" stop-color={onfire ? '#ff0000' : lineColor} stop-opacity="0" />
			  </linearGradient>
			  <filter id={'SegmentShadow_' + id}>
			    <feGaussianBlur in="SourceAlpha" stdDeviation="0.005" />
			    <feOffset dx="0.01" dy="0.005" result="offsetblur" />
			    <feFlood flood-color={isDark ? '#cdcdcd' : '#242424'} flood-opacity="0.4"/>
			    <feComposite in2="offsetblur" operator="in" />
			    <feMerge>
				  <feMergeNode />
				  <feMergeNode in="SourceGraphic" />
			    </feMerge>
			  </filter>
            </defs>
			{data.showSegments && scalesValues.map(value => {
			  const segmentScale = scaleLinear()
			    .domain([0, 1])
			    .range([-Math.PI / 1.8, Math.PI / 1.8])
			    .clamp(true);
			  const segmentStart = segmentScale(value.segmentStart);
			  const segmentStop = segmentScale(value.segmentStop);
			  const segmentArc = arc()
			    .innerRadius((data.ringWidth - 5) / 95)
			    .outerRadius(0.95)
			    .startAngle(segmentStart + (arcPadding / 200))
			    .endAngle(segmentStop - (arcPadding / 200))
			    .cornerRadius(0.25)();
			  const segmentLine = arc()
			    .innerRadius(0.90)
			    .outerRadius(0.98)
			    .startAngle(segmentStop - 0.005)
			    .endAngle(segmentStop + 0.005)
			    .cornerRadius(0)();
			  const indicator = getCoordsOnArc(segmentStop, 1);
			  const indicatorText = chekValueUnit(value.realValue);
			  return (
				<g key={value.index}>
				  <path d={segmentLine} fill={isDark ? '#aaa' : '#555'} />
				  <g>
				    <circle cx={indicator[0]} cy={indicator[1]} r={markerRadius} fill={isDark ? '#101010' : '#fafafa'} />
				    <text 
				      x={indicator[0]}
					  y={indicator[1]}
					  dy="0.01"
					  letter-spacing="0.01"
					  textAnchor="middle"
					  fill={data.textColor}
					  fontSize={(data.labelFontSize - 8) / 100}
				    >
					  {indicatorText}
				    </text>
				  </g>
				  <g key={value.index + '_s'} filter={'url(#SegmentShadow_' + id + ')'}>
				    <path d={segmentArc} fill={value.color} />
				  </g>
				</g>
			  );
		    })}
			{data.showSegments && scalesValues.length > 0 && (
			  <g key={scalesValues.length}>
				<path d={ceroLine} fill={isDark ? '#aaa' : '#555'} />
				<g>
				  <circle cx={ceroIndicator[0]} cy={ceroIndicator[1]} r={markerRadius} fill={isDark ? '#101010' : '#fafafa'} />
				  <text 
				    x={ceroIndicator[0]}
				    y={ceroIndicator[1]}
					dy="0.01"
					letter-spacing="0.01"
					textAnchor="middle"
					fill={data.textColor}
					fontSize={(data.labelFontSize - 8) / 100}
				  >
					{ceroText}
				  </text>
				</g>
			  </g>
		    )}
			{data.showSegments && useThreshold && (
			  <path d={thresholdLine} fill="red" />
			)}
			{data.showSegments && useThreshold2 && (
			  <path d={threshold2Line} fill="red" />
			)}
			<polygon points={points} fill={'url(#Line_gradient_' + id + ')'} filter={'url(#SegmentShadow_' + id + ')'} />
            <circle
              cx="0"
              cy="0"
              r={0.05}
              stroke={isDark ? '#a0a0a0' : '#5A6171'}
              strokeWidth="0.01"
              fill={onfire ? warningColor : "#555"}
			  fill-opacity="1"
            />
			<text 
			  x="0"
			  y="0.35"
			  letter-spacing="0.01"
			  textAnchor="middle"
			  fontSize={(data.valueTextFontSize - 8) / 100}
			  className={SpeedOdometerLegend}
			>
			  {showText ? data.currentValueText : valueText}
			</text>
          </svg>
        </div>
      </div>
    );
  });
  return gaugeSVG;
}

function chekValueUnit(value: number) {
  let indicatorText = value > 99 ? value.toFixed(0) : value.toFixed(1);
  if (value > 999) {
	const aux = value / 1000;
	indicatorText = value > 99999 ? aux.toFixed(0) + 'K' : aux.toFixed(1) + 'K';
  } else if (value > 999999) {
	const aux = valuee / 1000000;
	indicatorText = value > 99999999 ? aux.toFixed(0) + 'M' : aux.toFixed(1) + 'M';
  } else if (value > 999999999) {
	const aux = value / 1000000000;
	indicatorText = value > 99999999999 ? aux.toFixed(0) + 'G' : aux.toFixed(1) + 'G';
  }
  return indicatorText;
}

function gaugeFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  show360: boolean,
  showText: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  backgroundColor: string,
  showInfoField: boolean,
  warningColor: string,
  drillDownLink: string,
  chartTitle: string
) {
  const isDark = config.theme.isDark;
  const theme = isDark ? 'dark' : 'light';
  const tooltipClass = onfire ? 'SpeedOdometerTooltip SpeedOdometerTooltipWarn' : 'SpeedOdometerTooltip';
  const legendClass = isDark ? 'GaugueLegendDark' : 'GaugueLegend'
  const legendClassLink = isDark ? 'GaugueLegendLinkDark' : 'GaugueLegendLink'
  const SpeedOdometerLegend = onfire ? 'GaugueLegendFire' : legendClass;
  const lineColor = isDark ? '#F8DFE9' : '#5A6171';
  const backColor = isDark ? '#1B2733' : '#D8DFE9';
  const fontColor = isDark ? '#D8DFE9' : ' #1B2733';
  const circleColor = isDark ? '#585A5E' : ' #9DA5B8';
  const background = backgroundColor === 'text' ? backColor : backgroundColor;
  const circlePercent =  show360 ? 1.1 : 1.8;

  const svgHeight = ((width > (height * 1.5)) && !show360) ? height * 0.75 : height;

  const label = tableData.map(data => {
    var tooltipLabel = '<div>';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_center">';
	  tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_left">';
	tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
	tooltipLabel = tooltipLabel + '</span></div>';
    return tooltipLabel;
  });
  
  const showTooltip = (e) => {
	let x = e.pageX > e.view.innerWidth - 160 ? e.view.innerWidth - 160 : e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const gaugeSVG = tableData.map(data => {
    const innerRadiusColor = isDark ? '#23282E' : '#E6E9ED';
	const sortedIndices = data.segmentStops.map((_, index) => index).sort((a, b) => data.segmentStops[b] - data.segmentStops[a]);
    const sortedArray1 = sortedIndices.map(index => data.segmentStops[index]);
	const scale = data.maxValue - data.minValue;
    const scalesValues = [];
    for (let i = 0; i < sortedIndices.length; i++) {
      let indexValue = sortedArray1[i];
	  if (i < 1) {
	  	scalesValues.push({
		  segmentStart: sortedArray1[i] / scale,
		  segmentStop: 1,
          index: i,
        });
	  } else {
	    scalesValues.push({
		  segmentStart: sortedArray1[i] / scale,
		  segmentStop: sortedArray1[i-1] / scale,
          index: i,
        });
	  }
    }
	scalesValues.push({
	  segmentStart: 0,
	  segmentStop: sortedArray1[sortedIndices.length - 1] / scale,
      index: sortedIndices.length + 1,
    });

	const currentValue = data.value;
	const valueText = currentValue.toFixed(2) + ' ' + unit;

	const backgroundArc = arc()
      .innerRadius(data.ringWidth / 95)
      .outerRadius(0.95)
      .startAngle(-Math.PI / circlePercent)
      .endAngle(Math.PI / circlePercent)
      .cornerRadius(0.25)();
    const percentScale = scaleLinear()
      .domain([data.minValue, data.maxValue])
      .range([0, 1]);
    const percent = percentScale(currentValue);
    const angleScale = scaleLinear()
      .domain([0, 1])
      .range([-Math.PI / circlePercent, Math.PI / circlePercent])
      .clamp(true);
    const angle = angleScale(percent);
    const filledArc = arc()
      .innerRadius(data.ringWidth / 95)
      .outerRadius(0.95)
      .startAngle(-Math.PI / circlePercent)
      .endAngle(angle)
      .cornerRadius(0.25)();
	const ceroLine = arc()
	  .innerRadius(0.96)
	  .outerRadius(1)
	  .startAngle((-Math.PI / circlePercent) + 0.01)
	  .endAngle(-Math.PI / circlePercent)
	  .cornerRadius(0)();

	const useThreshold = (data.threshold > data.minValue && data.threshold < data.maxValue) ? true : false;
	const threshold = percentScale(data.threshold);
	const thresholdPosition = angleScale(threshold);
	const thresholdLine = arc()
	  .innerRadius((data.ringWidth - 12) / 95)
	  .outerRadius((data.ringWidth - 8) / 95)
	  .startAngle(thresholdPosition - 0.005)
	  .endAngle(thresholdPosition + 0.005)
	  .cornerRadius(0)();
	
	const useThreshold2 = (data.secondThreshold > data.minValue && data.secondThreshold < data.maxValue) ? true : false;
    const threshold2 = percentScale(data.secondThreshold);
	const threshold2Position = angleScale(threshold2);
	const threshold2Line = arc()
	  .innerRadius((data.ringWidth - 12) / 95)
	  .outerRadius((data.ringWidth - 8) / 95)
	  .startAngle(threshold2Position - 0.005)
	  .endAngle(threshold2Position + 0.005)
	  .cornerRadius(0)();

    const colorScale = scaleLinear()
      .domain([0, 1])
      .range([data.startColor, data.endColor]);

    const gradientSteps = colorScale.ticks(data.segmentsLength).map(currentValue => colorScale(currentValue));

    const getCoordsOnArc = (angle, offset = 1) => [
      Math.cos(angle - Math.PI / 2) * offset,
      Math.sin(angle - Math.PI / 2) * offset
    ];

    const markerLocation = show360 ?
	  getCoordsOnArc(angle, 1 - (1 - (data.ringWidth / 88)) / circlePercent) :
	  getCoordsOnArc(angle, 1 - (1 - (data.ringWidth / 97)) / circlePercent);

	const circleClass = isDark ? 'gaugeChartInnerCircle_dark' : 'gaugeChartInnerCircle_light';

    return (
      <div key={id} className="speedometer_box" id={'speedometer_box_' + id}>
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <svg
            width={width}
            height={show360 ? height : svgHeight}
            viewBox={show360 ? [-1, -1, 2, 2].join(' ') : [-1, -1, 2, 1].join(' ')}
            style={{
              overflow: 'visible'
            }}
          >
            <defs>
              <linearGradient
                id={'Gauge_gradient_' + id}
                gradientUnits="userSpaceOnUse"
                x1="-1"
                x2="1"
                y2="0"
              >
                {gradientSteps.map((color, index) => (
                  <stop
                   key={color}
                    stopColor={color}
                    offset={`${index / (gradientSteps.length - 1)}`}
                  />
                ))}
              </linearGradient>
			  {!show360 && (
			    <linearGradient
			      id={'Line_gradient_' + id}
			      gradientUnits="userSpaceOnUse"
				  x1="0"
				  y1="0"
				  x2={markerLocation[0]}
				  y2={markerLocation[1]}
			    >
			      <stop offset="0%" stop-color={onfire ? warningColor : lineColor} stop-opacity="1" />
			      <stop offset="100%" stop-color={onfire ? warningColor : lineColor} stop-opacity="0" />
			    </linearGradient>
			  )}
			  {!show360 && (
			    <radialGradient id={'Gauge_needle_' + id} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
				  <stop offset="40%" stop-color={onfire ? warningColor : data.needleColor} stop-opacity="1" />
				  <stop offset="100%" stop-color={onfire ? warningColor : data.needleColor} stop-opacity="0" />
			    </radialGradient>
			  )}
			  <filter id={'dropShadow_' + id} x="-50%" y="-50%" width="200%" height="200%">
			    <feGaussianBlur in="SourceAlpha" stdDeviation="0.04" />
			    <feOffset dx="0.1" dy="0.05" result="offsetblur" />
			    <feFlood flood-color={isDark ? '#44444C' : '#D8DFE9'} flood-opacity="1"/>
			    <feComposite in2="offsetblur" operator="in" />
			    <feMerge>
				  <feMergeNode />
				  <feMergeNode in="SourceGraphic" />
			    </feMerge>
			  </filter>
			  {show360 && (
			    <filter id={'circleShadow_' + id} x="-50%" y="-50%" width="200%" height="200%">
			      <feGaussianBlur in="SourceAlpha" stdDeviation="0.04" />
			      <feOffset dx="0" dy="0" result="offsetblur" />
			      <feFlood flood-color={isDark ? '#9DA5B8' : '#9DA5B8'} flood-opacity="1"/>
			      <feComposite in2="offsetblur" operator="in" />
			      <feMerge>
				    <feMergeNode />
				    <feMergeNode in="SourceGraphic" />
			      </feMerge>
			    </filter>
			  )}
            </defs>
			{data.showSegments && scalesValues.map(value => {
			  const segmentScale = scaleLinear()
			    .domain([0, 1])
			    .range([-Math.PI / circlePercent, Math.PI / circlePercent])
			    .clamp(true);
			  const segmentStart = segmentScale(value.segmentStart);
			  const segmentStop = segmentScale(value.segmentStop);
			  const segmentLine = arc()
			    .innerRadius(0.97)
			    .outerRadius(1)
			    .startAngle(segmentStop - 0.005)
			    .endAngle(segmentStop + 0.005)
			    .cornerRadius(0)();
			  return (
				<g key={value.index}>
				  <path d={segmentLine} fill={isDark ? '#F8DFE9' : '#5A6171'} />
				</g>
			  );
		    })}
			{data.showSegments && scalesValues.length > 0 && (
			  <g key={scalesValues.length - 1}>
			    <path d={ceroLine} fill={isDark ? '#F8DFE9' : '#5A6171'} />
			  </g>
		    )}
			{useThreshold && (
			  <g key={'thresholdDown'}>
			    <path d={thresholdLine} fill={warningColor} />
			  </g>
			)}
			{useThreshold2 && (
			  <g key={'thresholdUp'}>
			    <path d={threshold2Line} fill={warningColor} />
			  </g>
			)}
			{!show360 && (
			  <g>
			    <line 
			      x1="0"
			      y1="0"
			      x2={markerLocation[0]}
			      y2={markerLocation[1]}
			      stroke={'url(#Line_gradient_' + id + ')'}
			      strokeWidth="0.01"
			    />
                <circle
                  cx="0"
                  cy="0"
                  r={data.needleSize / 200}
                  stroke={isDark ? '#F8DFE9' : '#5A6171'}
                  strokeWidth="0.01"
                  fill={onfire ? warningColor : circleColor}
			      fill-opacity="1"
                />
			  </g>
			)}
            <path d={backgroundArc} fill={background} />
            <path d={filledArc} fill={'url(#Gauge_gradient_' + id + ')'} filter={'url(#dropShadow_' + id + ')'} />
            {!show360 && (
			  <g>
                <circle
                  cx={markerLocation[0]}
                  cy={markerLocation[1]}
                  r={data.needleSize / 100}
				  fill={'url(#Gauge_needle_' + id + ')'}
                />
			    {showText && (
			      <text 
				    x={markerLocation[0]}
				    y={markerLocation[1]}
				    dy="0.02"
				    letter-spacing="0.01"
				    textAnchor="middle"
				    fill="white"
				    fontSize={(data.labelFontSize - 6) / 100}
			      >
				    {currentValue.toFixed(0) + unit}
			      </text>
			    )}
			  </g>
			)}
            {!show360 && (
			  <text 
			    x="0"
			    y="0.2"
			    letter-spacing="0.01"
			    textAnchor="middle"
			    fontSize={(data.valueTextFontSize - 10) / 100}
			    className={SpeedOdometerLegend}
			  >
			    {showText ? data.currentValueText : valueText}
			  </text>
			)}
            {show360 && (
		      <g
				key={'innerCircle'}
				onMouseMove={showTooltip}
				onMouseOut={hideTooltip}
			  >
			    <a href={validUrl ? drillDownLink : null} target="_self" className={validUrl ? 'gaugeChartInnerText' : null}>
			      <circle
                    cx="0"
                    cy="0"
		            r={((data.ringWidth) / 95) - 0.15}
		            fill={innerRadiusColor}
		            stroke={'transparent'}
			        strokeWidth={1}
		            filter={'url(#circleShadow_' + id + ')'}
		          />
			      <text 
			        x="0"
			        y="0.1"
			        letter-spacing="0.01"
			        textAnchor="middle"
			        fontSize={(data.valueTextFontSize - 2) / 100}
			        fontWeight="600"
					className={SpeedOdometerLegend}
			      >
			        {showText ? data.currentValueText : valueText}
			      </text>
			    </a>
			  </g>
			)}
			{!showText && show360 && (
			  <g>
			    <text 
				  x="0"
				  y="-0.2"
				  letter-spacing="0.01"
				  textAnchor="middle"
				  fontSize={(data.valueTextFontSize - 2) / 100}
				  fontWeight="500"
				  className={validUrl ? legendClassLink : legendClass}
				  fill={fontColor}
			    >
				  {chartTitle}
			    </text>
			    <text 
			      x="0"
			      y="0.35"
			      letter-spacing="0.01"
			      textAnchor="middle"
			      fontSize={(data.valueTextFontSize - 6) / 100}
				  fontWeight="400"
			      className={validUrl ? legendClassLink : legendClass}
				  fill={fontColor}
			    >
			      {data.currentValueText}
			    </text>
			  </g>
			)}
          </svg>
        </div>
      </div>
    );
  });
  return gaugeSVG;
}

function thermometerFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  onfire: boolean,
  validUrl: boolean,
  revertColorScale: boolean,
  unit: string,
  showInfoField: boolean,
  showLabels: boolean
) {
  let theme = 'light';
  const isDark = config.theme.isDark;
  if (isDark) {
    theme = 'dark';
  }

  let size = 'normal';
  let tooltipClass = 'SpeedOdometerTooltip';
  let SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<div>';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_center">';
	  tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_left">';
	tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
	tooltipLabel = tooltipLabel + '</span></div>';
    return tooltipLabel;
  });
  
  const showTooltip = (e) => {
    let x = data.pageX > data.view.innerWidth - 160 ? data.view.innerWidth - 160 : data.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const thermometer = tableData.map(data => {
    let valstr = data.value.toFixed(2) + unit;
    let valuePercent = Number((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100;
    let reverseGradient = '';
    if (revertColorScale) {
      reverseGradient = 'Reverse';
    }
    let intervals: Intervals[] = [];
    if (data.segmentsLength > 0) {
      let total = data.minValue;
	  let relTotal = 0;
	  let scale = data.maxValue - data.minValue;
	  for (let i = 0; i <= data.segmentsLength; i++) {  
		total = total + (data.segmentStops[i] * scale);
		relTotal = relTotal + data.segmentStops[i];
        let val = Number(total).toFixed(1);
        let relValue = Number(relTotal).toFixed(1);
        if (!isNaN(val)) {
          let interval: Intervals = {
            percent: relValue * 100,
            label: String(val) + unit,
          };
          intervals.push(interval);
        }
      }
    }
    var marginBottom = 10;
    if (showText) {
      marginBottom = 25;
    }
    const stepIntervals = createIntervalsUI(intervals);
    const thermoTheme = `thermometer--theme-${theme}`;
    const thermoSize = `thermometer--${size}`;
    const thermoHeight = { height: `${height - 35}px`, width: `${width}px`, top: `5px` };
    const heightPercent = { height: `${valuePercent}%` };
    const heightBgColor = { height: `calc(${height - 30}px - 57px)` };
    let onfireClass = 'thermometer__onfire';
    if (!onfire) {
      onfireClass = 'thermometer__draw-a';
    }
    return (
      <div key={id} className="speedometer_box" id={'speedometer_box_' + id}>
        <div
          style={{
            width: width,
            height: height - marginBottom,
			top: 5,
            overflow: 'hidden',
            display: 'flex',
            position: 'relative',
          }}
          onMouseMove={showTooltip}
          onMouseOut={hideTooltip}
        >
          <div style={thermoHeight} className={`thermometer ${thermoSize} ${thermoTheme}`}>
            <div className={onfireClass}></div>
            <div className={`thermometer__draw-b${reverseGradient}`}></div>
            <div className="thermometer__meter">
              {showLabels && (
			    <ul className="thermometer__statistics">{stepIntervals}</ul>
			  )}
              <div style={heightPercent} className="thermometer__mercury">
                <div className="thermometer__percent-current">{valstr}</div>
                <div className="thermometer__mask">
                  <div className={`thermometer__bg-color${reverseGradient}`} style={heightBgColor}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showText && (
          <div
            style={{
              color: String(data.textColor),
              fontSize: `${data.valueTextFontSize / 16}em`,
              fontWeight: '500'
            }}
            className={SpeedOdometerLegend}
          >
            {data.currentValueText}
          </div>
        )}
      </div>
    );
  });
  return thermometer;
}

function createIntervalsUI(intervals: Intervals[]) {
  return intervals.map((step, i) => {
    return (
      <li key={i} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
        {step.label}
      </li>
    );
  });
}

function liquidFillGaugeFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  needleColor: string,
  warningColor: string,
  showInfoField: boolean
) {
  const panelMinDimension = Math.min(width, height);
  let tooltipClass = 'SpeedOdometerTooltip';
  var SpeedOdometerLegend = 'SpeedOdometerLegend';
  let waveColor = {
    fill: needleColor,
  };
  let waveTextStyle = {
    fill: '#04CA96',
  };
  let textColor = {
    fill: '#1B2733',
  };
  let circleStyle = {
    fill: '#5D2BE9',
  };
  const isDark = config.theme.isDark;
  if (isDark) {
    waveTextStyle = {
      fill: '#58F3A8',
    };
    textColor = {
      fill: '#EFF4FA',
    };
    circleStyle = {
      fill: '#936B77',
    };
  }
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    waveColor = {
      fill: warningColor,
    };
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<div>';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_center">';
	  tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_left">';
	tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
	tooltipLabel = tooltipLabel + '</span></div>';
    return tooltipLabel;
  });

  const showTooltip = (e) => {
    let x = data.pageX > data.view.innerWidth - 160 ? data.view.innerWidth - 160 : data.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  var backgroundClass = {
    width: panelMinDimension,
    height: panelMinDimension,
    backgroundImage: `url(/public/img/icons/unicons/liquid-empty.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
  };

  return tableData.map(data => (
	<div key={id} className="speedometer_box" id={'speedometer_box_' + id}>
      <div style={backgroundClass}>
        <div
          className="liquidFillContainer"
          onMouseMove={showTooltip}
          onMouseOut={hideTooltip}
          width={panelMinDimension * 0.9}
          height={panelMinDimension * 0.9}
        >
	      <LiquidFillGauge
		    width={panelMinDimension * 0.9}
            height={panelMinDimension * 0.9}
            value={((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100}
            percent={unit}
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            textRenderer={() => {
              const valueStyle = {
                fontSize: data.labelFontSize,
              };
              const percentStyle = {
                fontSize: data.labelFontSize,
              };
              return (
                <tspan>
                  <tspan className="value" style={valueStyle}>
                    {data.value}
                  </tspan>
                  <tspan style={percentStyle}>{unit}</tspan>
                </tspan>
              );
            }}
            riseAnimation
            waveAnimation
            waveStyle={waveColor}
            circleStyle={circleStyle}
            textStyle={textColor}
            waveTextStyle={waveTextStyle}
            waveFrequency={2}
            waveAmplitude={1}
          />
        </div>
      </div>
      {showText && (
        <div
          style={{
            color: String(data.textColor),
            fontSize: `${data.valueTextFontSize / 16}em`,
            fontWeight: '500'
          }}
          className={SpeedOdometerLegend}
        >
          {data.currentValueText}
        </div>
      )}
    </div>
  ));
}

function batteryFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  revertColorScale: boolean,
  unit: string,
  showInfoField: boolean
) {
  var tooltipClass = 'SpeedOdometerTooltip';
  var SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }
  const panelMinDimension = Math.min(width, height);
  const panelMaxDimension = Math.max(width, height);

  var panelWidth = panelMaxDimension;
  var panelHeight = panelMinDimension;
  var marginBottom = showText ? 15 : 0;

  if (panelWidth > panelHeight * 1.5) {
    panelWidth = panelHeight * 1.5;
  }
  if (height > width) {
    panelWidth = panelMinDimension;
    panelHeight = panelWidth - marginBottom;
  }
  const svgHeight = Math.round(panelWidth * 0.56) - 2;

  const marginLines = Math.round(panelWidth * 0.09);
  let marginLeft = 10;
  if (centered) {
    marginLeft = (width - panelWidth) / 2;
  }

  var backgroundClass = {
    width: '100%',
    height: '87%',
    display: 'flex',
    backgroundImage: `url(/public/img/icons/unicons/gaugebattery-empty.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	alignItems: 'center',
  };
  const isDark = config.theme.isDark;
  if (isDark) {
    backgroundClass = {
      width: '100%',
      height: '87%',
      display: 'flex',
      backgroundImage: `url(/public/img/icons/unicons/gaugebattery-empty_dark.svg)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
	  backgroundPosition: 'center',
	  alignItems: 'center',
    };
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<div>';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_center">';
	  tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<span><p class="SpeedOdometerTooltip_left">';
	tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
	tooltipLabel = tooltipLabel + '</span></div>';
    return tooltipLabel;
  });

  const showTooltip = (e) => {
    let x = data.pageX > data.view.innerWidth - 160 ? data.view.innerWidth - 160 : data.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const battery = tableData.map(data => {
    const percentage = ((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100;
    const segmentWide = Math.round(100 / data.segmentsLength);
    const segments = Math.round(percentage / segmentWide);
    var bateryLines: BateryLines[] = [];

    if (segments > 0) {
      for (let i = 1; i <= segments; i++) {
        let total = 0;
        for (let n = 0; n <= i; n++) {
          total = total + segmentWide;
        }
        let interval: BateryLines = {
          value: Number(total).toFixed(1),
          id: i,
        };
        bateryLines.push(interval);
      }
    }

    const batteryIntervals = createBatteryIntervals(
      bateryLines,
      data.segmentsLength,
      panelWidth,
      svgHeight,
      onfire,
      data.needleColor,
      data.warningColor
    );

    return (
	  <div key={id} className="speedometer_box" id={'speedometer_box_' + id}>
        <div
          style={{
            width: panelWidth,
            height: panelHeight,
            overflow: 'hidden',
          }}
        >
          <div
            style={backgroundClass}
            onMouseMove={showTooltip}
            onMouseOut={hideTooltip}
          >
            <div
              style={{
                left: marginLines,
                height: svgHeight * 0.7,
              }}
              className="linesContainer"
            >
              {batteryIntervals}
            </div>
          </div>
        </div>
        {showText && (
          <div
            style={{
              color: String(data.textColor),
              fontSize: `${data.valueTextFontSize / 16}em`,
              fontWeight: '500'
            }}
            className={SpeedOdometerLegend}
          >
            {data.currentValueText}
          </div>
        )}
      </div>
    );
  });
  return battery;
}

function createBatteryIntervals(
  bateryLines: BateryLines[],
  segments: number,
  width: number,
  height: number,
  onfire: boolean,
  needleColor: string,
  warningColor: string
) {
  const gaugeWidth = width * 0.8;
  const gaugeContentWidth = gaugeWidth * 0.84;
  const gaugeBarsNb = segments;
  const gaugeBarWidth = gaugeContentWidth / gaugeBarsNb;
  const gaugeBarMargin = 1;
  const gaugeBarRadius = 15;
  const paddingBottom = height * 0.015;
  var barBackgroundColor = needleColor;

  if (onfire) {
    barBackgroundColor = warningColor;
  }
  const styles = {
    barContainer: {
      width: `${gaugeBarWidth}px`,
      height: '100%',
      paddingLeft: `${gaugeBarMargin}px`,
      paddingRight: `${gaugeBarMargin}px`,
      paddingBottom: `${paddingBottom}px`,
    },
    bar: {
      width: `${gaugeBarWidth - gaugeBarMargin * 2}px`,
      height: '100%',
      backgroundColor: `${barBackgroundColor}`,
      zIndex: 1,
    },
    barFirst: {
      borderTopLeftRadius: `${gaugeBarRadius}px`,
      borderBottomLeftRadius: `${gaugeBarRadius}px`,
    },
    barLast: {
      borderTopRightRadius: `${gaugeBarRadius}px`,
      borderBottomRightRadius: `${gaugeBarRadius}px`,
    },
  };

  return bateryLines.map((step, i) => {
    return (
      <div style={styles.barContainer}>
        {i === 0 ? (
          <div key={step.id} id={step.value} style={{ ...styles.bar, ...styles.barFirst }} />
        ) : i === segments ? (
          <div key={step.id} id={step.value} style={{ ...styles.bar, ...styles.barLast }} />
        ) : (
          <div key={step.id} id={step.value} style={{ ...styles.bar }} />
        )}
      </div>
    );
  });
}

import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { NavbarPanel } from './NavbarPanel';

export const plugin = new PanelPlugin<SimpleOptions>(NavbarPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'optionTypeOption',
      path: 'optionTypeOption',
      name: 'Option Type Field',
      description: 'The Type of option. Could be option or button.',
      defaultValue: 'option_type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionStyleOption',
      path: 'optionStyleOption',
      name: 'Option Style Field',
      description: 'The option color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'option_style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionIconOption',
      path: 'optionIconOption',
      name: 'Option Icon Field',
      description: 'The field that will be used as the Icon for the option',
      defaultValue: 'option_icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionTextOption',
      path: 'optionTextOption',
      name: 'Option Text Field',
      description: 'The field that will be used as the Text for the option',
      defaultValue: 'option_text',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionUrlOption',
      path: 'optionUrlOption',
      name: 'Option Url Field',
      description: 'The Url open by the option',
      defaultValue: 'option_url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionTargetOption',
      path: 'optionTargetOption',
      name: 'Option Target Field',
      description: 'The Url target',
      defaultValue: 'option_target',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addNumberInput({
      path: 'minWidth',
      name: 'Plugin min width',
      description: 'Hide content when panel width cross below this threshold',
      category: ['General Settings'],
	  settings: {
        placeholder: '250',
        min: 200,
        max: 500,
      },
      defaultValue: '250',
    })
    .addBooleanSwitch({
      path: 'displayOptionsHorizontal',
      name: 'Small Icon Options',
      description: 'Display options horizontally with small icons.',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTimepickerOption',
      name: 'Time Picker Options',
      description: 'Display time picker options',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTimepickerSelect',
      name: 'Show select options',
      description: 'Display time picker as select option or buttons?',
      category: ['General Settings'],
	  showIf: config => config.showTimepickerOption === true,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showKioskOption',
      name: 'Kiosk Options',
      description: 'Display Kiosk options.',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'useRoleVariable',
      name: 'Update role variable',
      description: 'Allow to update a variable with the user role',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'roleVariable',
      name: 'role Variable',
      description: 'Variable to update',
      category: ['General Settings'],
      defaultValue: 'role',
	  showIf: config => config.useRoleVariable === true,
    })
    .addBooleanSwitch({
      path: 'showSearchOption',
      name: 'Show Search Options',
      description: 'Display search input box',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'openNewWindow',
      name: 'Open a new window on search?',
      description: 'Opena new window when user press enter or change select filter',
      category: ['General Settings'],
	  showIf: config => config.showSearchOption === true,
      defaultValue: true,
    })
    .addTextInput({
      path: 'searchVariable',
      name: 'Search Variable',
      description: 'Variable to update when user ingress text on search container',
      category: ['General Settings'],
      defaultValue: 'search',
	  showIf: config => config.showSearchOption === true,
    })
    .addTextInput({
      path: 'searchFilterVariable',
      name: 'Search Filter Variable',
      description: 'Variable to update when user change tipe of search on search container',
      category: ['General Settings'],
      defaultValue: 'filter',
	  showIf: config => config.showSearchOption === true,
    })
    .addTextInput({
      path: 'filterDefaultValue',
      name: 'Search Filter Default Value',
      description: 'Default value in search filter',
      category: ['General Settings'],
      defaultValue: 'Todos',
	  showIf: config => config.showSearchOption === true,
    })
    .addTextInput({
      path: 'placeholderText',
      name: 'Placeholder text',
      description: 'Placeholder text in search input.',
      category: ['Input mode'],
      defaultValue: 'Buscar ...',
	  showIf: config => config.showSearchOption === true,
    })
    .addTextInput({
      path: 'globalSearchLink',
      name: 'Global Search Panel',
      description: 'Panel to open when user press Enter on search input',
      category: ['General Settings'],
      defaultValue: '/d/search',
	  showIf: config => config.showSearchOption === true,
    })
    .addTextInput({
      path: 'panelSearchLink',
      name: 'Search Panel',
      description: 'Panel to open when user clic on search icon',
      category: ['General Settings'],
      defaultValue: '/d/search',
	  showIf: config => config.showSearchOption === true,
    })
    .addBooleanSwitch({
      path: 'showConfigOption',
      name: 'Show Config Options',
      description: 'Display config option',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      path: 'configLink',
      name: 'Main Config Panel',
      description: 'Panel to open when user clic on config icon',
      category: ['General Settings'],
      defaultValue: '/d/config',
	  showIf: config => config.showConfigOption === true,
    })
    .addBooleanSwitch({
      path: 'showHelpOption',
      name: 'Show Help Options',
      description: 'Display help option',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'helpLink',
      name: 'Main Help Panel',
      description: 'Panel to open when user clic on help icon',
      category: ['General Settings'],
      defaultValue: '/d/help',
	  showIf: config => config.showHelpOption === true,
    })
    .addTextInput({
      path: 'defaultPrimary',
      name: 'Default primary tab',
      description: 'indicate the tab number use as primary',
      category: ['General Settings'],
      defaultValue: '0',
    })
    .addTextInput({
      path: 'customer_logo',
      name: 'Customer logo',
      description: 'Customer logo image name (must include the complete path)',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import Slider from 'rc-slider';
import React, { ChangeEvent, useState } from 'react';
import { css, cx } from '@emotion/css';
import { dateTime, DateTime, SelectableValue } from '@grafana/data';
import { CodeEditor, DateTimePicker, InlineFieldRow, RadioButtonGroup, Icon } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { BooleanElementOptions, CodeEditorHeight, CodeLanguage, FormElementType } from '../../constants';
import { FormElement, LayoutSection, PanelOptions } from '../../types';
import Select, { components } from 'react-select';
import '../../css/GenericForm.css';

interface Props {
  options: PanelOptions;
  onOptionsChange: any;
  initial: { [id: string]: any };
  section: LayoutSection | null;
}

export const FormElements: React.FC<Props> = ({ options, onOptionsChange, section, initial, width, refresh }) => {
  const isDark = config.theme.isDark || false;
  
	let classSelect = 'button-select-box';
	var customStyles = {
	  menu: provided => ({
		...provided,
		zIndex: 9999,
		color: '#333',
		background: '#fafafa',
		border: 'none',
		borderRadius: '10px',
		marginTop: '5px',
		marginBottom: '0px',
	  }),
	  option: (base, state) => ({
		...base,
		height: '30px',
		marginTop: '0px',
		marginBottom: '0px',
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		background: state.isSelected ? '#ffb101' : '#fafafa',
		'&:hover': {
		  color: '#000',
		  background: '#edeef3',
		},
	  }),
		singleValue: base => ({
		  ...base,
		  color: "#333"
		}),
	  control: base => ({
		...base,
		border: 0,
		boxShadow: 'none',
	  }),
	  indicatorContainer: provided => ({
		...provided,
		paddingLeft: '10px',
	  }),
	  menuPortal: provided => ({
		...provided,
		zIndex: 9999,
	  }),
	};

	if (isDark) {
	  classSelect = 'button-select-box_dark';
	  customStyles = {
		menu: provided => ({
		  ...provided,
		  zIndex: 9999,
		  color: '#fafafa',
		  background: '#222426',
		  border: 'none',
		  borderRadius: '10px',
		  marginTop: '5px',
		  marginBottom: '0px',
		  paddingTop: '0px',
		  paddingBottom: '0px',
		}),
		option: (base, state) => ({
		  ...base,
		  height: '30px',
		  marginTop: '0px',
		  marginBottom: '0px',
		  fontSize: '12px',
		  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		  borderRadius: '10px',
		  background: state.isSelected ? '#ffb101' : '#222426',
		  '&:hover': {
			color: '#eee',
			background: '#5a5a5a',
		  },
		}),
		singleValue: base => ({
		  ...base,
		  color: "#eee"
		}),
		control: base => ({
		  ...base,
		  border: 0,
		  boxShadow: 'none',
		}),
		indicatorContainer: provided => ({
		  ...provided,
		  paddingLeft: '10px',
		}),
		menuPortal: provided => ({
		  ...provided,
		  zIndex: 9999,
		}),
	  };
	}
	const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
	  return (
		<components.DropdownIndicator {...props}>
		  <Icon name="angle-down" size="xl" />
		</components.DropdownIndicator>
	  );
	};

	const ClearIndicator = (props) => {
	  return (
		<components.ClearIndicator {...props}>
		  <Icon name="times" size="lg" />
		</components.ClearIndicator>
	  );
	};

	const NoOptionsMessage = props => {
	  return (
		<components.NoOptionsMessage {...props}>
		  <span className="custom-css-class">{error2}</span> 
		</components.NoOptionsMessage>
	  );
	};

  const formatDatetime = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  return (
    <div>
      {options.elements?.map((element) => {
		if (section && element.section !== section.name) {
          return;
        }
		let defaultValue = element.value;
		if (element.type === FormElementType.SELECT && refresh) {
		  defaultValue = element.options.filter(function(option) {
			return option.value === element.value;
		  });
		}
        return (
          <InlineFieldRow key={element.id}>
            {element.type === FormElementType.NUMBER && (
              <div className="inlineField-container">
			    <div
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <input
                  id={'generic_form_' + element.id}
				  value={element.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    element.value = event.target.value;
                    if (element.max !== undefined && element.max !== null) {
                      element.value = Math.min(element.max, Number(element.value));
                    }
                    if (element.min !== undefined && element.min !== null) {
                      element.value = Math.max(element.min, Number(element.value));
                    }

                    onOptionsChange(options);
                  }}
                  type="number"
                  className={isDark ? 'inlineField-Input-dark' : 'inlineField-Input'}
                  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                  min={element.min !== null ? element.min : ''}
                  max={element.max !== null ? element.max : ''}
                />
				{element.unit && element.unit !== '' && (
				  <div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					{element.unit}
				  </div>
				)}
              </div>
            )}

            {element.type === FormElementType.STRING && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <input
                  id={'generic_form_' + element.id}
				  value={element.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    element.value = event.target.value;
                    onOptionsChange(options);
                  }}
                  type="text"
                  className={isDark ? 'inlineField-Input-dark' : 'inlineField-Input'}
                  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                />
				{element.unit && element.unit !== '' && (
				  <div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					{element.unit}
				  </div>
				)}
              </div>
            )}

            {element.type === FormElementType.PASSWORD && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <input
                  id={'generic_form_' + element.id}
				  value={element.value}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    element.value = event.target.value;
                    onOptionsChange(options);
                  }}
                  type="password"
                  className={isDark ? 'inlineField-Input-dark' : 'inlineField-Input'}
                  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                />
              </div>
            )}

            {element.type === FormElementType.DISABLED && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <input
                  id={'generic_form_' + element.id}
				  value={
                    !element.options?.length
                      ? element.value
                      : element.options.find((option) => option.value === element.value)?.label
                  }
                  type="text"
                  className={isDark ? 'inlineField-Input-dark' : 'inlineField-Input'}
				  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                />
				{element.unit && element.unit !== '' && (
				  <div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					{element.unit}
				  </div>
				)}
              </div>
            )}

            {element.type === FormElementType.TEXTAREA && (
              <div className="inlineDynamicField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <textarea
                  id={'generic_form_' + element.id}
				  value={element.value}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    element.value = event.target.value;
                    onOptionsChange(options);
                  }}
                  className={isDark ? 'inlineField-TextArea-dark' : 'inlineField-TextArea'}
                  style={{ width: `${element.width}px` }}
                  rows={element.rows}
                  title={element.tooltip}
                />
              </div>
            )}

            {element.type === FormElementType.CODE && (
              <div className="inlineDynamicField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <div 
                  className={isDark ? 'inlineField-codeEditor-dark' : 'inlineField-codeEditor'}
				  style={
				    { 
					  width: `${element.width > 0 ? element.width : width}px`, 
					  height: `${element.height > 0 ?  element.height : CodeEditorHeight}px` 
					
					}
				  }
				>
				  <CodeEditor
				    language={element.language || CodeLanguage.JAVASCRIPT}
                    showLineNumbers={true}
                    showMiniMap={(element.value && element.value.length) > 100}
                    value={element.value}
                    width={element.width > 0 ? `${element.width - 10}px` : `95%`}
					height={element.height > 0 ? `${element.height - 15}px` : `${CodeEditorHeight - 15}px`}
                    onBlur={(code) => {
                      element.value = code;
                      onOptionsChange(options);
                    }}
                    monacoOptions={{ formatOnPaste: true, formatOnType: true }}
                  />
				</div>
              </div>
            )}

            {element.type === FormElementType.DATETIME && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <input
                  id={'generic_form_' + element.id}
				  className={isDark ? 'inlineField-time-dark' : 'inlineField-time'}
				  value={formatDatetime(element.value)}
                  onChange={(dateTime: DateTime) => {
					element.value = dateTime.target.valueAsNumber;
                    onOptionsChange(options);
                  }}
				  type="datetime-local"
				  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                />
              </div>
            )}

            {element.type === FormElementType.SLIDER && element.value != null && (
              <>
                <div className="inlineField-container">
			      <div 
				    className="inlineField-text"
				    style={{ width: `${element.labelWidth}px` }}
				    title={element.tooltip}
				  >
				    {element.label ? element.label + ': ' : element.title + ': '}
				  </div>
                  <div 
				    className="inlineField-slider"
					style={element.unit && element.unit !== '' ? 
					  { width: `${width - element.labelWidth - 80 }px` } :
					  { width: `${width - element.labelWidth - 30 }px` }
					}
					title={element.tooltip}
					>
				    <Slider
                      value={element.value || 0}
                      onChange={(value: number | number[]) => {
                        element.value = value;
                        onOptionsChange(options);
                      }}
                      min={element.min || 0}
                      max={element.max || 0}
                      step={element.step || 0}
                    />
                    <input
                      value={element.value}
                      onChange={(e) => {
                        element.value = Math.max(
                          element.min || 0,
                          Math.min(element.max || 0, Number(e.currentTarget.value))
                        );
                        onOptionsChange(options);
                      }}
                      type="number"
					  className={isDark ? 'inlineField-Input-dark inline-valueLabel' : 'inlineField-Input inline-valueLabel'}
                      width={'100px'}
                      min={element.min || 0}
                      max={element.max || 0}
                    />
				  </div>
				  {element.unit && element.unit !== '' && (
					<div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					  {element.unit}
					</div>
				  )}
                </div>
              </>
            )}

            {element.type === FormElementType.BOOLEAN && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <RadioButtonGroup
                  value={element.value}
                  onChange={(value: Boolean) => {
                    element.value = value;
                    onOptionsChange(options);
                  }}
                  className={isDark ? 'inlineField-Boolean-dark' : 'inlineField-Boolean'}
                  style={{ width: `${element.width}px` }}
                  options={BooleanElementOptions}
				  title={element.tooltip}
                />
              </div>
            )}

            {element.type === FormElementType.RADIO && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <RadioButtonGroup
                  value={element.value}
                  onChange={(value: any) => {
                    element.value = value;
                    onOptionsChange(options);
                  }}
                  options={element.options || []}
                  className={isDark ? 'inlineField-Boolean-dark' : 'inlineField-Boolean'}
				  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
                />
				{element.unit && element.unit !== '' && (
				  <div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					{element.unit}
				  </div>
				)}
              </div>
            )}

            {element.type === FormElementType.SELECT && (
              <div className="inlineField-container">
			    <div 
				  className="inlineField-text"
				  style={{ width: `${element.labelWidth}px` }}
				  title={element.tooltip}
				>
				  {element.label ? element.label + ': ' : element.title + ': '}
				</div>
                <div 
				  className={isDark ? 'inlineField-selectBox-dark' : 'inlineField-selectBox'}
				  style={{ width: `${element.width}px` }}
				  title={element.tooltip}
				>
				  <Select
                    instanceId={element.id}
                    className={classSelect}
                    classNamePrefix={classSelect}
				    style={{ width: `${element.width}px` }}
                    components={{ DropdownIndicator, NoOptionsMessage }}
					value={defaultValue}
                    onChange={e => {
					  element.value = e.value;
                      onOptionsChange(options);
                    }}
                    options={element.options || []}
				    isSearchable={false}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    styles={customStyles}
                    getOptionLabel={e => (
                      <div className="inlineFieldSelect-icon">
                        <Icon name={e.icon} size="lg" />
                        <div className="inlineFieldSelect-label">{e.label}</div>
                      </div>
                    )}
                  />
				</div>
				{element.unit && element.unit !== '' && (
				  <div className={isDark ? 'inlineField-label label_dark' : 'inlineField-label label_light'}>
					{element.unit}
				  </div>
				)}
              </div>
            )}
          </InlineFieldRow>
        );
      })}
    </div>
  );
};

import React, { useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon, useForceUpdate } from '@grafana/ui';
import { config, locationService, getTemplateSrv } from '@grafana/runtime';
import { SimpleOptions, NavbarData } from 'types';
import './css/navbarOptions.css';
import { toggleKioskMode } from 'app/core/navigation/kiosk';
import { contextSrv } from 'app/core/services/context_srv';
import { NavbarOptionSmall, NavbarOption, GenericOption } from './NavbarButton';
import Select, { components } from 'react-select';
import { changeUrl, getNetMonitorVariableValue, openUrl } from './utils';

interface NavbarPanelProps extends PanelProps<SimpleOptions> {}

export const NavbarPanel: React.FC<NavbarPanelProps> = React.memo(({ options, data, width, height, replaceVariables, id }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const renderCount = useRef(0);
  const searchActualValue = getNetMonitorVariableValue(options.searchVariable);
  const searchActualValuefilter = getNetMonitorVariableValue(options.searchFilterVariable);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [error4, setError4] = useState('');
  const [defaultPrimary, setDefaultPrimary] = useState(0);
  const [primaryButton, setPrimaryButton] = useState(0);
  const [helpLink, setHelpLink] = useState('');
  const [configLink, setConfigLink] = useState('');
  const [searchLink, setSearchLink] = useState('');
  const [globalSearchLink, setGlobalSearchLink ] = useState('');
  const [searchFilter, setSearchFilter] = useState(searchActualValue);
  const [searchFilterValue, setSearchFilterValue] = useState(searchActualValuefilter !== '' ? searchActualValuefilter : options.filterDefaultValue);

  const logo = options.customer_logo !== undefined && options.customer_logo !== '' ?
    options.customer_logo : '/public/img/customer_logo.png';
  const exist = verificarImagen(logo);
  let customer_logo = '/public/img/customer_logo.svg';
  verificarImagen(logo).then(existe => {
    if (existe) {
      customer_logo = logo;
    }
  });
  const currentLocation = window.location.pathname;

  const userName = contextSrv.user.name;
  let userRole = 'v';
  if (contextSrv.isNetMonitorAdmin) {
    userRole = 'a';
  } else if (contextSrv.isEditor) {
    userRole = 'e';
  }

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
	if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));
      const defaultPrimaryValue = Number(replaceVariables(options.defaultPrimary));
	  setDefaultPrimary(defaultPrimaryValue);
      setPrimaryButton(isNaN(defaultPrimaryValue) ? null : defaultPrimaryValue);
      setHelpLink(replaceVariables(options.helpLink));
      setConfigLink(replaceVariables(options.configLink));
      setSearchLink(replaceVariables(options.panelSearchLink));
      setGlobalSearchLink(replaceVariables(options.globalSearchLink));
      setSearchFilter(searchActualValue);	
	  if (searchActualValuefilter !== searchFilterValue) {
	    setSearchFilterValue(searchActualValuefilter);
	  }
      if (options.useRoleVariable && options.roleVariable !== undefined && options.roleVariable !== '') {
        let queryMap = {
          [`var-${options.roleVariable}`]: userRole,
        };
        changeUrl(queryMap);
	  }
	  setInitialCalculationsDone(true);
	}
  }, [options, replaceVariables, getNetMonitorVariableValue]);

  if (width < options.minWidth) {
    return (
	  <div className="timeoptionsErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="timeoptionsErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xl" />
	  </div>
	);
  }
  if (data.series[0].length < 1) {
    return (
	  <div className="timeoptionsErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xl" />
	  </div>
	);
  }

  const forceUpdate = useForceUpdate();

  const timeFrom = useMemo(() => (getTemplateSrv().replace('${__from}')));
  const timeTo = useMemo(() => (getTemplateSrv().replace('${__to}')));
  const d = Date.now();
  const timeGap = Math.abs(d - timeTo);
  const timeFromString = new Date(Number(timeFrom));
  const timeToString = new Date(Number(timeTo));
  var time_window = timeFromString.toLocaleString() + ' \n' + timeToString.toLocaleString();
  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 0;
  if (timeWindow <= 24 && timeGap < 1000) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  } else if (timeWindow <= 8640) {
    actualWin = 6;
  }
  const [actualZoom, setActualZoom] = useState(actualWin);

  const isDark = config.theme.isDark || false;
  const customerLogo = isDark ? 'customer_logo_dark' : 'customer_logo';
  const searchContainer = isDark ? 'option_search_container_dark' : 'option_search_container';
  const searchContainerSmall = isDark ? 'option_search_container_small_dark' : 'option_search_container_small';
  const inputOption = isDark ? 'no-border_dark input_option_dark' : 'no-border_light input_option';
  const options_container = isDark ? 'options_container_dark' : 'options_container';
  const timeoptions_container = isDark ? 'timeoptions_container_dark' : 'timeoptions_container';
  const timeoptions_container_small = isDark ? 'timeoptions_container_small_dark' : 'timeoptions_container_small';
  const classOption = isDark ? 
	'option_disable_dark option_color option_black_dark_disable' :
	'option_disable_light option_color option_white_light_disable';

  let titleIn = 'Zoom In';
  let titleOut = 'Zoom Out';
  const timeOptions = [
	{ value: 1, label: 'Ultimas 3hs' },
	{ value: 2, label: 'Ultimas 24hs' },
	{ value: 3, label: 'Ultima semana' },
	{ value: 4, label: 'Ultimo mes' },
	{ value: 5, label: 'Ultimo trimestre' },
	{ value: 6, label: 'Ultimo semestre' },
  ];
  if (actualZoom === 0) {
	titleIn = 'Reducir ventana de tiempo';
	titleOut = 'Ampliar ventana de tiempo';
  } else if (actualZoom === 1) {
	titleIn = 'No es posible reducir ventana de tiempo';
	titleOut = 'Ampliar ventana de tiempo a ultimas 24hs';
  } else if (actualZoom === 2) {
	titleIn = 'Reducir ventana de tiempo a ultimas 3hs';
	titleOut = 'Ampliar ventana de tiempo a ultima semana';
  } else if (actualZoom === 3) {
	titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
	titleOut = 'Ampliar ventana de tiempo a ultimo mes';
  } else if (actualZoom === 4) {
	titleIn = 'Reducir ventana de tiempo a ultima semana';
	titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
  } else if (actualZoom === 5) {
	titleIn = 'Reducir ventana de tiempo a ultimo mes';
	titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
  } else if (actualZoom === 6) {
	titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
	titleOut = 'Ampliar ventana de tiempo a ultimo año';
  } else {
	titleIn = 'Reducir ventana de tiempo';
	titleOut = 'Ampliar ventana de tiempo';
  }

  const onTimeFilterChange = (event) => {
	if (actualZoom !== event.value) {
	  zoomTime(event.value);
	  setActualZoom(event.value);
	}
  };

  const onSearchFilterChange = (event) => {
	if (searchFilterValue !== event.value) {
	  const queryMap = {
		[`var-${options.searchFilterVariable}`]: event.value,
	  };
	  changeUrl(queryMap);
	  setSearchFilterValue(event.value);
	  if (searchValue.length > 0 && searchValue !== ' ') {
	    const queryMap = {
		  [`var-${options.searchVariable}`]: searchValue,
	    };
		changeUrl(queryMap);
	    if (options.openNewWindow) {
	      handleUrl(globalSearchLink + '?var-search=' + searchValue + '&var-filter=' + event.value, '_blank');
	    }
	  }
	}
  };

  const [searchValue, setSearchValue] = useState(searchFilter);
  const onSearchChange = (event) => {
    if (searchValue !== event.target.value) {
	  setSearchValue(event.target.value);
	}
  };

  const changeSearch = (event) => {
	if (event.key === 'Enter') {
	  const queryMap = {
		[`var-${options.searchVariable}`]: searchValue,
	  };
	  changeUrl(queryMap);
	  if (options.openNewWindow) {
	    handleUrl(globalSearchLink + '?var-search=' + searchValue + '&var-filter=' + searchFilterValue, '_blank');
	  }
    }
  };

  const generateMenu = useMemo(() => {
    const navbarOptions: NavbarData[] = [];
	const indicatorOptions: NavbarData[] = [];
    if (!initialCalculationsDone) {
	  return { navbarOptions, indicatorOptions };
	}

    data.series.forEach(series => {
	  const frame: GraphSeriesValue[] = series.fields[0].values.toArray();
	  var n = 0;
	  for (let i = 0; i < frame.length; i++) {
	    let option: NavbarData = {
		  id: i,
		  type: series.fields.find(field => field.name === options.optionTypeOption)?.values.get(i),
		  text: series.fields.find(field => field.name === options.optionTextOption)?.values.get(i),
		  icon: series.fields.find(field => field.name === options.optionIconOption)?.values.get(i),
		  url: series.fields.find(field => field.name === options.optionUrlOption)?.values.get(i),
		  target: series.fields.find(field => field.name === options.optionTargetOption)?.values.get(i),
		  primary: false,
		  style: series.fields.find(field => field.name === options.optionStyleOption)?.values.get(i),
	    };
	    if (option.url === null || option.url === undefined) {
		  option.url = ''
	    } else {
		  option.url = replaceVariables(String(option.url));
		  if ((primaryButton === null && currentLocation.includes(option.url)) || primaryButton === i + 1) {
		    option.primary = true;
		  }
	    }
	    if (option.target === undefined || option.target === 'self' || option.target === '_self') {
		  option.target = '_self';
	    } else if (option.target === 'top' || option.target === '_top') {
		  option.target = '_top';
	    } else if (option.target === 'parent' || option.target === '_parent') {
		  option.target = '_parent';
	    } else {
		  option.target = '_blank';
	    }
	    if (option.type === 'option') {
		  navbarOptions.push(option);
	    } else {
		  indicatorOptions.push(option);
	    }
	    n = i;
	  }
    });
	return { navbarOptions, indicatorOptions };
  }, [data, options, initialCalculationsDone]);

  const onToggleTVMode = () => {
	toggleKioskMode();
  };

  const onCloseSession = () => {
	handleUrl('/logout', '_self');
  };
  
  const onChangeProfile = () => {
	handleUrl('/d/profile', '_self');
  };

  const onSearchClic = () => {
	handleUrl(searchLink, '_blank');
  };
  
  const onConfigMode = () => {
	handleUrl(configLink, '_self');
  };

  const onHelpMode= () => {
	handleUrl(helpLink, '_self');
  };

  let classSelect = 'searchSelectBox';	  
  let customStyles = {
	menu: provided => ({
	  ...provided,
	  zIndex: 9999,
	  color: '#23282E',
	  background: '#EFF4FA',
	  border: 'none',
	  borderRadius: '10px',
	  marginTop: '5px',
	  marginBottom: '0px',
	}),
	option: (base, state) => ({
	  ...base,
	  height: '30px',
	  marginTop: '0px',
	  marginBottom: '0px',
	  fontSize: '12px',
	  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
	  background: state.isSelected ? '#FFC530' : '#E6E9ED',
	  '&:hover': {
		color: 'black',
		background: '#FF7F27',
	  },
	}),
	  singleValue: base => ({
		...base,
		color: "#23282E"
	  }),
	control: base => ({
	  ...base,
	  border: 0,
	  boxShadow: 'none',
	}),
  };

  if (isDark) {
	classSelect = 'searchSelectBox_dark';
	customStyles = {
	  menu: provided => ({
		...provided,
		zIndex: 9999,
		color: '#F4F9FF',
		background: '#1B2733',
		border: 'none',
		borderRadius: '10px',
		marginTop: '5px',
		marginBottom: '0px',
		paddingTop: '0px',
		paddingBottom: '0px',
	  }),
	  option: (base, state) => ({
		...base,
		height: '30px',
		marginTop: '0px',
		marginBottom: '0px',
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		borderRadius: '10px',
		background: state.isSelected ? '#E5CD6B' : '#23282E',
		'&:hover': {
		  color: 'white',
		  background: '#D9AF27',
		},
	  }),
	  singleValue: base => ({
		...base,
		color: "#EFF4FA"
	  }),
	  control: base => ({
		...base,
		border: 0,
		boxShadow: 'none',
	  }),
	};
  }

  const inputPlaceholder = replaceVariables(options.placeholderText);
  const searchOptions = [
	{ value: options.filterDefaultValue, label: 'Todos' },
	{ value: 'asset', label: 'Activos' },
	{ value: 'ticket', label: 'Alarmas/Alertas' },
	{ value: 'dir', label: 'Direcciones' },
	{ value: 'iface', label: 'Interfaces' },
	{ value: 'service', label: 'Servicios' },
	{ value: 'user', label: 'Usuarios' },
  ];

  return (
	<div>
	  <div className={options_container}>
		<div className="options_pagination">
		  {!contextSrv.isNetMonitorAdmin && width >= 650 && (
			<div className="logo_netmonitor">
			  <img src="/public/img/netmonitor_text_logo-light.svg" height="25"></img>
			</div>
		  )}
		  {!contextSrv.isNetMonitorAdmin && width < 650 && (
			<div className="logo_netmonitor">
			  <img src="/public/img/netmonitor_icon1.svg" height="25"></img>
			</div>
		  )}
		  {!options.displayOptionsHorizontal && (
			<>
			  <div className="option_container_left">{optionFactorySmall(generateMenu.navbarOptions)}</div>
			  <div className="option_separator"></div>
			</>
		  )}
		  {options.displayOptionsHorizontal && options.showSearchOption && (
			<>
			  <div className="option_separator_small"></div>
			  <div className={searchContainerSmall}>
				<button className={classOption} onClick={onSearchClic} title={'Buscar en NetMonitor'}>
				  <div className="option_icon">
					<Icon name={'search'} size="lg" />
				  </div>
				</button>
			  </div>
			  <div className={searchContainer}>
				<Icon
				  className="search_icon"
				  name='search'
				  size="lg"
				  title="Buscar en NetMonitor"
				/>
				<input 
				  type="search"
				  id="site-search"
				  className={inputOption}
				  onKeyPress={changeSearch}
				  onChange={onSearchChange}
				  value={searchValue}
				  placeholder={inputPlaceholder}
				/>
				<Select
				  className={classSelect}
				  classNamePrefix={classSelect}
				  isSearchable={false}
				  onChange={onSearchFilterChange}
				  defaultValue={searchOptions.filter(function(option) {
					return option.value === searchFilterValue;
				  })}
				  options={searchOptions}
				  styles={customStyles}
				  menuPortalTarget={document.body}
				  menuPosition={'fixed'}
				/>
			  </div>
			  <div className="option_separator_small"></div>
			</>
		  )}
		  {options.displayOptionsHorizontal && !options.showSearchOption && (
		    <div className="option_separator"></div>
		  )}
		  {options.showTimepickerOption && (
			<div className={options.showTimepickerSelect ? timeoptions_container : timeoptions_container_small}>
			  {!options.showTimepickerSelect && (
				<>
				  <div className="time_option">
					<button 
					  className={classOption}
					  onClick={() => {
						if (actualZoom > 1) {
						  zoomTime(actualZoom - 1);
						  setActualZoom(actualZoom - 1);
						} else if (actualZoom < 1) {
						  zoomTime(1);
						  setActualZoom(1);
						} else if (actualZoom > 6) {
						  zoomTime(5);
						  setActualZoom(5);
						}
					  }}
					  title={titleIn}
					>
					  <div className="option_icon">
						<Icon name={'minus-circle'} size="md" />
					  </div>
					</button>
				  </div>
				  <div className="time_option">
					<Icon name={'clock'} size="xl" />
				  </div>
				  <div className="time_option">
					<button
					  className={classOption}
					  onClick={() => {
						if (actualZoom < 6) {
						  zoomTime(actualZoom + 1);
						  setActualZoom(actualZoom + 1);
						} else if (actualZoom < 1) {
						  zoomTime(6);
						  setActualZoom(6);
						} else if (actualZoom > 6) {
						  zoomTime(6);
						  setActualZoom(6);
						}
					 }}
					  title={titleOut}
					>
					  <div className="option_icon">
						<Icon name={'plus-circle'} size="md" />
					  </div>
					</button>
				  </div>
				</>
			  )}
			  {options.showTimepickerSelect && (
				<div className="time_select" title={time_window}>
				  <Icon name={'clock'} size="xl" />
				  <Select
					className={classSelect}
					classNamePrefix={classSelect}
					isSearchable={false}
					onChange={onTimeFilterChange}
					defaultValue={() => {
					  if (actualZoom > 0 && actualZoom < 7) {
					    timeOptions.filter(function(option) {
					      return option.value === actualZoom;
						})
					  } else {
					    return 'Seleccionar';
					  }
					}}
					options={timeOptions}
					styles={customStyles}
					menuPortalTarget={document.body}
					menuPosition={'fixed'}
				  />
				</div>
			  )}
			</div>
		  )}
		  <div className="option_container_right">{indicatorFactory(generateMenu.indicatorOptions)}</div>
		  {userRole === 'e' && options.showConfigOption && (
			<div className="generic_option">
			  <button className={classOption} onClick={onConfigMode} title={'Configurar NetMonitor'}>
				<div className="option_icon">
				  <Icon name={'cog-fill'} size="xl" />
				</div>
			  </button>
			</div>
		  )}
		  {options.showHelpOption && (
			<div className="generic_option">
			  <button className={classOption} onClick={onHelpMode} title={'Ayuda de NetMonitor NetMonitor'}>
				<div className="option_icon">
				  <Icon name={'help-fill'} size="xl" />
				</div>
			  </button>
			</div>
		  )}
		  {width >= 500 && (
			<div className={customerLogo}>
			  <div className="option_username">{userName}</div>
			  <img className="customer_logo_img" src={customer_logo} height="25"></img>
			  <button className={classOption} onClick={onChangeProfile} title={'Editar Perfil'}>
				<div className="option_icon">
				  <Icon name={'setting-fill'} size="xl" />
				</div>
			  </button>
			  <button className={classOption} onClick={onCloseSession} title={'Cerrar Sesión'}>
				<div className="option_icon">
				  <Icon name={'sign-out-fill'} size="xl" />
				</div>
			  </button>
			</div>
		  )}
		  {width < 500 && (
			<div className={customerLogo}>
			  <button className={classOption} onClick={onChangeProfile} title={'Editar Perfil'}>
				<div className="option_icon">
				  <Icon name={'user-circle-fill'} size="xl" />
				</div>
			  </button>
			  <button className={classOption} onClick={onCloseSession} title={'Cerrar Sesión'}>
				<div className="option_icon">
				  <Icon name={'sign-out-fill'} size="xl" />
				</div>
			  </button>
			</div>			    
		  )}
		  {options.showKioskOption && width >= 650 && (
			<div className="generic_option">
			  <button className={classOption} onClick={onToggleTVMode} title={'Cambiar modo de visualización'}>
				<div className="option_icon">
				  <Icon name={'max-fill'} size="xl" />
				</div>
			  </button>
			</div>
		  )}
		</div>
	  </div>
	  {options.displayOptionsHorizontal && (
		<div className={'option_container_bottom'}>{optionFactory(generateMenu.navbarOptions)}</div> 
	  )}
	</div>
  );
});

function handleUrl(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function optionFactorySmall(navbarOptions: NavbarData[]) {
  return navbarOptions.map(option => (
    <NavbarOptionSmall
      id={option.id}
      key={option.text}
      title={option.text}
      icon={option.icon}
      url={option.url}
      target={option.target}
      primary={option.primary}
      style={option.style}
    />
  ));
}

function optionFactory(navbarOptions: NavbarData[]) {
  return navbarOptions.map(option => (
    <NavbarOption
      id={option.id}
      key={option.text}
      title={option.text}
      icon={option.icon}
      url={option.url}
      target={option.target}
      primary={option.primary}
      style={option.style}
    />
  ));
}

function indicatorFactory(indicatorOptions: NavbarData[]) {
  return indicatorOptions.map(option => (
    <GenericOption
      id={option.id}
      key={option.text}
      title={option.text}
      icon={option.icon}
      url={option.url}
      target={option.target}
      style={option.style}
    />
  ));
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 10800000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 7:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    locationService.partial(queryMap, true);
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    locationService.partial(queryMap, true);
  }
}

async function verificarImagen(url) {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    return false;
  }
}

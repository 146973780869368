import React, { Component } from 'react';
import './css/netmonitor-tabs.css';
import { TabsData } from './types';
import { changeUrl, openUrl, setBackUrl } from './utils';
import { config } from '@grafana/runtime';
import Select, { components } from 'react-select';
import { Icon } from '@grafana/ui';

interface TabsSelectProps {
  TabsData: TabsData[];
}

export class TabsSelect extends Component<TabsSelectProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedValue: {},
      selectOptions: [],
    };
  }

  async getOptions() {
    const options = this.props.TabsData.map((option, index) => ({
      value: index,
      label: option.text,
      icon: option.icon,
    }));
    this.setState({ selectOptions: options });
  }

  handleChange(item) {
    this.setState({ selectedValue: item });
    const valueToSet = this.props.TabsData.find(row => {
      return row.text === item.label;
    });
    if (valueToSet) {
      if (valueToSet.url !== 'null' && valueToSet.url !== '') {
        openUrl(valueToSet.url, valueToSet.updateRoute, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
      } else if (valueToSet.variable !== 'Cerrar' && valueToSet.variable !== 'Volver') {
        const item = {
          label: valueToSet.text,
          value: String(valueToSet.id),
          icon: valueToSet.icon,
        };
        this.setState({ selectedValue: item });
        if (valueToSet.variable !== '') {
		  const queryMap = {
            [`var-${valueToSet.variable}`]: valueToSet.value,
          };
          changeUrl(queryMap, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
		}
      }
    }
  }

  componentDidMount() {
    this.getOptions();
    const valueToSet = this.props.TabsData.find(row => {
      return row.primary === true;
    });
    if (valueToSet) {
      const item = {
        label: valueToSet.text,
        value: String(valueToSet.id),
        icon: valueToSet.icon,
      };
      this.setState({ selectedValue: item });
	  if (valueToSet.variable !== '') {
        const queryMap = {
          [`var-${valueToSet.variable}`]: valueToSet.value,
        };
        changeUrl(queryMap, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
	  }
      if (valueToSet.id === 1 && valueToSet.varBack !== undefined && valueToSet.varBack !== 'null') {
	    setBackUrl(valueToSet.varBack, valueToSet.varBack1);
	  }

    }
  }

  render() {
    const { selectedValue = {} } = this.state;
	const isDark = config.theme.isDark || false;
    let classSelect = 'tabSelectBox';	  
    let customStyles = {
      menu: provided => ({
        ...provided,
        zIndex: 9999,
        color: '#333',
        background: '#fafafa',
        border: 'none',
        borderRadius: '10px',
        marginTop: '5px',
        marginBottom: '0px',
      }),
      option: (base, state) => ({
        ...base,
	    height: '30px',
        marginTop: '0px',
        marginBottom: '0px',
		fontSize: '12px',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		background: state.isSelected ? '#ffb101' : '#fafafa',
        '&:hover': {
          color: '#000',
          background: '#edeef3',
        },
      }),
	  singleValue: base => ({
		...base,
		color: "#333"
	  }),
	  control: base => ({
		...base,
		border: 0,
		boxShadow: 'none',
	  }),
    };
    if (isDark) {
      classSelect = 'tabSelectBox_dark';
      customStyles = {
        menu: provided => ({
          ...provided,
          zIndex: 9999,
		  color: '#fafafa',
          background: '#222426',
          border: 'none',
          borderRadius: '10px',
          marginTop: '5px',
          marginBottom: '0px',
		  paddingTop: '0px',
		  paddingBottom: '0px',
        }),
        option: (base, state) => ({
          ...base,
		  height: '30px',
          marginTop: '0px',
          marginBottom: '0px',
		  fontSize: '12px',
		  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		  borderRadius: '10px',
		  background: state.isSelected ? '#ffb101' : '#222426',
          '&:hover': {
            color: '#eee',
            background: '#5a5a5a',
          },
        }),
		singleValue: base => ({
		  ...base,
		  color: "#eee"
		}),
		control: base => ({
		  ...base,
		  border: 0,
		  boxShadow: 'none',
		}),
      };
    }

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon name="menu_dark" size="lg" />
        </components.DropdownIndicator>
      );
    };
    return (
      <Select
        className={classSelect}
        classNamePrefix={classSelect}
        components={{ DropdownIndicator }}
        value={selectedValue}
        onChange={this.handleChange.bind(this)}
        options={this.state.selectOptions}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={customStyles}
        getOptionLabel={e => (
          <div style={{ display: 'flex', height: '20px' }}>
            {e.icon !== '' && (
			  <Icon name={e.icon} size="md"></Icon>
			)}
            <p style={{ marginLeft: 10, marginTop: 1 }}>{e.label}</p>
          </div>
        )}
      />
    );
  }
}

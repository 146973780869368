import { css, cx } from '@emotion/css';
import React, { ReactNode, useEffect, useState } from 'react';
import { locationService, getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { IconButton, Pagination, useStyles2, Icon } from '@grafana/ui';
import { usePagination } from 'app/features/alerting/unified/hooks/usePagination';
import { getPaginationStyles } from 'app/features/alerting/unified/styles/pagination';
import { L1OyMExpandedForm } from './L1OyMExpandedForm';
import { updateElement, cleanElements, saveElement, saveImportData, updateImportElements } from './L1OyMTable';
import { L1OyMData } from './L1OyMManager';
import { MapComponent } from './PointPickerModal';
import { L1OyMMapForm } from './L1OyMMapForm';

interface DynamicTablePagination {
  itemsPerPage: number;
}

export interface DynamicTableColumnProps<T = unknown> {
  id: string | number;
  label: string;
  renderCell: (item: DynamicTableItemProps<T>, index: number) => ReactNode;
  size?: number | string;
}

export interface DynamicTableItemProps<T = unknown> {
  id: string | number;
  data: T;
  renderExpandedContent?: () => ReactNode;
}

export interface DynamicTableProps<T = unknown> {
  cols: Array<DynamicTableColumnProps<T>>;
  items: Array<DynamicTableItemProps<T>>;
  isExpandable?: boolean;
  pagination?: DynamicTablePagination;
  paginationStyles?: string;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  renderPrefixHeader?: () => ReactNode;
  renderPrefixCell?: (
    item: DynamicTableItemProps<T>,
    index: number,
    items: Array<DynamicTableItemProps<T>>
  ) => ReactNode;
  isDark:boolean;
  isAdmin: boolean;
  footerRow?: JSX.Element;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const DynamicTable = <T extends object>({
  cols,
  items,
  isExpandable,
  pagination,
  paginationStyles,
  onSave,
  onCancel,
  renderPrefixHeader,
  renderPrefixCell,
  isDark,
  isAdmin,
  footerRow,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}: DynamicTableProps<T>) => {
  const defaultPaginationStyles = useStyles2(getPaginationStyles);
  const styles = useStyles2(getStyles(cols, isExpandable, !!renderPrefixHeader, isDark));
  const [expandedIds, setExpandedIds] = useState<Array<DynamicTableItemProps['id']>>([]);

  const toggleExpanded = (item: DynamicTableItemProps<T>) => {
    setExpandedIds(
      expandedIds.includes(item.id) ? expandedIds.filter((itemId) => itemId !== item.id) : [item.id]
    );
  };

  const itemsPerPage = pagination?.itemsPerPage ?? items.length;
  const { page, numberOfPages, onPageChange, pageItems } = usePagination(items, 1, itemsPerPage);

  return (
    <>
      <div className={styles.container} style={{ height: height }}>
        <div className={styles.header}>
          {renderPrefixHeader && renderPrefixHeader()}
          {isExpandable && <div className={styles.cell} />}
          {cols.map((col) => (
            <div className={styles.cell} key={col.id}>
              {col.label}
            </div>
          ))}
        </div>
        {pageItems.map((item, index) => {
		  const isItemExpanded = expandedIds.includes(item.id);
		  return (
            <div
              className={styles.row}
              key={`${item.id}-${index}`}
            >
              {renderPrefixCell && renderPrefixCell(item, index, items)}
              {(isExpandable && isAdmin) && (
                <div className={cx(styles.iconCell, styles.expandCell)}>
                  <IconButton
                    aria-label={`${isItemExpanded ? 'Collapse' : 'Expand'} row`}
                    size={'xl'}
                    className={styles.expandButton}
                    name={isItemExpanded ? 'angle-down' : 'angle-right'}
                    onClick={() => toggleExpanded(item)}
                    type="button"
                  />
                </div>
              )}
              {(!isExpandable || !isAdmin) && (
                <div className={cx(styles.cell, styles.expandCell)}>
                  <Icon
                    size="xs"
                    className={styles.expandIcon}
                    name={'minus'}
                  />
                </div>
              )}
              {cols.map((col) => (
				<div 
				  className={cx(styles.cell, styles.bodyCell)} 
				  data-column={col.label} 
				  key={`${item.id}-${col.id}`}
				>
                  {col.renderCell(item, index)}
                </div>
              ))}
              {isItemExpanded && (
                <div className={styles.expandedContentRow}>
                  <L1OyMExpandedForm
					onCancel={(item) => {
					  onCancel();
					  toggleExpanded(expandedIds);
					}}
					onSave={(item, typeOfUpdate) => {
					  const actualElements = cleanElements(items);
					  const newElements = updateElement(actualElements, item);
					  saveElement(newElements, pluginVariables[0]).then((resultado) => {
						if (resultado && typeOfUpdate === 'full') {
						  onSave(newElements);
						  toggleExpanded(expandedIds);
						} else if (typeOfUpdate === 'full') {
						  onCancel();
						  toggleExpanded(expandedIds);
						}
					  }).catch((error) => {
						console.error('Error al guardar el elemento:', error);
						onCancel();
						toggleExpanded(expandedIds);
					  });
					}}
					element={item.data}
					isDark={isDark}
					isAdmin={isAdmin}
					width={width}
					elements={elements}
					places={places}
					types={types}
					sites={sites}
					pluginVariables={pluginVariables}
					tableMode={true}
				  />
                </div>
              )}
            </div>
          );
        })}
        {footerRow && <div className={cx(styles.row, styles.footerRow)}>{footerRow}</div>}
      </div>
      {pagination && (
        <Pagination
          className={cx(defaultPaginationStyles, paginationStyles)}
          currentPage={page}
          numberOfPages={numberOfPages}
          onNavigate={onPageChange}
          hideWhenSinglePage
        />
      )}
    </>
  );
};

export interface DynamicMapProps<T = unknown> {
  items: Array<DynamicTableItemProps<T>>;
  onImportData: (newElements: []) => void;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  isDark:boolean;
  isAdmin: boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const DynamicMap = <T extends object>({
  items,
  onSave,
  onImportData,
  onCancel,
  isDark,
  isAdmin,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}: DynamicMapProps<T>) => {
  const styles = useStyles2(getStyles(null, false, false, isDark));
  const uidVariable = getTemplateSrv().replace(`$${pluginVariables[9]}`);
  const itemSelected = elements.find(ele => ele.uid === uidVariable);
  const [elementSelected, setElementSelected] = useState(itemSelected);
  //console.log(elementSelected, isKmzAddMode);

  const placeToMarker = (places: [], nextId: number) => {
	const empToMarker: [] = [];
	for (let i = 0; i < places.length; i++) {
	  const id = i + nextId
	  let newElement = {
		id: id,
		idx: places[i].label,
		uid: places[i].value,
		title: places[i].description,
		elementId: places[i].value,
		siteId: places[i].site,
		style: {color: '#000000', size: 32, weight: 2, icon: '', dashArray: ''},
		pathId: '',
		linkId: '',
		status: 'in_use',
		state: true,
		installationDate: '',
		installationNotes: '',
		technician: places[i].manteinance,
		lastInspection: '',
		nextMaintenance: '',
		owner: '',
		elementType: 'emp',
		subType: places[i].family,
		technicalDetails: '',
		origin: '',
		destination: '',
		coordinates: [Number(places[i].lat), Number(places[i].lng)],
		address: '',
		elevation: 0,
		parentsIds: [],
		relationIds: [],
		relationDistances: [],
		relationCapacities: [],
		sublinkIds: [],
	    sublinkBuffer: [],
	    sublinkColor: [],
		sublinkAttenuation: [],
		sublinkDistance: [],
		segmentId: '',
		pathEventId: '',
		capacity: 0,
		fiberIds: [],
		fiberAttenuation: [],
		lastInspector: '',
		inspectionReport: '',
		inspectionNotes: '',
		inspectionMediaData: '',
		user: 'Asset Manager',
		created: '',
		updated: '',
		version: 1,
		wasChange: false,
	  };
	  empToMarker.push({ data: newElement, id: id });
    }
	return empToMarker;
  }	
  const empToMarker = places ? placeToMarker(places, items.length) : [];

  return (
    <>
      <div className={styles.container}>
		<div className={styles.mapContainer}>
		  <L1OyMMapForm
			onCancel={(item) => {
			  setElementSelected(null);
			  onCancel();
			}}
			onImportData={(newElements) => {
			  saveImportData(newElements, elements.length).then((resultado) => {
			    if (resultado !== null) {
				  const elementsUpdated = updateImportElements(elements, resultado);
				  onImportData(elementsUpdated);
				} else {
				  onCancel();
				}
			  }).catch((error) => {
				console.error('Error al guardar el elemento:', error);
				onCancel();
			  });
			}}
			onSave={(item, typeOfUpdate) => {
			  const actualElements = cleanElements(items);
			  const newElements = updateElement(actualElements, item);
			  saveElement(newElements, pluginVariables[0]).then((resultado) => {
				if (resultado && typeOfUpdate === 'full') {
				  onSave(newElements);
				} else if (typeOfUpdate === 'full') {
				  onCancel();
				}
			  }).catch((error) => {
				console.error('Error al guardar el elemento:', error);
				onCancel();
			  });
			}}
			onSelect={(uid) => {
			  if (uid && uid !== null) {
				let itemSelected = elements.find(ele => ele.uid === uid);
				itemSelected = itemSelected ? itemSelected : items.find(ele => ele.data.uid === uid);
				itemSelected = itemSelected ? itemSelected : empToMarker.find(ele => ele.data.uid === uid).data;
				if (itemSelected) {
				  if (itemSelected.elementType === 'path') {
				    itemSelected = elements.find(ele => ele.uid === uid && ele.pathId === uid);
				  }
			      setElementSelected(itemSelected);
				}
			  } else {
			    setElementSelected(null);
			  }
			}}
			items={[ ...items, ...empToMarker]}
			element={elementSelected}
			isDark={isDark}
			isAdmin={isAdmin}
			width={width}
			height={height}
			elements={elements}
			places={places}
			types={types}
			sites={sites}
			pluginVariables={pluginVariables}
			isKmzAddMode={isKmzAddMode}
		  />
		</div>
      </div>
    </>
  );
};


const getStyles = <T extends unknown>(
  cols: Array<DynamicTableColumnProps<T>>,
  isExpandable: boolean,
  hasPrefixCell: boolean,
  isDark: boolean
) => {
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  let sizes = ['auto'];
  
  if (cols !== null) {
    sizes = cols.map((col) => {
      if (!col.size) {
        return 'auto';
      }
      if (typeof col.size === 'number') {
        return `${col.size}fr`;
      }
      return col.size;
    });
  }

  if (isExpandable) {
    sizes.unshift('calc(1em + 16px)');
  }

  if (hasPrefixCell) {
    sizes.unshift('0');
  }

  return (theme: NetMonitorTheme2) => ({
    container: css`
      color: ${fontColor};
      font-size: 12px;
	  overflow: auto;
	  padding-left: 3px;
    `,
    header: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;
      border-bottom: 1px solid ${theme.v1.colors.formInputBorder};
      font-weight: 600;
    `,
    row: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;

      &:nth-child(2n + 1) {
        background-color: ${theme.colors.background.secondary};
      }

      &:nth-child(2n) {
        background-color: ${theme.colors.background.primary};
      }
    `,
    footerRow: css`
      display: flex;
      padding: 12px 4px 0px 4px;
    `,
    cell: css`
      align-items: center;
      margin-left: -8px;
      padding:  13px 4px 0px 4px;
    `,
    iconCell: css`
      margin-left: -2px;
      padding:  16px 4px 0px 4px;

      ${theme.breakpoints.down('sm')} {
        padding:  12px 0px 0px 0px;
        grid-template-columns: 1fr;
      }
    `,
    bodyCell: css`
      overflow: hidden;
    `,
    expandCell: css`
      justify-content: center;
    `,
    expandedContentRow: css`
      grid-column-end: ${sizes.length + 1};
      grid-column-start: ${hasPrefixCell ? 3 : 2};
      grid-row: 2;
      padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(1)};
      position: relative;

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    expandButton: css`
      margin-right: 0;
      display: block;
    `,
    expandIcon: css`
      margin-top: 5px;
      margin-right: 0;
      display: block;
    `,
  });
};

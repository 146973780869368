import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { severityFieldOptions } from 'app/features/alerting/unified/utils/alertmanager';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { CustomFormSmSign } from './CustomSmSigns';
import { Text } from './Tags';
import { DynamicTable, DynamicTableColumnProps, DynamicTableItemProps } from './CustomSmDynamicTable';

export interface SmSignsTableProps {
  id: string;
  isAdmin: boolean;
  isAddMode: boolean;
  onSave: (signs: CustomFormSmSign[]) => void;
  onCancel: () => void;
  filters?: { sign?: string; name?: string ; oid?: string };
  isDark:boolean;
  width: number;
  height: number;
  signs: CustomFormSmSign[];
  assets: [];
  signTypeList: [];
  types: [];
  pluginVariables: [];
}

type RouteTableColumnProps = DynamicTableColumnProps<CustomFormSmSign>;
type RouteTableItemProps = DynamicTableItemProps<CustomFormSmSign>;

export const getFilteredSigns = (
  signs: CustomFormSmSign[],
  assetQuery?: string,
  nameQuery?: string,
  oidQuery?: string
) => {
  let filteredSigns = signs;

  if (assetQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.asset.toLowerCase().includes(assetQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }

  if (nameQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.name.toLowerCase().includes(nameQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }

  if (oidQuery) {
    filteredSigns = signs.filter((sign) => {
      if (sign.oid.toLowerCase().includes(oidQuery.toLowerCase())) {
        return true;
      }
	  return false;
	});
  }
  return filteredSigns;
};

export const cleanSigns = (signs: CustomFormSmSign[]): CustomFormSmSign[] => {
  const cleannerSigns: CustomFormSmSign[] = [];

  signs.forEach((sign, index) => {
	const alertSign = {
	  id: sign.id,
	  idx: sign.signId,
	  sign: sign.signName,
	  state: sign.signState,
	  date: sign.signDate,
	  user: sign.signUser,
	  asset: sign.signAsset,
	  assetType: '',
	  oid: sign.signOid,
	  oidValueType: sign.signOidValueType,
	  oidThresholdBelow: sign.signThresholdBelow,
	  oidDualThreshold: sign.signDualThreshold,
	  oidThresholdUp: sign.signOidThresholdUp,
	  oidThresholdBoolean: sign.signOidThresholdBoolean,
	  oidThresholdDown: sign.signOidThresholdDown,
	  signType: sign.signType,
	  name: sign.alertName,
	  summary: sign.alertSummary,
	  resolSummary: sign.resolSummary,
	  severity: sign.alertSeverity,
	  severityName: '',
	  scope: sign.alertScope,
	  openTicket: sign.openTicket
	};
	cleannerSigns.push(alertSign);
  });

  return cleannerSigns;
};
  
export const updateSign = (signs: CustomFormSmSign[], updatedSign: CustomFormSmSign): CustomFormSmSign[] => {
  const newSigns = [...signs];
  const editIndex = newSigns.findIndex((sign) => sign.id === updatedSign.id);

  if (editIndex >= 0) {
    newSigns[editIndex] = {
      ...newSigns[editIndex],
      ...updatedSign,
    };
  }
  return newSigns;
};

export const saveSign = (signs: CustomFormSmSign[], pluginApi: string, isAddMode: boolean): CustomFormSmSign[] => {
  const sign = signs.find(sign => sign.idx !== undefined);
  const userName = contextSrv.user.name;
  const isNew = isAddMode ? 'true' : 'false';
  const d = Date.now();
  const signDate = moment(d).format('DD/MM/YYYY HH:mm:ss');
  var signToUpdate = '{"dest":"net_cfg_custom_alert_values_data", "params":"';
  if (sign) {
	signToUpdate = signToUpdate +
	  '1;' + sign.idx + '||' +
	  '2;' + sign.asset + '||' +
	  '3;' + sign.oid + '||' +
	  '4;' + sign.oidValueType + '||' +
	  '5;' + !sign.oidThresholdBelow + '||' +
	  '6;' + sign.oidDualThreshold + '||' +
	  '7;' + sign.oidThresholdUp + '||' +
	  '8;' + sign.oidThresholdDown + '||' +
	  '9;' + sign.signType + '||' +
	  '10;' + sign.sign + '||' +
	  '11;' + sign.state + '||' +
	  '12;' + sign.name + '||' +
	  '13;' + sign.summary + '||' +
	  '14;' + sign.resolSummary + '||' +
	  '15;' + sign.severity + '||' +
	  '16;' + sign.openTicket + '||' +
	  '17;' + userName + '||' +
	  '100;' + isNew + '||"}';
    axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	axios.post(pluginApi, signToUpdate).then(
      response => {
		if (response.statusText === 'OK') {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, ['Firma actualizada correctamente']);
          });
        } else {
		  SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
          });
        }
      },
      error => {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
          appEvents.emit(AppEvents.alertError, ['Error al actualizar firma' + error.response.status]);
		});
      }
    );
  }
};

export const CustomSmSignsTable: FC<SmSignsTableProps> = ({
  id,
  isAdmin,
  isAddMode,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  signs,
  assets,
  signTypeList,
  types,
  pluginVariables
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const alertSigns: [] = [];
  const severities = severityFieldOptions;
  const valueToString = (value: string) => {
	const valueSelected = severities.find(severity => severity.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }
  const typeToString = (value: string) => {
	const valueSelected = assets.find(asset => asset.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return null;
	}
  }

  signs.forEach((sign, index) => {
	const alertSign = {
	  id: sign.id,
	  idx: sign.signId,
	  sign: sign.signName,
	  state: sign.signState,
	  date: sign.signDate,
	  user: sign.signUser,
	  asset: sign.signAsset,
	  assetType : typeToString(sign.signAsset),
	  oid: sign.signOid,
	  oidValueType: sign.signOidValueType,
	  oidThresholdBelow: sign.signThresholdBelow,
	  oidDualThreshold: sign.signDualThreshold,
	  oidThresholdUp: sign.signOidThresholdUp,
	  oidThresholdBoolean: sign.signOidThresholdBoolean,
	  oidThresholdDown: sign.signOidThresholdDown,
	  signType: sign.signType,
	  name: sign.alertName,
	  summary: sign.alertSummary,
	  resolSummary: sign.resolSummary,
	  severity: sign.alertSeverity,
	  severityName: valueToString(sign.alertSeverity),
	  scope: sign.alertScope,
	  openTicket: sign.openTicket
	};
	alertSigns.push(alertSign);
  });

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'sign',
      label: 'Firma',
      renderCell: (item) => {
		return item.data.sign.length ? (
          <Text text={item.data.sign} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} enable={item.data.state} />
        ) : (
          <Text text={'Deshabilitada'} enable={item.data.state} />
        );
      },
      size: 5,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'sign',
      label: 'Firma',
      renderCell: (item) => {
		return item.data.sign.length ? (
          <Text text={item.data.sign} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'asset',
      label: 'Activo',
      renderCell: (item) => {
        return item.data.asset ? (
		  <Text text={item.data.asset} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 4,
    },
    {
      id: 'oid',
      label: 'OID SNMP',
      renderCell: (item) => {
		return item.data.oid.length ? (
          <Text text={item.data.oid} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'name',
      label: 'Nombre de la Alerta',
      renderCell: (item) => {
		return item.data.name.length ? (
          <Text text={item.data.name} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 8,
    },
    {
      id: 'severityName',
      label: 'Severidad',
      renderCell: (item) => {
        return item.data.severityName ? (
		  <Text text={item.data.severityName} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} />
        ) : (
          <Text text={'Deshabilitada'} />
        );
      },
      size: 5,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 5,
	});
	cols.push({
	  id: 'date',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.date ? (
		  <Text text={item.data.date} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 6,
	});
  }

  const filteredSigns = useMemo(
    () => getFilteredSigns(alertSigns, filters?.sign, filters?.name, filters?.oid),
    [alertSigns, filters]
  );
  const dynamicTableSigns = useMemo(
    () => prepareItems(filteredSigns),
    [filteredSigns]
  );

  if (alertSigns.length > 0 && filteredSigns.length === 0) {
    return (
      <EmptyArea>
        <p>No existen reglas configuradas</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        isExpandable={true}
        items={dynamicTableSigns}
		onSave={onSave}
		onCancel={onCancel}
		isDark={isDark}
		isAdmin={isAdmin}
		isAddMode={isAddMode}
		width={width}
		height={height}
		signs={signs}
		assets={assets}
		signTypeList={signTypeList}
		types={types}
		pluginVariables={pluginVariables}
      />
    </>
  );
};
import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Cell,
  Column,
  TableState,
  useAbsoluteLayout,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import { useExportData } from 'react-table-plugins';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';

import { FixedSizeList } from 'react-window';

import { DataFrame, getFieldDisplayName } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon } from '@grafana/ui/src/components/Icon/Icon';
import { useStyles2 } from '@grafana/ui/src/themes';
import { CustomScrollbar } from '@grafana/ui/src/components/CustomScrollbar/CustomScrollbar';
import { Pagination } from './Pagination';

import { FooterRow } from './FooterRow';
import { HeaderRow } from './HeaderRow';
import { TableCell } from './TableCell';
import { getTableStyles } from './styles';
import {
  TableColumnResizeActionCallback,
  TableFilterActionCallback,
  FooterItem,
  TableSortByActionCallback,
  TableSortByFieldState,
} from './types';
import { getColumns, sortCaseInsensitive, sortNumber } from './utils';

const COLUMN_MIN_WIDTH = 100;
var filename = 'netmonitor';
var reportName = 'Netmonitor';
var orientation = 'l';

export interface Props {
  ariaLabel?: string;
  data: DataFrame;
  width: number;
  height: number;
  /** Minimal column width specified in pixels */
  columnMinWidth?: number;
  noHeader?: boolean;
  showTypeIcons?: boolean;
  resizable?: boolean;
  initialSortBy?: TableSortByFieldState[];
  onColumnResize?: TableColumnResizeActionCallback;
  onSortByChange?: TableSortByActionCallback;
  onCellFilterAdded?: TableFilterActionCallback;
  footerValues?: FooterItem[];
  enablePagination?: boolean;
  reportStyle: [];
  rowColorMode: [];
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === 'csv') {
    const config = { delimiter: ',' };
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data, config });
    //return new Blob([csvString], { type: "text/csv" });
    var a = $("<a style='display: none;'/>");
    var url = window.URL.createObjectURL(new Blob([csvString], { type: "text/csv" }, `${filename}.csv`));
    a.attr("href", url);
    a.attr("download", `${filename}.csv`);
    $("body").append(a);
    a[0].click();
    window.URL.revokeObjectURL(url);
    a.remove();
  } else if (fileType === 'xlsx') {
    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, reportName);
    XLSX.writeFile(wb, `${filename}.xlsx`);
    return false;
  } else if (fileType === 'pdf') {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF(orientation, 'pt');
    doc.setFontSize(14);
    doc.text(25, 25, reportName);
    doc.autoTable({
      startY: 40,
      theme: 'grid',
      head: [headerNames],
      body: data,
      margin: {horizontal: 20},
      styles: {
        font: 'Helvetica',
        fontSize: 9,
        halign: 'left',
        cellPadding: 3.5,
        lineWidth: 0.5,
        lineColor: [128, 128, 128],
        textColor: [10, 10, 10]
      },
      headStyles: {
        textColor: [255, 255, 255],
        fontStyle: 'normal',
        fontSize: 10,
        lineWidth: 0.75,
        lineColor: [128, 128, 128],
        fillColor: [13, 13, 235]
      },
      alternateRowStyles: {
        fontSize: 9,
        fillColor: [235, 236, 240],
        textColor: [10, 10, 10],
        lineWidth: 0.5,
        lineColor: [128, 128, 128]
      },
      rowStyles: {
        fontSize: 9,
        fillColor: [250, 250, 250],
        lineWidth: 0.5,
        textColor: [10, 10, 10],
        lineColor: [128, 128, 128]
      },
      tableLineColor: [0, 0, 0]
    });
    doc.save(`${filename}.pdf`);
    return false;
  }

  return false;
}

function useTableStateReducer({ onColumnResize, onSortByChange, data }: Props) {
  return useCallback(
    (newState: TableState, action: any) => {
      switch (action.type) {
        case 'columnDoneResizing':
          if (onColumnResize) {
            const info = (newState.columnResizing.headerIdWidths as any)[0];
            const columnIdString = info[0];
            const fieldIndex = parseInt(columnIdString, 10);
            const width = Math.round(newState.columnResizing.columnWidths[columnIdString] as number);

            const field = data.fields[fieldIndex];
            if (!field) {
              return newState;
            }

            const fieldDisplayName = getFieldDisplayName(field, data);
            onColumnResize(fieldDisplayName, width);
          }
        case 'toggleSortBy':
          if (onSortByChange) {
            const sortByFields: TableSortByFieldState[] = [];

            for (const sortItem of newState.sortBy) {
              const field = data.fields[parseInt(sortItem.id, 10)];
              if (!field) {
                continue;
              }

              sortByFields.push({
                displayName: getFieldDisplayName(field, data),
                desc: sortItem.desc,
              });
            }

            onSortByChange(sortByFields);
          }
          break;
      }

      return newState;
    },
    [data, onColumnResize, onSortByChange]
  );
}

function getInitialState(initialSortBy: Props['initialSortBy'], columns: Column[]): Partial<TableState> {
  const state: Partial<TableState> = {};

  if (initialSortBy) {
    state.sortBy = [];

    for (const sortBy of initialSortBy) {
      for (const col of columns) {
        if (col.Header === sortBy.displayName) {
          state.sortBy.push({ id: col.id as string, desc: sortBy.desc });
        }
      }
    }
  }

  return state;
}

export const Table: FC<Props> = memo((props: Props) => {
  const {
    ariaLabel,
    data,
    height,
    onCellFilterAdded,
    width,
    columnMinWidth = COLUMN_MIN_WIDTH,
    noHeader,
    footerInfo,
    showToolbar,
	totalTitle,
	hideTable,
    resizable = true,
    initialSortBy,
    footerValues,
    showTypeIcons,
    enablePagination,
    reportStyle,
	rowColorMode,
  } = props;

  filename = reportStyle[1];
  reportName = reportStyle[0].substring(0, 30);
  orientation = reportStyle[2];
  const tableBodyRef = useRef(null);
  const listRef = useRef<FixedSizeList>(null);
  const tableStyles = useStyles2(getTableStyles);
  const headerHeight = noHeader ? 0 : tableStyles.cellHeight;
  const toolbarHeight = 35;

  const footerHeight = useMemo(() => {
    const EXTENDED_ROW_HEIGHT = 33;
    let length = 0;

    if (!footerValues) {
      return 0;
    }

    for (const fv of footerValues) {
      if (Array.isArray(fv) && fv.length > length) {
        length = fv.length;
      }
    }

    if (length > 1) {
      return EXTENDED_ROW_HEIGHT * length;
    }

    return EXTENDED_ROW_HEIGHT;
  }, [footerValues]);

  const memoizedData = useMemo(() => {
    if (!data.fields.length) {
      return [];
    }
    return Array(data.length).fill(0);
  }, [data]);

  const memoizedColumns = useMemo(
    () => getColumns(data, width - 35, columnMinWidth, footerValues),
    [data, width - 35, columnMinWidth, footerValues]
  );
  
  const stateReducer = useTableStateReducer(props);

  const options: any = useMemo(
    () => ({
      columns: memoizedColumns,
      data: memoizedData,
      disableResizing: !resizable,
      stateReducer: stateReducer,
      initialState: getInitialState(initialSortBy, memoizedColumns),
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      sortTypes: {
        number: sortNumber,
        'alphanumeric-insensitive': sortCaseInsensitive,
      },
      getExportFileBlob,
    }),
    [initialSortBy, memoizedColumns, memoizedData, resizable, stateReducer]
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    totalColumnsWidth,
    footerGroups,
    page,
    state,
    gotoPage,
    setPageSize,
    pageOptions,
    preGlobalFilteredRows,
    setGlobalFilter,
    exportData,
  } = useTable(options, useFilters, useSortBy, usePagination, useAbsoluteLayout, useResizeColumns, useGlobalFilter, useExportData);

  const tableHeight = enablePagination ? height : rows.length * tableStyles.cellHeight;
  let listHeight = height - 8 - (headerHeight + footerHeight);
  if (showToolbar) {
    listHeight = height - 15 - (headerHeight + footerHeight + toolbarHeight);
  }
  if (enablePagination) {
    listHeight -= tableStyles.cellHeight - 5;
  }
  const pageSize = Math.round(listHeight / tableStyles.cellHeight) - 1;
  useEffect(() => {
    // Don't update the page size if it is less than 1
    if (pageSize <= 0) {
      return;
    }
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  const getCellBackgroundColor = (rowIndex, firstCell) => {
    // set row background color
    const value = firstCell.value;
	const field = (firstCell.column as any as NetMonitorTableColumn).field;
    const thresholds = field.config.thresholds.steps;

	var cellColor = 'default';
    
	if (thresholds.length > 0 && !isNaN(value)) {
	  for (let i = 0; i < thresholds.length; i++) {
		if (value >= thresholds[i].value) {
		  cellColor = thresholds[i].color;
		}
	  }
	}
	return cellColor;
  };

  const RenderRow = React.useCallback(
    ({ index: rowIndex, style }) => {
      let row = rows[rowIndex];
      if (enablePagination) {
        row = page[rowIndex];
      }
      prepareRow(row);
	  var rowColor = 'default';
	  if (rowColorMode[0]) {
	    rowColor = getCellBackgroundColor(rowIndex, rows[rowIndex].cells[rowColorMode[1] - 1]);
	  }
      return (
        <div {...row.getRowProps({ style: style })} className={tableStyles.row}
		>
          {row.cells.map((cell: Cell, index: number) => (
            <TableCell
              key={index}
              tableStyles={tableStyles}
              cell={cell}
              onCellFilterAdded={onCellFilterAdded}
              columnIndex={index}
              columnCount={row.cells.length}
			  background={rowColor}
            />
          ))}
        </div>
      );
    },
    [onCellFilterAdded, page, enablePagination, prepareRow, rows, tableStyles]
  );

  const onNavigate = useCallback(
    (toPage: number) => {
      gotoPage(toPage - 1);
    },
    [gotoPage]
  );

  const itemCount = enablePagination ? page.length : rows.length;
  let paginationEl = null;
  if (enablePagination) {
    const itemsRangeStart = state.pageIndex * state.pageSize + 1;
    let itemsRangeEnd = itemsRangeStart + state.pageSize - 1;
    const isSmall = width < 550;
    if (itemsRangeEnd > data.length) {
      itemsRangeEnd = data.length;
    }
    paginationEl = (
      <div className={tableStyles.paginationWrapper}>
        {footerInfo && !isSmall && (
          <div className={tableStyles.paginationItem} />
        )}
        <div className={tableStyles.paginationCenterItem}>
          <Pagination
            currentPage={state.pageIndex + 1}
            numberOfPages={pageOptions.length}
            showSmallVersion={isSmall}
            onNavigate={onNavigate}
          />
        </div>
        {footerInfo && !isSmall && (
          <div className={tableStyles.paginationSummary}>
            Mostrando {itemsRangeStart} a {itemsRangeEnd} de {data.length}
          </div>
        )}
      </div>
    );
  }

  const handleScroll: React.UIEventHandler = (event) => {
    const { scrollTop } = event.target as HTMLDivElement;

    if (listRef.current !== null) {
      listRef.current.scrollTo(scrollTop);
    }
  };

  let isWider = false;
  if (width < totalColumnsWidth) {
    isWider = true;
  }

  const [exportAll, setExportAll] = useState(false);
  const [exportFormat, setExportFormat] = useState('csv');
  let tableLeftSpace = 2;
  if (showToolbar) {
    tableLeftSpace = 42;
  }

  return (
    <>
      {showToolbar && (
        <div className={tableStyles.toolbarBox}>
          <div className={tableStyles.toolBar}>
			<div
			  className={tableStyles.toolBarButtonLeft}
			  title={'Descargar rerporte en formato ' + exportFormat}
			  onClick={e => {
				exportData(exportFormat, exportAll);
			  }}
			>
			  <Icon
                className={tableStyles.toolbarIcon}
                name={'download-alt'}
                size="md"
              />
			  Descargar Reporte
			</div>
            {width > 500 && (
			  <div className={tableStyles.toolBarGroup}>
			    <div className={tableStyles.toolbarFilter}>
				  <Icon
                    className={tableStyles.toolbarIcon}
                    name={exportFormat === 'csv' ? 'check-square' : 'square'}
                    size="lg"
                    title={exportAll ? 'Exportar vista actual en formato csv' : 'Exportat todo en formato csv'}
                    onClick={() => {
                      setExportFormat('csv');
                    }}
                  />
				    csv
                </div>
                <div className={tableStyles.toolbarFilter}>
                  <Icon
                    className={tableStyles.toolbarIcon}
                    name={exportFormat === 'xlsx' ?  'check-square' : 'square'}
                    size="lg"
                    title={exportAll ? 'Exportar vista actual en formato Excel' : 'Exportat todo en formato Excel'}
                    onClick={() => {
                      setExportFormat('xlsx');
                    }}
                  />
				    Excel
                </div>
                <div className={tableStyles.toolbarFilter}>
                  <Icon
                    className={tableStyles.toolbarIcon}
                    name={exportFormat === 'pdf' ?  'check-square' : 'square'}
                    size="lg"
                    title={exportAll ? 'Exportar vista actual como pdf' : 'Exportat todo como pdf'}
                    onClick={() => {
                      setExportFormat('pdf');
                    }}
                  />
				  pdf
			    </div>
              </div>
			)}
            {!hideTable && (
			  <div className={tableStyles.toolbarFilter}>
                <Icon
                  className={tableStyles.toolbarIcon}
                  name={exportAll ? 'check-square' : 'square'}
                  size="lg"
                  title={exportAll ? 'Exportar solo las filas de la vista actual' : 'Exportar todas las filas, quitando filtro aplicado'}
                  onClick={(e) => {
                    setExportAll(!exportAll);
                  }}
                />
                {exportAll ? 'Vista actual' : 'Todo'}
              </div>
			)}
          </div>
		  {totalTitle !== 'none' && width > 300 && (
		    <div className={tableStyles.toolbarTotalBox}>
			  <div className={tableStyles.toolbarTotal}>
			    {totalTitle} {rows.length} {' de '} {data.length}
			  </div>
            </div>
		  )}
        </div>
      )}
      {!hideTable && (
	    <div {...getTableProps()}
		  className={tableStyles.table} 
          style={{ height: 'Calc( 100% - ' + tableLeftSpace + 'px', width: 'Calc( 100% - 2px)' }}
          aria-label={ariaLabel}
          role="table"
        >
          {!noHeader && <HeaderRow headerGroups={headerGroups} showTypeIcons={showTypeIcons} />}
          <div 
            className={isWider 
              ? tableStyles.tableContentWrapper(totalColumnsWidth)
              : tableStyles.tableContentWrapper(totalColumnsWidth + 29)
            }
			style={{ overflowX: 'hidden', overflowY: 'auto', height: tableHeight * 1.08 + 'px' }}
			ref={tableBodyRef}
		  >
            {itemCount > 0 ? (
              <FixedSizeList
                height={listHeight}
                itemCount={itemCount}
                itemSize={tableStyles.rowHeight}
                width={totalColumnsWidth - 2}
                ref={listRef}
                style={{ overflowX: 'hidden', overflowY: 'auto', minWidth: '100%' }}
              >
                {RenderRow}
              </FixedSizeList>
            ) : (
              <div style={{ height: height - headerHeight }} className={tableStyles.noData}>
                <Icon name="file-lanscape-slash" size="lg" />
              </div>
            )}
          </div>
          {footerValues && enablePagination && (
            <FooterRow
              height={footerHeight}
              isPaginationVisible={Boolean(enablePagination)}
              footerValues={footerValues}
              footerGroups={footerGroups}
              totalColumnsWidth={isWider ? totalColumnsWidth : totalColumnsWidth + 29}
            />
          )}
          {paginationEl}
        </div>
	  )}
    </>
  );
});

Table.displayName = 'Table';

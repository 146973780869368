import { locationService, getTemplateSrv } from '@grafana/runtime';

export function changeUrl(queryMap) {
  locationService.partial(queryMap, true);
}

export function getNetMonitorVariableValue(variableName: any) {
  return getTemplateSrv().replace(`$${variableName}`);
}

export function openUrl(
  url: string,
  updateBackUrl: boolean,
  useBack: boolean,
  varBackVariable: string,
  varBack1Variable: string
) {
  if (useBack) {
    let varBack = getNetMonitorVariableValue(varBack1Variable);
    if (updateBackUrl) {
      varBack = getNetMonitorVariableValue(varBackVariable);
    }
    if (varBack.length > 1 && varBack !== '-' && varBack !== null) {
      if (url.includes('?')) {
        url = url + '&var-' + varBackVariable + '=' + varBack;
      } else {
        url = url + '?var-' + varBackVariable + '=' + varBack;
      }
    }
  }
  if (url !== null) {
    window.open(url, '_self');
  }
}

import React, { FC } from 'react';
import { config } from '@grafana/runtime';
import './css/alertGroups.css';

type Tag = {
  name: string;
  value: string;
  operator: string;
  color: string;
};

type TagsProps = { tags: Tag[]; };
type TextProps = { text: string; };

export const Tags: FC<TagsProps> = ({ tags }) => {
  const isDark = config.theme.isDark ? '_dark' : '';
  const onclickUrl = 'd/am/alarmas?var-in=1&var-name=';
  const numTags = tags.length;
  const tagsToDisplay = tags.slice(0, 10);
  return (
	<div className={'alertGroupTags'}>
      <ul className={'alertGroupwrapper'} aria-label="Tags">
        {tagsToDisplay.map((tag, i) => (
          <li key={tag}>
            <a href={onclickUrl + tag.value} target={'_blank'}>
			  <span className={'alertGroupTag' + tag.color + isDark} aria-label={tag.name}>{tag.name}</span>
			  <span className={'alertGroupText' + isDark} aria-label={tag.operator}><b>{tag.operator}</b></span>
			  <span className={'alertGroupText' + isDark} aria-label={tag.value}>{tag.value}</span>
			</a>
          </li>
        ))}
        {numTags - 10 > 0 && (
          <span className={'alertGroupText' + isDark}>+ {numTags - 10} ...</span>
        )}
      </ul>
	</div>
  );
};


export const Text: FC<TextProps> = ({ text }) => {
  const isDark = config.theme.isDark ? '_dark' : '';
  const color = (text !== 'Cualquiera' && text !== 'Todas' && text !== 'Ninguna' && text !== '-') ? '' : '_shadow';
  return (
	<div className={'alertGroupTags'}>
	  <span className={'alertGroupText' + color + isDark}>{text}</span>
	</div>
  );
};

export function ruleToTag(rule: string): Tag {
  const itemEqual = rule.split('==');
  const itemDistinct = rule.split('!=');
  const alertColors = {
    Alarma: 'Red',
    Alerta: 'Orange',
    Trap: 'Blue',
    Resolucion: 'Green',
    Reporte: 'Violet',
	Grupo: 'Blue',
  };
  if (itemEqual.length > 1) {
	return {
      name: itemEqual[0],
      value: itemEqual[1],
	  operator: ' = ',
	  color: alertColors[`${itemEqual[0]}`] ?? '',
    };
  } else if (itemDistinct.length > 1) {
	return {
      name: itemDistinct[0],
      value: itemDistinct[1],
	  operator: ' ≠ ',
	  color: alertColors[`${itemDistinct[0]}`] ?? '',
    };
  } 
}

import { SelectableValue } from '@grafana/data';

export interface L1OyMPanelOptions {
  uid: string;
  title: string;
  technicalDetails: string;
  updated_at: string;
  created_at: string;
  user: string;
  version: string;
  api: string;
  mapSource: string;
  uidVariable: string;
  useZoomVariable: boolean;
  zoomVariable: string;
  xVariable: string;
  yVariable: string;
  variableToTrack: string;
  siteList: string;
  placeList: string;
  placeTypeList: string;
  placeVariable: string;
  placeTypesVariable: string;
  showTableMode: boolean;
  pluginTitle: string;
  successMessage: string;
  editorCanAdmin: boolean;
  showTitle: boolean;
  showPlaceSelector: boolean;
  error4: string;
}

export const valueToSubtype: SelectableValue[] = [
  { label: 'Dependencia', description: 'Edificio/Oficina donde opera una sucursal o dependencia', value: 'emp', family: 'emp' },
  { label: 'Nodo', description: 'Sitio donde se ubica un nodo de comunicaciones', value: 'node', family: 'emp' },
  { label: 'Centro de Datos', description: 'Centro de datos que opera dentro de una dependencia o nodo de red', value: 'data_center', family: 'emp' },
  { label: 'Cuarto de Cableado', description: 'Gabinete o habitación con elementos de conectividad', value: 'wiring_room', family: 'emp' },
  { label: 'Mastil', description: 'Mastil que posee elementos de conectividad', value: 'mast', family: 'emp' },
  { label: 'Poste', description: 'Poste que posee elementos de conectividad', value: 'pole', family: 'emp' },
  { label: 'Trayecto', description: 'Uno o más secciones que conectan fisicamente emplazamientos', value: 'path', family: 'path' },
  { label: 'Tramo Subterráneo', description: 'Sección compuesta de ductos de PVC o acero', value: 'underground_span', family: 'span' },
  { label: 'Tramo Aereo', description: 'Sección instalada sobre postes, torres o estructuras elevadas', value: 'aerial_span', family: 'span' },
  { label: 'Enlace Génerico', description: 'Enlace de cobre entre emplazamientos', value: 'ge_path', family: 'link' },
  { label: 'Enlace Optico', description: 'Enlace de fibra optica entre emplazamientos', value: 'fo_path', family: 'link' },
  { label: 'Enlace de Radio', description: 'Enlace de Radio entre emplazamientos', value: 're_path', family: 'link' },
  { label: 'Enlace Satelital', description: 'Enlace Satelital entre emplazamientos', value: 'sa_path', family: 'link' },
  { label: 'Cable Subterráneo', description: 'Segmento subterraneo entre elementos', value: 'underground', family: 'segment' },
  { label: 'Cable Aereo', description: 'Segmento aereo entre elementos', value: 'aerial', family: 'segment' },
  { label: 'Enlace de Radio', description: 'Segmento inalambrico entre elementos', value: 'radio', family: 'segment' },
  { label: 'Fusión', description: 'Fusion de hilos de fibra óptica', value: 'fusion', family: 'splicer' },
  { label: 'Conector', description: 'Interconexión empleando conectores mecanicos', value: 'connector', family: 'splicer' },
  { label: 'FBT Splitter', description: 'Fused Biconical Taper', value: 'fbt', family: 'splitter' },
  { label: 'PLC Splitter', description: 'Planar Lightwave Circuit', value: 'plc', family: 'splitter' },
  { label: 'Caja de Inpección', description: 'Caja de acceso de interconexión o empalme', value: 'hand_hole', family: 'element' },
  { label: 'Cámara de Inspección', description: 'Cámaras subterráneas de derivación o empalme', value: 'man_hole', family: 'element' },
  { label: 'Botella de Empalme', description: 'Dispositivos sellados donde se conectan y protegen los empalmes de cables', value: 'fo_closure', family: 'element' },
  { label: 'Panel de Cruzada', description: 'Paneles de interconexión mecánicos', value: 'patch_panel', family: 'element' },
  { label: 'Distribuidor Optico', description: 'Paneles de interconexión óptica', value: 'odf', family: 'element' },
  { label: 'Cajas de distribución', description: 'Puntos donde se dividen o distribuyen cables a varias ubicaciones', value: 'distribution_box', family: 'element' },
  { label: 'Cajas de terminación', description: 'Puntos donde se conectan equipos u otros cables mediante conectores.', value: 'termination_box', family: 'element' },
];

export const valueToType: SelectableValue[] = [
  { label: 'Emplazamiento', description: 'Lugar físico donde se ubica un elemento determinado', value: 'emp' , order: 0},
  { label: 'Trayecto', description: 'Trayecto fisico compuesto por tramos que incluyen elementos de conectividad pasiva', value: 'path' , order: 1},
  { label: 'Elemento', description: 'Elementos de conectividad pasiva', value: 'element', order: 3 },
  { label: 'Enlace', description: 'Enlace de comunicaciones que conecta dos emplazamientos a traves de segmentos', value: 'link', order: 2 },
  { label: 'Segmento', description: 'Sección de Cable o RE entre elementos de conectividad pasiva', value: 'segment', order: 3 },
  { label: 'Empalme', description: 'Elemento que conecta dos segmentos de un mismo enlace', value: 'splicer', order: 5 },
  { label: 'División', description: 'Elemento que conecta dos segmentos de diferentes enlaces', value: 'splitter', order: 4 },
];

export const valueToStatus: SelectableValue[] = [
  { label: 'En Uso', description: 'Elemento actualmente en uso', value: 'in_use' },
  { label: 'Fuera de Uso', description: 'Elemento que no es utilizado', value: 'out_of_use' },
  { label: 'En Reparación', description: 'Elemento en reparación/modificación', value: 'under_repair' },
  { label: 'En Construcción', description: 'Elemento en contrucción', value: 'under_construction' },
];

export const fiberOpticColors = [
  { label: 'Azul', description: 'Primera fibra', value: 'blue' },
  { label: 'Naranja', description: 'Segunda fibra', value: 'orange' },
  { label: 'Verde', description: 'Tercera fibra', value: 'green' },
  { label: 'Marrón', description: 'Cuarta fibra', value: 'brown' },
  { label: 'Gris', description: 'Quinta fibra', value: 'gray' },
  { label: 'Blanco', description: 'Sexta fibra', value: 'white' },
  { label: 'Rojo', description: 'Séptima fibra', value: 'red' },
  { label: 'Negro', description: 'Octava fibra', value: 'black' },
  { label: 'Amarillo', description: 'Novena fibra', value: 'yellow' },
  { label: 'Violeta', description: 'Décima fibra', value: 'violet' },
  { label: 'Turquesa', description: 'Undécima fibra', value: 'turquoise' },
  { label: 'Rosa', description: 'Duodécima fibra', value: 'pink' },
  { label: 'Cyan', description: 'Decimotercera fibra', value: 'cyan' },
  { label: 'Magenta', description: 'Decimocuarta fibra', value: 'magenta' }
];

export const bufferColors = [
  { label: 'Azul', description: 'Primer buffer', value: 'blue' },
  { label: 'Naranja', description: 'Segundo buffer', value: 'orange' },
  { label: 'Verde', description: 'Tercer buffer', value: 'green' },
  { label: 'Marrón', description: 'Cuarto buffer', value: 'brown' },
  { label: 'Gris', description: 'Quinto buffer', value: 'gray' },
  { label: 'Blanco', description: 'Sexto buffer', value: 'white' },
  { label: 'Rojo', description: 'Séptimo buffer', value: 'red' },
  { label: 'Negro', description: 'Octavo buffer', value: 'black' },
  { label: 'Amarillo', description: 'Noveno buffer', value: 'yellow' },
  { label: 'Violeta', description: 'Décimo buffer', value: 'violet' },
  { label: 'Turquesa', description: 'Undécimo buffer', value: 'turquoise' },
  { label: 'Rosa', description: 'Duodécimo buffer', value: 'pink' },
  { label: 'Cyan', description: 'Decimotercera buffer', value: 'cyan' },
  { label: 'Magenta', description: 'Decimocuarta buffer', value: 'magenta' }
];

export const multiparColors = [
  { label: 'Blanco/Azul', description: 'Par 1', value: 'white-blue' },
  { label: 'Blanco/Naranja', description: 'Par 2', value: 'white-orange' },
  { label: 'Blanco/Verde', description: 'Par 3', value: 'white-green' },
  { label: 'Blanco/Marrón', description: 'Par 4', value: 'white-brown' },
  { label: 'Blanco/Gris', description: 'Par 5', value: 'white-gray' },
  { label: 'Rojo/Azul', description: 'Par 6', value: 'red-blue' },
  { label: 'Rojo/Naranja', description: 'Par 7', value: 'red-orange' },
  { label: 'Rojo/Verde', description: 'Par 8', value: 'red-green' },
  { label: 'Rojo/Marrón', description: 'Par 9', value: 'red-brown' },
  { label: 'Rojo/Gris', description: 'Par 10', value: 'red-gray' },
  { label: 'Negro/Azul', description: 'Par 11', value: 'black-blue' },
  { label: 'Negro/Naranja', description: 'Par 12', value: 'black-orange' },
  { label: 'Negro/Verde', description: 'Par 13', value: 'black-green' },
  { label: 'Negro/Marrón', description: 'Par 14', value: 'black-brown' },
  { label: 'Negro/Gris', description: 'Par 15', value: 'black-gray' },
  { label: 'Amarillo/Azul', description: 'Par 16', value: 'yellow-blue' },
  { label: 'Amarillo/Naranja', description: 'Par 17', value: 'yellow-orange' },
  { label: 'Amarillo/Verde', description: 'Par 18', value: 'yellow-green' },
  { label: 'Amarillo/Marrón', description: 'Par 19', value: 'yellow-brown' },
  { label: 'Amarillo/Gris', description: 'Par 20', value: 'yellow-gray' },
  { label: 'Violeta/Azul', description: 'Par 21', value: 'violet-blue' },
  { label: 'Violeta/Naranja', description: 'Par 22', value: 'violet-orange' },
  { label: 'Violeta/Verde', description: 'Par 23', value: 'violet-green' },
  { label: 'Violeta/Marrón', description: 'Par 24', value: 'violet-brown' },
  { label: 'Violeta/Gris', description: 'Par 25', value: 'violet-gray' }
];

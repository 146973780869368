import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { AlarmThreshold } from './AlarmThreshold';
import { Text } from './Tags';
import { valueToSources } from './types';
import { DynamicTable, DynamicTableColumnProps, DynamicTableItemProps } from './AlarmThresholdDynamicTable';

export interface ThresholdTableProps {
  id: string;
  isAdmin: boolean;
  onSave: (thresholds: AlarmThreshold[]) => void;
  onCancel: () => void;
  isDark:boolean;
  width: number;
  height: number;
  thresholds: AlarmThreshold[];
  assets: [];
  thresholdTypeList: [];
  types: [];
  pluginVariables: [];
}

type RouteTableColumnProps = DynamicTableColumnProps<AlarmThreshold>;
type RouteTableItemProps = DynamicTableItemProps<AlarmThreshold>;

export const cleanThresholds = (thresholds: AlarmThreshold[]): AlarmThreshold[] => {
  const cleannerThresholds: AlarmThreshold[] = [];

  thresholds.forEach((threshold, index) => {
	const alertThreshold = {
	  id: threshold.id,
	  asset: threshold.thresholdAsset,
	  assetType: '',
	  state: threshold.thresholdState,
	  isBoolean: threshold.thresholdIsBoolean,
	  percentFail: threshold.thresholdPercentFail,
	  thresholdBelow: threshold.thresholdBelow,
	  thresholdDual: threshold.thresholdDual,
	  thresholdUp: threshold.thresholdUp,
	  thresholdDown: threshold.thresholdDown,
	  thresholdType: threshold.thresholdType,
	  notifRepeat: threshold.notifRepeat,
	  source: threshold.thresholdSource,
	  date: threshold.thresholdDate,
	  user: threshold.thresholdUser,
    };
	cleannerThresholds.push(alertThreshold);
  });

  return cleannerThresholds;
};
  
export const updateThreshold = (thresholds: AlarmThreshold[], updatedThreshold: AlarmThreshold): AlarmThreshold[] => {
  const newThresholds = [...thresholds];
  const editIndex = newThresholds.findIndex((threshold) => threshold.id === updatedThreshold.id);

  if (editIndex >= 0) {
    newThresholds[editIndex] = {
      ...newThresholds[editIndex],
      ...updatedThreshold,
    };
  }
  return newThresholds;
};

export const saveThreshold = (thresholds: AlarmThreshold[], pluginApi: string, thresholdTypeList: []): AlarmThreshold[] => {
  const threshold = thresholds.find(threshold => threshold.thresholdType !== undefined);
  const userName = contextSrv.user.name;
  const thresholdType = thresholdTypeList.find(element => element.label === threshold.thresholdType);
  const thresholdTypeValue = thresholdType ? thresholdType.value : null;
  var thresholdToUpdate = '{"dest":"net_panel_notification_values_data", "params":"';
  if (threshold && thresholdTypeValue !== null) {
	thresholdToUpdate = thresholdToUpdate +
	  '1;' + threshold.id + '||' +
	  '2;' + threshold.asset + '||' +
	  '3;' + thresholdTypeValue + '||' +
	  '4;' + threshold.state + '||' +
	  '5;' + 'asset' + '||' +
	  '6;' + !threshold.thresholdBelow + '||' +
	  '7;' + threshold.thresholdDual + '||' +
	  '8;' + threshold.thresholdUp + '||' +
	  '9;' + threshold.thresholdDown + '||' +
	  '10;' + threshold.percentFail + '||' +
	  '11;' + threshold.notifRepeat + '||' +
	  '12;' + userName + '||"}';
	axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	axios.post(pluginApi, thresholdToUpdate).then(
      response => {
		if (response.statusText === 'OK') {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, ['Firma actualizada correctamente']);
          });
        } else {
		  SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
          });
        }
      },
      error => {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
          appEvents.emit(AppEvents.alertError, ['Error al actualizar firma' + error.response.status]);
		});
      }
    );
  }
};

export const AlarmThresholdTable: FC<ThresholdTableProps> = ({
  id,
  isAdmin,
  onSave,
  onCancel,
  isDark,
  width,
  height,
  thresholds,
  assets,
  thresholdTypeList,
  types,
  pluginVariables
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const alertThresholds: [] = [];

  const aspectToString = (value: string) => {
	const valueSelected = thresholdTypeList.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }
  const typeToString = (value: string) => {
	const valueSelected = assets.find(asset => asset.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return null;
	}
  }
  const sourcetoString = (value: string) => {
	const valueSelected = valueToSources.find(source => source.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  thresholds.forEach((threshold, index) => {
	const alertThreshold = {
	  id: threshold.id,
	  source: sourcetoString(threshold.thresholdSource),
	  state: threshold.thresholdState,
	  date: threshold.thresholdDate,
	  user: threshold.thresholdUser,
	  asset: threshold.thresholdAsset,
	  assetType: typeToString(threshold.thresholdAsset),
	  isBoolean: threshold.thresholdIsBoolean,
	  percentFail: threshold.thresholdPercentFail,
	  notifRepeat: threshold.notifRepeat,
	  thresholdBelow: threshold.thresholdBelow,
	  thresholdDual: threshold.thresholdDual,
	  thresholdUp: threshold.thresholdUp,
	  thresholdDown: threshold.thresholdDown,
	  thresholdType: aspectToString(threshold.thresholdType),
	};
	alertThresholds.push(alertThreshold);
  });

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'type',
      label: 'Aspecto',
      renderCell: (item) => {
		return item.data.thresholdType.length ? (
          <Text text={item.data.thresholdType} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} enable={item.data.state} />
        ) : (
          <Text text={'Deshabilitada'} enable={item.data.state} />
        );
      },
      size: 5,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'type',
      label: 'Aspecto',
      renderCell: (item) => {
		return item.data.thresholdType.length ? (
          <Text text={item.data.thresholdType} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'thresholdUp',
      label: 'Umbral Superior',
      renderCell: (item) => {
		if (item.data.isBoolean) {
		  return item.data.thresholdBelow === true ? (
            <Text text={'Activo'} enable={item.data.state}/>
          ) : (
            <Text text={'-'} />
          );
		} else {
		  return item.data.thresholdUp !== null ? (
            <Text text={item.data.thresholdUp + ' %'} enable={item.data.state} />
          ) : (
            <Text text={'-'} />
          );
		}
      },
      size: 5,
    },
    {
      id: 'thresholdDown',
      label: 'Umbral Inferior',
      renderCell: (item) => {
		if (item.data.isBoolean) {
		  return item.data.thresholdBelow === true ? (
            <Text text={'Inactivo'} enable={item.data.state}/>
          ) : (
            <Text text={'-'} />
          );
		} else {
		  return item.data.thresholdDown !== null ? (
            <Text text={item.data.thresholdDown  + ' %'} enable={item.data.state} />
          ) : (
            <Text text={'-'} />
          );
		}
      },
      size: 8,
    },
    {
      id: 'source',
      label: 'Aplica a',
      renderCell: (item) => {
		return item.data.source !== null ? (
          <Text text={item.data.source} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 8,
    },
    {
      id: 'percentFail',
      label: 'SLI',
      renderCell: (item) => {
        return item.data.percentFail ? (
		  <Text text={item.data.percentFail + ' %'} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
      },
      size: 4,
    },
    {
      id: 'notifRepeat',
      label: 'Intervalo repetición',
      renderCell: (item) => {
        return item.data.notifRepeat !== '00:00:00' ? (
		  <Text text={item.data.notifRepeat} enable={item.data.state} />
		) : (
		  <Text text={'Deshabilitado'} />
		);
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        return item.data.state === true ? (
          <Text text={'Habilitada'} />
        ) : (
          <Text text={'Deshabilitada'} />
        );
      },
      size: 5,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 5,
	});
	cols.push({
	  id: 'date',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.date ? (
		  <Text text={item.data.date} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 6,
	});
  }

  const dynamicTableThresholds = useMemo(
    () => prepareItems(alertThresholds),
    [alertThresholds]
  );

  if (alertThresholds.length < 1) {
    return (
      <EmptyArea>
        <p>No existen reglas configuradas</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        items={dynamicTableThresholds}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		thresholds={thresholds}
		assets={assets}
		thresholdTypeList={thresholdTypeList}
		types={types}
		pluginVariables={pluginVariables}
      />
    </>
  );
};
import React, { ChangeEvent } from 'react';
import { StandardEditorProps } from '@grafana/data';
import { Button, InlineField, InlineFieldRow, Input, RadioButtonGroup } from '@grafana/ui';
import { LayoutSection } from '../../types';
import { BooleanElementOptions } from '../../constants';

interface Props extends StandardEditorProps<LayoutSection[]> {}

export const LayoutSectionsEditor: React.FC<Props> = ({ value: sections, onChange }) => {
  if (!sections || !sections.length) {
    sections = [];
  }

  return (
    <div>
      {sections.map((section, id) => (
        <InlineFieldRow key={id}>
          <InlineField 
		    label="Name" 
			grow
			labelWidth={6}
			invalid={section.name === ''}
		  >
            <Input
              placeholder="name"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                section.name = event.target.value;
                onChange(sections);
              }}
              value={section.name}
            />
          </InlineField>
          <InlineField 
		    label="Icon" 
			grow
			labelWidth={4}
			invalid={section.name === ''}
		  >
            <Input
              placeholder="icon"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                section.icon = event.target.value;
                onChange(sections);
              }}
              value={section.icon}
            />
          </InlineField>
          <InlineField 
		    label="Visible" 
			grow
			labelWidth={6}
			invalid={section.name === ''}
		  >
			<RadioButtonGroup
			  value={section.visible}
			  onChange={(value: Boolean) => {
                section.visible = value;
                onChange(sections);
              }}
			  options={BooleanElementOptions}
			/>
          </InlineField>
          <Button
            variant="secondary"
            onClick={(e) => {
              sections = sections?.filter((s) => s.name !== section.name);
              onChange(sections);
            }}
            icon="trash-alt"
          ></Button>
        </InlineFieldRow>
      ))}

      <Button
        variant="secondary"
        onClick={(e) => {
          sections.push({ name: '' });
          onChange(sections);
        }}
        icon="plus"
      >
        Add Section
      </Button>
    </div>
  );
};

import { css, cx } from '@emotion/css';
import React, { useMemo } from 'react';

import { useStyles2 } from '@grafana/ui/src/themes';
import { Button, ButtonVariant } from '@grafana/ui/src/components/Button';
import { Icon } from '@grafana/ui/src/components/Icon/Icon';
import { getTableStyles, TableStyles } from './styles';

export interface Props {
  currentPage: number;
  numberOfPages: number;
  onNavigate: (toPage: number) => void;
  hideWhenSinglePage?: boolean;
  showSmallVersion?: boolean;
  className?: string;
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  numberOfPages,
  onNavigate,
  hideWhenSinglePage,
  showSmallVersion,
  className,
}) => {
  const pageLengthToCondense = showSmallVersion ? 1 : 8;
  const tableStyles = useStyles2(getTableStyles);

  const pageButtons = useMemo(() => {
    const pages = [...new Array(numberOfPages).keys()];
    const condensePages = numberOfPages > pageLengthToCondense;
    const getListItem = (page, variant) => (
      <li key={page} className={tableStyles.item}>
        <button 
          className={variant}
          onClick={() => onNavigate(page)}
        >
          {page}
        </button>
      </li>
    );

    return pages.reduce<JSX.Element[]>((pagesToRender, pageIndex) => {
      const page = pageIndex + 1;
      const variant = page === currentPage ? tableStyles.footerPageActive : tableStyles.footerPageInactive;

      const lowerBoundIndex = pageLengthToCondense;
      const upperBoundIndex = numberOfPages - pageLengthToCondense + 1;
      const differenceOfBounds = upperBoundIndex - lowerBoundIndex;

      const isFirstOrLastPage = page === 1 || page === numberOfPages;
      const currentPageIsBetweenBounds =
        differenceOfBounds > -1 && currentPage >= lowerBoundIndex && currentPage <= upperBoundIndex;

      const ellipsisOffset = showSmallVersion ? 1 : 3;
      const pageOffset = showSmallVersion ? 0 : 2;

      if (condensePages) {
        if (
          isFirstOrLastPage ||
          (currentPage < lowerBoundIndex && page < lowerBoundIndex) ||
          (differenceOfBounds >= 0 && currentPage > upperBoundIndex && page > upperBoundIndex) ||
          (differenceOfBounds < 0 && currentPage >= lowerBoundIndex && page > upperBoundIndex) ||
          (currentPageIsBetweenBounds && page >= currentPage - pageOffset && page <= currentPage + pageOffset)
        ) {
          pagesToRender.push(getListItem(page, variant));
        } else if (
          (page === lowerBoundIndex && currentPage < lowerBoundIndex) ||
          (page === upperBoundIndex && currentPage > upperBoundIndex) ||
          (currentPageIsBetweenBounds &&
            (page === currentPage - ellipsisOffset || page === currentPage + ellipsisOffset))
        ) {
          pagesToRender.push(
            <li key={page} className={tableStyles.item}>
              <Icon className={tableStyles.ellipsis} name="ellipsis-v" />
            </li>
          );
        }
      } else {
        pagesToRender.push(getListItem(page, variant));
      }
      return pagesToRender;
    }, []);
  }, [currentPage, numberOfPages, onNavigate, pageLengthToCondense, showSmallVersion, tableStyles.ellipsis, tableStyles.item]);

  if (hideWhenSinglePage && numberOfPages <= 1) {
    return null;
  }

  return (
    <div className={cx(tableStyles.container, className)}>
      <ol>
        <li className={tableStyles.item}>
          <button
            aria-label="previous"
            className={currentPage === 1 ? tableStyles.footerButtonDisable : tableStyles.footerButton}
            onClick={() => onNavigate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Icon name="angle-left" />
          </button>
        </li>
        {pageButtons}
        <li className={tableStyles.item}>
          <button
            aria-label="next"
            className={currentPage === numberOfPages ? tableStyles.footerButtonDisable : tableStyles.footerButton}
            onClick={() => onNavigate(currentPage + 1)}
            disabled={currentPage === numberOfPages}
          >
            <Icon name="angle-right" />
          </button>
        </li>
      </ol>
    </div>
  );
};

import { css } from '@emotion/css';
import React, { useCallback } from 'react';
import { useForm, FormProvider, FieldErrors, Validate } from 'react-hook-form';

import { NetMonitorTheme2 } from '@grafana/data';
import { Alert, Icon, Field, Input, LinkButton, useStyles2 } from '@grafana/ui';
import { useAppNotification } from 'app/core/copy/appNotification';
import { useCleanup } from 'app/core/hooks/useCleanup';
import { AlertManagerCortexConfig } from 'app/plugins/datasource/alertmanager/types';
import { NotifierDTO } from 'app/types';

import { useControlledFieldArray } from 'app/features/alerting/unified/hooks/useControlledFieldArray';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { ChannelValues, CommonSettingsComponentType, ReceiverFormValues } from 'app/features/alerting/unified/types/receiver-form';
import { makeAMLink } from 'app/features/alerting/unified/utils/misc';

import { ChannelSubForm } from './ChannelSubForm';
import { DeletedSubForm } from 'app/features/alerting/unified/components/receivers/form/fields/DeletedSubform';

interface Props<R extends ChannelValues> {
  config: AlertManagerCortexConfig;
  notifiers: NotifierDTO[];
  defaultItem: R;
  alertManagerSourceName: string;
  onTestChannel?: (channel: R) => void;
  onSubmit: (values: ReceiverFormValues<R>) => void;
  takenReceiverNames: string[]; // will validate that user entered receiver name is not one of these
  commonSettingsComponent: CommonSettingsComponentType;
  initialValues?: ReceiverFormValues<R>;
  isEditable: boolean;
  isTestable?: boolean;
  isAdmin: boolean;
  isDark: boolean;
  width: number;
}

export function ReceiverForm<R extends ChannelValues>({
  config,
  initialValues,
  defaultItem,
  notifiers,
  alertManagerSourceName,
  onSubmit,
  onTestChannel,
  takenReceiverNames,
  commonSettingsComponent,
  isEditable,
  isTestable,
  isAdmin,
  isDark,
  width,
}: Props<R>): JSX.Element {
  const notifyApp = useAppNotification();
  const styles = useStyles2(getStyles(isDark, width));
  const defaultValues = initialValues || {
    name: '',
    items: [
      {
        ...defaultItem,
        __id: String(Math.random()),
      } as any,
    ],
  };
  const formAPI = useForm<ReceiverFormValues<R>>({
    defaultValues: JSON.parse(JSON.stringify(defaultValues)),
  });

  useCleanup((state) => state.unifiedAlerting.saveAMConfig);

  const { loading } = useUnifiedAlertingSelector((state) => state.saveAMConfig);

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = formAPI;

  const { fields, append, remove } = useControlledFieldArray<R>({ name: 'items', formAPI, softDelete: true });
  const validateNameIsAvailable: Validate<string> = useCallback(
    (name: string) =>
      takenReceiverNames.map((name) => name.trim().toLowerCase()).includes(name.trim().toLowerCase())
        ? 'Ya existe otro canal con este nombre'
        : true,
    [takenReceiverNames]
  );

  const submitCallback = (values: ReceiverFormValues<R>) => {
    onSubmit({
      ...values,
      items: values.items.filter((item) => !item.__deleted),
    });
  };

  const onInvalid = () => {
    notifyApp.error('Se produjo un error. Por favor compruebe la información ingresada');
  };

  return (
    <FormProvider {...formAPI}>
      {!config.alertmanager_config.route && (
        <Alert severity="warning" title="Atención">
          Como no existe otro canal definido, este canal será utilizado como canal predeterminado.
        </Alert>
      )}
      <form onSubmit={handleSubmit(submitCallback, onInvalid)}>
        <Field className={styles.fields} invalid={!!errors.name} error={errors.name && errors.name.message} required>
          <Input
            className={styles.input}
			readOnly={!isAdmin}
            id="name"
            {...register('name', {
              required: 'Debe ingresar un nombre',
              validate: { nameIsAvailable: validateNameIsAvailable },
            })}
            width={39}
            placeholder="Nombre"
          />
        </Field>
        {fields.map((field, index) => {
          const pathPrefix = `items.${index}.`;
          if (field.__deleted) {
            return <DeletedSubForm key={field.__id} pathPrefix={pathPrefix} />;
          }
          const initialItem = initialValues?.items.find(({ __id }) => __id === field.__id);
          return (
            <ChannelSubForm<R>
              defaultValues={field}
              key={field.__id}
              onDuplicate={() => {
                const currentValues: R = getValues().items[index];
                append({ ...currentValues, __id: String(Math.random()) });
              }}
              onTest={
                onTestChannel
                  ? () => {
                      const currentValues: R = getValues().items[index];
                      onTestChannel(currentValues);
                    }
                  : undefined
              }
              onDelete={() => remove(index)}
              pathPrefix={pathPrefix}
              notifiers={notifiers}
              secureFields={initialItem?.secureFields}
              errors={errors?.items?.[index] as FieldErrors<R>}
              commonSettingsComponent={commonSettingsComponent}
              isEditable={isEditable}
              isTestable={isTestable}
              isAdmin={isAdmin}
			  isDark={isDark}
			  width={width}
            />
          );
        })}
        <div className={styles.buttons}>
          {isEditable && isAdmin && (
            <>
			  <button 
			    type="button" 
			    className={styles.newButton} 
			    onClick={() => append({ ...defaultItem, __id: String(Math.random()) } as R)}
			    title={'Agregar otro medio de comunicación al canal'}
			  >
			    <div className={styles.button_icon}>
				  <Icon name="plus-circle-fill" size="lg" />
			    </div>
			    Medio
			  </button>
			  <button 
				type={loading ? 'button' : 'submit'}
				className={styles.saveButton} 
				disabled={loading}
				title={'Guardar configuración'}
			  >
				<div className={styles.button_icon}>
				  <Icon name={loading ? 'loading': 'save-fill'} size="lg" />
				</div>
				Guardar
			  </button>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

const getStyles = (isDark: boolean, width: number) => {
  const background = isDark ? '#141618' : '#F4F9FF';
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const newButtonBack = isDark ? '#557FFF' : '#6C63FE';
  const okButtonBack = isDark ? '#936BFF' : '#5E2BE9';
  const borderColor = isDark ? '#585A5E' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    fields: css`
      margin-top: ${theme.spacing(2)};
	  font-size: 12px;
    `,
    input: css`
      font-size: 12px;
      font-weight: 500;
  	  border-radius: 10px;
  	  border: 1px solid ${borderColor};
    `,
    buttons: css`
      margin-top: ${theme.spacing(4)};

      & > * + * {
        margin-left: ${theme.spacing(1)};
      }
    `,
    newButton: css`
      background-color: ${newButtonBack};
      border: 1px solid ${borderColor};
      box-shadow: 4px 2px 4px 0px ${borderColor};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
      padding: 0px 10px 0px 0px;
    `,
    saveButton: css`
      background-color: ${okButtonBack};
      border: 1px solid ${borderColor};
      box-shadow: 4px 2px 4px 0px ${borderColor};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
      padding: 0px 10px 0px 0px;
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};


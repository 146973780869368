import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import { LinkInspectorData } from 'types';
import { Icon } from '@grafana/ui';
import './css/LinkInspector.css';

interface LinkDescriptorInetProps {
  id: string;
  link: LinkInspectorData[];
  width: number;
  height: number;
  lineWidth: number;
  fontSize: number;
  iconSize: number;
  metricUnit: string;
}

export class LinkDescriptorInet extends Component<LinkDescriptorInetProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
	const linkInfo = this.props.link;
    const isDark = config.theme.isDark || false;
	const fontColor = isDark ? '#E6E9ED' : '#23282E';
	const fontColorP1 = isDark ? '#E6E9ED' : '#23282E';
    const okColor = isDark ? '#936BFF' : '#5D2BE9';
    const phase2Color = isDark ? '#EFF4FA' : '#1B2733';
    const warningColor = isDark ? '#D9AF27' : '#FF7F27';
	const alarmColor = isDark ? '#F74545' : '#FB3333';
	const sfpCage = isDark ? '#58A95680' : '#D8DFE9';
	const borderColor = isDark ? '#585A5E' : '#9DA5B8';
	const tunnelColor = isDark ? '#557FFF' : '#6C63FE';
	const tunnelEndColor = isDark ? '#646A73' : '#5A6171';
	const gwStatusOk = isDark ? '#58A956' : '#D8DFE9';
    const markColor = isDark ? '#E6E9ED' : '#44444C';
    const shadowColor = isDark ? '#5A6171' : '#646A73';
    const lineWidth = this.props.lineWidth;
    const totalWidth = this.props.width;
	const totalHeight = this.props.height;
	const rectWidth = [110, 35, 30, 30];
	const rectHeight = [100, 50, 30, 30];
	const icons = [linkInfo.assetTypeA,'','exchange','modem'];
	const legends = [`${linkInfo.assetA} [${linkInfo.siteA}]`,'','',totalWidth > 795 ? linkInfo.tunnelGwNameA : ''];

    const radioColorA = linkInfo.linkAdminA ? sfpCage : alarmColor;
    const gwColorA = linkInfo.inetGwStatus ? gwStatusOk : alarmColor;
	const rectColors = isDark ?
	  ['#5A6171', radioColorA, '#D9AF27', gwColorA]:
	  ['#F4F9FF', radioColorA, '#FF7F27', gwColorA];
	const lineAColor = linkInfo.linkStatusA && linkInfo.inetGwStatus ? okColor : alarmColor;

    const rectPositionsX = totalWidth > 995 ? 
	  [totalWidth * 0.1, totalWidth * 0.1, totalWidth * 0.1, totalWidth - 300] :
	  [100, 100, 100, totalWidth - 300];
	const rectPositionsY = [80, 105, 115, 115];

	const convertUnit = (kbps) => {
	  if (kbps >= 1000000000) {
		const tbps = kbps / 1000000000;
		return `${tbps.toFixed(0)} Tbps`;
	  } else if (kbps >= 1000000) {
		const gbps = kbps / 1000000;
		return `${gbps.toFixed(0)} Gbps`;
	  } else if (kbps >= 1000) {
		const mbps = kbps / 1000;
		return `${mbps.toFixed(0)} Mbps`;
	  } else {
		return `${kbps} Kbps`;
	  }
	};

	const tooltips = [
	  linkInfo.assetTypeA + ' ' + linkInfo.assetA,
	  `Estado Administrativo: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `Puerto ${linkInfo.portA} - ${linkInfo.linkStatusA ? 'Habilitado' : 'Deshabilitado'}`,
	  `${linkInfo.tunnelGwNameA}`
    ];

	const OriginInfo = linkInfo.linkAdminA ? [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	  `BW: ${convertUnit(linkInfo.speedA)}`
	] : [
	  `Puerto ${linkInfo.portA}`,
	  `[${linkInfo.aliasA}]`,
	  `Admin: ${linkInfo.linkAdminA ? 'Habilitado' : 'Deshabilitado'}`,
	];
	const LinkIpInfo = [
	  `Direccionamiento IP`,
	  linkInfo.inetIp !== '' ? `IPv4: ${linkInfo.inetIp}` : '',
	  linkInfo.inetMask !== '' ? `Mascara: ${linkInfo.inetMask}` : '',
	  linkInfo.inetIpv6 !== '' ? `IPv6: ${linkInfo.inetIpv6}` : '',
	  linkInfo.inetMaskv6 !== '' ? `Mascara: ${linkInfo.inetMaskv6}` : '',
	];
	const ProviderLabel = [
	  `${linkInfo.inetLabel}`
	];
	const ProviderInfo = [
	  `Operador: ${linkInfo.inetProvider}`,
	  `REF: [${linkInfo.inetRef}]`,
	  `BW up: ${convertUnit(linkInfo.inetBwUpMax)}`,
	  `BW down: ${convertUnit(linkInfo.inetBwDownMax)}`
	];
	const usageUP = (linkInfo.inetBwUpActual / linkInfo.inetBwUpMax) * 100;
	const usageDown = (linkInfo.inetBwDownActual / linkInfo.inetBwDownMax) * 100;
	const UpTrafficInfo = linkInfo.linkAdminA ? [
	  `${linkInfo.assetA} ▶`,
	  `Tráfico Up: ${convertUnit(linkInfo.inetBwUpActual)}`,
	  `Nivel de utilización: ${usageUP.toFixed(2)} %`
	] : [];
	const DownTrafficInfo = linkInfo.linkAdminA ? [
	  `◀ ${linkInfo.assetA}`,
	  `Tráfico Down: ${convertUnit(linkInfo.inetBwDownActual)}`,
	  `Nivel de utilización: ${usageDown.toFixed(2)} %`
	] : [];

	const OriginGWInfo = [
	  `IP: ${linkInfo.inetGwIp}`,
	  `Estado: ${linkInfo.inetGwStatus ? 'Alcanzable' : 'Inalcanzable'}`
	];
	
	const filterLight = linkInfo.inetGwStatus ? 
	  'invert(62%) sepia(8%) saturate(2785%) hue-rotate(70deg) brightness(94%) contrast(77%)' :
	  'invert(35%) sepia(73%) saturate(2956%) hue-rotate(339deg) brightness(95%) contrast(115%)';
	const filterDark = linkInfo.inetGwStatus ? 
	  'invert(41%) sepia(100%) saturate(886%) hue-rotate(73deg) brightness(95%) contrast(101%)' :
	  'invert(52%) sepia(60%) saturate(4152%) hue-rotate(332deg) brightness(96%) contrast(102%)';

    return (
      <svg width={totalWidth} height={totalHeight}>
        {/* Línea de Radio */}
		<line
		  x1={rectPositionsX[2] + rectWidth[2] / 2}
		  y1={rectPositionsY[2] + (rectHeight[2] / 2)}
		  x2={rectPositionsX[3] + rectWidth[3] / 2}
		  y2={rectPositionsY[3] + (rectHeight[3] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<line
		  x1={rectPositionsX[3] + rectWidth[3] / 2}
		  y1={rectPositionsY[3] + (rectHeight[3] / 2)}
		  x2={totalWidth - 125}
		  y2={rectPositionsY[3] + (rectHeight[3] / 2)}
		  stroke={lineAColor}
		  strokeWidth={lineWidth + 1}
		  strokeDasharray={linkInfo.linkStatusA ? 'none' : '2,2'}
		  style={{ filter: `drop-shadow(2px 2px 5px ${shadowColor})` }}
		/>
		<image
		  href={`/public/img/icons/unicons/cloud.svg`}
		  x={totalWidth - 200}
		  y={rectPositionsY[3] - 75}
		  style={isDark ? { filter: filterDark } : { filter: filterLight }}
		  width={150}
		  height={150}
		/>
		{/* Elementos */}
        {rectWidth.map((width, index) => {
		  const iconX = index === 1 ? (width * 0.4) : 2;
		  const rectX = index === 1 || index === 3 ? -2 : 0;
		  return (
            <g key={'I_' + index}>
		      <rect
                x={index === 0 ? (rectPositionsX[2] + rectWidth[2] - width + 2) : rectPositionsX[index] + rectX}
                y={rectPositionsY[index]}
				rx={width * 0.05 > 2 ? width * 0.05 : 2}	
                width={width}
                height={rectHeight[index]}
                fill={rectColors[index]}
				stroke={shadowColor}
				stroke-width="1"
              />
              {icons[index] !== '' && (
			    <image
                  href={`/public/img/icons/unicons/${icons[index]}.svg`}
                  x={index === 0 ? (rectPositionsX[2] + rectWidth[2] - width) : rectPositionsX[index] + iconX}
                  y={rectPositionsY[index] + 5}
				  style={isDark && index < 2 ? { filter: 'invert(1)' } : { filter: 'none' }}
				  width={index < 2 ? width * 0.6 : width - 4}
                  height={index < 2 ? rectHeight[index] * 0.6 : rectHeight[index] - 8}
                />
			  )}
			  <title>{tooltips[index]}</title>
		    </g>
		  );
        })}
		{/* Testo de leyendas */}
		<g>
		  {legends.map((legend, index) => {
            const isTop = index === 0 ? true : false;
			const textY = isTop ? 70 : (rectPositionsY[index] + rectHeight[index]);
            let textX = isTop ? (rectPositionsX[index] + (rectWidth[index] / 2)) : (rectPositionsX[index] + rectWidth[index] + 5);
            let textAnchor = isTop ? 'middle' : 'start';
            return (
              <text
                key={index}
                x={index === 0 ? (rectPositionsX[2] + rectWidth[2] - (rectWidth[0] / 2)) : textX}
                y={textY}
                textAnchor={textAnchor}
                fill={fontColor}
                fontSize={isTop ? this.props.fontSize + 2 : this.props.fontSize}
                fontWeight={isTop ? 'bold' : 'normal'}
              >
                {legend}
              </text>
            );
		  })}
		  {ProviderLabel.map((line, index) => (
		    <text
			  key={'ProviderLabel' + index}
			  x={totalWidth - 200}
			  y={rectPositionsY[3] - 80 + (index * 15)}
			  textAnchor={'center'}
			  textLength={150}
			  fill={fontColor}
			  fontSize={this.props.fontSize}
			  fontWeight={'bold'}
		    >
			  {line}
		    </text>		  
		  ))}
		  {OriginInfo.map((line, index) => (
		    <text
			  key={'OriginInfo' + index}
			  x={rectPositionsX[2] + (rectWidth[2] / 2) - 5}
			  y={rectPositionsY[2] + rectHeight[2] + 50 + (index * 15)}
			  textAnchor={'end'}
			  fill={fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {ProviderInfo.map((line, index) => (
		    <text
			  key={'ProviderInfo' + index}
			  x={totalWidth - 175}
			  y={rectPositionsY[3] + rectHeight[3] + 45 + (index * 15)}
			  textAnchor={'start'}
			  fill={fontColor}
			  fontSize={index === 0 ? this.props.fontSize : this.props.fontSize - 1}
			  fontWeight={index < 2 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {LinkIpInfo.map((line, index) => (
		    <text
			  key={'OriginGWInfo' + index}
			  x={rectPositionsX[2] + rectWidth[2] + 5}
			  y={rectPositionsY[2] + rectHeight[2] + 65	 + (index * 15)}
			  textAnchor={'start'}
			  fill={fontColor}
			  fontSize={index === 0 ? this.props.fontSize  : this.props.fontSize - 1}
			  fontWeight={index === 0 ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {OriginGWInfo.map((line, index) => (
		    <text
			  key={'OriginGWInfo' + index}
			  x={rectPositionsX[3] + rectWidth[3] + 25}
			  y={rectPositionsY[3] + rectHeight[3] + 50 + (index * 15)}
			  textAnchor={'end'}
			  fill={linkInfo.inetGwStatus ? fontColor : warningColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 895 && linkInfo.linkAdminA && linkInfo.inetGwStatus) && UpTrafficInfo.map((line, index) => (
		    <text
			  key={'UpTrafficInfo' + index}
			  x={(totalWidth * 0.45) - 5}
			  y={rectPositionsY[3] + rectHeight[3] + 50 + (index * 15)}
			  textAnchor={'end'}
			  fill={index === 2 && usageUP > 100 ? warningColor : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 || (index === 2 && usageUP > 100) ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		  {(totalWidth > 895 && linkInfo.linkAdminA && linkInfo.inetGwStatus) && DownTrafficInfo.map((line, index) => (
		    <text
			  key={'DownTrafficInfo' + index}
			  x={(totalWidth * 0.5) + 5}
			  y={rectPositionsY[3] + rectHeight[3] + 50 + (index * 15)}
			  textAnchor={'start'}
			  fill={index === 2 && usageDown > 100 ? warningColor : fontColor}
			  fontSize={this.props.fontSize - 1}
			  fontWeight={index < 1 || (index === 2 && usageDown > 100) ? 'bold' : 'normal'}
		    >
			  {line}
		    </text>
		  ))}
		</g>
		{/* Lineas de leyendas */}
		<line
          x1={rectPositionsX[2] + rectWidth[2] / 2}
          y1={rectPositionsY[2] + rectHeight[2]}
          x2={rectPositionsX[2] + rectWidth[2] / 2}
          y2={rectPositionsY[2] + rectHeight[2] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		<line
          x1={rectPositionsX[3] + rectWidth[3] / 2}
          y1={rectPositionsY[3] + rectHeight[3]}
          x2={rectPositionsX[3] + rectWidth[3] / 2}
          y2={rectPositionsY[3] + rectHeight[3] + 40}
          stroke={markColor}
          strokeWidth={lineWidth}
		  strokeDasharray="3,3"
        />
		<line
		  x1={totalWidth - 125}
		  y1={rectPositionsY[3]}
          x2={totalWidth - 125}
          y2={rectPositionsY[3] + rectHeight[3] + 35}
          stroke={markColor}
          strokeWidth={lineWidth}
		   trokeDasharray="3,3"
        />
		{linkInfo.linkAdminA && linkInfo.inetGwStatus && totalWidth > 895 && ( 
		  <line
            x1={totalWidth * 0.45}
            y1={rectPositionsY[3] + (rectHeight[3] / 2)}
            x2={totalWidth * 0.45}
            y2={rectPositionsY[3] + (rectHeight[3] / 2)  + 50}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
		{linkInfo.linkAdminA && linkInfo.inetGwStatus && totalWidth > 895 && ( 
          <line
            x1={totalWidth * 0.5}
            y1={rectPositionsY[3] + (rectHeight[3] / 2)}
            x2={totalWidth * 0.5}
            y2={rectPositionsY[3] + (rectHeight[3] / 2) + 50}
            stroke={markColor}
            strokeWidth={lineWidth}
		    strokeDasharray="3,3"
          />
		)}
      </svg>
    );
  }
}

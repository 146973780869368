import React, { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PanelProps, NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, ConfirmModal, Modal, Icon } from '@grafana/ui';
import { ContactOptions } from 'types';
//import './css/netmonitor-contactManager.css';
import { css } from '@emotion/css';

import { contextSrv } from 'app/core/services/context_srv';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { fetchAlertManagerConfigAction, fetchNetMonitorNotifiersAction } from 'app/features/alerting/unified/state/actions';
import { NETMONITOR_RULES_SOURCE_NAME } from 'app/features/alerting/unified/utils/datasource';
import { initialAsyncRequestState } from 'app/features/alerting/unified/utils/redux';
import { isVanillaPrometheusAlertManagerDataSource } from 'app/features/alerting/unified/utils/datasource';
import { extractNotifierTypeCounts, extractNotifierSettings } from 'app/features/alerting/unified/utils/receivers';

import { ContactGroup } from './ContactGroup';

export const ContactManagerPanel: React.FC<Props> = ({ options, replaceVariables, id, width, height }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const pluginTitle = replaceVariables(options.pluginTitle) || 'Canales de Notificación existentes';
  const showTitle = options.showTitle;
  const okMessage = replaceVariables(options.successMessage);

  const alertManager = replaceVariables(options.alertmanager) || 'netmonitor';
  const dispatch = useDispatch();

  const [alertManagerSourceName, setAlertManagerSourceName] = useState(alertManager);

  const configRequests = useUnifiedAlertingSelector((state) => state.amConfigs);
  
  const {
    result: config,
    loading: resultLoading,
    error: resultError,
  } = (alertManagerSourceName && configRequests[alertManagerSourceName]) || initialAsyncRequestState;
  const netmonitorNotifiers = useUnifiedAlertingSelector((state) => state.netmonitorNotifiers);

  useEffect(() => {
    if (alertManagerSourceName) {
      dispatch(fetchAlertManagerConfigAction(alertManagerSourceName));
    }
  }, [alertManagerSourceName, dispatch, true]);

  useEffect(() => {
    if (
      alertManagerSourceName === NETMONITOR_RULES_SOURCE_NAME &&
      !(netmonitorNotifiers.result || netmonitorNotifiers.loading || netmonitorNotifiers.error)
    ) {
      dispatch(fetchNetMonitorNotifiersAction());
    }
  }, [alertManagerSourceName, dispatch, netmonitorNotifiers]);

  const rows = useMemo(
    () =>
      (config?.alertmanager_config.receivers ?? []).map((receiver) => ({
        name: receiver.name,
        types: Object.entries(extractNotifierTypeCounts(receiver, netmonitorNotifiers.result ?? [])).map(
          ([type, count]) => {
            if (count > 1) {
              return `${type} (${count})`;
            }
            return type;
          }
        ),
		address: extractNotifierSettings(receiver),
        provisioned: receiver.netmonitor_managed_receiver_configs?.some((receiver) => receiver.provenance),
      })) ?? [],
    [config, netmonitorNotifiers.result]
  );

  const readOnly = alertManagerSourceName
    ? isVanillaPrometheusAlertManagerDataSource(alertManagerSourceName)
    : true;

  if (!alertManagerSourceName) {
    return <div>Error al cargar complemento</div>;
  }

  return (
    <div className={styles.section} key={String(id)}>
      {resultError && !resultLoading && (
        <Alert severity="error" title="Error al cargar datos desde el gestor de alertas">
          {resultError.message || 'Error desconocido'}
        </Alert>
      )}
      {resultLoading && !config && <LoadingPlaceholder text="Cargando..." />}
      {!resultLoading && config && (
        <ContactGroup
          id={String(id)}
          showTitle={showTitle}
          pluginTitle={pluginTitle}
          isAdmin={isAdmin}
		  configuration={config}
          alertManagerSourceName={alertManagerSourceName}
		  readOnly={readOnly}
		  rows={rows}
		  width={width}
		  height={height}
        />
      )}
    </div>
  );
};
const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: 4px;
  `,
});

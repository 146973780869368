export const GRID_CELL_HEIGHT = 36;
export const GRID_CELL_VMARGIN = 2;
export const GRID_COLUMN_COUNT = 24;
export const REPEAT_DIR_VERTICAL = 'v';
export const REPEAT_DIR_HORIZONTAL = 'h';

export const DEFAULT_PANEL_SPAN = 2;
export const DEFAULT_ROW_HEIGHT = 250;
export const MIN_PANEL_HEIGHT = GRID_CELL_HEIGHT;

export const LS_PANEL_COPY_KEY = 'panel-copy';

export const PANEL_BORDER = 2;

export const EDIT_PANEL_ID = 23763571993;

export const DEFAULT_PER_PAGE_PAGINATION = 40;

export const LS_VISUALIZATION_SELECT_TAB_KEY = 'VisualizationSelectPane.ListMode';

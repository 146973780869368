import { PanelPlugin } from '@grafana/data';
import { FieldSelectEditor } from './FieldSelectEditor';
import { SpeedometerOptions } from 'types';
import { SpeedometerPanel } from './speedometerPanel';

export const plugin = new PanelPlugin<SpeedometerOptions>(SpeedometerPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'maxValueField',
      path: 'maxValueField',
      name: 'Max Value Field',
      category: ['Data Map Settings'],
      defaultValue: 'maxvalue',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'minValueField',
      path: 'minValueField',
      name: 'Min Value Field',
      category: ['Data Map Settings'],
      defaultValue: 'minvalue',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'valueField',
      path: 'valueField',
      name: 'Value Field',
      category: ['Data Map Settings'],
      defaultValue: 'value',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'valueTitle',
      name: 'Value title',
      category: ['Data Map Settings'],
      defaultValue: 'Valor actual',
    })
    .addBooleanSwitch({
      path: 'showCurrentValue',
      name: 'Show current Value',
      description: 'show current Value at chart center',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'currentValueField',
      path: 'currentValueField',
      name: 'Current Value Text',
      category: ['Data Map Settings'],
      defaultValue: 'valueText',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useThreshold',
      name: 'Use Threshold',
      description: 'Change needle color when value cros above this threshold',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'thresholdField',
      path: 'thresholdField',
      name: 'Threshold',
      category: ['Data Map Settings'],
      showIf: config => config.useThreshold === true,
      defaultValue: 'threshold',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useSecondThreshold',
      name: 'Use Second Threshold',
      description: 'Change needle color when value cros below this threshold',
      category: ['Data Map Settings'],
      showIf: config =>
        config.useThreshold === true && (config.chartType === 'Speedometer' || config.chartType === 'GaugeChart'),
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'secondThresholdField',
      path: 'secondThresholdField',
      name: 'Second Threshold',
      category: ['Data Map Settings'],
      showIf: config => config.useSecondThreshold === true,
      defaultValue: 'secondThreshold',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'reverseThreshold',
      name: 'reverse Threshold',
      description: 'Change needle color when value cros below first threshold or above second Threshold',
      category: ['Data Map Settings'],
      showIf: config => config.useThreshold === true,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showInfoField',
      name: 'Show aditional info',
      description: 'Allow to use info on tooltip',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'infoField',
      path: 'infoField',
      name: 'Aditional info Field',
      category: ['Data Map Settings'],
      showIf: config => config.showInfoField === true,
      defaultValue: 'info',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Aditional info title',
      category: ['Data Map Settings'],
      showIf: config => config.showInfoField === true,
      defaultValue: '',
    })

    .addSelect({
      path: 'chartType',
      name: 'Chart Type',
      category: ['General Settings'],
      defaultValue: 'Speedometer',
      settings: {
        options: [
          {
            value: 'Speedometer',
            label: 'Speedometer',
          },
          {
            value: 'GaugeChart',
            label: 'GaugeChart',
          },
          {
            value: 'Thermometer',
            label: 'Thermometer',
          },
          {
            value: 'LiquidFillGauge',
            label: 'LiquidFillGauge',
          },
          {
            value: 'BatteryGauge',
            label: 'BatteryGauge',
          },
        ],
      },
    })
    .addTextInput({
      path: 'chartTitle',
      name: 'Chart Title',
      category: ['General Settings'],
      showIf: config => config.chartType === 'GaugeChart', 
	  defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'show360',
      name: 'Display 360°',
	  description: 'Show 240° or 360° grauge chart.',
      category: ['General Settings'],
      showIf: config => config.chartType === 'GaugeChart',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showSegments',
      name: 'Display discrete segments',
      category: ['General Settings'],
      showIf: config => config.chartType !== 'LiquidFillGauge' && config.chartType !== 'BatteryGauge',
      defaultValue: false,
    })
    .addNumberInput({
      path: 'segmentsLength',
      name: 'Number of segments',
      description: 'The number of segments stops to use.',
      category: ['General Settings'],
      showIf: config => 
	    config.showSegments === true && config.chartType !== 'LiquidFillGauge' && config.chartType !== 'BatteryGauge',
      defaultValue: 3,
    })
    .addTextInput({
      path: 'segmentStops',
      name: 'Segments Stops',
      description:
        'Array with values to split the segments at custom points in the range of min / max values. Must start with min value',
      category: ['General Settings'],
      showIf: config =>
	    config.showSegments === true && config.chartType !== 'BatteryGauge',
      defaultValue: '0.5, 0.75, 1',
    })
    .addTextInput({
      path: 'segmentColors',
      name: 'Segments color',
      description: 'Array with valid color codes.',
      category: ['General Settings'],
      showIf: config => config.showSegments === true && (config.chartType === 'Speedometer' || config.chartType === 'BatteryGauge'),
      defaultValue: '#9399ff,#14ffec,#00bbf0',
    })
    .addBooleanSwitch({
      path: 'displaySegmentsLegends',
      name: 'Display segments legends',
      category: ['General Settings'],
      showIf: config => config.showSegments === true && config.chartType === 'Thermometer',
      defaultValue: false,
    })

    .addBooleanSwitch({
      path: 'useColorScale',
      name: 'Use Color Scales',
      description: 'Use color scales to represent values',
      category: ['General Settings'],
      showIf: config => config.chartType === 'Speedometer' || config.chartType === 'GaugeChart',
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'revertColorScale',
      name: 'Revert Color Scales',
      description: 'Revert color scales to represent values',
      category: ['General Settings'],
      showIf: config => config.chartType === 'Thermometer',
      defaultValue: true,
    })
    .addSelect({
      path: 'colorScale',
      name: 'Color Scale',
      category: ['General Settings'],
      defaultValue: 'Orange',
      showIf: config =>
        config.useColorScale === true,
      settings: {
        options: [
          {
            value: 'Orange',
            label: 'Orange',
          },
          {
            value: 'Blue',
            label: 'Blue',
          },
          {
            value: 'Green',
            label: 'Green',
          },
        ],
      },
    })
    .addColorPicker({
      path: 'startColor',
      name: 'Start color',
      category: ['General Settings'],
      showIf: config =>
        config.useColorScale === false && config.chartType === 'GaugeChart',
      defaultValue: 'red',
    })
    .addColorPicker({
      path: 'endColor',
      name: 'End color',
      category: ['General Settings'],
      showIf: config =>
        config.useColorScale === false && config.chartType === 'GaugeChart',
      defaultValue: 'green',
    })
    .addColorPicker({
      path: 'needleColor',
      name: 'Needle Color',
      category: ['General Settings'],
      showIf: config => config.chartType !== 'Thermometer',
      defaultValue: 'blue',
    })
    .addColorPicker({
      path: 'backgroundColor',
      name: 'Gauge ring background Color',
      category: ['General Settings'],
      showIf: config => config.chartType === 'GaugeChart',
      defaultValue: '#dbdbe7',
    })
    .addNumberInput({
      path: 'needleSize',
      name: 'The width of needle',
      category: ['General Settings'],
      showIf: config => config.chartType === 'GaugeChart' || config.chartType === 'Speedometer',
      defaultValue: 15,
    })
    .addColorPicker({
      path: 'warningColor',
      name: 'Warning Color',
      category: ['General Settings'],
      showIf: config => config.chartType !== 'Thermometer',
      defaultValue: 'red',
    })
    .addBooleanSwitch({
      path: 'chartCentered',
      name: 'Centered',
      category: ['General Settings'],
      showIf: config => config.chartType === 'LiquidFillGauge' || config.chartType === 'BatteryGauge',
      defaultValue: true,
    })
    .addNumberInput({
      path: 'ringWidth',
      name: 'Width of Speedometer or Gauge ring',
      category: ['General Settings'],
      showIf: config => config.chartType === 'Speedometer' || config.chartType === 'GaugeChart',
      defaultValue: 60,
    })
    .addNumberInput({
      path: 'arcPadding',
      name: 'Arc padding betwen slices',
      category: ['General Settings'],
      showIf: config => config.chartType === 'Speedometer',
      defaultValue: 5,
    })
    .addBooleanSwitch({
      path: 'useThemeFontColor',
      name: 'Use Theme FontColor',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addColorPicker({
      path: 'fontColor',
      name: 'Font Color',
      category: ['General Settings'],
      showIf: config => config.useThemeFontColor === false,
      defaultValue: '#888',
    })
    .addNumberInput({
      path: 'labelFontSize',
      name: 'On Chart Text Font Size',
      category: ['General Settings'],
      defaultValue: 12,
	  showIf: config => config.chartType === 'LiquidFillGauge' || config.chartType === 'Speedometer',
    })
    .addNumberInput({
      path: 'valueFontSize',
      name: 'Actual Value Text Font Size',
      category: ['General Settings'],
      defaultValue: 20,
    })
    .addTextInput({
      path: 'valueUnit',
      name: 'Unit to use',
      category: ['General Settings'],
      defaultValue: '°C',
    })

    .addTextInput({
      path: 'drillDownLink',
      name: 'speedometer slice base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import './css/indicators.css';
import { Icon } from '@grafana/ui';

interface GenericIndicatorProps {
  id: string;
  width: number;
  height: number;
  fontSize: number;
  iconSize: number;
  textCentered: boolean;
  iconCentered: boolean;
  style: string;
  icon: string;
  text: string;
  result: string;
  title: string;
  url: string;
  evolutionText: string;
  evolutionLabel: string;
}

export class GenericIndicator extends Component<GenericIndicatorProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
    const isDark = config.theme.isDark || false;
    const labelColor = isDark ? '#D9AF27' : ' #FF7F27';
    const fontColor = isDark ? '#E6E9ED' : ' #23282E';
    let buttonStyle = isDark ? 
	  'indicator_' + String(this.props.style) + '_dark' :
	  'indicator_' + String(this.props.style);
    if (buttonStyle === 'indicator_default' || buttonStyle === 'indicator_default_dark') {
      if (isDark) {
        buttonStyle = 'indicator_black_dark';
      } else {
        buttonStyle = 'indicator_white';
      }
    }

    let classInnerButton = 'indicator_light indicator_color ' + buttonStyle;
    if (isDark) {
      classInnerButton = 'indicator_dark indicator_color ' + buttonStyle;
    }
    if (this.props.iconCentered) {
      classInnerButton = 'indicator2_light indicator_color ' + buttonStyle;
      if (isDark) {
        classInnerButton = 'indicator2_dark indicator_color ' + buttonStyle;
      }
	}

	const iconClass = isDark ? 'icon_indicator_centered_dark' : 'icon_indicator_centered';
    const buttonHeight = (this.props.evolutionText !== null && this.props.evolutionText !== '' && this.props.width > 215) ? '68%' : '99%';
	const buttonWidth = (this.props.evolutionText !== null && this.props.evolutionText !== '' && this.props.width > 215) ? '58%' : '99%';
	let indicator_label = 'indicator_label';
	let iconSize = 12;

    if (this.props.textCentered) {
      indicator_label = 'indicator_label indicator_label_centered';
    }
    if (this.props.iconCentered) {
      indicator_label = 'indicator2_label';
	  iconSize = this.props.height - (this.props.fontSize * 4);
    } else {
	  switch (this.props.iconSize) {
		case 'sm':
		  iconSize = 14;
		case 'md':
		  iconSize = 16;
		case 'lg':
		  iconSize = 18;
		case 'xl':
		  iconSize = 24;
		case 'xxl':
		  iconSize = 36;
		case 'xxxl':
		  iconSize = 48;
	  }
	}

    if (this.props.iconCentered && !this.props.textCentered) {
	  return (
        <>
		  <button
            style={{
              width: '100%',
              height: this.props.height,
              fontSize: this.props.fontSize,
            }}
            key={this.props.id}
            className={isDark ? 'indicator_button_dark' : 'indicator_button'}
            onClick={this.handleUrl}
            title={this.props.title}
          >
			<div 
			  className={classInnerButton}
              style={{ height: buttonHeight }}
			>
              {this.props.icon !== '' && this.props.icon !== '-' && (
			    <div 
				  className={iconClass}
				  style={{ fontColor: this.props.iconColor }}
				>
                  <Icon name={this.props.icon} size={this.props.iconSize} />
                </div>
			  )}
              <div className={indicator_label}>
                <b>{this.props.text}</b>
              </div>
              <div
				className={'indicator_sublabel'}
				style={{
				  fontColor: fontColor,
				  fontSize: this.props.fontSize - 6,
			    }}
			  >
                {this.props.result}
              </div>
			</div>
		    {this.props.evolutionText !== null && this.props.evolutionText !== '' && this.props.width > 215 && (
			  <div 
			    className={'evolutionIndicatorWrapper'}
                style={{ height: '28%' }}
			  >
                <div 
			      className={'evolution_label'}
 				  style={{
					fontSize: this.props.fontSize,
					fontColor: labelColor,
				  }}
				>
                  {this.props.evolutionText}
                </div>
                <div
				  className={'evolution_sublabel'}
				  style={{
					fontColor: fontColor,
					fontSize: this.props.fontSize - 6,
				  }}
				>
                  {this.props.evolutionLabel}
                </div>
              </div>
			)}
          </button>
		</>
      );
	} else if (!this.props.iconCentered && !this.props.textCentered) {
      return (
        <>
		  <button
            style={{
              width: '100%',
              height: this.props.height,
              fontSize: this.props.fontSize,
            }}
            key={this.props.id}
            className={isDark ? 'indicator_button_dark' : 'indicator_button'}
            onClick={this.handleUrl}
            title={this.props.title}
          >
			<div 
			  className={classInnerButton}
              style={{
                height: buttonHeight,
              }}
			>
              <div className={indicator_label}>
                <b>{this.props.text}</b>
              </div>
              {this.props.icon !== '' && this.props.icon !== '-' && (
			    <div className={iconClass}>
                  <Icon name={this.props.icon} size={this.props.iconSize} />
                </div>
			  )}
              <div
				className={'indicator_sublabel'}
				style={{
				  fontColor: fontColor,
				  fontSize: this.props.fontSize - 6,
				}}
			  >
                {this.props.result}
              </div>
			</div>
		    {this.props.evolutionText !== null && this.props.evolutionText !== '' && this.props.width > 215 && (
			  <div 
			    className={'evolutionIndicatorWrapper'}
                style={{
                  height: '28%',
                }}
			  >
                <div 
				  className={'evolution_label'}
 				  style={{
					fontSize: this.props.fontSize,
					fontColor: labelColor,
				  }}
				>
                  {this.props.evolutionText}
                </div>
                <div
				  className={'evolution_sublabel'}
				  style={{
					fontColor: fontColor,
					fontSize: this.props.fontSize - 6,
				  }}
				>
                  {this.props.evolutionLabel}
                </div>
              </div>
			)}
          </button>
		</>
      );
	} else if (this.props.textCentered) {
      const showEvolution = this.props.evolutionLabel !== '' && this.props.evolutionText !== '' && this.props.width > 215 ? true : false;
	  return (
        <>
		  <button
            style={{
              width: this.props.width,
              height: this.props.height - 10,
              fontSize: this.props.fontSize,
			  marginLeft: 5,
			  paddingLeft: 0,
			  paddingRight: 0,
            }}
            key={this.props.id}
            className={isDark ? 'indicator_button_dark' : 'indicator_button'}
            onClick={this.handleUrl}
            title={this.props.title}
          >
			<div 
			  className={classInnerButton}
              style={{
				height: '100%',
				width: buttonWidth,
				borderRadius: showEvolution ? '10px 0px 0px 10px' : '10px',
              }}
			>
              <div 
			    className="indicator_container"
			  >
                {this.props.icon !== '' && this.props.icon !== '-' && (
			      <div className="icon_indicator">
                    <Icon name={this.props.icon} size={this.props.iconSize} />
                  </div>
			    )}
                <div className="label_wrapper">
				  <div className={indicator_label}>
                    <b>{this.props.text}</b>
				  </div>
                  <div
				    className="indicator_sublabel"
				    style={{
				      fontColor: fontColor,
					  fontSize: this.props.fontSize - 6,
				    }}
			      >
                    {this.props.result}
                  </div>
                </div>
			  </div>
			</div>
		    {showEvolution && (
			  <div 
			    className={isDark ? 'evolutionIndicatorWrapperH_dark' : 'evolutionIndicatorWrapperH'}
                style={{
				  width: '40%',
                }}
			  >
                <div 
				  className={isDark ? 'evolution_label_dark' : 'evolution_label'}
 				  style={{
					fontSize: this.props.fontSize,
					fontColor: labelColor,
				  }}
				>
                  {this.props.evolutionText}
                </div>
                <div
				  className={isDark ? 'evolution_sublabel_dark' : 'evolution_sublabel'}
				  style={{
					fontColor: fontColor,
					fontSize: this.props.fontSize - 6,
				  }}
				>
                  {this.props.evolutionLabel}
                </div>
              </div>
			)}
          </button>
		</>
      ); 
    }
  }
}

import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { AlarmThresholdManagerPanel } from './AlarmThresholdManagerPanel';
import { ThresholdManagerPanelOptions } from './types';

export const plugin = new PanelPlugin<ThresholdManagerPanelOptions>(AlarmThresholdManagerPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'thresholdAsset',
      path: 'thresholdAsset',
      name: 'Asset ID Field',
      defaultValue: 'asset',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdType',
      path: 'thresholdType',
      name: 'Threshold Type Field',
      description: 'The Threshold type. Pre-defined threshold types or custom/generic type',
      defaultValue: 'type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdIsBoolean',
      path: 'thresholdIsBoolean',
      name: 'Threshold boolean indicator Field',
      description: 'The Threshold is a boolean variable',
      defaultValue: 'boolean',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdState',
      path: 'thresholdState',
      name: 'Threshold State Field',
      description: 'The Threshold state. If the threshold if enable, this field must be true',
      defaultValue: 'state',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdPercentFail',
      path: 'thresholdPercentFail',
      name: 'Threshold Percent Fail Field',
      description: 'The Threshold percent fail in last 1 hour',
      defaultValue: 'fail',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdBelow',
      path: 'thresholdBelow',
      name: 'Threshold Up Field',
      description: 'Fire the alart when value is above or below threshold',
	  defaultValue: 'thresholdBelow',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdDual',
      path: 'thresholdDual',
      name: 'Dual Threshold Field',
      defaultValue: 'thresholdDual',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdUp',
      path: 'thresholdUp',
      name: 'Threshold Up Field',
      defaultValue: 'thresholdUp',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdDown',
      path: 'thresholdDown',
      name: 'Threshold Down Field',
      defaultValue: 'thresholdDown',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'notifRepeat',
      path: 'notifRepeat',
      name: 'Notification Repeat Field',
      defaultValue: 'notifRepeat',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdSource',
      path: 'thresholdSource',
      name: 'Threshold Source Field',
      defaultValue: 'source',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdDate',
      path: 'thresholdDate',
      name: 'Threshold Date Field',
      description: 'The date from threshold threshold is enabled or disabled',
      defaultValue: 'date',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'thresholdUser',
      path: 'thresholdUser',
      name: 'Last user who modified the threshold',
      description: 'The user who modified the threshold for the last time',
      defaultValue: 'user',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'variableToTrack',
      name: 'Main filter variable to Track',
      description: 'This field is used to determine if plugin need to be re-renderized',
      defaultValue: '',
      category: ['General Settings'],
    })
    .addTextInput({
      path: 'assetTypes',
      name: 'Asset List Types',
      category: ['General Settings'],
	  description: 'A comma separeted list respecting the following format: type||typeDescription',
      defaultValue: '',
    })
    .addTextInput({
      path: 'assetList',
      name: 'Asset List',
      category: ['General Settings'],
      description: 'A comma separeted list respecting the following format: assetID||assetDescription||assetType',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'thresholdTypes',
      name: 'Threshold Types List',
      category: ['General Settings'],
	  description: 'A comma separeted Threshold type list respecting the following format: label||description||value',
      defaultValue: '',
    })	
    .addTextInput({
      path: 'assetVariable',
      name: 'Asset Variable',
      category: ['General Settings'],
      description: 'Asset variable to modify when select an ID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'assetTypesVariable',
      name: 'Asset Types Variable',
      category: ['General Settings'],
      description: 'variable to modify when select differente asset Type',
	  defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showAssetSelector',
      name: 'show asset selector',
      description: 'Enable Asset selector inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Firmas customizadas - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Firma modificada exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

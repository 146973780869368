import { SelectableValue } from '@grafana/data';
import { CapitalizeFirstLetter } from '../utils';

export const enum RequestMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  QUERY = 'QUERY',
  NONE = '-',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export const RequestMethodInitialOptions: SelectableValue[] = [
  {
    value: RequestMethod.NONE,
    label: RequestMethod.NONE,
  },
  {
    value: RequestMethod.QUERY,
    label: RequestMethod.QUERY,
  },
  {
    value: RequestMethod.GET,
    label: RequestMethod.GET,
  },
];

export const RequestMethodUpdateOptions: SelectableValue[] = [
  {
    value: RequestMethod.NONE,
    label: RequestMethod.NONE,
  },
  {
    value: RequestMethod.DELETE,
    label: RequestMethod.DELETE,
  },
  {
    value: RequestMethod.PATCH,
    label: RequestMethod.PATCH,
  },
  {
    value: RequestMethod.POST,
    label: RequestMethod.POST,
  },
  {
    value: RequestMethod.PUT,
    label: RequestMethod.PUT,
  },
];

export const enum ContentType {
  JSON = 'application/json',
  PLAIN = 'text/plain',
}

export const ContentTypeOptions: SelectableValue[] = [
  { label: CapitalizeFirstLetter(ContentType.JSON), value: ContentType.JSON },
  { label: CapitalizeFirstLetter(ContentType.PLAIN), value: ContentType.PLAIN },
];

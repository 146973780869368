import React, { FC, useEffect, useRef } from 'react';
import { TemplateSrv, locationService, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import L, { LatLng } from 'leaflet';
import 'leaflet-kmz';
import './css/leaflet.css';
import { icons } from './Icons';
import {valueToSubtype } from './types';

interface MapComponentProps {
  items: Array<DynamicTableItemProps<T>>;
  currentNodes: [];
  onCoordinatesChange: (coords: [number, number], currentPlace: string) => void;
  onImportData: (newElements: []) => void;
  onSelectionChange: (uid: string) => void;
  markers: [];
  showMarkerIcons: boolean;
  mapSource: string;
  geoVariables: [boolean, string, string, string, string];
  readOnly: boolean;
  tableMode: boolean;
  isKmzAddMode: any;
}

export const MapComponent: FC<MapComponentProps> = ({ 
  items,
  currentNodes,
  onCoordinatesChange,
  onImportData,
  onSelectionChange,
  markers,
  showMarkerIcons,
  mapSource,
  geoVariables,
  readOnly,
  tableMode,
  isKmzAddMode
}) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const leafRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);
  const kmzRef = useRef<L.kmzLayer | null>(null);
  var leaf: any = leafRef.current;
  var marker: any = markerRef.current;
  var kmz: any = kmzRef.current;
  var control: any;
  var currentLayer: any;
  var objectBounds: [] = [];
  var selectionBounds: [] = [];

  const base = 'https://services.arcgisonline.com/ArcGIS/rest/services/';
  const arcgisUrl = `${base}${mapSource}/MapServer/tile/{z}/{y}/{x}`;
  const attribution = `Tiles © <a href="${base}${mapSource}/MapServer">ArcGIS</a>`;
  const initialZ = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[1]}`)) : 15;
  const initialX = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[2]}`)) : 0;
  const initialY = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[3]}`)) : 0;

  //console.log('Elemento seleccionado: ', currentNodes, markers, initialX, initialY, initialZ);

  const familyToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const getBoundsArea = (bounds) => {
	if (bounds !== null) {
	  const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      const width = Math.abs(northEast.lng - southWest.lng);
      const height = Math.abs(northEast.lat - southWest.lat);
      return width * height;
	} else {
	  return 0;
	}
  };

  const findLargestBounds = (boundsArray) => {
    const areas = boundsArray.map(getBoundsArea);
    const maxArea = Math.max(...areas);
    return boundsArray[areas.indexOf(maxArea)];
  };


  const mapOptions = {
	center: { lat: initialX, lng: initialY },
	zoom: initialZ,
	minZoom: 3,
	maxZoom: 19,
	zoomControl: false,
	boxZoom: true,
	scrollWheelZoom: true,
	touchZoom: true,
  };

  const toLatLng = (lat, lng) => [parseFloat(lat), parseFloat(lng)];

  const drawMarkers = (places: [], items: [], map: L.Map, controlLayer: any, layerName: string) => {
    const elementLayer = L.featureGroup({ interactive: true });
	const bounds = L.latLngBounds();
	const markerLines = new Map();
	const allLines = [];
	let originalPosition = null;
    if (!places || !map) {
	  return null;
	}
	places.forEach(place => {
      if (place) {
        const iconType = place.family;
        const family = familyToString(place.family);
        const icon = icons[iconType] || icons['defaultIcon'];
        if (!isNaN(place.lat) && !isNaN(place.lng)) {
		  const opacityValue = layerName === 'Seleccion' ? 1 : 0.5;
		  const dragable = layerName === 'Seleccion' && !readOnly ? true : false;
          if (showMarkerIcons) {
			const marker = L.marker([place.lat, place.lng], { 
			    icon: icon, 
				opacity: opacityValue, 
				draggable: dragable 
			  }).bindTooltip(`<b>${family}</b><br>${place.label}<br>${place.description}`);
            marker.id = place.elementId;

			const elementLatLng = toLatLng(place.lat, place.lng);
            bounds.extend(elementLatLng);

			const parentLatLng = place.destLat !== null && place.destLng !== null && place.destLat !== place.lat && place.destLng !== place.lng
              ? toLatLng(place.destLat, place.destLng)
              : null;
			if (parentLatLng !== null) {
			  const path = items.find(ele => ele.data.uid === place.pathId);
			  const family = path ? familyToString(path.data.subType) : 'Trayecto';
			  const title = path ? path.data.title : 'S/N';
              const line = L.polyline([elementLatLng, parentLatLng], { 
			      color: path ? path.data.style.color : '#00000080', 
				  weight: path ? path.data.style.weight : 1, 
				  opacity: path ? opacityValue : 0.25, 
				  dashArray: path ? path.data.style.dashArray : '2, 5',
			    }).bindTooltip(`<b>${family}</b><br>${title}`);
              line.id = place.pathId;
			  line.on('click', (event) => {
				if (geoVariables[4] && geoVariables[4] !== '') {
				  const queryMap = {
					[`var-${geoVariables[4]}`]: line.id,
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
					console.error(error);
				  }
				}
				if (!tableMode) {
				  onSelectionChange(line.id);
				}
			  });
			  markerLines.set(marker, line);
			  allLines.push(line); 
			  bounds.extend(parentLatLng);
			  elementLayer.addLayer(line);
           }
			marker.on('movestart', (event) => {
              originalPosition = event.target.getLatLng();
            });
			marker.on('moveend', (event) => {
			  let mustUpdate = false;
			  const newLatLng = event.target.getLatLng();
			  const line = markerLines.get(marker);
			  allLines.forEach(existingLine => {
				const latLngs = existingLine.getLatLngs();
				const isNotIconLine = line ? line._leaflet_id !== existingLine._leaflet_id : false;
				if (isNotIconLine || !line) {
				  if (latLngs[0] !== null) {
					if (latLngs[0].lat === originalPosition.lat && latLngs[0].lng === originalPosition.lng) {
					  latLngs[0] = newLatLng;
					  mustUpdate = true;
					}
				  }
				  if (!mustUpdate && latLngs[1] !== null) {
					if (latLngs[1].lat === originalPosition.lat && latLngs[1].lng === originalPosition.lng) {
					  latLngs[1] = newLatLng;
					  mustUpdate = true;
					}
				  }
				} else if (line) {
				  const oldLatLngs = line.getLatLngs();
				  line.setLatLngs([newLatLng, oldLatLngs[1]]);
				}
				if (mustUpdate) {
				  existingLine.setLatLngs(latLngs);
				  mustUpdate = false;
				}
			  });
			  if (!readOnly) {
			    onCoordinatesChange([newLatLng.lat.toFixed(7), newLatLng.lng.toFixed(7)], marker.id);
			  }
            });
			marker.on('click', (event) => {
			  if (geoVariables[4] && geoVariables[4] !== '') {
			    const queryMap = {
			  	  [`var-${geoVariables[4]}`]: marker.id,
				};
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(marker.id);
			  }
            });
		    if (layerName === 'Seleccion') {
			  marker.on('moveend', function (event) {
				const newCoords = event.target.getLatLng();
				if (!readOnly) {
				  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], marker.id);
				}
			  });
			}
			marker.addTo(map);
			//bounds.extend([place.lat, place.lng]);
			elementLayer.addLayer(marker);
          }
        } else {
          console.error(`Invalid coordinates for place: ${place.label}`);
        }
      }
    });
	elementLayer.addTo(map);
	controlLayer.addOverlay(elementLayer, layerName);
	return bounds;
  };

  const drawPlaces = (places: [], map: L.Map, controlLayer: any, layerName: string) => {
	const markerLayer = L.featureGroup();
	const bounds = L.latLngBounds();
    places.forEach(place => {
      if (place.data.elementType == 'emp') {
        const family = familyToString(place.data.subType);
        const icon = icons['emp'];
        if (!isNaN(place.data.coordinates[0]) && !isNaN(place.data.coordinates[1])) {
		  const opacityValue = 0.75;
		  const dragable = false;
		  const marker = L.marker(place.data.coordinates, { 
		    color: place.data.style.color ? place.data.style.color : '#000000',
			icon: icon, 
			opacity: opacityValue, 
			draggable: dragable 
		  }).bindTooltip(`<b>Emplazamiento - </>${family}</b><br>${place.data.idx}<br>${place.data.title}`);
          marker.id = place.data.uid;
		  marker.on('click', (event) => {
			if (geoVariables[5] && geoVariables[5] !== '' && geoVariables[4] && geoVariables[4] !== '') {
			  const queryMap = {
				[`var-${geoVariables[4]}`]: marker.id,
				[`var-${geoVariables[5]}`]: marker.id,
			  };
			  try {
				locationService.partial(queryMap, true);
			  } catch (error) {
				console.error(error);
			  }
			}
			if (!tableMode) {
			  onSelectionChange(marker.id);
			}
		  });
		  markerLayer.addLayer(marker);
		  bounds.extend(place.data.coordinates);
        } else {
          console.log(`Invalid coordinates for place: ${place.data.title}`);
        }
      }
    });
	markerLayer.addTo(map);
    controlLayer.addOverlay(markerLayer, layerName);
	return bounds;
  };

  const drawPaths = (routes, items, map, controlLayer, layerName: string, lineType: string) => {
	const routeLayer = L.featureGroup();
	const bounds = L.latLngBounds();
    routes.forEach(route => {
      const family = familyToString(route.data.subType);
	  const coordinates = route.data.coordinates ? route.data.coordinates : [];
      if (coordinates.length >= 2) {
        for (let i = 0; i < coordinates.length - 1; i++) {
          const start = coordinates[i];
          const end = coordinates[i + 1];
          if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
            const line = L.polyline([start, end], {
              color: route.data.style.color,
              weight: 2,
              opacity: 0.8,
              dashArray: '5, 10',
            }).bindTooltip(`${family}<br>${route.data.title}`);
            line.id = route.data.uid;
			line.on('click', (event) => {
			  if (geoVariables[4] && geoVariables[4] !== '') {
				const queryMap = {
				  [`var-${geoVariables[4]}`]: line.id,
				};
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(line.id);
			  }
			});
			routeLayer.addLayer(line);
			bounds.extend(start);
            bounds.extend(end);
          } else {
            console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
          }
        }
      } else if (route.data.destination !== '' && route.data.origin !== '') {
		const origin = items.find(item => item.data.idx === route.data.origin);
		const destination = items.find(item => item.data.idx === route.data.destination);
		if (origin && destination) {
		  const start = origin.data.coordinates;
          const end = destination.data.coordinates;
          if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
            const line = L.polyline([start, end], {
              color: route.data.style.color,
              weight: 2,
              opacity: 0.8,
              dashArray: '5, 10',
            }).bindTooltip(`<b>${family}</b><br>${route.data.title}`);
            line.id = route.data.uid; 
			line.on('click', (event) => {
			  if (geoVariables[4] && geoVariables[4] !== '') {
				const queryMap = {
				  [`var-${geoVariables[4]}`]: line.id,
				};
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(line.id);
			  }
			});
			routeLayer.addLayer(line);
			bounds.extend(start);
            bounds.extend(end);
          } else {
            console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
          }
		}
	  }
    });
    routeLayer.addTo(map);
    controlLayer.addOverlay(routeLayer, layerName);
	return bounds;
  };

  useEffect(() => {
	if (!leaf) {
	  leaf = L.map(mapRef.current!, mapOptions);
      L.tileLayer(arcgisUrl, {
		attribution: attribution,
		minZoom: 3,
		maxZoom: 19,
	  }).addTo(leaf);

	  currentLayer = L.featureGroup();
	  control = L.control.layers(null, null, { collapsed:false }).addTo(leaf);

	  leaf.on('click', (e) => {
		if (!tableMode) {
		  onSelectionChange(null);
		}
		if (geoVariables[5] && geoVariables[5] !== '' && geoVariables[4] && geoVariables[4] !== '') {
		  const queryMap = {
			[`var-${geoVariables[4]}`]: '',
			[`var-${geoVariables[5]}`]: '',
		  };
		  try {
			locationService.partial(queryMap, true);
		  } catch (error) {
			console.error(error);
		  }
		}
	  });

	  if (isKmzAddMode) {
	    isKmzAddMode.onload = (event) => {
		  const base64KMZData = isKmzAddMode.result;
		  const byteCharacters = atob(base64KMZData.split(',')[1]);
		  const byteNumbers = new Array(byteCharacters.length);
		  for (let i = 0; i < byteCharacters.length; i++) {
		    byteNumbers[i] = byteCharacters.charCodeAt(i);
		  }
		  const byteArray = new Uint8Array(byteNumbers);
		  const blob = new Blob([byteArray], { type: 'application/vnd.google-earth.kmz' });
		  const blobUrl = URL.createObjectURL(blob);

	      kmz = L.kmzLayer().addTo(leaf);
	      kmz.on('load', function (e) {
			const layerGroup = e.layer;
			const geoJSONData = layerGroup.toGeoJSON();
			const elementArray = geoJSONData.features;
			onImportData(elementArray);
	      });
		  kmz.load(blobUrl);
		}
	  }
	  const hasCurrentNode = (currentNodes[0] && currentNodes[0] !== null && currentNodes[0].uid !== null) ? true : false;

	  console.log(hasCurrentNode, currentNodes, showMarkerIcons, markers, items);	  
	  if (!showMarkerIcons && hasCurrentNode) {
		let nodesCount = 0;
	    currentNodes.forEach(current => {
		  if (current.uid !== null && current.coordinates.length > 0) {
		    const family = familyToString(current.subType);
		    marker = L.marker(toLatLng(current.coordinates[0], current.coordinates[1]), {
			  icon: icons['current'], 
			  draggable: !readOnly 
			}).bindTooltip(`<b>${family}</b><br>${current.elementId}<br>${current.title}`);
			nodesCount = nodesCount + 1;
			currentLayer.addLayer(marker);
		  }
		});

	    if (nodesCount > 0) {
		  marker.on('moveend', function (e) {
            const newCoords = e.target.getLatLng();
		    if (!readOnly) {
			  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], null);
			}
          });
		}
		currentLayer.addTo(leaf);
		control.addOverlay(currentLayer, 'Selección actual');
		objectBounds[0] = currentLayer.getBounds();
	  }

	  const isPlace = hasCurrentNode && (currentNodes[0].elementType === 'emp' || currentNodes[0].elementType === 'element') ? true : false;
	  const reference = hasCurrentNode ? currentNodes[0].title.slice(0, 20) : 'Seleccion actual';
	  const coordsX = geoVariables[0] && isPlace && currentNodes[0].coordinates.length > 0 ? currentNodes[0].coordinates[0] : initialX;
	  const coordsY = geoVariables[1] && isPlace && currentNodes[0].coordinates.length > 0 ? currentNodes[0].coordinates[1] : initialY;
	  const currentPathId = hasCurrentNode ? currentNodes[0].pathId : 'No pathId';
	  const currentUid = hasCurrentNode ? currentNodes[0].uid : 'No nodeId';
	  const currentDestination = hasCurrentNode && !isPlace ? currentNodes[0].destination : 'No toId';
	  const currentOrigin = hasCurrentNode && !isPlace ? currentNodes[0].origin : 'No fromId';

	  const currentPathRelations = markers.length > 0 ? 
	    markers.filter(value => value.pathId === currentPathId) : [];
	  const restOfRelations = markers.length > 0 ? 
	    markers.filter(value => value.pathId !== currentPathId) : [];	  

	  if (items) {
		  const currentPaths = items.length > 0 ? 
			items.filter(value => value.data.uid === currentPathId && value.data.elementType === 'path') : [];
		  const restOfPaths = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentPathId && value.data.elementType === 'path' && !value.data.wasChange) : [];
		  const newPaths = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentPathId && value.data.elementType === 'path' && value.data.wasChange) : [];

		  const currentPathElements = items.length > 0 ? 
			items.filter(value => value.data.pathId === currentPathId && value.data.elementType === 'element') : [];
		  const restOfElements = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentUid && value.data.elementType === 'element' && !value.data.wasChange) : [];
		  const newElements = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentUid && value.data.elementType === 'element' && value.data.wasChange) : [];

		  const currentEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && !value.data.wasChange && 
			  (value.data.uid === currentDestination || value.data.uid === currentOrigin)) : [];
		  const restOfEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && !value.data.wasChange && 
			  value.data.uid !== currentDestination && value.data.uid !== currentOrigin) : [];
		  const newEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && value.data.wasChange && 
			  value.data.uid !== currentDestination && value.data.uid !== currentOrigin) : [];
			  
		  const currentSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') &&
			  value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') && 
			  value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') && 
			  value.data.uid === currentPathId && value.data.wasChange) : [];

	      selectionBounds[0] = currentPathRelations.length > 0 ? drawMarkers(currentPathRelations, items, leaf, control, reference) : null;
	      selectionBounds[1] = restOfRelations.length > 0 ? drawMarkers(restOfRelations, items, leaf, control, 'Relaciones') : null;

		  selectionBounds[2] = currentPaths.length > 0 ? drawPaths(currentPaths, items, leaf, control, reference) : null;
		  objectBounds[1] = (restOfPaths.length > 0 || newPaths.length > 0) ? 
			drawPaths([...restOfPaths, ...newPaths], items, leaf, control, 'Otros trayectos') : null;				

		  selectionBounds[3] = currentEmps.length > 0 ? drawPlaces(currentEmps, leaf, control, reference) : null;
		  objectBounds[2] = (restOfEmps.length > 0 || newEmps.length > 0) ? 
			drawPlaces([...restOfEmps, ...newEmps], leaf, control, 'Otros emplazamientos') : null;

		  selectionBounds[4] = currentSpans.length > 0 ? drawPaths(currentSpans, items, leaf, control, reference) : null;
		  objectBounds[3] = (restofSpans.length > 0 || newSpans.length > 0) ? 
			drawPaths([...restofSpans, ...newSpans], items, leaf, control, 'Otros tramos') : null;	

		  selectionBounds[5] = currentLinks.length > 0 ? drawPaths(currentLinks, items, leaf, control, reference) : null;
		  objectBounds[4] = (restofLinks.length > 0 || newLinks.length > 0) ? 
			drawPaths([...restofLinks, ...newLinks], items, leaf, control, 'Otros enlaces') : null;	
			
		  selectionBounds[6] = currentSegments.length > 0 ? drawPaths(currentSegments, items, leaf, control, reference) : null;
		  objectBounds[5] = (restofSegments.length > 0 || newSegments.length > 0) ? 
			drawPaths([...restofSegments, ...newSegments], items, leaf, control, 'Otros segmentos') : null;	

		  selectionBounds[7] = currentUnions.length > 0 ? drawPlaces(currentUnions, leaf, control, reference) : null;
		  objectBounds[6] = (restofUnions.length > 0 || newUnions.length > 0) ? 
			drawPaths([...restofUnions, ...newUnions], items, leaf, control, 'Otros eventos') : null;	
	  } else {
	    const currentItem = [];
		const newItem = {
		  data: currentNodes[0],
		  id: 0,
		}
		currentItem.push(newItem);
		selectionBounds[0] = currentPathRelations.length > 0 ? drawMarkers(currentPathRelations, currentItem, leaf, control, reference) : null;
	    selectionBounds[1] = restOfRelations.length > 0 ? drawMarkers(restOfRelations, currentItem, leaf, control, 'Otros') : null;
	  }
	  const selectionFiltered = selectionBounds.filter(item => item && Object.keys(item).length > 0);
	  const boundsFiltered = objectBounds.filter(item => item && Object.keys(item).length > 0);
	  const largestBounds = hasCurrentNode && selectionBounds[0] ? 
	    findLargestBounds(selectionFiltered) : findLargestBounds([...selectionFiltered, ...boundsFiltered]);
	  if (largestBounds) {
	    leaf.fitBounds(largestBounds);
	  } else {
	    leaf.flyTo(toLatLng(coordsX, coordsY), initialZ);
	  }
    }

    return () => {
	  leaf?.remove();
    };
  }, [currentNodes, items, markers, arcgisUrl, geoVariables, isKmzAddMode]);

  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
  );
};

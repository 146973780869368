import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { config } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Modal, Button, Label, useStyles2, RadioButtonGroup, Icon } from '@grafana/ui';
import { TestReceiversAlert } from 'app/plugins/datasource/alertmanager/types';
import { Annotations, Labels } from 'app/types/unified-alerting-dto';

import AnnotationsField from './AnnotationsField';
import LabelsField from './LabelsField';

interface Props {
  isOpen: boolean;
  onDismiss: () => void;
  onTest: (alert?: TestReceiversAlert) => void;
}

type AnnoField = {
  key: string;
  value: string;
};

interface FormFields {
  annotations: AnnoField[];
  labels: AnnoField[];
}

enum NotificationType {
  predefined = 'Pre-definido',
  custom = 'Personalizado',
}

const notificationOptions = Object.values(NotificationType).map((value) => ({ label: value, value: value }));

const defaultValues: FormFields = {
  annotations: [{ key: '', value: '' }],
  labels: [{ key: '', value: '' }],
};

export const TestContactPointModal = ({ isOpen, onDismiss, onTest }: Props) => {
  const isDark = config.theme.isDark;
  const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.predefined);
  const styles = useStyles2(getStyles);
  const formMethods = useForm<FormFields>({ defaultValues, mode: 'onBlur' });

  const onSubmit = (data: FormFields) => {
    if (notificationType === NotificationType.custom) {
      const alert = {
        annotations: data.annotations
          .filter(({ key, value }) => !!key && !!value)
          .reduce((acc, { key, value }) => {
            return { ...acc, [key]: value };
          }, {} as Annotations),
        labels: data.labels
          .filter(({ key, value }) => !!key && !!value)
          .reduce((acc, { key, value }) => {
            return { ...acc, [key]: value };
          }, {} as Labels),
      };
      onTest(alert);
    } else {
      onTest();
    }
  };

  return (
    <Modal onDismiss={onDismiss} isOpen={isOpen} title={'Probar canal de comunicación'}>
      <div className={styles.sectionLabel}>
        <Label>Mensaje de prueba</Label>
      </div>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          {notificationType === NotificationType.predefined && (
            <div className={styles.section}>
              NetMonitor enviará una notificación de prueba utilizando un tipo de alerta pre-definida y la configuración ingresada.
            </div>
          )}
          <Modal.ButtonRow>
			<button 
			  type="submit" 
			  className={isDark ? styles.button_dark : styles.button_light} 
			  title={'Enviar mensaje de prueba'}
			>
			  <div className={styles.button_icon}>
				<Icon name="envelope-alt" size="lg" />
			  </div>
			  Enviar Mensaje
			</button>
          </Modal.ButtonRow>
        </form>
      </FormProvider>
    </Modal>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  flexRow: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: ${theme.spacing(1)};
  `,
  sectionLabel: css`
    font-size: 12px;
	font-weight: 500;
	margin-bottom: ${theme.spacing(2)};
  `,
  section: css`
    font-size: 11px;
	margin-bottom: ${theme.spacing(2)};
  `,
  button_light: css`
    background-color: #6C63FE;
    border: 1px solid #EFF4FA;
	box-shadow: 4px 2px 4px 0px #D8DFE9;
    padding: 0px 10px 0px 0px;
    color: white;
    border-radius: 10px;
    vertical-align: middle;
	align-items: center;
    justify-content: center;
    display: inline-flex;
    align-content: center;
    cursor: pointer;
    max-width: 150px;
    min-width: 32px;
    margin-left: 3px;
    margin-right: 3px;
	height: 24px;
  `,
  button_dark: css`
    background-color: #557FFF;
    border: 1px solid #1B2733;
	box-shadow: 1px 2px 5px 0px #585A5E;
    padding: 0px 10px 0px 0px;
	color: white;
    border-radius: 10px;
    vertical-align: middle;
	align-items: center;
    justify-content: center;
    display: inline-flex;
    align-content: center;
    cursor: pointer;
    max-width: 150px;
    min-width: 32px;
    margin-left: 3px;
    margin-right: 3px;
	height: 24px;
  `,
  button_icon: css`
    border: none;
    min-width: 24px;
	margin-right: 5px;
  `,
});

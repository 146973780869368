import { DataFrame } from '@grafana/data';

export interface PanelSettings {
  style: PanelStyleSettings;
  filterEmptyConnections: boolean;
  showBaselines: boolean;
  allCollapsed: boolean;
  initBlock: boolean;
  showConnectionStats: boolean;
  showSLA: boolean;
  showSysInfo: boolean;
  showToolbar: boolean;
  showStatTables: boolean;
  showEdgeStatus: boolean;
  isStpTopology: boolean;
  isL1Topology: boolean;
  useTrafficMetric: boolean;
  useMetricOne: boolean;
  useMetricTwo: boolean;
  icons: IconResource[];
  externalIcons: IconResource[];
  dataMapping: DataMapping;
  drillDownLink_asset: string;
  drillDownLink_site: string;
  nodeTitle: string;
  nodeHeader: string;
  sysHeader: string;
  edgeTitle: string;
  edgeHeader: string;
  layoutFormat: string;
  initialCyZoom: number;
  addMap: boolean;
  addMapVariable: string;
  mapSource: string;
  scaleNodes: boolean;
  hideThreshold: number;
  hideNodes: boolean;
  initialZoom: number;
  initialLat: number;
  initialLon: number;
  useZoomCyVariable: boolean;
  zoomCyVariable: string;
  latVariable: string;
  lonVariable: string;
  useZoomVariable: boolean;
  zoomVariable: string;
  xVariable: string;
  yVariable: string;
  map: string;
  api: string;
  groupVariable: string;
  nodeVariable: string;
  edgeVariable: string;
  iconShape: string;
  nodeShape: string;
  error2: string;
}

export interface DataMapping {
  isRoot: string;
  xColumn: string;
  yColumn: string;
  latColumn: string;
  lonColumn: string;
  nodeDescriptionColumn: string;
  labelColumn: string;
  siteColumn: string;
  nodeID: string;
  sourceColumn: string;
  targetColumn: string;
  labelOutColumn: string;
  siteOutColumn: string;
  trafficColumn: string;
  trafficOutColumn: string;
  metricOneColumn: string;
  metricOneActualColumn: string;
  metricOneThresholdColumn: string;
  metricTwoColumn: string;
  metricTwoActualColumn: string;
  metricTwoThresholdColumn: string;
  extOrigin: string;
  extTarget: string;
  nodeSla: string;
  nodeGroup: string;
  nodeIcon: string;
  nodeType: string;
  nodeVisible: string;
  descriptionColumn: string;
  userColumn: string;
}

export interface PanelStyleSettings {
  healthyThreshold: number;
  warningThreshold: number;
  healthyColor: string;
  warningColor: string;
  dangerColor: string;
  noDataColor: string;
}

export interface IconResource {
  pattern: string;
  filename: string;
  node_title: string;
  node_header: string;
  links_title: string;
  links_header: string;
}

export interface QueryResponseColumn {
  type?: string;
  text: string;
}

export interface CyData {
  group: string;
  data: {
    id: string;
    label?: string;
    site?: string;
    source?: string;
    target?: string;
    metrics: IntGraphMetrics;
    type?: string;
    user?: string;
    external_type?: string;
    node_sla?: number;
    parent?: string;
    node_icon?: string;
    node_visible?: boolean;
    node_description?: string;
    description?: string;
  };
  position: {
    x?: number;
    y?: number;
    lat?: number;
    lng?: number;
  };
  classes: string;
}

export interface CurrentData {
  graph: GraphDataElement[];
  raw: DataFrame[];
  columnNames: string[];
}

export interface GraphDataElement {
  x: number;
  y: number;
  lat: number;
  lng: number;
  label: string;
  site: string;
  source?: string;
  target: string;
  node_sla: number;
  parent: string;
  node_icon: string;
  node_visible: boolean;
  node_description: string;
  data: DataElement;
  type: string;
  user: string;
}

export interface DataElement {
  target_label?: string;
  target_site?: string;
  traffic_in?: number;
  traffic_out?: number;
  metricOne_max?: number;
  metricOne_actual?: number;
  metricOne_threshold?: number;
  metricTwo_max?: number;
  metricTwo_actual?: number;
  metricTwo_threshold?: number;
  type?: string;
  user?: string;
  node_sla?: number;
  parent?: string;
  node_icon?: string;
  node_visible?: boolean;
  node_description?: string;
  description?: string;
}

export enum GraphDataType {
  SELF = 'SELF',
  INTERNAL = 'INTERNAL',
  EXTERNAL_OUT = 'EXTERNAL_OUT',
  EXTERNAL_IN = 'EXTERNAL_IN',
  GROUP_COL = 'GROUP_COL',
  GROUP_EXP = 'GROUP_EXP',
}

export interface IntGraph {
  nodes: IntGraphNode[];
  edges: IntGraphEdge[];
}

export interface IntGraphNode {
  data: IntGraphNodeData;
}

export interface IntGraphNodeData {
  x: number;
  y: number;
  lat: number;
  lng: number;
  label: string;
  site: string;
  id: string;
  type: string;
  user: string;
  metrics?: IntGraphMetrics;
  external_type?: string;
  node_sla: number;
  parent: string;
  node_icon: string;
  node_visible: boolean;
  node_description: string;
}

export interface IntGraphMetrics {
  description?: string;
  target_label?: string;
  target_site?: string;
  metricOne_max?: number;
  metricOne_actual?: number;
  metricOne_threshold?: number;
  metricTwo_max?: number;
  metricTwo_actual?: number;
  metricTwo_threshold?: number;
  traffic_in?: number;
  traffic_out?: number;
}

export interface IntGraphEdge {
  x: number;
  y: number;
  lat: number;
  lng: number;
  label: string;
  site: string;
  source: string;
  target: string;
  node_sla: number;
  parent: string;
  node_description: string;
  node_icon: string;
  node_visible: boolean;
  type: string;
  user: string;
  data: IntGraphEdgeData;
  metrics?: IntGraphMetrics;
}

export interface IntGraphEdgeData {
  x: number;
  y: number;
  lat: number;
  lng: number;
  label: string;
  site: string;
  source: string;
  target: string;
  node_sla: number;
  node_description: string;
  node_icon: string;
  node_visible: boolean;
  metrics?: IntGraphMetrics;
}

export interface Particle {
  velocity: number;
  startTime: number;
}

export interface Particles {
  normal: Particle[];
  danger: Particle[];
}

export interface TableContent {
  name: string;
  label: string;
  site: string;
  traffic_in: string;
  traffic_out: string;
  metricOneMax: string;
  metricTwoMax: string;
  metricTwoActual: string;
  metricOneActual: string;
}

export interface TableHeader {
  name_header: string;
  label_header: string;
  site_header: string;
  traffic_header: string;
  metricOne_header: string;
  metricTwo_header: string;
}

export interface Table2Content {
  name: string;
  label: string;
  source_port: string;
  source_lag: string;
  target_label: string;
  target_port: string;
  target_lag: string;
  stat: string;
}

export interface Table2Header {
  label_header: string;
  source_port_header: string;
  source_type_header: string;
  target_label_header: string;
  target_port_header: string;
  target_type_header: string;
  status_header: string;
}

export interface Table3Content {
  sysDescription: string;
  sysTime: string;
  sysLocation: string;
}

export interface Table3Header {
  description_header: string;
  time_header: string;
  location_header: string;
}

export interface IntSelectionStatistics {
  description?: string;
  target_label?: string;
  target_site?: string;
  metricOne_max?: number;
  metricOne_threshold?: number;
  metricOne_actual?: number;
  metricTwo_actual?: number;
  traffic_in?: number;
  traffic_out?: number;
  thresholdViolation?: boolean;
}

export interface CyCanvas {
  getCanvas: () => HTMLCanvasElement;
  clear: (arg0: CanvasRenderingContext2D) => void;
  resetTransform: (arg0: CanvasRenderingContext2D) => void;
  setTransform: (arg0: CanvasRenderingContext2D) => void;
}

export interface IntTableHeader {
  text: string;
  dataField: string;
  isKey?: boolean;
  sort: boolean;
  headerClasses?: string;
  footerClasses?: string;
  classes?: string;
  sortFunc?: (a: string, b: string, order: string, _dataField: any, _rowA: any) => number;
  ignoreLiteral?: string;
}

export interface NodeData {
  name: string;
  time: string;
  label: string;
  site: string;
  metricOne_max: string;
  metricOne_actual: string;
  metricTwo_actual: string;
}

export interface ScaleValue {
  unit: string;
  factor: number;
}

export interface DrawContext {
  ctx: CanvasRenderingContext2D;
  now: number;
  xDirection: number;
  yDirection: number;
  xMinLimit: number;
  xMaxLimit: number;
  yMinLimit: number;
  yMaxLimit: number;
  sourcePoint: cytoscape.Position;
}

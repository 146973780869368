import { css, cx } from '@emotion/css';
import React, { FC } from 'react';

import { colorManipulator } from '@grafana/data';
import { useTheme2, styleMixins } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/netmonitor_icon.svg" alt="NetMonitor" />;
};
const LoginSubLogo: FC<BrandComponentProps> = ({ className }) => {
  const theme = useTheme2();

  if (theme.isDark) {
    return <img className={className} src="public/img/netmonitor_net_white.svg"/>;
  } else {
    return <img className={className} src="public/img/netmonitor_net_blue.svg"/>;
  }
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const theme = useTheme2();

  const background = css`
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url(public/img/netmonitor_login_background_${theme.isDark ? 'blue' : 'white'}.svg);
      background-position: top center;
      background-size: auto;
      background-repeat: no-repeat;
      opacity: 1;
      z-index: 0;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.md)} {
        background-position: center;
        background-size: cover;
      }
    }
  `;

  const svgContainer = css`
    &:before {
	  display: flex;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
	}
  `;

  return (
    <div className={cx(background, className)}>
      <div className={cx(svgContainer, className)}>
        <object
          type="image/svg+xml"
          data={`public/img/netmonitor_login_${theme.isDark ? 'blue' : 'white'}.svg`}
          style={{ width: '100%', height: '100%' }}
        >
        </object>
      </div>
      {children}
    </div>
  );
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/netmonitor_icon.svg" alt="NetMonitor" />;
};

const LoginBoxBackground = () => {
  const theme = useTheme2();
  return css`
    background: ${colorManipulator.alpha(theme.colors.background.primary, 0.85)};
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginSubLogo = LoginSubLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'NetMonitor';
  static LoginTitle = 'Por favor ingrese sus credenciales';
  static GetLoginSubTitle = (): null | string => {
    return null;
  };
}
